import React from 'react';

import CoreFormElementInputBase from './BaseInput';
import { FormElementSyncRestProps, FormUniqueKey } from '../../factories';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, TEXT_TYPE_AREA } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Text Area Element
 * @export
 * @class CoreFormElementTextArea
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementTextArea extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementTextArea
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: TEXT_TYPE_AREA
  });

  /**
   * Render
   * @returns
   * @memberof CoreFormElementTextArea
   */
  public render() {
    const { OnChangeOrInput, OnBlur, props } = this;
    const { type, name, value, options, onChange, onInput, onBlur, ...rest } = props;
    const synced = FormElementSyncRestProps(type, rest);
    const key = FormUniqueKey(type, name, value);
    return <textarea key={key} name={name} defaultValue={value as string} onInput={OnChangeOrInput} onBlur={OnBlur} {...synced}  />
  }
}
