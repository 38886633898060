import React, { Component } from 'react';

import { CoreFormConsumer, CoreFormConsumerTypeEnum } from '../../forms/';

import { CoreListModalKidsProps } from '../interfaces/';

/**
 * List Modal Kids
 * @export
 * @class CoreListModalKidsContainer
 * @extends {Component<CoreListModalKidsProps, {}>}
 */
export default class CoreListModalKidsContainer extends Component<CoreListModalKidsProps, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreListModalKidsContainer
   */
  public render() {
    const { dialog } = this.props;

    return (
      <CoreFormConsumer type={CoreFormConsumerTypeEnum.FORM}>
        {({ form, values: { valid } }) => {

          /* istanbul ignore next */
          dialog.source = () => {
            return form.source;
          };

          /* istanbul ignore next */
          if (dialog.valid !== valid) {
            dialog.valid = valid;
            dialog.activate();
          }

          return null;
        }}
      </CoreFormConsumer>
    );
  }
}
