import React, { MouseEvent } from 'react';
import CoreFormMenuBase from './Base';

import MenuToggle from './Toggle';
import MenuAllOrNone from './AllOrNone';
import MenuFilter from './Filter';
import MenuOptions from './Options';
import MenuSelected from './Selected';

import { ControllerMenuProps } from '../../../../interfaces/';
import { MergeClassNames, WaitForDelay } from '../../../../../../../utils/';

/**
 * Controller Menu Container
 * @export
 * @class ControllerMenuContainer
 * @extends {CoreFormMenuBase<ControllerMenuProps>}
 */
export default class ControllerMenuContainer extends CoreFormMenuBase<ControllerMenuProps> {
  /**
   * Default Props
   * @static
   * @type {ControllerMenuProps}
   * @memberof ControllerMenuContainer
   */
  public static defaultProps: Omit<ControllerMenuProps, 'controller' | 'name' | 'options' | 'filter' | 'allornone'> = { tabIndex: 0 };

  /**
   * Activated
   * @private
   * @type {boolean}
   * @memberof ControllerMenuContainer
   */
  private activated: boolean = false;

  /**
   * Render
   * @returns
   * @memberof ControllerMenuContainer
   */
  public render() {
    const { OnMouseLeave, OnMouseEnter, context: { active }, props } = this;
    const clz = MergeClassNames('', { active });
    return (
      <dl className={clz} onMouseLeave={OnMouseLeave} onMouseEnter={OnMouseEnter}>
        <dt>
          <MenuToggle {...props} />
        </dt>
        <MenuSelected />
        <dd className="options">
          <MenuFilter />
          <MenuAllOrNone />
          <MenuOptions />
        </dd>
      </dl>
    );
  }

  /**
   * Menu Event Listener
   * @protected
   * @memberof CoreFormMenuBase
   */
  /* istanbul ignore next */
  protected MenuEventListener = (value: any[], options: CoreFormOptionsType, active: boolean, term: string): void => {
    if (active !== this.activated) {
      this.activated = active;
      WaitForDelay().then(() => {
        if (!this.listener.signal.aborted) {
          this.forceUpdate();
        }
      });
    }
  };

  /**
   * On Mouse Enter
   * @protected
   * @param {MouseEvent<HTMLDListElement>} event
   * @memberof ControllerMenuContainer
   */
  protected OnMouseEnter = (event: MouseEvent<HTMLDListElement>): void => {
    if (this.listener) {
      this.listener.abort();
    }
    this.listener = new AbortController();
  };

  /**
   * On Mouse Leave
   * @protected
   * @param {MouseEvent<HTMLDListElement>} event
   * @memberof ControllerMenuContainer
   */
  protected OnMouseLeave = (event: MouseEvent<HTMLDListElement>): void => {
    const { listener, context: { ToggleActive, active } } = this;
    /* istanbul ignore next */
    WaitForDelay(500).then(() => {
      if (!listener.signal.aborted) {
        if (active) {
          ToggleActive();
        }
      }
    });
  };
}
