import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTablePaginationLink } from '../components/';
import { CoreTableFooterProps } from '../interfaces/';
import { CoreFooterLinksFactory } from '../factories/';
import { CoreTablePaginationStepEnum } from '../enums/';

import { TABLE_FOOTER_PROPS } from '../constants';

/**
 * Table Pagination Footer Container
 * @export
 * @class CoreTablePaginationFooterContainer
 * @extends {Component<CoreTableFooterProps, {}>}
 */
export default class CoreTablePaginationFooterContainer extends Component<CoreTableFooterProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTablePaginationFooterContainer
   */
  public static displayName = 'CoreTablePaginationFooter';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTablePaginationFooterContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableFooterProps}
   * @memberof CoreTablePaginationFooterContainer
   */
  public static defaultProps: CoreTableFooterProps = cloneDeep(TABLE_FOOTER_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreTablePaginationFooterContainer
   */
  public componentDidMount() {
    this.context.subscribe(CoreTablePaginationFooterContainer.displayName, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTablePaginationFooterContainer
   */
  public componentWillUnmount() {
    this.context.unsubscribe(CoreTablePaginationFooterContainer.displayName);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTablePaginationFooterContainer
   */
  public render() {
    const { context, pages, props, handler, exceeds, cantStepBack, cantStepForward } = this;
    const { threshold } = props;
    const { pagination, columns } = context;
    const { pageable, page, limit, count } = pagination;

    if (!pageable || count <= limit) {
      return null;
    }

    const colSpan = columns.count;

    const links = CoreFooterLinksFactory(page, pages, threshold||limit);

    return (
      <tfoot>
        <tr>
          <td colSpan={colSpan} className="footer-pagination">
            <ul>
              <li>
                <button type="button" title="first" onClick={handler(CoreTablePaginationStepEnum.START)} disabled={cantStepBack} />
              </li>
              <li>
                <button type="button" title="previous" onClick={handler(CoreTablePaginationStepEnum.BACK)} disabled={cantStepBack} />
              </li>
              {exceeds ? (
                <li>
                  <button type="button" title="previous group" onClick={handler(CoreTablePaginationStepEnum.BACK_GROUP)} disabled={cantStepBack} />
                </li>
              ) : null}
              {links.map((page: number, ndx: number) => (
                <CoreTablePaginationLink key={`tpl.${ndx}`} page={page} />
              ))}
              {exceeds ? (
                <li>
                  <button type="button" title="next group" onClick={handler(CoreTablePaginationStepEnum.FORWARD_GROUP)} disabled={cantStepForward} />
                </li>
              ) : null}
              <li>
                <button type="button" title="next" onClick={handler(CoreTablePaginationStepEnum.FORWARD)} disabled={cantStepForward} />
              </li>
              <li>
                <button type="button" title="last" onClick={handler(CoreTablePaginationStepEnum.END)} disabled={cantStepForward} />
              </li>
            </ul>
          </td>
        </tr>
      </tfoot>
    );
  }

  /**
   * Handler
   * @protected
   * @param {string} action
   * @returns {(evt: any) => void}
   * @memberof CoreTablePaginationFooterContainer
   */
  protected handler = (action: string): ((evt: any) => void) => {
    const { props, context, pages } = this;
    const { threshold } = props;
    const { page } = context.pagination;

    return (evt: any): void => {
      let selected = null;

      /* istanbul ignore next */
      switch (action) {
        case CoreTablePaginationStepEnum.START:
          selected = 0;
          break;
        case CoreTablePaginationStepEnum.BACK_GROUP:
          selected = page - 1;
          while (!!(selected % threshold)) {
            selected--;
          }
          break;
        case CoreTablePaginationStepEnum.BACK:
          selected = page - 1;
          break;
        case CoreTablePaginationStepEnum.FORWARD:
          selected = page + 1;
          break;
        case CoreTablePaginationStepEnum.END:
          selected = pages - 1;
          break;
        case CoreTablePaginationStepEnum.FORWARD_GROUP:
          selected = page + 1;
          while (!!(selected % threshold)) {
            selected++;
          }
          if (selected >= pages) {
            selected = pages - 1;
          }
          break;
        default:
          break;
      }

      context.pagination.update(selected);
      context.process();
    };
  };

  /**
   * Exceeds - Threshold - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreTableFootComponent
   */
  private get exceeds(): boolean {
    const { pages, props } = this;
    const { threshold } = props;
    return pages > threshold;
  }

  /**
   * Can't Step Back
   * @readonly
   * @private
   * @type {boolean}
   * @memberof CoreTablePaginationFooterContainer
   */
  private get cantStepBack(): boolean {
    const { page } = this.context.pagination;
    return page === 0 || null;
  }

  /**
   * Can't Step Forward
   * @readonly
   * @private
   * @type {boolean}
   * @memberof CoreTablePaginationFooterContainer
   */
  private get cantStepForward(): boolean {
    const { pages, context } = this;
    const { pagination } = context;
    return pagination.page >= pages - 1 || null;
  }

  /**
   * Pages - getter
   * @readonly
   * @private
   * @type {number}
   * @memberof CoreTablePaginationFooterContainer
   */
  protected get pages(): number {
    const { count, limit } = this.context.pagination;
    return Math.ceil(count / limit);
  }
}
