import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import CoreToggleContext from './context';
import CoreToggleService from './service';

import { CoreToggleList } from './containers/';
import { CoreToggleProps } from './interfaces/';

import { TOGGLE_PROPS } from './constants';

/**
 * Toggle Module
 * @export
 * @class CoreToggleModule
 * @extends {PureComponent<CoreToggleProps, {}>}
 */
export default class CoreToggleModule extends PureComponent<CoreToggleProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreToggleProps}
   * @memberof CoreToggleListContainer
   */
  public static defaultProps: CoreToggleProps = cloneDeep(TOGGLE_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreToggleModule
   */
  public render() {
    const { props: { active, ...rest } } = this;
    return (
      <CoreToggleContext.Provider value={new CoreToggleService(active)}>
        <CoreToggleList {...rest} />
      </CoreToggleContext.Provider>
    );
  }
}
