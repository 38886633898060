import CoreGraphProvider from './BaseProvider';

/**
 * Core Graph Link Provider
 * @export
 * @param {((CoreGraphConnectionType | CoreGraphLinkProviderType))} config
 * @return {*} {CoreGraphProviderClassType}
 */
export default function CoreGraphLinkProvider(config: (CoreGraphConnectionType | CoreGraphLinkProviderType)): CoreGraphProviderClassType {
  return CoreGraphProvider(config, 'link');
}