import { CoreConfigPermissionsShape } from '../interfaces/';
import { CoreConfigPermissionsMask, CoreConfigPermissionSimpleMask } from '../enums/';

/**
 * Config Permissions Service
 * @export
 * @class CoreConfigPermissions
 */
export class CoreConfigPermissions implements CoreConfigPermissionsShape {
  public static COMPLEX: Array<string> = [];
  public static SIMPLE: Array<string> = [];
  private COMPLEX_MAP: HashMap<CoreConfigPermissionsMask> = null!;
  private SIMPLE_MAP: HashMap<CoreConfigPermissionSimpleMask> = null!;
  /* istanbul ignore next */
  public get complex() { return CoreConfigPermissions.COMPLEX; }
  public get simple() { return CoreConfigPermissions.SIMPLE; }
  public get complex_map() {
    /* istanbul ignore next */
    if (!this.COMPLEX_MAP) {
      CoreConfigPermissions.COMPLEX.reduce((d: HashMap<CoreConfigPermissionsMask>, perm: string): HashMap<CoreConfigPermissionsMask> => {
        d[perm] = CoreConfigPermissionsMask.READ;
        return d;
      }, this.COMPLEX_MAP = {});
    }
    return this.COMPLEX_MAP;
  }
  public get simple_map() { 
    /* istanbul ignore next */
    if (!this.SIMPLE_MAP) {
      CoreConfigPermissions.SIMPLE.reduce((d: HashMap<CoreConfigPermissionSimpleMask>, perm: string): HashMap<CoreConfigPermissionSimpleMask> => {
        d[perm] = CoreConfigPermissionSimpleMask.DISALLOWED;
        return d;
      }, this.SIMPLE_MAP = {});
    }
    return this.SIMPLE_MAP;
  }
}
