import React, { Fragment } from 'react';

import { CoreDialogInstance } from '../../Activity/Dialogs/';

/**
 * Core Boot Error
 * @export
 * @param {Error} err
 */
export function CoreBootError(err: Error): void {
  /* istanbul ignore next */
  const ErrorComponent = ({ error }: HashMap<any>): JSX.Element => (
    <Fragment>
      <p>{error.message||err}</p>
      <p>{error.stack}</p>
    </Fragment>
  );
  const alert = new CoreDialogInstance(<ErrorComponent error={err} />, 'alert', 'warning');
  alert.name = 'core_boot_error';
  alert.title = 'Core Boot Error.';
  alert.activate().promise();
}
