import { CoreToggleServiceShape } from './interfaces/';

/**
 * Toggle Service
 * @export
 * @class CoreToggleService
 * @implements {CoreToggleServiceShape}
 */
export default class CoreToggleService implements CoreToggleServiceShape {
  /**
   * Active
   * @private
   * @type {Func<void>}
   * @memberof CoreToggleService
   */
  private _active: boolean;

  /**
   * Creates an instance of CoreToggleService.
   * @param {boolean} [active=false]
   * @memberof CoreToggleService
   */
  constructor(active: boolean = false) {
    this._active = active;
  }

  /**
   * Active - getter
   * @readonly
   * @memberof CoreToggleService
   */
  public get active() {
    return this._active;
  }

  /**
   * Active - setter
   * @memberof CoreToggleService
   */
  public set active(active: boolean) {
    this._active = active;
  }
}
