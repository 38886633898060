import React, { Component } from 'react';

/**
 * Table Caption Container
 * @export
 * @class CoreTableCaptionContainer
 * @extends {Component<GenericPropType, {}>}
 */
export default class CoreTableCaptionContainer extends Component<GenericPropType, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableCaptionContainer
   */
  public static displayName = 'CoreTableCaption';

  /**
   * Render
   * @returns
   * @memberof CoreTableCaptionContainer
   */
  public render() {
    const { children, ...rest } = this.props;
    return <caption {...rest}>{children}</caption>;
  }
}
