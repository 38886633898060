import Highcharts, { Chart } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighMaps from 'highcharts/modules/map';
import HighStock from 'highcharts/modules/stock';
import HighchartsXrange from 'highcharts/modules/xrange';

HighchartsMore(Highcharts);
HighMaps(Highcharts);
HighStock(Highcharts);
HighchartsXrange(Highcharts);

export { Chart };
export default Highcharts;
