import CoreButtonBase from './ButtonBase';

import { ButtonProps } from '../interfaces/';

/**
 * Danger Button
 * @export
 * @class CoreDangerButton
 * @extends {CoreButtonBase<Component<ButtonProps, ButtonState>>}
 */
export default class CoreDangerButton extends CoreButtonBase {
  public static defaultProps: ButtonProps = {
    type: 'button',
    variant: 'danger',
    outline: false
  };
}
