import React, { PureComponent } from 'react';

import CoreInteropContext, { Context } from './context';

/**
 * Interop Wrapper
 * @export
 * @class CoreInteropWrapper
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreInteropWrapper extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreInteropWrapper
   */
  public render() {
    const { children } = this.props;
    return (
      <CoreInteropContext.Provider value={Context}>
        {children}
      </CoreInteropContext.Provider>
    );
  }
}
