export const BUTTON_TYPES = ['button', 'reset', 'submit'];
export const BUTTON_VARIANTS = ['default' , 'primary' , 'secondary' , 'success' , 'danger' , 'warning' , 'info'];


export const BUTTON_PROPS = {
  type: 'button',
  variant: 'default',
  disabled: false,
  outline: false,
  value: null
};

export const BUTTON_STATE = {
  variant: null,
  disabled: null,
  outline: null,
  value: null
};


export const TABLE_BUTTON_PROPS = {
  type: 'button',
  variant: 'success',
  disabled: false,
  outline: false,
  value: null,
  className: 'btn-md'
};

export const FORM_BUTTON_PROPS = {
  type: 'button',
  variant: 'success',
  disabled: false,
  outline: false,
  value: null
};