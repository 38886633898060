import React, { PureComponent, Children, ReactElement } from 'react';
import { cloneDeep } from 'lodash';

import CoreTabsContext from './context';
import CoreTabsService from './service';

import { CoreTabsContainer } from './containers/';
import { CoreTabsProps, CoreTabItemProps, CoreTabsServiceShape } from './interfaces/';
import { TABS_PROPS } from './constants';

import { IsDefined, IsEmpty } from '../../../utils/';

/**
 * Core Tabs Module
 * @export
 * @class CoreTabsModule
 * @extends {PureComponent<CoreTabsProps, {}>}
 */
export default class CoreTabsModule extends PureComponent<CoreTabsProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreTabsProps}
   * @memberof CoreTabsContainer
   */
  public static defaultProps: CoreTabsProps = cloneDeep(TABS_PROPS);

  /**
   * Service
   * @protected
   * @type {CoreTabsServiceShape}
   * @memberof CoreTabsModule
   */
  protected service: CoreTabsServiceShape = new CoreTabsService();

  /**
   * Creates an instance of CoreTabsModule.
   * @param {CoreTabsProps} props
   * @memberof CoreTabsModule
   */
  constructor(props: CoreTabsProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @return {*}
   * @memberof CoreTabs
   */
  public render() {
    const { service, props } = this;
    return (
      <CoreTabsContext.Provider value={service}>
        <CoreTabsContainer {...props} />
      </CoreTabsContext.Provider>
    );
  }

  /**
   * Init
   * @private
   * @param {CoreTabsProps} props
   * @memberof CoreTabsModule
   */
  private init = ({ auto, children, id }: CoreTabsProps): void => {
    const { service } = this;

    try {
      /**
       * Select via props
       */
      Children.forEach(children, (child: ReactElement<CoreTabItemProps>, ndx: number) => {
        const { props, type } = child as any;
        if (type.displayName === 'CoreTabItem') {
          service.items.set(props.item, child);
          if (props.default && !props.hide) {
            service.current = props.item;
          }
          if (props.marked) {
            service.marked.push(props.item);
          }
        } else {
          /* istanbul ignore next */
          throw new ReferenceError(`only children of type[CoreTabItem] allowed.`);
        }
      });

      if (!IsDefined(service.current)) {
        if (IsDefined(auto)) {
          if (service.items.has(auto)) {
            service.current = auto;
          } else {
            /* istanbul ignore next */
            throw new ReferenceError(`tab item props do not contain prop item [${auto}].`);
          }
        } else {
          service.current = service.items.keys().next().value;
        }
      }

      /**
       * Override via uri hash
       */
      /* istanbul ignore next */
      const $location = (window as any).location;
      /* istanbul ignore next */
      if ($location.hash) {
        const $hash: HashMap<any> = $location.hash.split(/\&/g).reduce((d: HashMap<any>, r: string) => {
          r = r.replace('#', '');
          const [id, value] = r.split(/\=/g);
          d[id] = value;
          return d;
        }, {});

        if (!IsEmpty($hash)) {
          if (id && id in $hash) {
            const selected = $hash[id];
            if (service.items.has(selected)) {
              service.current = selected;
            }
          }
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  };
}
