import { nanoid } from 'nanoid';
import { cloneDeep, merge } from 'lodash';

import { CoreFormControllerInstanceType } from '../types';
import { CoreFormControllerInstance } from '../services/';

import { GetDeepHashMap, FlattenHashMap, WaitForDelay, IsString, IsNumber, IsNull, IsEmpty, IsDefined } from '../../../../utils/';

/**
 * Map From Flattened
 * @param {HashMap<any>} flattened
 * @returns {*} {HashMap<any>}
 */
export function MapFromFlattened(flattened: HashMap<any>): HashMap<any> {
  const map: HashMap<any> = {};
  for (const name in flattened) {
    // these get collected
    if (/\.\d+$/.test(name) && (IsString(flattened[name]) || IsNumber(flattened[name]))) {
      const partial: string = name.replace(/\.\d+$/, '');
      if (!(partial in map)) {
        map[partial] = [];
      }
      map[partial].push(flattened[name]);
    }
  }
  return map;
}

/**
 * Install Or Update Registry
 * @param {Map<string, CoreFormControllerInstanceType>} registry
 * @param {string} name
 * @param {any} value
 * @param {CoreFormOptionsType} [options]
 */
export function InstallOrUpdateRegistry(registry: Map<string, CoreFormControllerInstanceType>, name: string, value: any, options?: CoreFormOptionsType): void {
  let instance: CoreFormControllerInstanceType;
  if (registry.has(name)) {
    instance = registry.get(name);
    options = options || instance.options;
    instance.install(value, options, true);
  } else {
    instance = new CoreFormControllerInstance();
    instance.init(name, '', value, options, false, true);
    registry.set(name, instance);
  }
}

/**
 * Form Registry Sorter
 * @export
 * @param {Map<string, CoreFormControllerInstanceType>} registry
 * @return {Map<string, CoreFormControllerInstanceType>} 
 * sort the registry by name length
 */
export function FormRegistrySorter(registry: Map<string, CoreFormControllerInstanceType>): Map<string, CoreFormControllerInstanceType> {
  return new Map<string, CoreFormControllerInstanceType>([...registry].sort(([aname]: [string, CoreFormControllerInstanceType], [bname]: [string, CoreFormControllerInstanceType]): number => {
    return aname.split('.').length - bname.split('.').length;
  }));
}

/**
 * Form Registry Transformer
 * @export
 * @param {CoreFormServiceType} form
 * @returns {void}
 */
export function FormRegistryTransformer(registry: Map<string, CoreFormControllerInstanceType>, values: HashMap<any>): void {
  if (IsEmpty(values)) {
    return void 0;
  }

  /**
   * Flatten to a single object with dot notation properties.
   */
  const flattened: HashMap<any> = FlattenHashMap(values);
  for (const name in flattened) {
    InstallOrUpdateRegistry(registry, name, flattened[name]);
  }

  /**
   * Scrape map for Array<[string|number]> values.
   */
  const map: HashMap<any> = MapFromFlattened(flattened);
  for (const name in map) {
    InstallOrUpdateRegistry(registry, name, map[name]);
  }

  /* istanbul ignore next */
  WaitForDelay()
    .then(() => {
      /**
       * screen registry for unneeded psuedos
       */
      let iname: string;
      let instance: CoreFormControllerInstanceType;
      for (const [name, controller] of registry.entries()) {
        if (Array.isArray(controller.value)) {
          let i: number = controller.value.length;
          if (!controller.pseudo) {
            while (i--) {
              iname = `${name}.${i}`;
              if (registry.has(iname)) {
                instance = registry.get(iname);
                if (instance.pseudo) {
                  registry.delete(iname);
                }
              }
            }
          }
        }
      }
    })
    .then(() => {
      /**
       * screen registry for unpopulated pseudos
       * - this occurs when form.use(...) is applied asynchronously 
       */
      let value: any;
      for (const [name, controller] of registry.entries()) {
        if (IsNull(controller.value) && IsEmpty(controller.type)) {
          value = GetDeepHashMap(values, name, null, true);
          if (!IsNull(value)) {
            controller.install(value);
          }
        }
      }
    })
  ;

  return void 0;
}

/**
 * Form Dictionary Sync
 * @export
 * @param {CoreFormServiceType} form
 * @param {string} name
 * @param {any} value
 * @returns {void}
 */
export function FormDictionarySync(form: CoreFormServiceType, name: string, value: any): void {

  const registry: Map<string, CoreFormControllerInstanceType> = form.registry;
  let controller: CoreFormControllerInstanceType;

  // Screen for dict types
  /* istanbul ignore if */
  if (registry.has(name)) {
    controller = registry.get(name);
    if (controller.type === 'dict') {
      return void 0;
    }
  }

  // flattened hashmap of values, adjusted for full names
  const flat_value = FlattenHashMap(value);
  const incoming = Object.keys(flat_value).reduce((d: HashMap<any>, prop: string) => {
    d[`${name}.${prop}`] = flat_value[prop];
    return d;
  }, {});

  // flattened hashmap of source
  const current = FlattenHashMap(cloneDeep(form.source));
  for (const prop in current) {
    /* istanbul ignore if */
    if (!~prop.indexOf(name)) {
      delete current[prop];
    }
  }

  // merge the two
  const merged = merge(current, incoming);

  // scan merged
  for (const prop in merged) {
    if (registry.has(prop)) {
      if (prop in incoming) {
        controller = registry.get(prop);
        controller.install(incoming[prop], null, true);
      } else {
        registry.delete(prop);
      }
    } else {
      controller = new CoreFormControllerInstance();
      controller.init(prop, '', incoming[prop], undefined, false, true);
      registry.set(prop, controller);
    }
  }
}

/**
 * Form Array Sync
 * @export
 * @param {CoreFormServiceType} form
 * @param {string} name
 * @param {any} value
 * @returns {void}
 */
export function FormArraySync(form: CoreFormServiceType, name: string, value: any): void {  
  const registry: Map<string, CoreFormControllerInstanceType> = form.registry;
  let controller: CoreFormControllerInstanceType;
  let ndx: number;

  // Screen for dict types
  if (registry.has(name)) {
    controller = registry.get(name);
    /* istanbul ignore if */
    if (controller.type === 'dict') {
      return void 0;
    }
  } else {
    controller = new CoreFormControllerInstance();
    controller.init(name, '', value, undefined, false, true);
    registry.set(name, controller);
  }

  for (const [NAME, controller] of registry.entries()) {
    if (!!~NAME.indexOf(`${name}.`)) {
      ndx = Number(NAME.split('.').pop());
      if (ndx < value.length) {
        controller.install(value[ndx], null, true);
      } else {
        registry.delete(NAME);
      }
    }
  }
}

/**
 * Form Unique Key
 * @export
 * @param {...any[]} args
 * @returns {string}
 */
export function FormUniqueKey(...args: any[]): string {
  let i: number = args.length;
  let a: any;
  while (i--) {
    a = args[i];
    if (IsNull(a)) {
      args.splice(i, 1);
      continue;
    }
    if (Array.isArray(a)) {
      a = a.join(' ');
    }
    if (IsString(a)) {
      if (IsEmpty(a)) {
        a = nanoid(3);
      } else {
        a = a.replace(/\s+\s*/g, '.').toLowerCase();
      }
    }
    args[i] = String(a);
  }
  return args.join('.');
}

/**
 * Form Deprecation Warning
 * @export
 * @param {any[]} args
 * @param {Func<boolean>} alg
 * @param {string} [reason='']
 * @param {number} [max=null]
 * @param {number} [min=null]
 * @returns {*}
 */
/* istanbul ignore next */
export function FormDeprecationWarning(args: any[], alg: Func<boolean>, reason: string = '', max: number = null, min: number = null): any[] {
  try {
    if (!IsNull(max) && !IsNull(min)) {
      if (args.length > min) {
        throw new ReferenceError(`Limit ${max} arguments for this method.`);
      }
    }

    const message: string = `DEPRECATION WARNING:: ${reason}
Please make adjustments to your code.`;

    let i: number = args.length;
    let arg: any;

    while (i--) {
      arg = args[i];
      /* istanbul ignore next */
      if (!IsDefined(arg)) {
        continue;
      }
      if (!alg(arg)) {
        args.splice(i, 1);
        console.warn(message);
      }
    }

    return args;
  } catch (err: any) {
    console.error(err);
  }
}
