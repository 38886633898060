import React, { Fragment } from 'react';

import { CoreConfigBounce } from '../../Config/';

/**
 * App Name Component
 * @export
 * @class CoreAppNameComponent
 * @extends {Component<{}, {}>}
 */
export default class CoreAppNameComponent extends CoreConfigBounce {
  /**
   * Render
   * @returns
   * @memberof CoreAppNameComponent
   */
  public render() {
    // const { context: { header : { product_name }}} = this;
    // return <h2>{product_name}</h2>;
    return <Fragment />;
  }
}
