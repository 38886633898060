import React, { Component, Fragment } from 'react';

/**
 * User Link Component
 * @export
 * @class CoreUserLinkComponent
 * @extends {Component<any, {}>}
 */
export default class CoreUserLinkComponent extends Component<any, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreUserLinkComponent
   */
  public render() {
    const { name, picture } = this.props;
    const gravatar: HashMap<any> = {
      backgroundImage: `url('${picture}')`,
    };

    return (
      <Fragment>
        <i style={gravatar} />
        {name}
      </Fragment>
    );
  }
}
