import React, { PureComponent } from 'react';

import { CoreFooterNav } from './containers/';

/**
 * Footer Module
 * @export
 * @class CoreFooterModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreFooterModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreFooterModule
   */
  public render() {
    return (
      <footer className="global">
        <CoreFooterNav />
      </footer>
    );
  }
}
