import React, { Component, Fragment } from 'react';

import CoreConfigContext from '../context';

import { WaitForDelay } from '../../utils/';

/**
 * Config Bounce Component
 * @export
 * @class CoreConfigBounceComponent
 * @extends {Component<P = {}, S = {}>}
 */
export default class CoreConfigBounceComponent<P = {}, S = {}> extends Component<P, S> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreConfigBounceComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Display Name
   * - can be overridden if the `OnConfigBouncer` is also overridden.
   * @static
   * @type {string}
   * @memberof CoreConfigBounceComponent
   */
  public static displayName: string = 'OnConfigBounce';

  /**
   * Listener
   * @private
   * @type {AbortController}
   * @memberof CoreConfigBounceComponent
   */
  private listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof CoreConfigBounceComponent
   */
  public componentDidMount() {
    const { context } = this;
    this.listener = new AbortController();
    if (context.subscribe) {
      context.subscribe(this.displayName, this.OnConfigBouncer);
      this.OnMount();
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreConfigBounceComponent
   */
  public componentWillUnmount() {
    const { context, listener } = this;
    listener.abort();
    if (context.unsubscribe) {
      context.unsubscribe(this.displayName);
      this.OnUnmount();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreConfigBounceComponent
   */
  public render() {
    console.warn('must extend in sub-class.');
    return <Fragment />;
  }

  /**
   * On Mount
   * @protected
   * @memberof CoreConfigBounceComponent
   */
  protected OnMount = (): void => void 0;

  /**
   * On Unmount
   * @protected
   * @memberof CoreConfigBounceComponent
   */
  protected OnUnmount = (): void => void 0;

  /**
   * On Config Bouncer
   * @protected
   * @memberof CoreBootLogoTitleComponent
   */
  protected OnConfigBouncer = (...args: any[]) => {
    WaitForDelay(100).then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };

  /**
   * Display Name - getter
   * @readonly
   * @protected
   * @memberof CoreConfigBounceComponent
   */
  protected get displayName() {
    return (this.constructor as any).displayName;
  }
}
