import { BUSY_MESSAGE_DEFAULT } from '../../Config/';

export const BUSY_PROPS = {
  message: BUSY_MESSAGE_DEFAULT,
  active: false,
};

export const BUSY_WITH_ICON_PROPS = {
  icon: true,
  message: BUSY_MESSAGE_DEFAULT
};
