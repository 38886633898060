import { ComponentClass } from 'react';
import InputBase from './Base';

import { CoreFormElementWeek } from '../../../components/';

/**
 * Week Input Controller
 * @export
 * @class WeekInputController
 * @extends {InputBase}
 */
export default class WeekInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof WeekInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementWeek;
}
