import countries from '../json/countries.json';

/**
 * Get Country By ISO
 * @export
 * @param {string} iso
 * @returns {string}
 */
export function GetCountryByISO(iso: string): string {
  return countries[iso];
}
