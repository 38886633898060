
import CoreFormElementSelectBase from './BaseSelect';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, SELECT_TYPE_SELECT, SELECT_DEFAULT_LABEL } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Select Element
 * @export
 * @class CoreFormElementSelect
 * @extends {CoreFormElementSelectBase}
 */
export default class CoreFormElementSelect extends CoreFormElementSelectBase {
  public static defaultProps: Omit<CoreFormElementProps, 'name' | 'options'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: SELECT_TYPE_SELECT,
    placeholder: SELECT_DEFAULT_LABEL
  });
}
