import React from 'react';

import COUNTRIES from '../../../../json/countries.json';

import SelectWidgetBase from './SelectWidgetBase';

import { CoreSelect } from '../../containers';

/**
 * Multi Select Countries Container
 * @export
 * @class CoreMultiSelectCountriesContainer
 * @extends {SelectWidgetBase}
 */
export default class CoreMultiSelectCountriesContainer extends SelectWidgetBase {
  /**
   * SRC
   * @override
   * @static
   * @memberof CoreSelectCountryContainer
   */
  public static SRC = COUNTRIES;

  /**
   * Render
   * @returns
   * @memberof CoreMultiSelectCountriesContainer
   */
  public render() {
    const { options, props: { type, options: OPTIONS, value: VALUE, className: CLASSNAME, children, ...rest}} = this;

    let opts: CoreFormOptionsType = options.slice(0);

    /* istanbul ignore next */
    if (Array.isArray(OPTIONS) && OPTIONS.length) {
      opts = OPTIONS.concat(opts);
    }

    return <CoreSelect type="multiple" className="countries" options={opts} {...rest} />;
  }
}
