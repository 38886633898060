import React from 'react';

import CoreFormElementInputBase from './BaseInput';
import { FormElementSyncRestProps, FormUniqueKey } from '../../factories/';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_NUMBER } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Number Input Element
 * @export
 * @class CoreFormElementNumber
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementNumber extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementNumber
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_NUMBER,
    placeholder: '...',
    step: 1,
    min: 0
  });

  /**
   * Render
   * @returns
   * @memberof CoreFormElementNumber
   */
  public render() {
    const { OnChangeOrInput, OnBlur, props } = this;
    const { type, name, value, onChange, onInput, onBlur, ...rest } = props;
    const synced = FormElementSyncRestProps(type, rest);
    const key = FormUniqueKey(type, name, value);
    return <input key={key} type={type} name={name} defaultValue={value as number} onInput={OnChangeOrInput} onBlur={OnBlur} {...synced} />;
  }
}
