import React, { Component } from 'react';

import CoreFormContext from '../../contexts/Form';

import { CoreFormGroup } from '../../containers/';
import { CoreDefaultButton } from '../../../buttons/';

import { CoreDictionaryOrderProps } from './interfaces';
import {
  FORM_DICT_REORDER_BUTTON_TITLE_UP as tup,
  FORM_DICT_REORDER_BUTTON_TITLE_DOWN as tdown,
  FORM_DICT_REORDER_BUTTON_TITLE_DISABLED_UP as tdup,
  FORM_DICT_REORDER_BUTTON_TITLE_DISABLED_DOWN as tddown,
} from './constants';

import { WaitForDelay, IsHashMap, MergeClassNames, IsFunction } from '../../../../../utils/';

/**
 * Dictionary Order
 * @export
 * @class CoreDictionaryOrder
 * @extends {Component<CoreDictionaryOrderProps, {}>}
 */
export default class CoreDictionaryOrder extends Component<CoreDictionaryOrderProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreDictionaryOrder
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * LifeCycle Hook
   * @memberof CoreDictionaryOrder
   */
  public componentDidMount() {
    const {
      context,
      props: { name, ndx },
    } = this;
    /* istanbul ignore next */
    try {
      const dict = context.value(name);
      if (!Array.isArray(dict)) {
        throw new TypeError(`must apply to an array.`);
      }
      if (dict.length && !IsHashMap(dict[ndx])) {
        throw new TypeError(`must apply to Dictionary Format. Passed [${typeof dict[ndx]}].`);
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreDictionaryOrder
   */
  public render() {
    const {
      context,
      OnUpClickHandler,
      OnDownClickHandler,
      props: { name, ndx, className, children },
    } = this;

    const dict = context.value(name) || [];
    const up_disabled = ndx === 0;
    const down_disabled = ndx === dict.length - 1;
    const up_title = up_disabled ? tdup : tup;
    const down_title = down_disabled ? tddown : tdown;

    const clz = MergeClassNames(className, { 'form-group-order': true });

    return (
      <CoreFormGroup className={clz}>
        {children}
        <kbd>{ndx}</kbd>
        <label htmlFor="_" className="half-btn-label">
          <CoreDefaultButton className="up" onClick={OnUpClickHandler} title={up_title} disabled={up_disabled}>
            {' '}
          </CoreDefaultButton>
          <CoreDefaultButton className="down" onClick={OnDownClickHandler} title={down_title} disabled={down_disabled}>
            {' '}
          </CoreDefaultButton>
        </label>
      </CoreFormGroup>
    );
  }

  /**
   * On Up Click Handler
   * @protected
   * @memberof CoreDictionaryOrder
   */
  protected OnUpClickHandler = (evt: any): void => {
    const { ndx, consumer } = this.props;
    this.Change(ndx, ndx - 1);
    if (consumer && IsFunction(consumer.refresh)) {
      /* istanbul ignore next */
      WaitForDelay().then(() => {
        consumer.refresh();
      });
    }
  };

  /**
   * On Down Click Handler
   * @protected
   * @memberof CoreDictionaryOrder
   */
  protected OnDownClickHandler = (evt: any): void => {
    const { ndx, consumer } = this.props;
    this.Change(ndx, ndx + 1);
    if (consumer && IsFunction(consumer.refresh)) {
      /* istanbul ignore next */
      WaitForDelay().then(() => {
        consumer.refresh();
      });
    }
  };

  /**
   * Change
   * @protected
   * @memberof CoreDictionaryOrder
   */
  protected Change = (oldIndex: number, newIndex: number): void => {
    const {
      context,
      props: { name },
    } = this;
    const dict = context.value(name);
    const mover = dict.find((r: any, i: number): boolean => i === oldIndex);
    const remainder = dict.filter((r: any, i: number): boolean => i !== oldIndex);
    const $dict = [...remainder.slice(0, newIndex), mover, ...remainder.slice(newIndex)];
    context.update(name, $dict);
  };
}
