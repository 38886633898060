import { ApolloClient, ApolloClientOptions, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

import { CoreAuth } from '../../../../Auth/';

import CoreGraphBase from './base';

import { CoreGraphConnectionShape } from '../interfaces/';


/**
 * Core Graph Rest
 * @export
 * @param {CoreGraphRestProviderType} { uri, endpoints, credentials, authenticate = true,  cache = new InMemoryCache() }
 * @param {CoreGraphAuthType} [auth=CoreAuth]
 * @return {*} {CoreGraphConnectionShape}
 */
export default function CoreGraphRest({ uri, endpoints, credentials, authenticate = true, cache = new InMemoryCache() }: CoreGraphRestProviderType, auth: CoreGraphAuthType = CoreAuth): CoreGraphConnectionShape {
  /**
   * Type
   * @type {CoreGraphProviderType}
   */
  const type: CoreGraphProviderType = 'rest';  

  /**
   * Core Graph Rest
   * @export
   * @class CoreGraphRest
   * @extends {CoreGraphBase<CoreGraphClientType, CoreGraphRestProviderType>}
   */
  class CoreGraphRestInstance extends CoreGraphBase<CoreGraphClientType, CoreGraphRestProviderType> {
    /**
     * Make
     * @override
     * @protected
     * @return {*} 
     * @memberof CoreGraphRest
     */
    protected make() {
      const { cache, uri } = this;
      let { token } = this;

      let opts: RestLink.Options = { uri };

      /* istanbul ignore next */
      if (credentials) {
        Object.assign(opts, { credentials });
      }

      /* istanbul ignore next */
      if (endpoints) {
        Object.assign(opts, { endpoints });
      }

      /* istanbul ignore next */
      if (authenticate) {
        if (!token && CoreAuth.token) {
          this.token = CoreAuth.token;
          token = this.token;
        }
        const headers = { authorization: `Bearer ${token}` };
        Object.assign(opts, { headers });
      }

      const link: any = new RestLink(opts);
      const options: ApolloClientOptions<any> = { link, cache };
      return new ApolloClient<any>(options);
    }
  }

  const { client, open, close } = new CoreGraphRestInstance({ uri, endpoints, credentials, authenticate, cache }, auth);
  return { client, open, close, type, uri, cache, authenticate };
}
