import React, { MouseEvent } from 'react';

import DatePickerBase from './Base';
import { CoreDefaultButton } from '../../buttons/';
import { PickerApplyProps } from '../interfaces/';

/**
 * Apply Button Component
 * @export
 * @class ApplyButtonComponent
 * @extends {DatePickerBase<PureComponent<P = PickerApplyProps, {}>>}
 */
export default class ApplyButtonComponent extends DatePickerBase<PickerApplyProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof ApplyButtonComponent
   */
  public static displayName: string = 'ApplyButton';

  /**
   * Render
   * @returns
   * @memberof ApplyButtonComponent
   */
  public render() {
    const { OnApplyClick, disabled } = this;
    return <CoreDefaultButton title="Apply" onClick={OnApplyClick} className="btn-sm icon check" disabled={disabled} />;
  }

  /**
   * On Apply Click
   * @protected
   * @memberof ApplyButtonComponent
   */
  /* istanbul ignore next */
  protected OnApplyClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    const { action } = this.props;
    this.context.apply();
    if (action) {
      action();
    }
  };

  /**
   * Disabled
   * @readonly
   * @protected
   * @type {boolean}
   * @memberof ApplyButtonComponent
   */
  protected get disabled(): boolean {
    return this.context.ready === false;
  }
}
