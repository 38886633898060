import { ClassType, FormEvent } from 'react';

import { CoreFormValueType, CoreFormOptionsType } from '../types';
import { CoreFormElementBaseProps, CoreFormOptionShape } from '../interfaces/';
import { NUMBER_TYPES, CHECKED_TYPES, OPTIONS_TYPES, RADIO_TYPES, SELECT_TYPE_MULTIPLE } from '../constants/';
import { IsBoolean, IsEmpty, IsNumber, IsNumeric } from '../../../../utils/';

/**
 * Form Element Numeric From Props
 * @export
 * @param {GenericPropType} { type, value, options }
 * @returns {boolean}
 */
export function FormElementNumericFromProps({ type, value, options }: GenericPropType): boolean {
  /**
   * For number types
   */
  if (!!~NUMBER_TYPES.indexOf(type)) {
    return true;
  }

  /**
   * For options types
   */
  else if (!!~OPTIONS_TYPES.indexOf(type)) {
    const opts: CoreFormOptionsType = options || [];
    const sample: CoreFormOptionShape = opts[0] || { value: '', label: '' };
    if (IsNumber(sample.value)) {
      return true;
    }
  }

  /**
   * For numeric values
   */
  else if (IsNumber(value)) {
    return true;
  }

  return false;
}

/**
 * Form Element Binary From Props
 * @export
 * @param {GenericPropType} { type, value, options }
 * @returns {boolean}
 */
export function FormElementBinaryFromProps({ type, value, options }: GenericPropType): boolean {
  /**
   * For options types
   */
  if (!!~OPTIONS_TYPES.indexOf(type)) {
    if ((IsBoolean(value) || IsEmpty(value)) && options.length <= 3) {
      let option: CoreFormOptionType;
      let i: number = options.length;
      let bools: number = 0;
      while(i--) {
        option = options[i];
        if (IsBoolean(option.value)) {
          bools++;
        }
      }
      return bools === 2;
    }
  }

  return false;
}

/**
 * Value From Props
 * @export
 * @param {ClassType<CoreFormElementBaseProps, any, any>} src
 * @param {GenericPropType} { type, value }
 * @returns {CoreFormValueType}
 */
export function FormElementValueFromProps(src: ClassType<CoreFormElementBaseProps, any, any>, { type, value }: GenericPropType): CoreFormValueType {
  const { numeric } = src;

  /**
   * For options types
   */
  if (!!~OPTIONS_TYPES.indexOf(type)) {
    // multiple options need special handling
    if (type === SELECT_TYPE_MULTIPLE) {
      if (IsEmpty(value)) {
        return [];
      }
      if (numeric) {
        if (Array.isArray(value)) {
          value = value.map(v => !IsNumber(v) && IsNumeric(v) ? Number(v) : v);
        } else if (!IsNumber(value)) {
          value = IsNumeric(value) ? Number(value) : value;
        }
      }
      return Array.isArray(value) ? value : [ value ];
    } else {
      // special handling *if numeric
      if (numeric && !IsNumber(value)) {
        value = IsNumeric(value) ? Number(value) : value;
      }
    }
    return value;
  }

  /**
   * For checkbox types
   */
  if (!!~CHECKED_TYPES.indexOf(type)) {
    if (!IsBoolean(value)) {
      value = value === 'true';
    }
  }

  /**
   * For number types
   * - must send back empty string if not valid number; prevents loops
   */
  if (!!~NUMBER_TYPES.indexOf(type)) {
    if (!IsNumber(value)) {
      value = IsNumeric(value) ? Number(value) : '';
    }
  }

  return value;
}

/**
 * Value From Event
 * @export
 * @param {ClassType<CoreFormElementBaseProps, any, any>} src
 * @param {FormEvent<any>} evt
 * @returns {CoreFormValueType}
 */
export function FormElementValueFromEvent(src: ClassType<CoreFormElementBaseProps, any, any>, evt: FormEvent<any>): CoreFormValueType {
  const { numeric, binary, props: { type } } = src;
  const { checked, options, value, valueAsNumber } = evt.nativeEvent.target as any;

  /**
   * For options types
   */
  if (!!~OPTIONS_TYPES.indexOf(type)) {
    // multiple options need special handling
    if (type === SELECT_TYPE_MULTIPLE) {
      const values: any[] = [];
      const l = options.length;
      let i = 0;
      for (;i<l;i++) {
        const { value, selected } = options[i];
        if (selected) {
          values.push(numeric ? Number(value) : value);
        }
      }
      return values;
    } else {
      // *if numeric
      if (numeric) {
        return Number(value);
      }
      // *else if binary
      else if (binary) {
        if (value === 'true' || value === 'false') {
          return value === 'true';
        }
      }
    }
  }

  /**
   * For checkbox types
   */
  if (!!~CHECKED_TYPES.indexOf(type)) {
    return checked === true;
  }

  /**
   * For number types
   * - must send back empty string if not valid number; prevents loops
   */
  if (!!~NUMBER_TYPES.indexOf(type)) {
    return isNaN(valueAsNumber) ? '' : valueAsNumber;
  }

  /**
   * For Radio Types
   * - basic boolean value conversions
   */
  if (!!~RADIO_TYPES.indexOf(type)) {
    if (value === 'true' || value === 'false') {
      return value === 'true';
    }
  }

  return value;
}

/**
 * Sync Rest Props
 * @export
 * @param {string} type
 * @param {HashMap<any>} rest
 * @returns {HashMap<any>}
 * @notes
 * 1. Applies alternate attributes to input when necessary.
 * 2. Conforms value type.
 */
export function FormElementSyncRestProps(type: string, rest: HashMap<any>): HashMap<any> {
  /**
   * Injected RefObject
   */
  if ('ControllerRef' in rest) {
    rest['ref'] = rest['ControllerRef'];
    delete rest['ControllerRef'];
  }

  /**
   * Default Values
   */
  if ('defaultValue' in rest) {
    delete rest['defaultValue'];
  }
  if ('defaultChecked' in rest) {
    delete rest['defaultChecked'];
  }
  if ('value' in rest) {
    delete rest['value'];
  }
  if ('custom' in rest) {
    delete rest['custom'];
  }

  /**
   * For options types
   */
  if (!!~OPTIONS_TYPES.indexOf(type)) {
    if (type === SELECT_TYPE_MULTIPLE) {
      const multiple: boolean = true;
      Object.assign(rest, { multiple });
    }
  }

  /**
   * For radio & checked types
   */
  if (!~RADIO_TYPES.indexOf(type) && !~CHECKED_TYPES.indexOf(type)) {
    if ('checked' in rest) {
      delete rest['checked'];
    }
  }

  return rest;
}
