import { MutableRefObject, createRef } from 'react';
import EventEmitter from 'events';

import { CoreFormValueType, CoreFormOptionsType } from '../types';
import { CoreFormControllerInstanceShape } from '../interfaces/';
import { CoreFormEventsEnum as E } from '../enums/';
import { EMPTY_VALIDITY, DEFAULT_VALIDATION_MESSAGE } from '../constants/';
import { ControllerValueResolver, ControllerTypeResolver, ControllerDirtyResolver, ControllerGenerateElement } from '../factories/';

/**
 * Form Controller Instance
 * @export
 * @class CoreFormControllerInstance
 * @implements {CoreFormControllerInstanceShape}
 */
export class CoreFormControllerInstance implements CoreFormControllerInstanceShape {
  /**
   * OPTIONS - unmutated source [for resetting]
   * @private
   * @type {CoreFormOptionsType}
   * @memberof CoreFormControllerInstance
   */
  private _OPTIONS: CoreFormOptionsType = null;

  /**
   * Ref Object
   * @private
   * @type {(MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)}
   * @memberof CoreFormControllerInstance
   */
  private _ref: MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreDialogService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Name
   * @private
   * @type {string}
   * @memberof CoreFormControllerInstance
   */
  private _name: string;

  /**
   * Type
   * @private
   * @type {string}
   * @memberof CoreFormControllerInstance
   */
  private _type: string;

  /**
   * Value
   * @private
   * @type {CoreFormValueType}
   * @memberof CoreFormControllerInstance
   */
  private _value: CoreFormValueType;

  /**
   * Options
   * @private
   * @type {CoreFormOptionsType}
   * @memberof CoreFormControllerInstance
   */
  private _options: CoreFormOptionsType;

  /**
   * Dirty
   * @private
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  private _dirty: boolean = false;

  /**
   * Required
   * @private
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  private _required: boolean = false;

  /**
   * Pseudo
   * @private
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  private _pseudo: boolean = false;

  /**
   * Creates an instance of CoreFormControllerInstance.
   * @memberof CoreFormControllerInstance
   */
  constructor() {
    this._emitter.setMaxListeners(10);
    this._ref = createRef();
  }

  /**
   * Init
   * @param {string} name
   * @param {string} [type]
   * @param {CoreFormValueType} [value]
   * @param {CoreFormOptionsType} [options=null]
   * @param {boolean} [required=false]
   * @param {boolean} [pseudo=false]
   * @memberof CoreFormControllerInstance
   */
  public init(name: string, type: string, value?: CoreFormValueType, options: CoreFormOptionsType = null, required: boolean = false, pseudo: boolean = false): void {
    /**
     * Standardize props.
     */
    options = Array.isArray(options) ? options.slice(0) : null;
    type = ControllerTypeResolver(type, value, options, pseudo);
    value = ControllerValueResolver(type, value);

    this._name = name;
    this._type = type;
    this._value = value;
    this._options = options;
    this._required = required;
    this._pseudo = pseudo;

    /**
     * Stored for resetting.
     */
    this._OPTIONS = Array.isArray(options) ? options.slice(0) : null;

    /**
     * Sets Initial Validity for required controllers
     * @notes
     *  1. Allows things like save & reset buttons to
     *  get accurate insight into form state.
     */
    this._ref.current = ControllerGenerateElement(name, value, options, required, type);
  }

  /**
   * Update
   * @param {CoreFormValueType} value
   * @memberof CoreFormControllerInstance
   */
  public update = (value: CoreFormValueType): void => {
    const { emit, name, type, value: VALUE } = this;

    /**
     * Standardize props.
     */
    value = ControllerValueResolver(type, value, VALUE);

    this._value = value;
    this._dirty = true;

    emit(name, this.value, this.options);
  };

  /**
   * Install
   * @param {CoreFormValueType} value
   * @param {CoreFormOptionsType} [options=null]
   * @param {boolean} [clean=false]
   * @memberof CoreFormControllerInstance
   */
  public install = (value: CoreFormValueType, options: CoreFormOptionsType = null, clean: boolean = false): void => {
    const { emit, name, type, dirty, value: VALUE, options: OPTIONS } = this;

    /**
     * Standardize props.
     */
    value = ControllerValueResolver(type, value, VALUE);

    options = options || OPTIONS;

    this._value = value;
    this._options = options;
    this._dirty = clean ? false : ControllerDirtyResolver(type, dirty, value, options, VALUE, OPTIONS);

    emit(name, value, options, true);
  };

  /**
   * Reset
   * @param {CoreFormValueType} value
   * @memberof CoreFormControllerInstance
   */
  public reset = (value: CoreFormValueType): void => {
    const { emit, name, type } = this;

    /**
     * Standardize props.
     */
    value = ControllerValueResolver(type, value);
    const options = this._OPTIONS ? this._OPTIONS.slice(0) : this._OPTIONS;

    this._value = value;
    this._dirty = false;
    this._options = options;

    emit(name, value, options, true);
  };

  /**
   * Emit
   * @param {...any[]} args
   * @memberof CoreFormControllerInstance
   */
  public emit = (...args: any[]): void => {
    this._emitter.emit(E.CONTROLLER_EVENT_UPDATE, ...args);
  };

  /**
   * Emitter - getter
   * @readonly
   * @memberof CoreFormControllerInstance
   */
  public get emitter() {
    return this._emitter;
  }

  /**
   * Name - getter
   * @readonly
   * @type {string}
   * @memberof CoreFormControllerInstance
   */
  public get name() {
    return this._name;
  }

  /**
   * Type - getter
   * @readonly
   * @type {string}
   * @memberof CoreFormControllerInstance
   */
  public get type() {
    return this._type;
  }

  /**
   * Type - getter
   * @readonly
   * @type {string}
   * @memberof CoreFormControllerInstance
   */
  public set type(type) {
    this._type = type;
  }

  /**
   * Value - getter
   * @readonly
   * @type {CoreFormValueType}
   * @memberof CoreFormControllerInstance
   */
  public get value() {
    return this._value;
  }

  /**
   * Options - getter
   * @readonly
   * @memberof CoreFormControllerInstance
   */
  public get options() {
    const options: CoreFormOptionsType = Array.isArray(this._options) ? this._options.slice(0) : this._options;
    return options;
  }

  /**
   * Ref - getter
   * @memberof CoreFormControllerInstance
   */
  public get ref() {
    return this._ref;
  }

  /**
   * Ref - setter
   * @memberof CoreFormControllerInstance
   */
  public set ref(ref) {
    this._ref = ref;
  }


  /**
   * Dirty - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  public get dirty(): boolean {
    return this._dirty;
  }

  /**
   * Pristine - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  /* istanbul ignore next */
  public get pristine(): boolean {
    return this._dirty === false;
  }

  /**
   * Required - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  /* istanbul ignore next */
  public get required(): boolean {
    return this._required;
  }

  /**
   * Pseudo - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreFormControllerInstance
   */
  public get pseudo(): boolean {
    return this._pseudo;
  }

  /**
   * Pseudo - setter
   * @memberof CoreFormControllerInstance
   */
  /* istanbul ignore next */
  public set pseudo(pseudo) {
    this._pseudo = pseudo;
  }

  /**
   * Error - getter
   * @readonly
   * @memberof CoreFormControllerInstance
   */
  public get error(): any {
    const { ref: { current } } = this;
    if (current) {
      const { validationMessage: VM, validity } = current;
      const validationMessage = VM || DEFAULT_VALIDATION_MESSAGE;
      return { validationMessage, validity };
    }
    /* istanbul ignore next */
    return { validationMessage: "", validity: EMPTY_VALIDITY };
  }

  /**
   * Valid - getter
   * @type {boolean}
   * @readonly
   * @memberof CoreFormControllerInstance
   */
  public get valid(): boolean {
    const { ref: { current } } = this;
    if (current) {
      const { validity: { valid } } = current;
      return valid;
    }
    /* istanbul ignore next */
    return true;
  }
}