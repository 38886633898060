import React, { ComponentClass } from 'react';


import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormElementColor, ControllerLabel, ControllerValue } from '../../../components/';

/**
 * Color Input
 * @export
 * @class ColorInputController
 * @extends {InputBase}
 */
export default class ColorInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof ColorInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementColor;

  /**
   * Render
   * @returns
   * @memberof ColorInputController
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, controller } = this;
    const { name, value } = controller;

    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel {...labels} htmlFor="_">
          <label>
            <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
            <ControllerValue defaultValue="#000000" />
          </label>
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
