import React, { PureComponent, Children } from 'react';

import { CoreInputLabelProps } from '../interfaces/';
import { INPUT_LABEL_PROPS } from '../constants/';

import { HashMapMergeProps, IsSymbol, IsDefined, MergeClassNames } from '../../../../utils/';

/**
 * Input Label
 * @export
 * @class CoreInputLabelComponent
 * @extends {PureComponent<CoreInputLabelProps, {}>}
 */
export default class CoreInputLabelComponent extends PureComponent<CoreInputLabelProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreInputLabelProps}
   * @memberof CoreInputLabelComponent
   */
  public static defaultProps: CoreInputLabelProps = HashMapMergeProps(INPUT_LABEL_PROPS);

  /**
   * Help
   * @protected
   * @type {*}
   * @memberof CoreInputLabelComponent
   */
  protected help: any = null;

  /**
   * Keyboard
   * @protected
   * @type {*}
   * @memberof CoreInputLabelComponent
   */
  protected kbd: any = null;

  /**
   * Tooltip
   * @protected
   * @type {*}
   * @memberof CoreInputLabelComponent
   */
  protected tooltip: any = null;

  /**
   * Description
   * @protected
   * @type {*}
   * @memberof CoreInputLabelComponent
   */
  protected description: any = null;

  /**
   * Kids
   * @protected
   * @type {*}
   * @memberof CoreInputLabelComponent
   */
  protected kids: any[] = [];

  /**
   * Creates an instance of CoreInputLabelComponent.
   * @param {CoreInputLabelProps} props
   * @memberof CoreInputLabelComponent
   */
  constructor(props: CoreInputLabelProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @returns
   * @memberof CoreInputLabelComponent
   */
  public render() {
    const { help, kbd, tooltip, description, kids, props: { type, label, title, htmlFor: HTMLFOR, className } } = this;
    const labelled: boolean = IsDefined(label);
    const clz = MergeClassNames(className, {'input-label': true, [type]: true, labelled });
    const htmlFor = help ? '__' : HTMLFOR;
    return (
      <label className={clz} htmlFor={htmlFor} title={title}>
        {label ? (<dfn>{label}{help}</dfn>) : help}
        {kbd}{tooltip}{description}{kids}
      </label>
    );
  }

  /**
   * Init
   * @protected
   * @param {CoreInputLabelProps} { children }
   * @memberof CoreInputLabelComponent
   */
  protected init = ({ children }: CoreInputLabelProps): void => {
    /**
     * Screen Children.
     */
    if (children) {
      Children.toArray(children).forEach((Child: any): void => {
        if (IsSymbol(Child.type)) {
          /* istanbul ignore next */
          if (Child.type.displayName === 'CoreHelp') {
            this.help = Child;
          } else if (Child.type.displayName === 'CoreTooltip') {
            this.tooltip = Child;
          } else if (Child.type.displayName === 'CoreInputKbd') {
            this.kbd = Child;
          } else if (Child.type.displayName === 'CoreInputDescription') {
            this.description = Child;
          }
        } else {
          this.kids.push(Child);
        }
      });
    }
  };
}
