
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_TEL } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Tel Input Element
 * @export
 * @class CoreFormElementTel
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementTel extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementTel
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_TEL,
    placeholder: '000-000-0000',
    pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}'
  });
}
