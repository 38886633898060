import React, { MouseEvent } from 'react';
import { cloneDeep } from 'lodash';

import CoreNavItemBase from './ItemBase';

import { CoreAnchor } from '../../shared/';

import { CoreNavItemToggleState } from '../interfaces/';
import { ITEM_TOGGLE_STATE } from '../constants';

import { MergeClassNames } from '../../utils/';

/**
 * Nav Item Toggle Component
 * @export
 * @class CoreNavItemToggleComponent
 * @extends {CoreNavItemBase<Component<CoreNavItemProps, any>>}
 */
export default class CoreNavItemToggleComponent extends CoreNavItemBase {
  /**
   * State
   * @type {CoreNavItemToggleState}
   * @memberof CoreNavItemToggleComponent
   */
  public readonly state: CoreNavItemToggleState = cloneDeep(ITEM_TOGGLE_STATE);

  /**
   * Render
   * @returns
   * @memberof CoreNavItemToggleComponent
   */
  public render() {
    const {
      OnItemClick,
      state: { expanded },
      props: {
        name,
        item: { label },
      },
    } = this;

    const clz = MergeClassNames('', {
      [name]: true,
      expandable: true,
      expanded,
    });

    return (
      <CoreAnchor className={clz} onClick={OnItemClick}>
        <span>{label}</span>
      </CoreAnchor>
    );
  }

  /**
   * On Navigation Change
   * @protected
   * @memberof CoreNavItemToggleComponent
   */
  protected OnNavigationChange = (current: string): void => {
    const { props: { name } } = this;
    const expanded = current === name || !!~current.split('.').indexOf(name);
    this.setState({ expanded });
  };

  /**
   * On Item Click
   * @protected
   * @param {MouseEvent<HTMLAnchorElement>} evt
   * @memberof CoreNavItemToggleComponent
   */
  protected OnItemClick = (evt: MouseEvent<HTMLAnchorElement>): void => {
    const { context, props: { name } } = this;
    const parent: string = name.split('.').slice(0, -1).join('.');
    context.change(context.current === name ? parent : name);
  };
}
