import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementTime } from '../../../components/';

/**
 * Time Input Controller
 * @export
 * @class TimeInputController
 * @extends {InputBase}
 */
export default class TimeInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof TimeInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementTime;
}
