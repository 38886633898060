import moment, { Moment } from 'moment';
import { IsNumeric } from './checks';
import {
  CORE_EPOCH_YEAR,
  CORE_EPOCH_MONTH,
  CORE_EPOCH_WEEK,
  CORE_EPOCH_DAY,
  CORE_EPOCH_HOUR,
  CORE_EPOCH_MINUTE
} from './constants';

type StringNumberDateType = string | number | Date; 

/**
 * Is Valid Date
 * @export
 * @param {any} date
 * @returns {boolean}
 */
export const IsValidDate = (date: any): boolean => {
  try {
    if (Object.prototype.toString.call(date) !== '[object Date]' || isNaN(date.getTime())) {
      throw new TypeError('invalid date');
    }
    return true;
  } catch (err: any) {
    return false;
  }
};

/**
 * Is Valid Calendar Date
 * @export
 * @param {string} date
 * @returns {boolean}
 */
export const IsValidCalendarDate = (date: string): boolean => {
  try {
    const d: string[] = date.split('-');
    if (!IsNumeric(d[1]) || Number(d[1]) > 12 || Number(d[1]) < 1) {
      /* istanbul ignore next */
      throw new TypeError('invalid calendar date - month');
    }
    if (!IsNumeric(d[2]) || Number(d[2]) > 31 || Number(d[2]) < 1) {
      /* istanbul ignore next */
      throw new TypeError('invalid calendar date - day');
    }
    return /^\d{4}-\d{2}-\d{2}$/.test(date);
  } catch (err: any) {
    /* istanbul ignore next */
    return false;
  }
};

/**
 * Is Valid Clock Time
 * @export
 * @param {string} time
 * @returns {boolean}
 */
export const IsValidClockTime = (time: string): boolean => {
  try {
    const t: string[] = time.split(':');
    if (Number(t[0]) > 23 || Number(t[0]) < 0) {
      /* istanbul ignore next */
      throw new TypeError('invalid time - hour');
    }
    if (!IsNumeric(t[1]) || Number(t[1]) > 59 || Number(t[1]) < 0) {
      /* istanbul ignore next */
      throw new TypeError('invalid time - minute');
    }
    if (!IsNumeric(t[2]) || Number(t[2]) > 59 || Number(t[2]) < 0) {
      /* istanbul ignore next */
      throw new TypeError('invalid time - second');
    }
    return /^\d{2}:\d{2}?:\d{2}$/.test(time);
  } catch (err: any) {
    /* istanbul ignore next */
    console.error(err);
    /* istanbul ignore next */
    return false;
  }
};

/**
 * Date Fromn String
 * @export
 * @param {string} value
 * @returns {Date}
 */
export const DateFromString = (value: string): Date => {
  return new Date(Date.parse(value));
};

/**
 * Dates Equal
 * @export
 * @param {Date} a
 * @param {Date} b
 * @returns {boolean}
 */
export const DatesEqual = (a: Date, b: Date): boolean => {
  a = CloneDate(a);
  b = CloneDate(b);
  a.setUTCHours(0, 0, 0, 0);
  b.setUTCHours(0, 0, 0, 0);
  return a.valueOf() === b.valueOf();
};

/**
 * Clone Date
 * @export
 * @param {Date} src
 * @returns {Date}
 */
export const CloneDate = (src: Date): Date => {
  return new Date(src.getTime());
};

/**
 * Date After
 * @export
 * @param {Date} a
 * @param {Date} b
 * @returns {boolean}
 */
export const DateAfter = (a: Date, b: Date): boolean => {
  return a.getTime() > b.getTime();
};

/**
 * Date Before
 * @export
 * @param {Date} a
 * @param {Date} b
 * @returns {boolean}
 */
export const DateBefore = (a: Date, b: Date): boolean => {
  return a.getTime() < b.getTime();
};

/**
 * Week To Date String
 * @export
 * @param {string} value
 * @returns {string}
 */
export const WeekToDateString = (value: string): string => {
  const match = value.match(/^(\d{4})-[W]{1}(\d{2})$/);
  if (match) {
    const yyyy = Number(match[1]);
    const week = Number(match[2]);
    const date = new Date(yyyy, 0, 1 + (week - 1) * 7);
    value = FormatDateTimeString(date, (r) => [r.yyyy, r.mm, r.dd].join('-'));
  }
  return value;
};

/**
 * Get Month Days Total
 * @export
 * @param {Date} src
 * @returns {number}
 */
export const GetMonthDaysTotal = (date: Date): number => {
  const copy = CloneDate(date);
  copy.setUTCMonth(copy.getUTCMonth() + 1);
  copy.setUTCDate(0);
  return copy.getUTCDate();
};

/**
 * Format Date Time String
 * @export
 * @param {Date} date
 * @param {Func<string>} callback
 * @returns {string}
 */
export const FormatDateTimeString = (date: Date, callback: Func<string, any>): string => {
  date = CloneDate(date);
  const yyyy = String(date.getUTCFullYear());
  const yy = yyyy.slice(2);
  let mm = String(date.getUTCMonth() + 1);
  let dd = String(date.getUTCDate());
  let HH = String(date.getUTCHours());
  let MM = String(date.getUTCMinutes());
  let ww = (function() {
    date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
    const year = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    const w: number = (date as any) - (year as any);
    return String(Math.ceil((w / CORE_EPOCH_DAY + 1) / 7));
  })();

  // init 0 string
  mm = mm.length < 2 ? `0${mm}` : mm;
  dd = dd.length < 2 ? `0${dd}` : dd;
  ww = ww.length < 2 ? `0${ww}` : ww;
  HH = HH.length < 2 ? `0${HH}` : HH;
  MM = MM.length < 2 ? `0${MM}` : MM;

  return callback({ yyyy, yy, mm, dd, ww, HH, MM });
};

/**
 * Format Local Date/Time String
 * @export
 * @param {Date} date
 * @param {Func<string>} callback
 * @returns {string}
 */
export const FormatLocalDateTimeString = (date: Date, callback: Func<string, any>): string => {
  date = CloneDate(date);
  const yyyy = String(date.getFullYear());
  const yy = yyyy.slice(2);
  let mm = String(date.getMonth() + 1);
  let dd = String(date.getDate());
  let HH = String(date.getHours());
  let MM = String(date.getMinutes());
  let ww = (function() {
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));
    const year = new Date().getFullYear();
    const w: number = (date as any) - (year as any);
    return String(Math.ceil((w / CORE_EPOCH_DAY + 1) / 7));
  })();

  // init 0 string
  mm = mm.length < 2 ? `0${mm}` : mm;
  dd = dd.length < 2 ? `0${dd}` : dd;
  ww = ww.length < 2 ? `0${ww}` : ww;
  HH = HH.length < 2 ? `0${HH}` : HH;
  MM = MM.length < 2 ? `0${MM}` : MM;
  return callback({ yyyy, yy, mm, dd, ww, HH, MM });
};

/**
 * Is Same Year
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const IsSameYear = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? a as Date : new Date(a);
  b = IsValidDate(b) ? b as Date : new Date(b);
  return a.getFullYear() === b.getFullYear();
};

/**
 * Is Same Month
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const IsSameMonth = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? a as Date : new Date(a);
  b = IsValidDate(b) ? b as Date : new Date(b);
  return IsSameYear(a, b) && a.getMonth() === b.getMonth();
};

/**
 * Is Same Day
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const IsSameDay = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? a as Date : new Date(a);
  b = IsValidDate(b) ? b as Date : new Date(b);
  return IsSameMonth(a, b) && a.getDate() === b.getDate();
};

/**
 * Is Same Hour
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const IsSameHour = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? a as Date : new Date(a);
  b = IsValidDate(b) ? b as Date : new Date(b);
  return IsSameDay(a, b) && a.getHours() === b.getHours();
};

/**
 * Is Same Minute
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const IsSameMinute = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? a as Date : new Date(a);
  b = IsValidDate(b) ? b as Date : new Date(b);
  return IsSameHour(a, b) && a.getMinutes() === b.getMinutes();
};


/**
 * Within Year Of
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const WithinYearOf = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
  b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
  const aa: number = a.getTime();
  const bb: number = b.getTime();
  const c: number = Math.abs(aa - bb);
  return aa === bb || c <= CORE_EPOCH_YEAR;
};

/**
 * Within Month Of
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const WithinMonthOf = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
  b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
  const aa: number = a.getTime();
  const bb: number = b.getTime();
  const c: number = Math.abs(aa - bb);
  return aa === bb || c <= CORE_EPOCH_MONTH;
};

/**
 * Within Week Of
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const WithinWeekOf = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
  b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
  const aa: number = a.getTime();
  const bb: number = b.getTime();
  const c: number = Math.abs(aa - bb);
  return aa === bb || c <= CORE_EPOCH_WEEK;
};

/**
 * Within Day Of
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const WithinDayOf = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
  b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
  const aa: number = a.getTime();
  const bb: number = b.getTime();
  const c: number = Math.abs(aa - bb);
  return aa === bb || c <= CORE_EPOCH_DAY;
};

/**
 * Within Hour Of
 * @export
 * @param {StringNumberDateType} a
 * @param {StringNumberDateType} b
 * @returns {boolean}
 */
export const WithinHourOf = (a: StringNumberDateType, b: StringNumberDateType): boolean => {
  a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
  b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
  const aa: number = a.getTime();
  const bb: number = b.getTime();
  const c: number = Math.abs(aa - bb);
  return aa === bb || c <= CORE_EPOCH_HOUR;
};

/**
 * Within Minutes Of
 * @export
 * @param {number} [factor=1]
 * @param {StringNumberDateType} [a=Date.now()]
 * @param {StringNumberDateType} [b=Date.now()]
 * @returns {boolean}
 */
export const WithinMinutesOf = (factor: number = 1, a: StringNumberDateType = Date.now(), b: StringNumberDateType = Date.now()): boolean => {
  try {
    /* istanbul ignore next */
    if (factor <= 0) {
      throw new Error(`factor must me a positive integer.`);
    }
    a = IsValidDate(a) ? CloneDate(a as Date) : new Date(a);
    b = IsValidDate(b) ? CloneDate(b as Date) : new Date(b);
    const aa: number = a.getTime();
    const bb: number = b.getTime();
    const c: number = Math.abs(aa - bb);
    return aa === bb || c <= (CORE_EPOCH_MINUTE * factor);
  } catch (err: any) {}
  /* istanbul ignore next */
  return false;
};

/**
 * Trim Epoch
 * @export
 * @param {number | string} dtg
 * @returns {number}
 */
export const TrimEpoch = (dtg: number | string): number => {
  let epoch: string = typeof dtg === 'number' ? String(dtg) : dtg;
  if (epoch.length > 13) {
    epoch = epoch.slice(0, 13);
  }
  return Number(epoch);
};

/**
 * Date Months Ago
 * @export
 * @param {number | Date} [date=new Date()]
 * @param {number} [months=0]
 * @param {boolean} [zero=false]
 * @returns {Date}
 */
export const DateMonthsAgo = (date: number | Date = new Date(), months: number = 0, zero: boolean = false): Date => {
  let result: Moment = moment(date).subtract(months, 'months');
  /* istanbul ignore next */
  if (zero) {
    result = result.startOf('month');
  }
  return result.toDate();
};

/**
 * Date Days Ago
 * @export
 * @param {number | Date} [date=new Date()]
 * @param {number} [days=0]
 * @param {boolean} [zero=false]
 * @returns {Date}
 */
export const DateDaysAgo = (date: number | Date = new Date(), days: number = 0, zero: boolean = false): Date => {
  let result: Moment = moment(date).subtract(days, 'days');
  /* istanbul ignore next */
  if (zero) {
    result = result.startOf('day');
  }
  return result.toDate();
};

/**
 * Date Hours Ago
 * @export
 * @param {number | Date} [date=new Date()]
 * @param {number} [hours=0]
 * @returns {Date}
 */
export const DateHoursAgo = (date: number | Date = new Date(), hours: number = 0, zero: boolean = false): Date => {
  let result: Moment = moment(date).subtract(hours, 'hours');
  /* istanbul ignore next */
  if (zero) {
    result = result.startOf('hour');
  }
  return result.toDate();
};

/**
 * Date Minutes Ago
 * @export
 * @param {number | Date} [date=new Date()]
 * @param {number} [minutes=0]
 * @param {boolean} [zero=false]
 * @returns {Date}
 */
export const DateMinutesAgo = (date: number | Date = new Date(), minutes: number = 0, zero: boolean = false): Date => {
  let result: Moment = moment(date).subtract(minutes, 'minutes');
  /* istanbul ignore next */
  if (zero) {
    result = result.startOf('minute');
  }
  return result.toDate();
};

/**
 * Date End Of Today
 * @export
 * @returns {Date}
 */
export const DateEndOfToday = (): Date => {
  return moment().endOf('day').toDate();
};

/**
 * Date Time To Local ISO
 * @export
 * @param {Date} [date=new Date()]
 * @returns {string}
 */
/* istanbul ignore next */
export const DateTimeToLocalISO = (date: Date = new Date()): string => moment(date.toISOString()).format();

/**
 * Date Time Local To ISO
 * @export
 * @param {string} date
 * @returns {string}
 */
/* istanbul ignore next */
export const DateTimeLocalToISO = (date: string): string => moment(date).format();

