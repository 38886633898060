import React, { PureComponent, Fragment } from 'react';

import CoreAuthContext from '../../Auth/context';
import { SessionWatcherFactory } from '../factories/';

/**
 * Session Directive
 * @export
 * @class CoreSessionDirectiveComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreSessionDirectiveComponent extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreAuthContext>}
   * @memberof CoreMenusContainer
   */
  public static contextType: Readonly<typeof CoreAuthContext> = CoreAuthContext;

  /**
   * Watcher
   * @protected
   * @type {SessionWatcherFactory}
   * @memberof CoreSessionDirectiveComponent
   */
  protected watcher: SessionWatcherFactory = new SessionWatcherFactory();

  /**
   * LifeCycle Hook
   * @memberof CoreSessionDirectiveComponent
   */
  /* istanbul ignore next */
  public componentDidMount() {
    this.watcher.init(this.context).start();
  }

  /**
   * LifeCycle Hook
   * @memberof CoreSessionDirectiveComponent
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    this.watcher.stop();
  }

  /**
   * Render
   * @returns
   * @memberof CoreSessionDirectiveComponent
   */
  /* istanbul ignore next */
  public render() {
    return <Fragment />;
  }
}

