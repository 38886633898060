import React, { Component, Fragment, FC } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableHeadCellProps } from '../interfaces/';

import { TABLE_HEAD_CELL_PROPS } from '../constants';

/**
 * Table Head Cell Component
 * @export
 * @class CoreTableHeadCellComponent
 * @extends {Component<CoreTableHeadCellProps, {}>}
 */
export default class CoreTableHeadCellComponent extends Component<CoreTableHeadCellProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableHeadCellComponent
   */
  public static displayName = 'CoreTableHeadCell';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableHeadCellComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableCellProps}
   * @memberof CoreTableHeadCellComponent
   */
  public static defaultProps: CoreTableHeadCellProps = cloneDeep(TABLE_HEAD_CELL_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreTableHeadCellComponent
   */
  public componentDidMount() {
    const {
      context,
      props: { name },
    } = this;
    if (name) {
      context.subscribe(`${CoreTableHeadCellComponent.displayName}_${name}`, this);
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableHeadCellComponent
   */
  public componentWillUnmount() {
    const {
      context,
      props: { name },
    } = this;
    if (name) {
      context.unsubscribe(`${CoreTableHeadCellComponent.displayName}_${name}`);
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableHeadCellComponent
   */
  public render() {
    const { colSpan, rowSpan, className, children } = this.props;

    const spanners = {};

    /* istanbul ignore next */
    if (colSpan !== undefined) {
      spanners['colSpan'] = colSpan;
    }

    /* istanbul ignore next */
    if (rowSpan !== undefined) {
      spanners['rowSpan'] = rowSpan;
    }

    const Contents: FC = () => (<Fragment>{children}</Fragment>);

    return (
      <th className={className} {...spanners}>
        <span>
          <Contents />
        </span>
      </th>
    );
  }
}
