import React, { PureComponent, Fragment } from 'react';
import ReactGA, { pageview } from 'react-ga';

import { CoreHistory } from '../../Router/';

import CoreConfigContext from '../../Config/context';

/**
 * Analytics Directive
 * @export
 * @class CoreAnalyticsDirectiveComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreAnalyticsDirectiveComponent extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreAnalyticsDirectiveComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Unlisten
   * @protected
   * @type {Func<any>}
   * @memberof CoreAnalyticsDirectiveComponent
   */
  protected unlisten: Func<any> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreAnalyticsDirectiveComponent
   */
  /* istanbul ignore next */
  public componentDidMount() {
    const {
      context: {
        app: { uaid },
      },
    } = this;

    /* istanbul ignore next */
    if (uaid) {
      const options = {};
      if (process.env.NODE_ENV !== 'production') {
        const testMode: boolean = true;
        Object.assign(options, { testMode });
      }

      ReactGA.initialize(uaid, options);
      this.unlisten = CoreHistory.listen(({ location: { pathname, search }}: any) => {
        pageview(`${pathname}${search}`);
      });
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreAnalyticsDirectiveComponent
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreAnalyticsDirectiveComponent
   */
  /* istanbul ignore next */
  public render() {
    return <Fragment />;
  }
}
