import { CoreTableClassEnum } from '../enums/';

import { EnumToArray, MergeClassNames } from '../../../../utils/';

/**
 * Ensure Table Style
 * @export
 * @param {string} [current='']
 * @returns {string}
 */
export const CoreEnsureTableStyle = (current: string = ''): string => {
  const currents: string[] = current.trim().split(' ');

  const types = EnumToArray(CoreTableClassEnum);

  let found: boolean = false;
  let i: number = types.length;
  let t: string;

  while (i--) {
    t = types[i];
    if (!!~currents.indexOf(t)) {
      found = true;
      break;
    }
  }

  if (!found) {
    currents.unshift(CoreTableClassEnum.STRIPED);
  }

  const cnames: HashMap<boolean> = currents.reduce((d, r) => {
    r = r.trim();
    if (r) {
      d[r] = true;
    }
    return d;
  }, {});

  return MergeClassNames('', cnames);
};
