import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import { CoreNotificationsGraphStatic } from '../graph/';
import { CoreNotificationMenuItemProps } from '../interfaces/';
import { NOTIFICATION_ITEM_PROPS } from '../constants';

import { CoreAnchor } from '../../shared/';
import { MergeClassNames } from '../../utils/';

/**
 * Notification Menu Item Component
 * @export
 * @export
 * @class CoreNotificationMenuItemComponent
 * @extends {PureComponent<CoreNotificationMenuItemProps, {}>}
 */
export default class CoreNotificationMenuItemComponent extends PureComponent<CoreNotificationMenuItemProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreNotificationMenuItemProps}
   * @memberof CoreNotificationMenuItemComponent
   */
  public static defaultProps: any = cloneDeep(NOTIFICATION_ITEM_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreNotificationMenuItemComponent
   */
  public render() {
    const { item } = this.props;
    const { id, level, subject, content, date } = item;

    const note = true;
    const clz = MergeClassNames('', { note, [level]: true });

    const ParseItemContent = (src: string = ''): any => {
      src = src.trim().replace(/\s\s+/g, ' ');
      const match = src.match(/&lt;(https:\/\/.+)>/gi);
      if (match) {
        src = src.replace(match[0], `<a href="${match[1]||'#'}" target="_blank">link</a>`);
      }
      return src;
    };

    const parsed = ParseItemContent(content);
    const hashid: string = id ? `#${id}` : '';

    return (
      <CoreAnchor href={`${CoreNotificationsGraphStatic.VIEW}${hashid}`} className={clz}>
        {subject ? <h5>{subject}</h5> : null}
        <p dangerouslySetInnerHTML={{ __html: parsed }} />
        <time dateTime={date}>{date}</time>
      </CoreAnchor>
    );
  }
}
