import { nanoid } from 'nanoid';

import { DatePickerProps, PickerServiceBaseShape, DateTimeRangeSourceShape } from '../interfaces/';

import { ArrayNumeralsToNumbers, CORE_EPOCH_MINUTE } from '../../../../utils/';

/**
 * Picker Service Base
 * @export
 * @class PickerServiceBase
 * @implements {PickerServiceBaseShape}
 */
export default class PickerServiceBase implements PickerServiceBaseShape {
  /**
   * Offset
   * @static
   * @type {number}
   * @memberof PickerServiceBase
   */
  public static OFFSET: number = new Date().getTimezoneOffset() * CORE_EPOCH_MINUTE;

  /**
   * Source
   * @protected
   * @type {DateTimeRangeSourceShape}
   * @memberof PickerServiceBase
   */
  protected _source: DateTimeRangeSourceShape = {};

  /**
   * ID
   * @private
   * @type {string}
   * @memberof CoreFormService
   */
  private _id: string = nanoid(10);

  /**
   * Dirty
   * @private
   * @type {boolean}
   * @memberof PickerServiceBase
   */
  private _dirty: boolean = false;

  /**
   * Subscribers
   * @private
   * @type {Map<string, Func<void>>}
   * @memberof PickerServiceBase
   */
  private _subscribers: Map<string, Func<void>> = new Map<string, Func<void>>();

  /**
   * Props
   * @protected
   * @type {DatePickerProps}
   * @memberof PickerServiceBase
   */
  protected _props: DatePickerProps;

  /**
   * Form
   * @protected
   * @type {CoreFormServiceType}
   * @memberof PickerServiceBase
   */
  protected _form: CoreFormServiceType;

  /**
   * Init
   * @public
   * @param {any[]} args
   * @memberof PickerServiceBase
   */
  public init(...args: any[]): void {
    /* istanbul ignore next */
    console.warn('must extend in sub-class.');
  }

  /**
   * Reset
   * @memberof PickerServiceBase
   */
  public reset = (): void => {
    /* istanbul ignore next */
    console.warn('must extend in sub-class.');
  };

  /**
   * Apply
   * @memberof PickerServiceBase
   */
  /* istanbul ignore next */
  public apply(): void {
    const { form, actual, props: { name, required } } = this;
    this._dirty = true;
    const value: string = required ? actual : (actual === ',') ? '' : actual;
    form.update(name, value);
    this.broadcast();
  }

  /**
   * Subscribe
   * @param {string} name
   * @param {Func<void>} callback
   * @memberof PickerServiceBase
   */
  public subscribe(name: string, callback: Func<void>): void {
    this._subscribers.set(name, callback);
  }

  /**
   * Unsubscribe
   * @param {string} name
   * @memberof PickerServiceBase
   */
  /* istanbul ignore next */
  public unsubscribe(name: string): void {
    this._subscribers.delete(name);
  }

  /**
   * Broadcast
   * @private
   * @memberof PickerServiceBase
   */
  /* istanbul ignore next */
  public broadcast(): void {
    this._subscribers.forEach((callback: Func<void>) => {
      callback();
    });
  }

  /**
   * utc
   * @protected
   * @memberof PickerServiceBase
   */
  protected utc(dts: string): Date {
    const args: any = [];
    let [ date, time ]: string[] = dts.split('T');

    const ArgsToMilliSeconds = (args: any): number => {
      return Date.UTC.apply(null, args) + new Date(Date.UTC.apply(null, args)).getTimezoneOffset() * CORE_EPOCH_MINUTE;
    };

    // the calendar
    if (date) {
      const calendar: number[] = ArrayNumeralsToNumbers(date.split('-'));
      args.push(calendar[0]);
      args.push(calendar[1] - 1); // months are zero based
      args.push(calendar[2]);
    }

    // the clock
    if (time) {
      if (/\w+$/.test(time)) {
        time = time.slice(0, -1);
      }

      const clock: number[] = ArrayNumeralsToNumbers(time.split(':'));
      args.push(clock[0]||0);
      args.push(clock[1]||0);
      args.push(clock[2]||0);

    } else {
      const now: Date = new Date();
      args.push(now.getUTCHours());
      args.push(now.getUTCMinutes());
      args.push(now.getUTCSeconds());
    }

    return new Date(ArgsToMilliSeconds(args));
  };

  /**
   * ID - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  public get id() {
    return this._id;
  }

  /**
   * Props - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  public get props() {
    return this._props;
  }

  /**
   * Form - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  public get form() {
    return this._form;
  }

  /**
   * Source - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  public get source() {
    return this._source;
  }

  /**
   * Source - setter
   * @memberof PickerServiceBase
   */
  public set source(source: DateTimeRangeSourceShape) {
    this._source = source;
  }

  /**
   * Actual - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  /* istanbul ignore next */
  public get actual() {
    return 'must extend in sub-class.'
  }

  /**
   * Dirty - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  public get dirty() {
    return this._dirty;
  }

  /**
   * Ready - getter
   * @readonly
   * @memberof PickerServiceBase
   */
  /* istanbul ignore next */
  public get ready() {
    console.warn('DEVELOPER ERROR:: ready - getter - must extend in sub-class.');
    return false;
  }
}
