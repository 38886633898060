import React, { PureComponent } from 'react';

import { CoreDefaultButton } from '../../buttons';

import CoreTabsContext from '../context';

import { CoreTabsProps, CoreTabItemShape } from '../interfaces';

import { CoreTabItemTitle, CoreTabItemDefinitions } from '../components/';

import { TABS_ALLOWED_HEIGHTS } from '../constants';
import { IsEmpty, MergeClassNames } from '../../../../utils/';

/**
 * Tabs Container
 * @export
 * @class CoreTabsContainer
 * @extends {PureComponent<CoreTabsProps, {}>}
 */
export default class CoreTabsContainer extends PureComponent<CoreTabsProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTabsContext>}
   * @memberof CoreTabsContainer
   */
  public static contextType: Readonly<typeof CoreTabsContext> = CoreTabsContext;

  /**
   * Render
   * @returns
   * @memberof CoreTabsContainer
   */
  public render() {
    const { OnClose, props, context: { items, current } } = this;
    const { closable, height, className } = props;

    const $closeable = closable && !IsEmpty(current);

    const tclz = { tabs: true };

    if (height && !!~TABS_ALLOWED_HEIGHTS.indexOf(height)) {
      tclz[`h-${height}`] = true;
    }

    const dclz = MergeClassNames(className, tclz);

    const $items: Array<CoreTabItemShape> = Array.from(items);

    return (
      <dl className={dclz}>
        {$items.map((item: CoreTabItemShape, ndx: number) => <CoreTabItemTitle key={`tab.title.${item[0]}.${ndx}`} item={item} />)}
        {$closeable ? (
          <dt className="close-tab">
            <CoreDefaultButton onClick={OnClose} />
          </dt>
        ) : null}
        <CoreTabItemDefinitions {...props} />
      </dl>
    );
  }

  /**
   * On Close
   * @protected
   * @memberof CoreTabsContainer
   */
  protected OnClose = () => {
    const { context } = this;
    context.current = undefined;
    context.emit();
  };
}
