import mapKeysDeep from 'map-keys-deep-lodash';
import omitDeep from 'omit-deep-lodash';

/**
 * Graph Results Remove Type Notation
 * @export
 * @param {(HashMap<any> | Array<HashMap<any>>)} src
 * @param {(value: any, key: string) => string} [callback]
 * @returns {(HashMap<any> | Array<HashMap<any>>)}
 */
export function CoreGraphResultsRemoveTypeNotation(src: HashMap<any> | Array<HashMap<any>>, callback?: (value: any, key: string) => string): HashMap<any> | Array<HashMap<any>> {
  /* istanbul ignore next */
  if (callback) {
    if (Array.isArray(src)) {
      src = src.map(o => mapKeysDeep(o, callback));
    } else {
      src = mapKeysDeep(src, callback);
    }
  }

  if (Array.isArray(src)) {
    src = src.map(o => omitDeep(o, '__typename'));
  } else {
    src = omitDeep(src, '__typename');
  }
  return src;
}
