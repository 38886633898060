import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreInteropContext from '../../Interop/context';
import { CoreHeaderToggleMask } from '../../Interop/enums/';

import { CoreHeaderToggleState } from '../interfaces/';
import { HEADER_TOGGLE_STATE } from '../constants';

import CoreMenusContainer from './Menus';

import { MergeClassNames } from '../../utils/';

/**
 * Header Nav Container
 * @export
 * @class CoreHeaderNavContainer
 * @extends {Component<{}, CoreHeaderToggleState>}
 */
export default class CoreHeaderNavContainer extends Component<{}, CoreHeaderToggleState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreHeaderNavContainer
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * State
   * @type {CoreHeaderToggleState}
   * @memberof CoreHeaderNavContainer
   */
  public readonly state: CoreHeaderToggleState = cloneDeep(HEADER_TOGGLE_STATE);

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreHeaderNavContainer
   */
  private subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreHeaderNavContainer
   */
  public componentDidMount() {
    const { context } = this;
    this.subscriber = context.subscribe(this.CoreHeaderNavContainerListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreHeaderNavContainer
   */
  public componentWillUnmount() {
    const { context, subscriber } = this;
    context.unsubscribe(subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreHeaderNavContainer
   */
  public render() {
    const { header } = this.state;
    const active = header === CoreHeaderToggleMask.MENUS;
    const clz = MergeClassNames('', { header: true, active });
    return (
      <nav className={clz}>
        <CoreMenusContainer />
      </nav>
    );
  }

  /**
   * Menus Container Listener
   * @memberof CoreHeaderNavContainer
   */
  public CoreHeaderNavContainerListener = (): void => {
    const { state, context } = this;
    const { header } = context;
    if (header !== state.header) {
      this.setState({ header });
    }
  };
}
