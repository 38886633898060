import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

import CoreTableContext, { CoreTableSyncContext } from '../context';

import { CoreTableModeType, CoreListConfigShape, CoreTableStatusShape, CoreTableSyncShape } from '../interfaces/';
import { IsEqual } from '../../../../utils/';

const STATE: HashMap<any> = { key: nanoid(3) };
type CoreTableSyncState = Readonly<typeof STATE>;

/**
 * Table Sync SyncContainer
 * @export
 * @class CoreTableSyncContainer
 * @extends {Component<{}, CoreTableSyncState>}
 */
export default class CoreTableSyncContainer extends Component<{}, CoreTableSyncState> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableSyncContainer
   */
  public static displayName = 'CoreTableSync';

  /**
   * State
   * @type {CoreTableSyncState}
   * @memberof CoreTableSyncContainer
   */
  public readonly state: CoreTableSyncState = cloneDeep(STATE);

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableSyncContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Initted
   * @private
   * @type {boolean}
   * @memberof CoreTableSyncContainer
   */
  private _initted: boolean = false;

  /**
   * Params
   * @private
   * @type {CoreListConfigShape}
   * @memberof CoreTableSyncContainer
   */
  private _params: CoreListConfigShape = null;

  /**
   * Changed
   * @private
   * @type {boolean}
   * @memberof CoreTableSyncContainer
   */
  private _changed: boolean = false;

  /**
   * Force Update
   * @override
   * @private
   * @type {(callback?: () => void) => void}
   * @memberof CoreTableSyncContainer
   */
  private ForceUpdate: (callback?: () => void) => void = null!;

  /**
   * Creates an instance of CoreTableSyncContainer.
   * @param {*} props
   * @param {CoreTableServiceType} context
   * @memberof CoreTableSyncContainer
   */
  constructor(props: any, context: CoreTableServiceType) {
    super(props);
    this.ForceUpdate = this.forceUpdate;
    this.forceUpdate = this.OverrideForceUpdate;
    this._params = cloneDeep(context.list);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableSyncContainer
   */
  public componentDidMount() {
    const { context } = this;
    
    try {
      if (context.mode !== 'connected' as CoreTableModeType) {
        throw new ReferenceError(`CoreTableSync may only be used within a mode=[connected] table.`);
      }
  
      /* istanbul ignore next */
      context.subscribe(CoreTableSyncContainer.displayName, this);

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * LifeCycle Hook
   * @param {GenericDataType} props
   * @param {CoreTableSyncState} state
   * @param {CoreTableServiceType} context
   * @return {boolean} 
   * @memberof CoreTableSyncContainer
   */
  public shouldComponentUpdate(props: GenericDataType, state: CoreTableSyncState, context: CoreTableServiceType): boolean {
    /* istanbul ignore next */
    if (!this.initted) {
      return false;
    }

    const { params } = this;

    let changed: boolean = false;

    const searchable = context.search.searchable;
    const sortable = context.sort.sortable;
    const pageable = context.pagination.pageable;

    // search change
    if (searchable) {
      changed = params.search.term !== context.list.search.term || false;
      if (changed && pageable) {
        // sets page to zero if serach params have changed.
        context.pagination.page = 0;
      }
    }

    // sort change
    if (sortable) {
      if (!changed) {
        changed = params.sort.method !== context.list.sort.method || params.sort.column !== context.list.sort.column || false;
      }
    }

    // pagination change
    if (pageable) {
      if (!changed) {
        // guards initial query adjusting pagination count.
        if (context.list.paginate.count > 0) {
          changed = params.paginate.page !== context.list.paginate.page || params.paginate.count !== context.list.paginate.count || false;
        }
      }
    }

    // secondary search change
    if (searchable) {
      if (!changed) {
        changed = !IsEqual(params.search.fields, context.list.search.fields) || false;
      }
    }

    this._changed = changed;

    return changed;
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableSyncContainer
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(CoreTableSyncContainer.displayName);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableSyncContainer
   */
  public render() {
    const { context, init, initted, changed, status, props: { children } } = this;
    const table = context;
    const marked = table.collection.marked;
    const params = cloneDeep(table.list);
    this._params = params;
    return (
      <CoreTableSyncContext.Provider value={{ init, initted, changed, params, marked, status, table } as CoreTableSyncShape}>
        <CoreTableSyncContext.Consumer children={children as any} />
      </CoreTableSyncContext.Provider>
    );
  }

  /**
   * Init
   * @public
   * @memberof CoreTableSyncContainer
   */
  public init = (): void => {
    this._initted = true;
    this.ForceUpdate();
  };

  /**
   * Override Force Update
   * @protected
   * @memberof CoreTableSyncContainer
   * - override prevents the disabling of shouldComponentUpdate which we need to decide to render.
   */
  /* istanbul ignore next */
  protected OverrideForceUpdate = (callback?: () => void): void => {
    const { key } = this.state;
    this.setState({ key });
  };

  /**
   * Initted - getter
   * @readonly
   * @memberof CoreTableSyncContainer
   */
  public get initted() {
    return this._initted;
  }

  /**
   * Params - getter
   * @readonly
   * @memberof CoreTableSyncContainer
   */
  public get params() {
    /* istanbul ignore next */
    return cloneDeep(this._params);
  }

  /**
   * Changed - getter
   * @readonly
   * @memberof CoreTableSyncContainer
   */
  public get changed() {
    return this._changed;
  }

  /**
   * Status - getter
   * @readonly
   * @type {CoreTableStatusShape}
   * @memberof CoreTableSyncContainer
   */
  /* istanbul ignore next */
  public get status(): CoreTableStatusShape {
    const { context } = this;
    const searchable = context.search.searchable;
    const sortable = context.sort.sortable;
    const pageable = context.pagination.pageable;
    return { searchable, sortable, pageable };
  }
}
