import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import CoreFormContext from '../../contexts/Form';
import { CoreDefaultButton } from '../../../buttons/';
import { IsDefined, IsEmpty, IsNumeric, IsFunction, IsNull } from '../../../../../utils/';

// HOC
import { DictionaryBuilderProps } from './interfaces';
import { FORM_DICT_PROPS, FORM_DICT_BUILD_BUTTON_TITLE_ADD as tadd, FORM_DICT_BUILD_BUTTON_TITLE_REMOVE as tremove } from './constants';

/**
 * Dictionary Builder Container
 * @export
 * @class CoreDictionaryBuilderContainer
 * @extends {PureComponent<DictionaryBuilderProps, {}>}
 */
export default class CoreDictionaryBuilderContainer extends PureComponent<DictionaryBuilderProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreDictionaryBuilderContainer
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default Props
   * @static
   * @type {DictionaryBuilderProps}
   * @memberof CoreDictionaryBuilderContainer
   */
  public static defaultProps: DictionaryBuilderProps = cloneDeep(FORM_DICT_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreDictionaryBuilderContainer
   */
  public componentDidMount() {
    const { source, index, add, remove } = this.props;
    /* istanbul ignore next */
    try {
      if (process.env.NODE_ENV !== 'production') {
        if (!IsDefined(source) || !Array.isArray(source)) {
          throw new ReferenceError(`CoreDictionaryBuilder: missing or malformed prop [source].`);
        }
        if (!IsDefined(index) || !IsNumeric(index)) {
          throw new ReferenceError(`CoreDictionaryBuilder: missing or malformed prop [index].`);
        }
        if (!IsEmpty(add) && !IsFunction(add)) {
          throw new ReferenceError(`CoreDictionaryBuilder: missing or malformed prop [add].`);
        }
        if (!IsEmpty(remove) && !IsFunction(remove)) {
          throw new ReferenceError(`mCoreDictionaryBuilder: issing or malformed prop [remove].`);
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreDictionaryBuilderContainer
   */
  public render() {
    const { props, OnClickHandler } = this;
    const { source, limit, index, add, remove, nullable } = props;

    const min = IsNull(add) ? 0 : 1;

    // AT LIMIT
    if (source.length === limit) {
      return (
        <label htmlFor="_" className="builder-buttons-label">
          <CoreDefaultButton className="minus" onClick={OnClickHandler(remove)} title={tremove} />
        </label>
      );
    }

    // FIRST
    if (index === 0) {
      // FIRST OF MANY
      if (source.length > min) {
        return (
          <label htmlFor="_" className="builder-buttons-label">
            <CoreDefaultButton className="minus" onClick={OnClickHandler(remove)} title={tremove} />
          </label>
        );
      } else {
        if (IsNull(add)) {
          return null;
        }
        /* istanbul ignore next */
        if (nullable) {
          return (
            <label htmlFor="_" className="builder-buttons-label">
              <CoreDefaultButton className="minus" onClick={OnClickHandler(remove)} title={tremove} />{' '}
              <CoreDefaultButton className="plus" onClick={OnClickHandler(add)} title={tadd} />
            </label>
          );
        } else {

          // FIRST AND ONLY
          return (
            <label htmlFor="_" className="builder-buttons-label">
              <CoreDefaultButton className="plus" onClick={OnClickHandler(add)} title={tadd} />
            </label>
          );
        }
      }
    }

    // LESS THAN LIMIT
    // NOT FIRST
    if (source.length > min && source.length > index + 1) {
      return (
        <label htmlFor="_" className="builder-buttons-label">
          <CoreDefaultButton className="minus" onClick={OnClickHandler(remove)} title={tremove} />
        </label>
      );
    }

    return (
      <label htmlFor="_" className="builder-buttons-label">
        <CoreDefaultButton className="minus" onClick={OnClickHandler(remove)} title={tremove} />{' '}
        {IsNull(add) ? null : (
          <CoreDefaultButton className="plus" onClick={OnClickHandler(add)} title={tadd} />
        )}
      </label>
    );
  }

  /**
   * On Click Handler
   * @protected
   * @memberof CoreDictionaryBuilderContainer
   */
  /* istanbul ignore next */
  protected OnClickHandler = (callback: Func<void, any>) => {
    const { context } = this;
    return (evt: any) => {
      return callback(evt, context);
    };
  };
}
