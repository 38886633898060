import React, { Fragment } from 'react';

import { CoreConfigBounce } from '../components/';
import { CoreConfigMeta, CoreConfigApp } from '../services/';
import { CoreSyncWebStorage } from '../../shared/modules/storage/';
import { IsHashMap, IsDefined } from '../../utils/';

import { THEMES, THEME_DEFAULT } from '../constants/';

/**
 * Config Html Directive
 * @export
 * @class CoreConfigHtmlDirective
 * @extends {CoreConfigBounce<CoreRouteProps>}
 */
export default class CoreConfigHtmlDirective extends CoreConfigBounce<CoreRouteProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreConfigHtmlDirective
   */
  public static displayName: string = 'OnConfigHtmlDirective';

  /**
   * Storage
   * @private
   * @type {CoreSyncWebStorage}
   * @memberof CoreConfigHtmlDirective
   */
  private _storage: CoreSyncWebStorage = new CoreSyncWebStorage(CoreConfigApp.CONFIG_META_STORAGE_NAME);

  /**
   * Current - getter
   * @readonly
   * @memberof CoreConfigHtmlDirective
   */
  public get configMeta() {
    return this._storage.get();
  }

  /**
   * HTML Object
   * @private
   * @memberof CoreConfigHtmlDirective
   */
  private html = document.querySelector('html');

  /**
   * Creates an instance of CoreConfigHtmlDirective.
   * @param {*} props
   * @memberof CoreConfigHtmlDirective
   */
  constructor(props: any) {
    super(props);

    // add debug for developers
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      this.html.classList.add('debug');
    }

    if (!this.html.classList.contains(THEME_DEFAULT)) {
      this.html.classList.add(THEME_DEFAULT);
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreConfigHtmlDirective
   */
  public render() {
    const { html: { classList }, context: { meta: { favicon } }, configMeta } = this; // context: { meta: { favicon } },
    const selectedFavicon = (configMeta && IsHashMap(configMeta['FAVICON'])) ? configMeta['FAVICON'] : favicon;
    const theme = (configMeta && IsDefined(configMeta['THEME'])) ? configMeta['THEME'] : CoreConfigMeta.THEME;
    const whitelabeled = CoreConfigMeta.WHITELABELED;
    let i: number;
    
    i = THEMES.length;

    while (i--) {
      if (THEMES[i] === theme) {
        if (!classList.contains(THEMES[i])) {
          classList.add(THEMES[i]);
        }
      } else {
        classList.remove(THEMES[i]);
      }
    }

    if (whitelabeled && !classList.contains('wl')) {
      classList.add('wl');
    } else if (!whitelabeled && classList.contains('wl')) {
      classList.remove('wl');
    }

    let link: HTMLLinkElement;
    /* istanbul ignore next */
    for (const size in selectedFavicon) {
      link = document.getElementById(`link_icon_${size}`) as any;
      if (link && selectedFavicon[size]) {
        link.href = `data:image;base64,${selectedFavicon[size]}`;
      }
    }

    return <Fragment />;
  }
}
