import React, { Component, createRef, RefObject } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableConsumer } from '../containers/';

import { CoreTableService } from '../services/';
import { CoreScrollTableProps, CoreScrollTableState } from '../interfaces/';
import { CoreEnsureTableStyle } from '../factories/';
import { SCROLL_TABLE_PROPS, SCROLL_TABLE_STATE } from '../constants';

import { WaitForDelay } from '../../../../utils/';

/**
 * Core Table Container
 * @export
 * @class CoreScrollTableContainer
 * @extends {Component<CoreScrollTableProps, {}>}
 */
export default class CoreScrollTableContainer extends Component<CoreScrollTableProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreScrollTableProps}
   * @memberof CoreScrollTableContainer
   */
  public static defaultProps: CoreScrollTableProps = cloneDeep(SCROLL_TABLE_PROPS);

  /**
   * State
   * @type {CoreScrollTableState}
   * @memberof CoreScrollTableContainer
   */
  public readonly state: CoreScrollTableState = cloneDeep(SCROLL_TABLE_STATE);

  /**
   * Ref
   * @type {RefObject<HTMLTableElement>}
   * @memberof CoreScrollTableContainer
   */
  public ref: RefObject<HTMLTableElement>;

  /**
   * Table Service
   * @private
   * @type {CoreTableServiceType}
   * @memberof CoreScrollTableContainer
   */
  private service: CoreTableServiceType = new CoreTableService();

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreScrollTableContainer
   */
  private listener: AbortController;

  /**
   * Creates an instance of CoreScrollTableContainer.
   * @param {CoreScrollTableProps} props
   * @memberof CoreScrollTableContainer
   */
  constructor(props: CoreScrollTableProps) {
    super(props);
    this.ref = createRef<HTMLTableElement>();
    this.init(props);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreScrollTableContainer
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    if (this.listener) {
      this.listener.abort();
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreScrollTableContainer
   */
  public componentDidUpdate() {
    const {
      props,
      state,
      ref: { current },
    } = this;
    if (current) {
      const tbody = current.querySelector('tbody');
      /* istanbul ignore next */
      if (tbody) {
        const { clientHeight } = tbody.querySelector('tr:first-child');
        tbody.style.height = state.rows > props.rows ? `${props.rows * clientHeight}px` : 'auto';
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreScrollTableContainer
   */
  public render() {
    const { ref, props, state, service, DeferState } = this;
    const { source, className, children, ...rest } = props;

    const clz = state.rows > props.rows ? 'scrollable scrolling' : 'scrollable';

    return (
      <CoreTableContext.Provider value={service}>
        <table ref={ref} className={CoreEnsureTableStyle(clz)} {...rest}>
          {children}
        </table>
        <CoreTableConsumer>
          {(table: any) => {
            const count = table.collection.results.length;
            if (count !== state.rows) {
              DeferState(count);
            }
            return null;
          }}
        </CoreTableConsumer>
      </CoreTableContext.Provider>
    );
  }

  /**
   * Defer State
   * @protected
   * @memberof CoreScrollTableContainer
   */
  protected DeferState = (rows: number): void => {
    WaitForDelay().then(() => {
      if (!this.listener.signal.aborted) {
        this.setState({ rows });
      }
    });
  };

  /**
   * Init
   * @protected
   * @param {CoreScrollTableProps} { source }
   * @memberof CoreScrollTableContainer
   */
  protected init = ({ source }: CoreScrollTableProps): void => {
    if (source instanceof CoreTableService) {
      this.service = source;
    } else {
      this.service = new CoreTableService();
    }
    this.service.use(source);
    this.listener = new AbortController();
  };
}
