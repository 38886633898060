import { CryptoInstanceShape, CryptoMetaType } from '../interfaces/';
import { IsDefined } from '../../../../utils/';

import { IsValidMeta } from './meta';

/**
 * Crypto Instance
 * @export
 * @class CryptoInstance
 * @implements {CryptoInstanceShape}
 */
export class CryptoInstance implements CryptoInstanceShape {
  /**
   * Name
   * @private
   * @type {string}
   * @memberof CryptoInstance
   */
  private name: string = 'AES-GCM';

  /**
   * Crypto
   * @private
   * @memberof CryptoInstance
   */
  private crypto = (window as any).crypto;

  /**
   * Subtle Crypto 
   * @private
   * @memberof CryptoInstance
   */
  private SubtleCrypto = (window as any).crypto?.subtle;

  /**
   * Encoder
   * @private
   * @memberof CryptoInstance
   */
  // @ts-ignore
  private encoder: any = new TextEncoder();

  /**
   * Decoder
   * @private
   * @memberof CryptoInstance
   */
  // @ts-ignore
  private decoder: any = new TextDecoder();

  /**
   * Creates an instance of CryptoInstance.
   * @memberof CryptoInstance
   */
  constructor() {
    this.make = this.make.bind(this);
    this.encrypt = this.encrypt.bind(this);
    this.decrypt = this.decrypt.bind(this);
    this.derive = this.derive.bind(this);
  }

  /**
   * Make
   * @param {string} key
   * @return {*}  {Promise<CryptoMetaShape>}
   * @memberof CryptoInstance
   */
  /* istanbul ignore next */
  public async make(key: string): Promise<CryptoMetaType> {
    const i: Uint8Array = this.crypto.getRandomValues(new Uint8Array(12));
    const s: Uint8Array = this.crypto.getRandomValues(new Uint8Array(16));
    const p: string = key;
    return [i, s, p];
  }

  /**
   * Encrypt
   * @param {string} value
   * @param {CryptoMetaType} meta
   * @return {*}  {Promise<any>}
   * @memberof CryptoInstance
   */
  /* istanbul ignore next */
  public async encrypt(value: string, meta: CryptoMetaType): Promise<any> {
    const { cryptable } = this;
    if (cryptable && IsValidMeta(meta)) {
      const { SubtleCrypto, encoder, name, derive } = this;
      const iv: Uint8Array = meta[0];
      const derived = await derive(meta);
      const encoded = encoder.encode(value);
      return await SubtleCrypto.encrypt({ name, iv }, derived, encoded);
    }
    return value;
  }

  /**
   * Decrypt
   * @param {ArrayBuffer} value
   * @param {CryptoMetaType} meta
   * @return {*}  {Promise<any>}
   * @memberof CryptoInstance
   */
  /* istanbul ignore next */
  public async decrypt(value: ArrayBuffer, meta: CryptoMetaType): Promise<any> {
    const { cryptable } = this;
    if (cryptable && IsValidMeta(meta)) {
      const { SubtleCrypto, decoder, name, derive } = this;
      const iv: Uint8Array = meta[0];
      const derived = await derive(meta);
      const decrypted = await SubtleCrypto.decrypt({ name, iv }, derived, value);
      return decoder.decode(decrypted);
    }
    return value;
  }

  /**
   * Cryptable
   * @readonly
   * @type {boolean}
   * @memberof CryptoInstance
   */
  public get cryptable(): boolean {
    return IsDefined(this.SubtleCrypto);
  }

  /**
   * Derive
   * @private
   * @param {CryptoMetaType} meta
   * @return {*}  {Promise<any>}
   * @memberof CryptoInstance
   */
  /* istanbul ignore next */
  private async derive(meta: CryptoMetaType): Promise<any> {
    const { SubtleCrypto, encoder } = this;
    const key: string = meta[2];
    const salt: Uint8Array = meta[1];
    const encoded = encoder.encode(key);
    const initial = await SubtleCrypto.importKey('raw', encoded, { name: 'PBKDF2' }, false, ['deriveKey']);
    return SubtleCrypto.deriveKey({ name: 'PBKDF2', salt, iterations: 100000, hash: 'SHA-256' }, initial, { name: 'AES-GCM', length: 256 }, false, ['encrypt', 'decrypt']);
  }
}

