import React, { PureComponent, Fragment } from 'react';

/**
 * Noop Component
 * @export
 * @class NoopComponent
 * @extends {PureComponent<P, S>}
 * @template P
 * @template S
 */
export default class NoopComponent<P = {}, S = never> extends PureComponent<P, S> { render() { return <Fragment />; } }