import './navigation.scss';

import { CoreNavigation } from '@neustar/core-ui';

/**
 * Navigation Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function NavigationPlugin(): Promise<boolean | Error> {
  try {
    const NAVIGATION: Map<string, CoreNavItemType> = new Map<
      string,
      CoreNavItemType
    >()
      .set('home', {
        label: 'Home',
        children: new Map<string, CoreNavItemType>()
          .set('home.overview', {
            label: 'Overview',
            path: 'home/overview',
          })
          .set('home.how-to-use', {
            label: 'How to Use',
            path: 'home/how-to-use',
          })
          .set('home.content-area', {
            label: 'Content Area',
            path: 'home/content-area',
          }),
      })
      .set('architecture', {
        label: 'Architecture',
        children: new Map<string, CoreNavItemType>()
          .set('architecture.overview', {
            label: 'Overview',
            path: 'architecture/overview',
          })
          .set('architecture.theory', {
            label: 'Theory',
            path: 'architecture/theory',
          })
          .set('architecture.flow', {
            label: 'Flow',
            path: 'architecture/flow',
          }),
      })
      .set('features', {
        label: 'Features',
        children: new Map<string, CoreNavItemType>()
          .set('features.activity', {
            label: 'Activity',
            path: 'features/activity',
            children: new Map<string, CoreNavItemType>()
              .set('features.activity.overview', {
                label: 'Overview',
                path: 'features/activity',
              })
              .set('features.activity.dialogs', {
                label: 'Dialogs',
                path: 'features/activity/dialogs',
              }),
          })
          .set('features.buttons', {
            label: 'Buttons',
            path: 'features/buttons',
          })
          .set('features.forms', {
            label: 'Forms',
            path: 'features/forms',
            children: new Map<string, CoreNavItemType>()
              .set('features.forms.overview', {
                label: 'Overview',
                path: 'features/forms',
              })
              .set('features.forms.basics', {
                label: 'Basics',
                path: 'features/forms/basics',
              })
              .set('features.forms.controllers', {
                label: 'Controllers',
                path: 'features/forms/controllers',
              })
              .set('features.forms.elements', {
                label: 'Elements',
                path: 'features/forms/elements',
              })
              .set('features.forms.groups', {
                label: 'Groups',
                path: 'features/forms/groups',
              })
              .set('features.forms.widgets', {
                label: 'Widgets',
                path: 'features/forms/widgets',
              })
              .set('features.forms.consumers', {
                label: 'Consumers',
                path: 'features/forms/consumers',
              }),
          })
          .set('features.help_tooltips', {
            label: 'Help & Tooltips',
            path: 'features/help_tooltips',
          })
          .set('features.tables', {
            label: 'Tables',
            path: 'features/tables',
            children: new Map<string, CoreNavItemType>()
              .set('features.tables.overview', {
                label: 'Overview',
                path: 'features/tables',
              })
              .set('features.tables.basic', {
                label: 'Basic',
                path: 'features/tables/basic',
              })
              .set('features.tables.dynamic', {
                label: 'Dynamic',
                path: 'features/tables/dynamic',
              })
              .set('features.tables.advanced', {
                label: 'Advanced',
                path: 'features/tables/advanced',
              })
              .set('features.tables.pagination', {
                label: 'Pagination',
                path: 'features/tables/pagination',
              }),
          })
          .set('features.lists', {
            label: 'Lists',
            path: 'features/lists',
          })
          .set('features.tabs', {
            label: 'Tabs',
            path: 'features/tabs',
          })
          .set('features.toggle', {
            label: 'Toggle',
            path: 'features/toggle',
          }),
      })
      .set('graph', {
        label: 'Graph',
        children: new Map<string, CoreNavItemType>()
          .set('graph.overview', {
            label: 'Overview',
            path: 'graph/overview',
          })
          .set('graph.how-to-use', {
            label: 'How to Use',
            path: 'graph/how-to-use',
          })
          .set('graph.convenience', {
            label: 'Convenience',
            path: 'graph/convenience',
          })
          .set('graph.crud', {
            label: 'CRUD',
            path: 'graph/crud',
          })
          .set('graph.scratch', {
            label: 'Scratch',
            path: 'graph/scratch',
          }),
      })
      .set('styles', {
        label: 'Styles',
        children: new Map<string, CoreNavItemType>()
          .set('styles.overview', {
            label: 'Overview',
            path: 'styles/overview',
          })
          .set('styles.icons', {
            label: 'Icons',
            path: 'styles/icons',
          }),
      });

    /**
     * @notes
     * - Core Navigation takes the configuration array and
     * constructs the Navigation Aside Component.
     * Empty Array will result in no Navigation Container
     * (<aside/>)
     */
    CoreNavigation.use(NAVIGATION);

    return true;
  } catch (err: any) {
    throw err;
  }
}
