import React, { PureComponent } from 'react';

import './tool.scss';

import { CoreTabs, CoreTabItem } from '@neustar/core-ui';

/**
 * Sample Tool Container
 * @export
 * @class SampleToolContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class SampleToolContainer extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof SampleToolContainer
   */
  public render() {
    return (
      <CoreTabs>
        <CoreTabItem item="company" label="Company" default={true}>
          Company
        </CoreTabItem>
        <CoreTabItem item="users" label="Users">
          Users
        </CoreTabItem>
      </CoreTabs>
    );
  }
}
