import { CoreConfigApp, CoreConfigMeta, CoreConfigHeader, CoreConfigFooter, THEMES, THEME_LIGHT, SP_LOGO_LIGHT } from '@neustar/core-ui';
import { IsHashMap } from '@neustar/core-ui';

/**
 * Apply White Label - legacy
 * @async
 * @export
 * @param {HashMap<any>} src
 */
export async function ApplyWhiteLabelLegacy(src: HashMap<any>): Promise<void> {
  /**
   * Since used more than once and the user&rsquo;s whitelabel
   * arrives second, we have to reject if the object is
   * completely empty to avoid overwriting the effetc of
   * domain whitelabel
   */
  if (!src || !Object.keys(src).length) {
    return void 0;
  }

  const {
    enabled,
    header_logo,
    header_logo_title,
    product_name,
    hostname,
    support_url,
    login_marquee_items,
    favicon,
    theme,
    legal_notices,
    bottom_left_label,
    footer_links,
    bottom_right_label,
    contact_information,
  } = src;

  if (enabled) {

    // adds whitelabel class to html
    CoreConfigMeta.WHITELABELED = true;

    // Header
    if (header_logo) {
      CoreConfigHeader.LOGO = header_logo;
      CoreConfigApp.PDF_HEADER_IMAGE = header_logo;
    } else {
      CoreConfigApp.PDF_HEADER_IMAGE = null;
    }
    if (header_logo_title) {
      CoreConfigHeader.LOGO_TITLE = header_logo_title;
    }
    if (product_name) {
      CoreConfigHeader.PRODUCT_NAME = product_name;
    }
    if (hostname) {
      CoreConfigHeader.HOSTNAME = hostname;
    }
    if (support_url) {
      CoreConfigHeader.SUPPORT_URL = support_url;
    }

    // Footer Left
    if (legal_notices || bottom_left_label) {
      CoreConfigFooter.COPYRIGHT = legal_notices || bottom_left_label;
    }

    if (footer_links && footer_links.length) {
      CoreConfigFooter.LINKS = footer_links;
    }

    // Footer Right
    if (bottom_right_label) {
      CoreConfigFooter.PRODUCT_NAME = bottom_right_label;
    }

    if (bottom_right_label || contact_information) {
      CoreConfigFooter.CONTACT_INFORMATION = bottom_right_label || contact_information
    }

    // Meta
    if (theme) {
      const THEME = `theme-${theme}`;
      if (!!~THEMES.indexOf(THEME)) {
        CoreConfigMeta.THEME = THEME;

        if (!header_logo) {
          switch (CoreConfigMeta.THEME) {
            case THEME_LIGHT:
              CoreConfigHeader.LOGO = SP_LOGO_LIGHT;
              break;
            default:
              break;
          }
        }
      }
    }
    if (favicon && IsHashMap(favicon)) {
      CoreConfigMeta.FAVICON = favicon;
    }
    if (login_marquee_items) {
      CoreConfigMeta.MARQUEE = login_marquee_items;
    }
  }

  return void 0;
}

/**
 * Apply White Label - legacy
 * @async
 * @export
 * @param {HashMap<any>} src
 */
export async function ApplyWhiteLabel(src: HashMap<any>): Promise<void> {
  /**
   * Since used more than once and the user&rsquo;s whitelabel
   * arrives second, we have to reject if the object is
   * completely empty to avoid overwriting the effetc of
   * domain whitelabel
   */
  if (!src || !Object.keys(src).length) {
    return void 0;
  }

  const {
    enabled,
    favicon,
    headerLogo,
    productName,
    domain,
    theme,
    supportURL,
    loginMarqueeItems,
    legalNotices,
    contactInformation,
    bottomLeftLabel,
    bottomRightLabel,
    footerLinks,
  } = src;

  if (enabled) {

    // adds whitelabel class to html
    CoreConfigMeta.WHITELABELED = true;

    // Header
    if (headerLogo) {
      CoreConfigHeader.LOGO = headerLogo;
    }
    if (productName) {
      CoreConfigHeader.LOGO_TITLE = productName;
    }
    if (productName) {
      CoreConfigHeader.PRODUCT_NAME = productName;
    }
    if (domain) {
      CoreConfigHeader.HOSTNAME = domain;
    }
    if (supportURL) {
      CoreConfigHeader.SUPPORT_URL = supportURL;
    }

    // Footer Left
    if (legalNotices || bottomLeftLabel) {
      CoreConfigFooter.COPYRIGHT = legalNotices || bottomLeftLabel;
    }

    if (footerLinks && footerLinks.length) {
      CoreConfigFooter.LINKS = footerLinks;
    }

    // Footer Right
    if (bottomRightLabel) {
      CoreConfigFooter.PRODUCT_NAME = bottomRightLabel;
    }

    if (contactInformation) {
      CoreConfigFooter.CONTACT_INFORMATION = contactInformation;
    }

    // Meta
    if (theme) {
      const THEME = `theme-${theme}`;
      if (!!~THEMES.indexOf(THEME)) {
        CoreConfigMeta.THEME = THEME;

        if (!headerLogo) {
          switch (CoreConfigMeta.THEME) {
            case THEME_LIGHT:
              CoreConfigHeader.LOGO = SP_LOGO_LIGHT;
              break;
            default:
              break;
          }
        }
      }
    }
    if (favicon && IsHashMap(favicon)) {
      CoreConfigMeta.FAVICON = favicon;
    }
    if (loginMarqueeItems) {
      CoreConfigMeta.MARQUEE = loginMarqueeItems;
    }
  }

  return void 0;
}
