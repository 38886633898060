import React, { Component } from 'react';

import { MergeClassNames } from '../../utils/';

/**
 * Legend Container
 * @export
 * @class CoreLegendContainer
 * @extends {Component<GenericPropType, {}>}
 */
export default class CoreLegendContainer extends Component<GenericPropType, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreLegendContainer
   */
  public render() {
    const { legend, className, children } = this.props;

    const clz = MergeClassNames(className, {'legend': true });
    return <div className={clz} data-legend={legend}>{children}</div>;
  }
}
