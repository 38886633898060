import React, { Component, Fragment } from 'react';

import CoreConfigContext from '../../Config/context';
import { CoreConfigLinkShape } from '../../Config/interfaces/';

import { CoreAnchor } from '../../shared/';

/**
 * Footer Nav Left Component
 * @export
 * @class CoreFooterNavLeftComponent
 * @extends {CoreConfigBounce<C>}
 */
export default class CoreFooterNavLeftComponent extends Component<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreFooterNavLeftComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Render
   * @returns
   * @memberof CoreFooterNavLeftComponent
   */
  public render() {
    const { copyright, links } = this.context.footer;

    return (
      <Fragment>
        {copyright}
        {' | '}
        {links.map((link: CoreConfigLinkShape, ndx: number) => {
          /* istanbul ignore next */
          if (!link.url || !link.label) {
            return null;
          }

          /* istanbul ignore next */
          const title = link.title || null;
          const mt = ndx ? ' | ' : null;
          return (
            <Fragment key={`footer.link.${ndx}`}>
              {mt}
              <CoreAnchor href={link.url} rel="external" itemProp="url" title={title}>
                {link.label}
              </CoreAnchor>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
}
