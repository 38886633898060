import React, { Component, Fragment, ComponentType, ReactElement, ReactNode, Children, cloneElement } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableCellProps } from '../interfaces/';

import { TABLE_CELL_PROPS } from '../constants';

import { IsFunction, IsNumber, IsBoolean, NumberToHumanReadable, GetDeepHashMap } from '../../../../utils/';

/**
 * Table Cell Component
 * @export
 * @class CoreTableCellComponent
 * @extends {Component<CoreTableCellProps, {}>}
 */
export default class CoreTableCellComponent extends Component<CoreTableCellProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableCellComponent
   */
  public static displayName = 'CoreTableCell';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableCellComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableCellProps}
   * @memberof CoreTableCellComponent
   */
  public static defaultProps: CoreTableCellProps = cloneDeep(TABLE_CELL_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreTableCellComponent
   */
  public render() {
    const { name, ndx, data, content, colSpan, rowSpan, className, children } = this.props;

    /* istanbul ignore next */
    if (IsNumber(rowSpan) && Boolean(ndx % rowSpan)) {
      return null;
    }

    // handle rare situation when `[key,ref]` is a data prop
    if ('key' in data) {
      data['_key'] = data.key;
    }
    if ('ref' in data) {
      data['_ref'] = data.ref;
    }

    const spanners = {};
    const kids = Children.count(children) > 0;

    if (colSpan !== undefined) {
      spanners['colSpan'] = colSpan;
    }

    if (rowSpan !== undefined) {
      spanners['rowSpan'] = rowSpan;
    }

    Object.assign(data, { ndx });

    /**
     * Handles a variety of cell population methods
     */
    const Contents: any = (values: GenericPropType) => {
      if (content) {
        if (IsFunction(content)) {
          const PartialComp: ComponentType = content;
          return <PartialComp {...values} />;
        } else {
          return cloneElement(content as ReactElement, { ...values });
        }
      }
      if (kids) {
        return (
          <Fragment>
            {Children.map(children, (child: ReactNode, i: number) => cloneElement(child as ReactElement, { ...values }))}
          </Fragment>
        );
      } else {
        let value: any = GetDeepHashMap(values, name, '');
        value = IsNumber(value) ? NumberToHumanReadable(value) : value;

        if (IsBoolean(value)) {
          return <span>{value ? 'TRUE' : 'FALSE'}</span>;
        }
        return <span>{value}</span>;
      }
    };

    return (
      <td className={className} {...spanners}>
        <Contents {...data} />
      </td>
    );
  }
}
