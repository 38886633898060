import React, { Component } from 'react';

import { CoreDialogsModule } from './Dialogs/';
import { CoreBusyModule } from './Busy/';

/**
 * Activity Module
 * @export
 * @class CoreActivityModule
 * @extends {Component}
 */
export default class CoreActivityModule extends Component {
  /**
   * Render
   * @returns
   * @memberof CoreActivityModule
   */
  public render() {
    return (
      <div id="activity">
        <CoreBusyModule />
        <CoreDialogsModule />
      </div>
    );
  }
}
