import jwtDecode from 'jwt-decode';

import { CoreBootAuthPlugins } from '../../services/';

import { SearchToHashMap, Noop } from '../../../utils/';

/**
 * Boot Start External
 * @export
 * @param {string} name
 * @returns {*} {Promise<any>}
 */
export function BootStartExternal(name: string): Promise<any> {
  try {
    const { search } = (window as any).location;

    /* istanbul ignore next */
    if (!search) {
      throw new ReferenceError('Invalid url.');
    }

    const { token } = SearchToHashMap(search, true);

    /* istanbul ignore next */
    if (!token) {
      throw new ReferenceError('Invalid url.');
    }

    const { email, expiration } = jwtDecode(token) as HashMap<any>;

    /* istanbul ignore next */
    if (!email || !expiration) {
      throw new ReferenceError('Invalid token passed.');
    }

    // converts server time to js time.
    const exp = expiration * 1e3;
    const now = Date.now();

    /* istanbul ignore next */
    if (now > exp) {
      throw new ReferenceError(`This ${name} token has expired.`);
    }

    return Promise.resolve({ email, token });
  } catch (err: any) {
    /* istanbul ignore next */
    return Promise.reject(err);
  }
}

/**
 * Apply Reset Complete
 * @export
 * @param {*} values
 * @returns {*} {Promise<any>}
 */
export async function ApplyResetComplete(values: any): Promise<any> {
  try {
    return CoreBootAuthPlugins.apply('reset', values).catch(Noop);
  } catch (err: any) {
    /* istanbul ignore next */
    return Promise.reject(err);
  }
}

/**
 * Apply Activation Complete

 * @export
 * @param {*} values
 * @returns {*} {Promise<any>}
 */
export async function ApplyActivationComplete(values: any): Promise<any> {
  try {
    return CoreBootAuthPlugins.apply('activate', values).catch(Noop);
  } catch (err: any) {
    /* istanbul ignore next */
    return Promise.reject(err);
  }
}

/**
* Escape special characters
* @export
* @param {value} any
* @returns {*} {string | undefined}
*/
export function EscapePasswordRegExp(value: any): string | undefined {
  return value ? value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : undefined
}