import { Component } from 'react';

import { CoreTableSubscriptionShape, CoreTableModeType } from '../interfaces/';

/**
 * Core Table Subscription
 * @export
 * @class CoreTableSubscription
 * @implements {CoreTableSubscriptionShape}
 */
export default class CoreTableSubscription implements CoreTableSubscriptionShape {
  /**
   * Subscribers
   * @private
   * @type {Map<string, Component>}
   * @memberof CoreTableSubscription
   */
  private _subscribers: Map<string, Component> = new Map<string, Component>();

  /**
   * Mode
   * @protected
   * @type {CoreTableModeType}
   * @memberof CoreTableSubscription
   */
  protected _mode: CoreTableModeType = 'flat';

  /**
   * Process Promise
   * @protected
   * @type {any}
   * @memberof CoreTableSubscription
   */
  protected deferred: any;

  /**
   * Subscribe
   * @param {string} name
   * @param {Component} instance
   * @memberof CoreTableSubscription
   */
  public subscribe(name: string, instance: Component): void {
    this._subscribers.set(name, instance);
  }

  /**
   * Unsubscribe
   * @param {string} name
   * @memberof CoreTableSubscription
   */
  public unsubscribe(name: string): void {
    this._subscribers.delete(name);
  }

  /**
   * Broadcast
   * @private
   * @memberof CoreTableSubscription
   */
  public broadcast(): void {
    this._subscribers.forEach((instance: Component) => {
      instance.forceUpdate();
    });
  }

  /**
   * Mode - getter
   * @readonly
   * @type {CoreTableModeType}
   * @memberof CoreTableSubscription
   */
  public get mode() {
    return this._mode;
  }

  /**
   * Promise = getter
   * @readonly
   * @memberof CoreTableSubscription
   */
  public get promise() {
    return this.deferred.promise;
  }
}
