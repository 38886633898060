
import CoreFormElementInputBase from './BaseInput';

import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_RANGE } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Range Input Element
 * @export
 * @class CoreFormElementRange
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementRange extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementRange
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_RANGE,
    min: 0,
    max: 10,
    value: 5
  });
}
