import React, { PureComponent } from 'react';

import { CoreHeaderMenuItem } from '../components/';
import { CoreHeaderMenuItemsProps } from '../interfaces/';

/**
 * Header Menu Items Container
 * @export
 * @class CoreHeaderMenuItemsContainer
 * @extends {PureComponent<CoreHeaderMenuItemsProps, {}>}
 */
export default class CoreHeaderMenuItemsContainer extends PureComponent<CoreHeaderMenuItemsProps, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreHeaderMenuItemsContainer
   */
  public render() {
    const { items } = this.props;
    return Array.from(items).map(([key, item]: [string, CoreHeaderItemType]) => {
      /* istanbul ignore next */
      return <CoreHeaderMenuItem key={`hmi.${key}`} item={item} />;
    });
  }
}
