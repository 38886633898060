import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import { MarqueeItemProps } from '../interfaces/';

import { MARQUEE_ITEM_PROPS } from '../constants';

import { MergeClassNames } from '../../../../utils/';


/**
 * Marquee Item Component
 * @export
 * @class CoreMarqueeItemComponent
 * @extends {Component<{}, {}>}
 */
export default class CoreMarqueeItemComponent extends PureComponent<MarqueeItemProps, {}> {
  /**
   * Default Props
   * @static
   * @type {MarqueeItemProps}
   * @memberof CoreMarqueeItemComponent
   */
  public static defaultProps: MarqueeItemProps = cloneDeep(MARQUEE_ITEM_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreMarqueeItemComponent
   */
  public render() {
    const { active, item } = this.props;
    const clz = MergeClassNames('', { active });
    return <li className={clz}>{item}</li>;
  }
}
