import React from 'react';

import PermissionsContext from '../context';
import CorePermissionsBase from './Base';

/**
 * Core Permissions Consumer
 * @export
 * @class CorePermissionsReadOnly
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsConsumer extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsReadOnly
   */
  public render() {
    const {
      service,
      props: { children },
    } = this;
    return (
      <PermissionsContext.Provider value={service}>
        <PermissionsContext.Consumer children={children as any} />
      </PermissionsContext.Provider>
    );
  }
}
