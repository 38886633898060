import React from 'react';

import ControllerChildBase from './Base';
import { ChildrenContain } from '../../../../factories/';
import { CoreFormControllerLabelProps } from '../../interfaces/';
import { CONTROLLER_LABEL_PROPS } from '../../constants/';

import { HashMapMergeProps, MergeClassNames, IsEmpty } from '../../../../../utils/';

/**
 * Controller Label
 * @export
 * @class CoreFormControllerLabelComponent
 * @extends {ControllerChildBase<CoreFormControllerLabelProps>}
 */
export default class CoreFormControllerLabelComponent extends ControllerChildBase<CoreFormControllerLabelProps> {
  /**
   * Default Props
   * @static
   * @type {CoreFormControllerLabelProps}
   * @memberof CoreFormControllerLabelComponent
   */
  public static defaultProps: CoreFormControllerLabelProps = HashMapMergeProps(CONTROLLER_LABEL_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreFormControllerLabelComponent
   */
  public render() {
    const { context: { type, dirty }, props: { className, label, title, htmlFor, disabled, children, help }} = this;

    const labelled: boolean = !IsEmpty(label);
    const kbd: boolean = ChildrenContain(children, 'CoreInputKbd');
    const clz = MergeClassNames(className, { 'input-label': true, [type]: true, labelled, kbd, dirty, disabled });

    return (
      <label className={clz} htmlFor={htmlFor} title={title}>
        {label ? (<dfn>{label}{help}</dfn>) : help}
        {children}
      </label>
    );
  }
}
