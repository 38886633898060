import { PureComponent } from 'react';

import CoreBusy from '../service';

/**
 * Suspense Busy Component
 * @export
 * @class CoreSuspenseBusyComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreSuspenseBusyComponent extends PureComponent<{}, {}> {
  /**
   * LifeCycle Hook
   * @memberof CoreSuspenseBusyComponent
   */
  public componentDidMount() {
    if (CoreBusy.ready) {
      CoreBusy.on();
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreSuspenseBusyComponent
   */
  public componentWillUnmount() {
    if (CoreBusy.ready) {
      CoreBusy.off();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreSuspenseBusyComponent
   */
  public render() {
    return null;
  }
}
