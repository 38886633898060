import { mergeWith } from 'lodash';

import CoreMapCountriesJSON from './maps/countries.json';
import CoreMapContinentsJSON from './maps/continents.json';

import { RGBAAsString } from '../../../utils/';

export const MAP_OPTIONS_DEFAULT: any = {
  chart: {
    styledMode: true,
    type: 'map',
    map: null,
    width: null,
    reflow: true,
  },
  title: { text: null },
  credits: { enabled: false },
  exporting: { enabled: false },
  legend: {
    layout: 'vertical',
    align: 'left',
    verticalAlign: 'middle',
    floating: true,
    navigation: { enabled: false },
  },
  mapNavigation: { enabled: false },
  colorAxis: {
    reversed: false,
    type: 'logarithmic',
    minColor: RGBAAsString({ red: 0, green: 0, blue: 0, alpha: 0 }),
    maxColor: '#999999',
  },
  plotOptions: {
    map: {
      nullColor: null,
      animation: true,
    },
  },
  tooltip: {
    useHTML: true,
    shared: true,
    formatter: null,
  },
  series: [
    {
      name: '',
      joinBy: [],
      data: []
    },
  ],
};

export const MAP_OPTIONS_COUNTRIES = mergeWith({}, MAP_OPTIONS_DEFAULT, {
  chart: { map: CoreMapCountriesJSON },
  series: [
    {
      type: 'map',
      name: 'countries',
      joinBy: ['iso-a2', 'code']
    },
  ],
});

export const MAP_OPTIONS_CONTINENTS = mergeWith({}, MAP_OPTIONS_DEFAULT, {
  chart: { map: CoreMapContinentsJSON },
  series: [
    {
      type: 'map',
      name: 'continents',
      joinBy: ['iso-a2', 'code'],
    },
  ],
});