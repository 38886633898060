import { CoreTableModeType } from './interfaces/';


export const TABLE_PROPS = {
  source: null,
  mode: 'flat' as CoreTableModeType,
};

export const SCROLL_TABLE_PROPS = {
  mode: 'flat' as CoreTableModeType,
  source: null,
  rows: 5,
};

export const SCROLL_TABLE_STATE = {
  rows: 5,
};

export const TABLE_LINK_PROPS = {
  page: null,
};

export const TABLE_BODY_PROPS = {
  threshold: 0,
};

export const TABLE_FOOTER_PROPS = {
  threshold: 0,
};

export const TABLE_COLUMN_PROPS = {
  name: '',
  label: '',
  labelfn: null,
  sort: false,
  hide: false,
};

export const TABLE_CELL_PROPS = {
  name: null,
  ndx: null,
  data: null
};

export const TABLE_HEAD_CELL_PROPS = {
  name: null
};

export const TABLE_ACTION_STATE = {
  active: false
};

export const TABLE_ACTION_PROPS = {
  actions: [],
  mini: false,
  data: null
};

export const TABLE_SEARCH_PROPS = {
  tooltip: false
};

export const TABLE_ACTION_TITLE = 'Toggle action items';

export const TABLE_ACTION_MINI_DEFAULT = ['edit', 'delete', 'clone', 'add', 'remove', 'mark', 'pin', 'view', 'chart'];
