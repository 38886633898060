import gql from 'graphql-tag';

import { CoreAuth, CoreGraphRestProvider, CoreGetApiLocation, WithCoreNotifications } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';

const GET_NOTIFICATIONS_UNREAD = gql`
  query GetNotificationsUnread {
    notifications @rest(type: "Notifications", path: "/notifications/unread", endpoint: "xhr", method: "GET") {
      results
      count
    }
  }
`;

const POST_NOTIFICATIONS_MARK = gql`
  mutation MarkAllAsRead($input: Any!) {
    notifications(input: {}) @rest(type: "Notifications", path: "/notifications/mark/read", endpoint: "xhr", method: "POST") {
      data
    }
  }
`;

/**
 * Notifications Plugin
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<boolean> {
  try {

    const uri: string = 'https://config.qasiteprotect.com';

    const xhr: string = `${uri}/xhr`;
    const endpoints: HashMap<string> = { xhr };
    const credentials: string = 'include';

    const NotificationsProvider = CoreGraphRestProvider({ uri, endpoints, credentials }, CoreAuth);

    /**
     * WithCoreNotifications
     * @note
     * - These arguments replace core-ui's default Apollo structures with
     * Restful Versions that structures specific to the current framework (hybrid).
     */
    const manage = CoreGetApiLocation('manage');
    WithCoreNotifications(NotificationsProvider, GET_NOTIFICATIONS_UNREAD, POST_NOTIFICATIONS_MARK, CORE_EPOCH_MINUTE, `${manage}/notifications/view`, `${manage}/notifications/view`);

    return true;
  } catch (err: any) {
    return Promise.reject(err);
  }
}


// import { CoreNotificationsGraphStatic } from '@neustar/core-ui/lib/Notifications/graph/';
// import { CoreGetApiLocation } from '@neustar/core-ui';

// /**
//  * Notifications Plugin
//  * @export
//  * @returns {Promise<boolean>}
//  */
// export default async function NotificationsPlugin(): Promise<boolean> {
//   try {

//     const url = CoreGetApiLocation('manage');

//     CoreNotificationsGraphStatic.VIEW = `${url}/notifications/view`;
//     CoreNotificationsGraphStatic.ALL = `${url}/notifications/view`;
//     CoreNotificationsGraphStatic.TEST_MODE = true;

//     return true;
//   } catch (err: any) {
//     return Promise.reject(err);
//   }
// }
