import React, { PureComponent } from 'react';
import { MergeClassNames } from '../../../../../utils/';

/**
 * Form Group Inline
 * @export
 * @class CoreFormGroupInlineContainer
 * @extends {PureComponent<GenericPropType, {}>}
 * @notes
 * 1. A block level container that arranges direct child <CoreFormGroup/> as Inline Blocks.
 */
export default class CoreFormGroupInlineContainer extends PureComponent<GenericPropType, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreFormGroupInlineContainer
   */
  public render() {
    const { className, children } = this.props;

    const clz = MergeClassNames(className, {'form-group-inline': true });

    return (
      <div className={clz}>
        {children}
      </div>
    );
  }
}
