import React, { FormEvent } from 'react';
import moment from 'moment';

import DatePickerBase from './Base';

import { CoreFormElementDate, CoreFormInputProps } from '../../forms/';
import { IsEmpty, IsFunction, IsChromeBrowser, AsyncComponentTimeout } from '../../../../utils/';

/**
 * End Date Component
 * @export
 * @class EndDateComponent
 * @extends {DatePickerBase<CoreFormInputProps>}
 */
export default class EndDateComponent extends DatePickerBase<CoreFormInputProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof EndDateComponent
   */
  public static displayName: string = 'EndDate';

  /**
   * Watch
   * @private
   * @type {Required<AsyncComponentTimeout>}
   * @memberof EndDateComponent
   */
  private watch: Required<AsyncComponentTimeout> = new AsyncComponentTimeout();

  /**
   * Render
   * @returns
   * @memberof EndDateComponent
   */
  public render() {
    const { OnEndDateChange, OnEndDateBlur, context: { end_date_name, end_date_value, start_date_value, max_date_value }, props: { required: REQUIRED } } = this;
    const required: boolean = IsFunction(REQUIRED) ? (REQUIRED as any)() : REQUIRED;
    const disabled: boolean = required && IsEmpty(start_date_value);
    return <CoreFormElementDate key={`end.date.picker.${Date.now()}`} name={end_date_name} value={end_date_value} onChange={OnEndDateChange} onBlur={OnEndDateBlur} min={start_date_value} max={max_date_value} disabled={disabled} required={required} className="width-16" />;
  }

  /**
   * On End Date Change
   * @protected
   * @param {FormEvent<HTMLInputElement>} { target, currentTarget: { validity: { valid } }}
   * @param {CoreFormValueType} value
   * @memberof EndDateComponent
   */
  /* istanbul ignore next */
  protected OnEndDateChange = ({ target, currentTarget: { validity: { valid } }}: FormEvent<HTMLInputElement>, value: CoreFormValueType): void => {
    const { DoDateChange } = this;
    if (IsChromeBrowser()) {
      if (!this.context.ready) {
        this.watch.clear();
        this.watch.delay(2000).then(() => {
          (target as any).blur();
        });
      }
    } else {      
      if (valid) {
        DoDateChange(value);
      }
    }
  };

  /**
   * On End Date Blur
   * @protected
   * @param {FormEvent<HTMLInputElement>} { currentTarget: { validity: { valid } }}
   * @param {CoreFormValueType} value
   * @memberof EndDateComponent
   */
  /* istanbul ignore next */
  protected OnEndDateBlur = ({ currentTarget: { validity: { valid } }}: FormEvent<HTMLInputElement>, value: CoreFormValueType): void => {
    const { DoDateChange } = this;
    if (IsChromeBrowser()) {
      if (valid) {
        DoDateChange(value);
      }
    }
  };

  /**
   * Do Date Change
   * @protected
   * @param {CoreFormValueType} value
   * @memberof EndDateComponent
   */
  /* istanbul ignore next */
  protected DoDateChange = (value: CoreFormValueType): void => {
    const { dayspan, start_date_value } = this.context;
    const end_date_value = value as string;
    if (dayspan) {
      const diff = moment(end_date_value).diff(moment(start_date_value), 'days');

      if (diff < dayspan) {
        const max: number = moment(end_date_value).endOf('day').valueOf();
        const min: number = moment(end_date_value).subtract(dayspan, 'days').startOf('day').valueOf();
        this.context.min = min;
        this.context.max = max;
      }
    }

    this.context.update(undefined, end_date_value);
  };
}