export enum ErrorTypesEnum {
	badInput = 'badInput',
	patternMismatch = 'patternMismatch',
	rangeOverflow = 'rangeOverflow',
	rangeUnderflow = 'rangeUnderflow',
	stepMismatch = 'stepMismatch',
	tooLong = 'tooLong',
	tooShort = 'tooShort',
	typeMismatch = 'typeMismatch',
	valueMissing = 'valueMissing'
}
