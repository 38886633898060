import { ComponentType } from 'react';

import { CoreAuth } from '../../Auth/';

import { CoreHeaderToolServiceShape } from '../interfaces/';
import { IsNull } from '../../utils/';

/**
 * Header Tool Service
 * @export
 * @class CoreHeaderToolService
 * @implements {CoreHeaderToolServiceShape}
 */
export class CoreHeaderToolService implements CoreHeaderToolServiceShape {
  /**
   * Tool
   * @static
   * @type {ReactNode}
   * @memberof CoreHeaderToolService
   */
  public static Tool: ComponentType = null;

  /**
   * Permissions
   * @protected
   * @type {Func<boolean>}
   * @memberof CoreHeaderToolService
   */
  /* istanbul ignore next */
  protected permissions: Func<boolean> = () => {
    return CoreAuth.user.role.isSuperUser;
  };

  /**
   * Use
   * @param {ComponentType} component
   * @param {Func<boolean>} [permissions]
   * @memberof CoreHeaderToolService
   */
  public use(component: any, permissions?: Func<boolean>): void {
    CoreHeaderToolService.Tool = component;
    if (permissions) {
      this.permissions = permissions;
    }
  }

  /**
   * Installed - getter
   * @readonly
   * @memberof CoreHeaderMenuService
   */
  public get installed() {
    return !IsNull(CoreHeaderToolService.Tool);
  }

  /**
   * Allowed - getter
   * @readonly
   * @memberof CoreHeaderMenuService
   */
  public get allowed() {
    return this.permissions();
  }

  /**
   * Ready - getter
   * @readonly
   * @memberof CoreHeaderMenuService
   */
  public get ready() {
    return this.installed && this.allowed;
  }
}
