import React, { PureComponent } from 'react';

import CoreFormContext from '../../contexts/Form';

import { CoreFormButtonProps } from '../../interfaces/';
import { CoreFormEventsEnum } from '../../enums/';
import { RESET_PROPS, FORM_EVENT_DELAY } from '../../constants/';
import { HashMapMergeProps, WaitForDelay } from '../../../../../utils/';

/**
 * Reset Container
 * @export
 * @class CoreFormResetContainer
 * @extends {PureComponent<CoreFormButtonProps, {}>}
 */
export default class CoreFormResetContainer extends PureComponent<CoreFormButtonProps, {}> {
  /**
   * Form Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreFormResetContainer
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default Props
   * @static
   * @type {CoreFormButtonProps}
   * @memberof CoreFormResetContainer
   */
  public static defaultProps: CoreFormButtonProps = HashMapMergeProps(RESET_PROPS);

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreFormResetContainer
   */
  protected listener: AbortController;

   /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentDidMount() {
    const { FormEventListener, context } = this;
    this.listener = new AbortController();
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_RESET, FormEventListener);
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_SUBMIT, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    listener.abort();
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_RESET, FormEventListener);
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_SUBMIT, FormEventListener);
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormResetContainer
   */
  public render() {
    const { props: { label, type }, context: { validate, dirty } } = this;
    const disabled: boolean = validate ? dirty === false : false;
    return <nux-button-secondary type={type} outline disabled={disabled} tabIndex={-1}>{label}</nux-button-secondary>
  }

  /**
   * Form Event Listener
   * @protected
   * @memberof CoreFormResetContainer
   */
  /* istanbul ignore next */
  protected FormEventListener = (): void => {
    WaitForDelay(FORM_EVENT_DELAY).then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };
}
