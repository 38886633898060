import React, { PureComponent } from 'react';

import CoreTableContext from '../context';

/**
 * Core Table Consumer
 * @export
 * @class CoreTableConsumer
 * @extends {PureComponent<any, {}>}
 */
export default class CoreTableConsumer extends PureComponent<any, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableConsumer
   */
  public static displayName = 'CoreTableConsumer';

  /**
   * Render
   * @return {*}
   * @memberof CoreTableConsumer
   */
  public render() {
    return (
      <CoreTableContext.Consumer>
        {this.props.children as any}
      </CoreTableContext.Consumer>
    );
  }
}
