import React, { Component, Children, ReactElement, cloneElement, Fragment } from 'react';
import { clone, cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import CoreTableFooter from './PaginationFooter';

import { CoreFilterFlatFactory, CorePaginateFlatFactory, CoreSortFlatFactory } from '../factories/';
import { CoreTableBodyProps } from '../interfaces/';
import { TABLE_BODY_PROPS } from '../constants';

/**
 * Table Body Container
 * @export
 * @class CoreTableBodyContainer
 * @extends {Component<CoreTableBodyProps, {}>}
 */
export default class CoreTableBodyContainer extends Component<CoreTableBodyProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableBodyContainer
   */
  public static displayName = 'CoreTableBody';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableBodyContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableBodyProps}
   * @memberof CoreTableBodyContainer
   */
  public static defaultProps: CoreTableBodyProps = cloneDeep(TABLE_BODY_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreTableBodyContainer
   */
  public componentDidMount() {
    const { context } = this;
    context.subscribe(CoreTableBodyContainer.displayName, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableBodyContainer
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(CoreTableBodyContainer.displayName);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableBodyContainer
   */
  public render() {
    const {
      props: { threshold, children },
      context: {
        mode,
        columns,
        search,
        sort,
        pagination,
        collection: { show, results },
      },
    } = this;

    if (!show) {
      return null;
    }

    columns.count = Children.count(children);

    let output: Array<HashMap<any>> = results.slice(0);

    if (mode === 'flat') {
      // filter first
      if (search.searchable) {
        output = CoreFilterFlatFactory(output, search.fields, search.term);

        // because filter has the power to change results array length
        if (pagination.pageable) {
          const count = output.length;
          const page = count / pagination.limit < pagination.page ? 0 : pagination.page;
          pagination.update(page, undefined, count);
        }
      }

      // sort
      if (sort.sortable) {
        output = CoreSortFlatFactory(output, sort.column, sort.method);
      }

      // paginate
      if (pagination.pageable) {
        output = CorePaginateFlatFactory(output, pagination.page, pagination.limit);
      }
    }

    /**
     * When pagination is OFF, but threshold is non-zero:
     * "threshold" behaves as an Array Limit.
     */
    if (threshold && !pagination.pageable) {
      output = output.slice(0, threshold);
    }

    return (
      <Fragment>
        <CoreTableFooter threshold={threshold} />
        <tbody>
          {output.map((row: GenericDataType, ndx: number): JSX.Element => {
            const data = clone(row);
            return (
              <tr key={`tr.${ndx}.${pagination.page}`}>
                {Children.map(children, (child: any) => {
                  return cloneElement(child as ReactElement, { ndx, data });
                })}
              </tr>
            );
          })}
        </tbody>
      </Fragment>
    );
  }
}
