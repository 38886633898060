import React, { Component, ComponentClass, Fragment, FormEvent, FocusEvent, Children, RefObject, createRef, cloneElement } from 'react';

import CoreFormContext from '../../contexts/Form';

import { CoreFormServiceType, CoreFormControllerInstanceType, CoreFormValueType, CoreFormOptionsType } from '../../types';
import { CoreFormControllerProps, ControllerMenuProps } from '../../interfaces/';
import { CoreFormEventsEnum as E } from '../../enums/';
import { HTMLQuadrantEnum } from '../../../../enums/';
import { ControllerGetMakeInit, ControllerElementPropsFilter, ControllerValidateProps, ControllerShouldStash } from '../../factories/';

import { IsFunction, IsEqual, IsDefined, WaitForDelay, MergeClassNames, GetDeepHashMap } from '../../../../../utils/';

/**
 * Controller Base
 * @export
 * @abstract
 * @class CoreFormControllerBase
 * @extends {Component<CoreFormControllerProps, {}>}
 * @priority - SECONDARY
 * @notes
 * 1. Internally stateful component.
 * 2. Listens for Form Events
 * 3. Initializes Declared props.
 * 4. Prioritizes Declarative over Initial
 * 5. Synchronizes Imperative operations.
 * 6. Manages prop sets by child type.
 */
export default abstract class CoreFormControllerBase extends Component<CoreFormControllerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreFormControllerBase
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Controlled Element
   * @static
   * @type {ComponentClass<any>}
   * @memberof CoreFormControllerBase
   */
  public static ControlledElement: ComponentClass<any>;

  /**
   * Help Node
   * @protected
   * @type {*}
   * @memberof CoreFormControllerBase
   */
  protected help: any = null;

  /**
   * Keyboard
   * @protected
   * @type {*}
   * @memberof CoreFormControllerBase
   */
  protected kbd: any = null;

  /**
   * Tooltip
   * @protected
   * @type {*}
   * @memberof CoreFormControllerBase
   */
  protected tooltip: any = null;

  /**
   * Description
   * @protected
   * @type {*}
   * @memberof CoreFormControllerBase
   */
  protected description: any = null;

  /**
   * Datalist
   * @protected
   * @type {*}
   * @memberof CoreFormControllerBase
   */
  protected datalist: any = null;

  /**
   * Ref Object
   * @private
   * @type {(RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>)}
   * @memberof CoreFormControllerBase
   */
  private _ref: RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

  /**
   * Controller
   * @private
   * @type {CoreFormControllerInstanceType}
   * @memberof CoreFormControllerBase
   */
  private _controller: CoreFormControllerInstanceType;

  /**
   * Listener - AbortController
   * @private
   * @type {AbortController}
   * @memberof CoreFormControllerBase
   */
  private _listener: AbortController;

  /**
   * Creates an instance of CoreFormControllerBase.
   * @param {CoreFormControllerProps} props
   * @param {CoreFormServiceType} context
   * @memberof CoreFormControllerBase
   */
  constructor(props: CoreFormControllerProps, context: CoreFormServiceType) {
    super(props);
    this._ref = createRef();
    this.init(props, context);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormControllerBase
   */
  public componentDidMount() {
    const { ControllerEventListener, controller, ControllerRef } = this;

    controller.emitter.on(E.CONTROLLER_EVENT_UPDATE, ControllerEventListener);

    /**
     * Init Listener
     */
    this._listener = new AbortController();

    /**
     * Now that it's available, supply controller with Element Ref
     */
    controller.ref = ControllerRef;
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormControllerBase
   */
  public componentWillUnmount() {
    const { ControllerEventListener, controller, listener } = this;

    controller.emitter.off(E.CONTROLLER_EVENT_UPDATE, ControllerEventListener);

    /**
     * Abort Listener
     */
    listener.abort();
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormControllerBase
   * @notes
   * For Declarative Changes [Priority: Secondary]
   */
  /* istanbul ignore next */
  public shouldComponentUpdate(nprops: CoreFormControllerProps) {
    const { controller, props } = this;

    /**
     * Props Comparison
     * - Changes that require controller update.
     */
    if (!IsEqual(props.value, nprops.value) || !IsEqual(props.options, nprops.options)) {
      controller.install(nprops.value, nprops.options);
      return true;
    }

    /**
     * Other Props Comparison
     * - Changes that DONT require controller update.
     */
    if (!IsEqual(props, nprops)) {
      return true;
    }

    return false;
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormControllerBase
   */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>
  }

  /**
   * Controller Event Listener
   * @protected
   * @param {string} name
   * @param {CoreFormValueType} value
   * @param {CoreFormOptionsType} options
   * @param {boolean} [install=false]
   * @memberof CoreFormControllerBase
   * For imperative changes [Priority: Secondary]
   */
  /* istanbul ignore next */
  protected ControllerEventListener = (name: string, value: CoreFormValueType, options: CoreFormOptionsType, install: boolean = false): void => {
    const { controller, listener } = this;
    if (name === controller.name) {
      if (install || (value !== controller.value || !IsEqual(options, controller.options))) {
        /* istanbul ignore next */
        WaitForDelay().then(() => {
          if (!listener.signal.aborted) {
            this.forceUpdate();
          }
        });
      }
    }
  };

  /**
   * On Change Or Input
   * @protected
   * @param {FormEvent<any>} evt
   * @param {CoreFormValueType} value
   * @memberof CoreFormControllerBase
   */
  /* istanbul ignore next */
  protected OnChangeOrInput = (evt: FormEvent<any>, value: CoreFormValueType): void => {
    const { context, controller, listener, props: { name, onChange } } = this;

    controller.update(value);

    /* istanbul ignore next */
    WaitForDelay().then(() => {
      if (!listener.signal.aborted) {
        context.emit(E.FORM_EVENT_UPDATE, name, value);

        /**
         * Adjust value of pseudo parent if any...
         */
        if (/\.\d+$/.test(name)) {
          const pname: string = name.replace(/\.\d+$/, '');
          if (context.has(pname)) {
            const parent = context.registry.get(pname);
            const compare: any = GetDeepHashMap(context.source, pname);
            if (!IsEqual(parent.value, compare)) {
              parent.install(compare);
            }
          }
        }

      }
    });

    if (IsDefined(onChange)) {
      onChange(evt, context, controller.value);
    }
  };

  /**
   * On Input
   * @protected
   * @param {FormEvent<any>} evt
   * @param {CoreFormValueType} value
   * @memberof CoreFormControllerBase
   */
  /* istanbul ignore next */
  protected OnInput = (evt: FormEvent<any>, value: CoreFormValueType): void => {
    const { context, props: { onInput } } = this;
    if (IsDefined(onInput)) {
      onInput(evt, context, value);
    }
  };

  /**
   * On Blur
   * @protected
   * @param {FocusEvent<any>} evt
   * @param {CoreFormValueType} value
   * @memberof CoreFormControllerBase
   */
  /* istanbul ignore next */
  protected OnBlur = (evt: FocusEvent<any>, value: CoreFormValueType): void => {
    const { context, controller, listener, props: { name, onBlur } } = this;

    controller.update(value);

    /* istanbul ignore next */
    WaitForDelay().then(() => {
      if (!listener.signal.aborted) {
        context.emit(E.FORM_EVENT_UPDATE, name, value);
      }
    });

    if (IsDefined(onBlur)) {
      onBlur(evt, context, value);
    }
  };

  /**
   * Init
   * @protected
   * @param {CoreFormControllerProps} props
   * @param {CoreFormServiceType} context
   * @memberof CoreFormControllerBase
   * @notes
   * For Interpretive Instantiation [Priority: Initial]
   */
  protected init = (props: CoreFormControllerProps, context: CoreFormServiceType): void => {
    try {
      /**
       * Validate Controller
       */
      const error = ControllerValidateProps(props, context);

      /* istanbul ignore next */
      if (error) {
        throw error;
      }

      /**
       * Set Controller Instance as class property
       */
      this._controller = ControllerGetMakeInit(props, context);

      /**
       * Stash Controller Value to Vault.
       */
      if (ControllerShouldStash(this.controller, context)) {
        context.stash(this.controller);
      }


      const { children } = props;

      /**
       * Screen Children.
       */
      if (children) {
        Children.toArray(children).forEach((child: any): void => {
          if (IsDefined(child.type)) {
            if (child.type.displayName === 'CoreHelp') {
              /* istanbul ignore next */
              if (!child.props.quadrant) {
                const quadrant = HTMLQuadrantEnum.TOP_LEFT;
                child = cloneElement(child, { quadrant });
              }
              this.help = child;
            } else if (child.type.displayName === 'CoreTooltip') {
              this.tooltip = child;
            } else if (child.type.displayName === 'CoreInputKbd') {
              this.kbd = child;
            } else if (child.type.displayName === 'CoreInputDescription') {
              this.description = child;
            } else if (child.type.displayName === 'CoreInputDatalist') {
              const allowed: string[] = ['text', 'email', 'search'];
              if (!!~allowed.indexOf(props.type)) {
                this.datalist = child;
              } else {
                /* istanbul ignore next */
                throw new ReferenceError(`datalist only supported for type=[${allowed.join(', ')}]`);
              }
            }
          }
        });
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  };

  /**
   * Controller Instance - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  public get controller() {
    return this._controller;
  }

  /**
   * Label Props - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  public get labels() {
    const { help, datalist, controller: { type, dirty }, props: { className, label, title, disabled: DISABLED } } = this;
    const htmlFor: string = help || datalist ? '_' : null;
    const disabled: boolean = IsFunction(DISABLED) ? (DISABLED as any)() : DISABLED;
    return { className, type, label, title, dirty, disabled, htmlFor, help };
  }

  /**
   * Element Props - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  public get elements() {
    const { OnInput, OnBlur, ControllerRef, props: { className: CLASSNAME, ...rest } } = this;
    const className: string = MergeClassNames(CLASSNAME, { 'as-input': true });
    const props = ControllerElementPropsFilter(rest, OnInput, OnBlur);
    return { className, ControllerRef, ...props };
  }

  /**
   * Exceptions Props - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  public get exceptions() {
    const { errors } = this.props;
    return { errors };
  }

  /**
   * Menu Props - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  public get menu(): ControllerMenuProps {
    const { controller, props: { filter, allornone, tabIndex, placeholder, required: REQUIRED, disabled: DISABLED }} = this;
    const required: boolean = IsFunction(REQUIRED) ? (REQUIRED as any)() : REQUIRED;
    const disabled: boolean = IsFunction(DISABLED) ? (DISABLED as any)() : DISABLED;
    return { controller, filter, allornone, tabIndex, placeholder, required, disabled };
  }

  /**
   * ControllerRef - getter
   * @readonly
   * @protected
   * @memberof CoreFormControllerBase
   */
  protected get ControllerRef() {
    return this._ref;
  }

  /**
   * ControlledElement - getter
   * @readonly
   * @memberof CoreFormControllerBase
   */
  protected get ControlledElement() {
    return (this.constructor as any).ControlledElement;
  }

  /**
   * Listener - getter
   * @readonly
   * @private
   * @type {AbortController}
   * @memberof CoreFormControllerBase
   */
  private get listener(): AbortController {
    return this._listener;
  }
}


/*
NOTES
=============
Flow - As Controller.
-------------
Initial: constructor -> init -> render -> componentDidMount
Primary: OnChangeOrInput -> ControllerEventListener -> render -> render {why twice?}
Secondary: ControllerEventListener -> shouldComponentUpdate -> render
=============
*/
