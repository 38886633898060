import { cloneDeep } from 'lodash';
import { CoreUserProfileServiceShape, CoreUserInfoShape } from '../interfaces/';

import { THEME_DEFAULT, USER_ACCOUNT_DEFAULT, USERINFO_DEFAULT } from '../constants/';

/**
 * User Profile
 * @export
 * @class CoreUserProfile
 * @implements {CoreUserProfileServiceShape}
 */
export default class CoreUserProfile implements CoreUserProfileServiceShape {
  /**
   * Userinfo
   * @memberof CoreUserProfile
   */
  public userinfo = cloneDeep(USERINFO_DEFAULT);

  /**
   * Account - dname
   * @private
   * @type {string}
   * @memberof CoreUserProfile
   */
  private _account: string = null!;

  /**
   * Shortname - shortname
   * @private
   * @type {string}
   * @memberof CoreUserProfile
   */
  private _shortname: string = null!;

  /**
   * Masquerade
   * @private
   * @type {string}
   * @memberof CoreUserProfile
   */
  private _masquerade: boolean = false;

  /**
   * Install
   * @param {CoreUserInfoShape} userinfo
   * @memberof CoreUserProfile
   */
  public install(userinfo: CoreUserInfoShape): void {
    this.userinfo = userinfo;
    /* istanbul ignore next */
    if (userinfo.nsr && userinfo.nsr.dnm) {
      this._account = userinfo.nsr.dnm;
    }
    this._masquerade = userinfo.user_metadata && userinfo.user_metadata.masquerade ? true : false;
  }

  /**
   * ID - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get id() {
    const { sub } = this.userinfo;
    return sub ? sub.split('|').pop() : undefined;
  };

  /**
   * Username
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get username() {
    const { name, given_name, family_name, nickname, user_metadata } = this.userinfo;
    if (name) {
      return name;
    }
    if (given_name && family_name) {
      return [given_name, family_name].join(' ');
    }
    if (user_metadata && user_metadata.first_name && user_metadata.last_name) {
      return [user_metadata.first_name, user_metadata.last_name].join(' ');
    }
    if (nickname) {
      return nickname;
    }
    return '';
  }

  /**
   * Email - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get email() {
    const { email } = this.userinfo;
    return email || '';
  };

  /**
   * Picture - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get picture() {
    const { picture } = this.userinfo;
    return picture || null!;
  };

  /**
   * Updated At - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get updated_at() {
    const { updated_at } = this.userinfo;
    return updated_at || null!;
  };

  /**
   * Account - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get account() {
    return this._account || USER_ACCOUNT_DEFAULT.slice(0);
  }

  /**
   * Account - setter
   * @memberof CoreUserProfile
   * @notes - WORM
   */
  /* istanbul ignore next */
  public set account(account: string) {
    if (!this.account || this.masquerade) {
      this._account = account;
    }
  }

  /**
   * Shortname - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get shortname() {
    return this._shortname;
  }

  /**
   * Shortname - setter
   * @memberof CoreUserProfile
   * @notes - WORM
   */
  /* istanbul ignore next */
  public set shortname(shortname: string) {
    if (!this.shortname || this.masquerade) {
      this._shortname = shortname;
    }
  }

  /**
   * Theme
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get theme() {
    const { user_metadata: { theme } } = this.userinfo;
    return theme || THEME_DEFAULT.slice(0);
  }

  /**
   * Masquerade - getter
   * @readonly
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public get masquerade() {
    return this._masquerade;
  }

  /**
   * Masquerade - setter
   * @memberof CoreUserProfile
   */
  /* istanbul ignore next */
  public set masquerade(masquerade: boolean) {
    this._masquerade = masquerade;
  }
}
