import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions NotAdmin
 * @export
 * @class CorePermissionsNotAdmin
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsNotAdmin extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsNotAdmin
   */
  public render() {
    const {
      service: { IsAdmin },
      props: { children },
    } = this;
    return IsAdmin ? null : <Fragment>{children}</Fragment>;
  }
}
