import React, { Component, Fragment } from 'react';

import CoreConfigContext from '../../Config/context';

import { CoreAnchor } from '../../shared/';

/**
 * Footer Nav Right Component
 * @export
 * @class CoreFooterNavRightComponent
 * @extends {CoreConfigBounce<Component<{}, {}>>}
 */
export default class CoreFooterNavRightComponent extends Component<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreFooterNavLeftComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Render
   * @returns
   * @memberof CoreFooterNavRightComponent
   */
  public render() {
    const { product_name, contact_information } = this.context.footer;

    /* istanbul ignore next */
    const brspace = contact_information ? ' | ' : null;

    return (
      <Fragment>
        {product_name}
        {brspace}
        {contact_information
          ? contact_information
              .trim()
              .split('|')
              .map((phone: string, ndx: number) => {
                const [label, value, human_readable]: string[] = phone.trim().split(':');
                const mt = ndx ? ' | ' : null;
                return (
                  <Fragment key={`footer.link.${ndx}`}>
                    {mt}
                    <span>{label}</span>{': '}
                    <strong>
                      <CoreAnchor href={`tel:${value}`} itemProp="telephone">
                        {human_readable || value}
                      </CoreAnchor>
                    </strong>
                  </Fragment>
                );
              })
          : null}
      </Fragment>
    );
  }
}
