import { CoreConfigPermissions } from '@neustar/core-ui';

/**
 * Permissions Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function PermissionsPlugin(): Promise<boolean|Error> {
  try {
    CoreConfigPermissions.COMPLEX = ['alert', 'audit', 'bot', 'certificate', 'config-engine', 'company', 'devices', 'mitigations', 'nis', 'policy', 'proxy', 'traffic', 'vip-pools', 'reports', 'user'];
    CoreConfigPermissions.SIMPLE = ['ui:soc-tools'];
    return true;
  } catch (err: any) {
    throw Error(err);
  }
};
