import React, { PureComponent } from 'react';

import { SelectSingleController, SelectMultipleController } from './Selects/';
import { CoreFormControllerProps } from '../../interfaces/';
import { CORE_SELECT_PROPS, SELECT_TYPE_SELECT, SELECT_TYPE_MULTIPLE } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Select Container
 * @export
 * @class CoreSelectContainer
 * @extends {PureComponent<CoreFormControllerProps, {}>}
 */
export default class CoreSelectContainer extends PureComponent<CoreFormControllerProps, {}> {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormControllerProps, 'name'>}
   * @memberof CoreSelectContainer
   */
  public static defaultProps: Omit<CoreFormControllerProps, 'name'> = HashMapMergeProps(CORE_SELECT_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreSelectContainer
   */
  public render() {
    const { props } = this;
    switch (props.type) {
      case SELECT_TYPE_SELECT:
        return <SelectSingleController {...props} />;
      case SELECT_TYPE_MULTIPLE:
        return <SelectMultipleController {...props} />;
      /* istanbul ignore next */
      default:
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`DEVELOPER ERROR:: not a select type="${props.type}".`);
        }
        return null;
    }
  }
}
