import { CoreBootAuthPluginsShape } from '../interfaces/';

/**
 * NOOP callbacks - resolve
 */
/* istanbul ignore next */
const NP = (name: string): Func<Promise<any>> => {
  return async (): Promise<any> => {
    if (process.env.NODE_ENV !== 'production') {
      console.info(`CoreBootAuthPlugins::noop->resolve [${name}]`);
    }
    // this MUST  resolve true because `type: authenticate` must resolve a boolean to succeed.
    return Promise.resolve(true);
  };
};

/**
 * NOOP callbacks - reject
 */
/* istanbul ignore next */
const NPR = (name: string): Func<Promise<any>> => {
  return async (): Promise<any> => {
    if (process.env.NODE_ENV !== 'production') {
      console.info(`CoreBootAuthPlugins::noop->reject [${name}]`);
    }
    return Promise.reject();
  };
};

/**
 * Boot Plugins Service
 * @class CoreBootAuthPluginsService
 * @implements {CoreBootAuthPluginsShape}
 */
class CoreBootAuthPluginsService implements CoreBootAuthPluginsShape {
  /**
   * Authenticate Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _authenticate: Func<Promise<any>, any> = NP('authenticate');

  /**
   * Logouts Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _logout: Func<Promise<any>, any> = NP('logout');

  /**
   * Activate Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _activate: Func<Promise<any>, any> = NPR('activate');

  /**
   * Reset Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _reset: Func<Promise<any>, any> = NPR('reset');

  /**
   * Forgot Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _forgot: Func<Promise<any>, any> = NPR('forgot');

  /**
   * User Type Plugin
   * @private
   * @type {Func<Promise<any>, any>}
   * @memberof CoreBootAuthPluginsService
   */
  private _user: Func<Promise<any>, any> = NP('user');

  /**
   * Register Plugins
   * @param {CoreBootAuthPluginType} type
   * @param {Func<Promise<any>, any>} callback
   * @memberof CoreBootAuthPluginsService
   */
  public register(type: CoreBootAuthPluginType, callback: Func<Promise<any>, any>): void {
    switch (type) {
      case 'authenticate':
        this._authenticate = callback;
        break;
      case 'logout':
        this._logout = callback;
        break;
      case 'activate':
        this._activate = callback;
        break;
      case 'reset':
        this._reset = callback;
        break;
      case 'forgot':
        this._forgot = callback;
        break;
      case 'user':
        this._user = callback;
        break;
    }
  }

  /**
   * Apply
   * @param {CoreBootAuthPluginType} type
   * @param {*} value
   * @returns {Promise<any>}
   * @memberof CoreBootAuthPluginsService
   */
  public apply(type: CoreBootAuthPluginType, value: any): Promise<any> {
    switch (type) {
      case 'authenticate':
        return this.authenticate(value);
      case 'logout':
        return this.logout(value);
      case 'activate':
        return this.activate(value);
      case 'reset':
        return this.reset(value);
      case 'forgot':
        return this.forgot(value);
      case 'user':
        return this.user(value);
    }
  }

  /**
   * Type Getters
   * @readonly
   * @memberof CoreBootAuthPluginsService
   */
  /* istanbul ignore next */
  public get authenticate() {
    return this._authenticate;
  }
  /* istanbul ignore next */
  public get logout() {
    return this._logout;
  }
  /* istanbul ignore next */
  public get activate() {
    return this._activate;
  }
  /* istanbul ignore next */
  public get reset() {
    return this._reset;
  }
  /* istanbul ignore next */
  public get forgot() {
    return this._forgot;
  }
  /* istanbul ignore next */
  public get user() {
    return this._user;
  }
}

/**
 * Boot Plugins
 * @export
 * @class CoreBootAuthPlugins
 * @implements {CoreBootAuthPluginsShape}
 */
export const CoreBootAuthPlugins = new CoreBootAuthPluginsService();
