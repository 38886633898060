
import CoreFormElementSelectBase from './BaseSelect';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, SELECT_TYPE_MULTIPLE, SELECT_DEFAULT_LABEL } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Select Multiple Element
 * @export
 * @class CoreFormElementSelectMultiple
 * @extends {CoreFormElementSelectBase}
 */
export default class CoreFormElementSelectMultiple extends CoreFormElementSelectBase {
  public static defaultProps: Omit<CoreFormElementProps, 'name' | 'options'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: SELECT_TYPE_MULTIPLE,
    placeholder: SELECT_DEFAULT_LABEL,
    value: []
  });
}
