import { CORE_EPOCH_SECOND } from '../utils/';

export const NOTIFICATION_LEVEL_TYPE_ERROR = 'error';
export const NOTIFICATION_LEVEL_TYPE_SUCCESS = 'success';
export const NOTIFICATION_LEVEL_TYPE_WARNING = 'warning';
export const NOTIFICATION_LEVEL_TYPE_INFO = 'info';

export const NOTIFICATION_DEFAULT_STATE = {
  id: '',
  date: '',
  level: NOTIFICATION_LEVEL_TYPE_INFO,
  subject: '',
  type: '',
  from_soc: false,
  content: '',
  email_content: '',
  values: null,
};

export const NOTIFICATION_ITEM_PROPS = {
  item: {},
};

export const NOTIFICATIONS_POLL_INTERVAL = CORE_EPOCH_SECOND * 10;

export const NOTIFICATIONS_UPDATE_EVENT = 'NOTIFICATIONS_UPDATE_EVENT';
