import React, { PureComponent } from 'react';

/**
 * Busy Icon
 * @export
 * @class CoreBusyIconComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBusyIconComponent extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBusyIconComponent
   */
  public render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <rect x="0" y="0" fill="none" />
        <circle cx="50" cy="50" r="40" stroke="#5c5c5c" fill="none" strokeWidth="10" strokeLinecap="round" />
        <circle cx="50" cy="50" r="40" stroke="#E01F78" fill="none" strokeWidth="6" strokeLinecap="round">
          <animate attributeName="stroke-dashoffset" dur="3s" repeatCount="indefinite" from="0" to="502" />
          <animate attributeName="stroke-dasharray" dur="3s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4" />
        </circle>
      </svg>
    );
  }
}
