import { get } from 'lodash';
import { IsDefined, IsNumber } from '../../../../utils/';

/**
 * Filter Flat Factory
 * @param {Array<HashMap<any>>} data
 * @param {string[]} fields
 * @param {string} term
 * @returns {GenericDataType<Array<HashMap<any>>>}
 * @memberof CoreTableSortService
 */
export const CoreFilterFlatFactory = (data: Array<HashMap<any>>, fields: string[], term: string): Array<HashMap<any>> => {
  try {
    if (!Array.isArray(data) || !data.length) {
      return [];
    }

    term = term.toLowerCase();

    const FieldsHaveTerm = (src: HashMap<any>): boolean => {
      let i: number = fields.length;
      let field: string;
      let value: string;
      while (i--) {
        field = fields[i];
        value = get(src, field);
        if (IsDefined(value)) {          
          if (IsNumber(value)) {
            value = String(value);
          }
          value = value.toLowerCase();
          if (!!~value.indexOf(term)) {
            return true;
          }
        }
      }
      return false;
    };

    data = data.filter((row) => FieldsHaveTerm(row));

  } catch (err: any) {
    /* istanbul ignore next */
    console.error(err);
  }

  return data;
};
