import { WebAuth, Auth0Error } from 'auth0-js';
import jwtDecode from 'jwt-decode';

import { CoreAuthCredentialsService } from '../services/';
import { CoreUserInfoShape } from '../interfaces/';
import {
  AUTH_RESPONSE_TYPE as responseType,
  AUTH_RETURN_TO as returnTo,
  AUTH_INSTALL_EVENT
} from '../constants';
import { CoreAuth0UserProfile } from './profile';

import { CoreBootAuthPlugins } from '../../Boot/services/';

import CoreAuth from '../service';

/**
 * Refresh Auth0 Session
 * @export
 * @param {WebAuth} webAuth
 * @returns {Promise<any>}
 */
export function RefreshAuth0Session(webAuth: WebAuth): Promise<any> {
  const redirectUri: string = `${returnTo}/`;
  return new Promise((resolve) => {
    webAuth.checkSession({ responseType, redirectUri }, (error: Auth0Error, response: any) => {
      /* istanbul ignore next */
      if (error) {
        resolve({ refresh_error: error.error||error.description||error.errorDescription||error.error_description });
      } else {
        const token: string = response.accessToken;
        const state: string = response.state;
        resolve({ token, state });
      }
    });
  });
}

/**
 * Check Auth0 UserInfo
 * @export
 * @return {*}  {Promise<any>}
 */
export async function CheckAuth0UserInfo(): Promise<any> {
  await IsAuthenticatedAsync();

  /* istanbul ignore next */
  const webAuth: WebAuth = CoreAuth.auth;
  /* istanbul ignore next */
  const token: string = CoreAuth.token;

  /* istanbul ignore next */
  return new Promise((resolve) => {
    webAuth.client.userInfo(token, (error: Auth0Error, userinfo: any) => {
      /* istanbul ignore next */
      if (error) {
        resolve({ userinfo_error: error.error||error.description||error.errorDescription||error.error_description });
      } else {
        resolve({ userinfo });
      }
    });
  });
}

/**
 * Validate Auth0 Token
 * @async
 * @export
 * @param {string} token
 * @returns {Promise<any>}
 */
export async function ValidateAuth0Token(token: string): Promise<any> {
  /* istanbul ignore next */
  try {
    const { azp } = jwtDecode(token) as HashMap<any>;
    if (azp !== CoreAuthCredentialsService.CLIENTID) {
      throw new Error('invalid token.');
    }
    return { valid: true };
  } catch(err: any) {
    return Promise.reject({ token_error: err.message||err });
  }
}

/**
 * Get Expires Auth0 Token
 * @async
 * @export
 * @param {string} token
 * @returns {Promise<any>}
 */
export async function GetExpiresAuth0Token(token: string): Promise<any>  {
  try {
    const { exp } = jwtDecode(token) as HashMap<any>;

    if (!exp) {
      /* istanbul ignore next */
      throw new Error('invalid token.');
    }

    return { exp };
  } catch(err: any) {
    /* istanbul ignore next */
    return Promise.reject({ expires_error: err.message||err });
  }
}

/**
 * Install Auth 0User
 * @async
 * @export
 * @param {CoreAuthServiceShape} service
 * @returns {Promise<any>}
 */
export async function InstallAuth0User(): Promise<void>  {
  try {

    /* istanbul ignore next */
    if (!CoreAuth.installed) {
      // Authenticated - Build User & Profile
      const { userinfo, userinfo_error } = await CheckAuth0UserInfo();

      /* istanbul ignore next */
      if (userinfo_error) {
        CoreAuth.destroy();
        throw new Error(userinfo_error);
      }

      // Install profile
      /* istanbul ignore next */
      const profile: CoreUserInfoShape = CoreAuth0UserProfile(userinfo);

      /* istanbul ignore next */
      CoreAuth.user.install(profile, CoreAuth.token);

      // Apply Plugin;
      /* istanbul ignore next */
      await CoreBootAuthPlugins.apply('user', CoreAuth);

      // sets installed flag so auth0 doesn't double up on tokens
      /* istanbul ignore next */
      CoreAuth.installed = true;

      // emits install event for consumers
      CoreAuth.emit(AUTH_INSTALL_EVENT);
    }

    /* istanbul ignore next */
    return void 0;

  } catch(err: any) {
    /* istanbul ignore next */
    return Promise.reject(err.message || err);
  }
}

/**
 * Remove Transactional Cookies
 * @async
 * @export
 * @returns {Promise<any>}
 */
/* istanbul ignore next */
export async function RemoveTransactionalCookies(): Promise<void> {
  (window as any).document.cookie.slice(0).split(';').map((c: string): string => c.trim()).forEach((c: string): void => {
    if (/^co%2Fverifier/.test(c)) {
      document.cookie = `${c}=; path=/; Max-Age=-999;`;
    }
  });
  return void 0;
}

/**
 * IsAuthenticatedAsync
 * @async
 * @export
 * @return {*}  {Promise<boolean>}
 */
export async function IsAuthenticatedAsync(): Promise<boolean> {
  try {
    
    // @ts-ignore
    const expires = await CoreAuth.__expires.get();
    // @ts-ignore
    const token = await CoreAuth.__token.get();

    if (!token || !expires) {
      throw new TypeError('token and expires undefined.');
    }

    /* istanbul ignore next */
    if (expires <= Date.now()) {
      CoreAuth.destroy();
      throw new TypeError('token has expired.');
    }

    /* istanbul ignore next */
    // @ts-ignore
    CoreAuth._token = token;
    /* istanbul ignore next */
    // @ts-ignore
    CoreAuth._expires = expires;

    /* istanbul ignore next */
    return true;
  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      if (err instanceof TypeError) {
        console.debug(`DEBUG:: IsAuthenticatedAsync: ${err.message || err}`);
      } else {
        console.error(`DEVELOPER ERROR:: IsAuthenticatedAsync: ${err.message || err}`);
      }
    }
    return Promise.reject(false);
  }
}