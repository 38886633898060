import { RefObject } from 'react';
import { mergeWith } from 'lodash';
import { Chart, Options } from 'highcharts';
import HighCharts from '../module';

import { CoreMapChartShape } from '../interfaces/';
import { WaitForDelay, Noop } from '../../../../utils/';

/**
 * Map Base
 * @export
 * @class CoreMapBase
 */
export default class CoreMapBase implements CoreMapChartShape {
  /**
   * Ref
   * @type {RefObject<any>}
   * @memberof CoreMapBase
   */
  ref: RefObject<any>;

  /**
   * Chart
   * @type {Chart}
   * @memberof CoreMapBase
   */
  chart: Chart;

  /**
   * Options
   * @type {Options}
   * @memberof CoreMapBase
   */
  options: Options;

  /**
   * Config
   * @type {HashMap<any>}
   * @memberof CoreMapBase
   */
  config: HashMap<any>;

  /**
   * Creates an instance of CoreMapBase.
   * @param {RefObject<any>} ref
   * @param {HashMap<any>} [config={}]
   * @memberof CoreMapBase
   */
  constructor(ref: RefObject<any>, config: HashMap<any> = {}) {
    this.init(ref, config);
  }

  /**
   * Update
   * @override
   * @memberof CoreMapBase
   */
  public update(...series: any[]) {
    series.forEach((s, i) => {
      this.chart.series[i].update(s);
    });
  }

  /**
   * Build
   * @override
   * @async
   * @memberof CoreMapBase
   */
  public async build(): Promise<void> {
    try {
      await WaitForDelay();
      const { options, config, ref: { current } } = this;
      mergeWith(options, config);
      this.chart = HighCharts.mapChart(current, options);
      return void 0;
    } catch (err: any) {
      /* istanbul ignore next */
      return Promise.reject(err);
    }
  }

  /**
   * Init
   * @private
   * @param {RefObject<any>} ref
   * @param {HashMap<any>} config
   * @memberof CoreMapBase
   */
  private init(ref: RefObject<any>, config: HashMap<any>) {
    try {
      if (!ref) {
        throw new ReferenceError('must pass a react RefObject');
      }

      this.ref = ref;
      this.config = config;
      this.build().catch(Noop);
    } catch (err: any) {
      console.error(err);
    }
  }
}
