import React, { PureComponent } from 'react';

import CoreAuthContext from './context';
import CoreAuth from './service';

/**
 * Auth Wrapper
 * @export
 * @class CoreAuthWrapper
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreAuthWrapper extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreAuthWrapper
   */
  public render() {
    return <CoreAuthContext.Provider value={CoreAuth}>{this.props.children}</CoreAuthContext.Provider>;
  }
}
