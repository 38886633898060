import { IsString } from '../../../../utils/';

/**
 * Is Valid Meta
 * @param {*} meta
 * @return {*}  {boolean}
 */
/* istanbul ignore next */
export function IsValidMeta(meta: any): boolean {
  if (!Array.isArray(meta) || meta.length !== 3 || !(meta[0] instanceof Uint8Array) || !(meta[1] instanceof Uint8Array) || !IsString(meta[2])) {
    return false;
  }
  return true;
}