import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_TRAFFIC_SUMMARY } from './models';

/**
 * Get Traffic Summary Query
 */
export const GetTrafficSummaryQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TRAFFIC_SUMMARY,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
    fetchPolicy: 'network-only'
  };
});
