import React, { PureComponent, Fragment } from 'react';

/**
 * List Editor Container
 * @export
 * @class CoreListEditorContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreListEditorContainer extends PureComponent<{}, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreListEditorContainer
   */
  public static displayName = 'CoreListEditor';

  /**
   * Render
   * @returns
   * @memberof CoreListEditorContainer
   */
  public render() {
    return <Fragment>{this.props.children}</Fragment>;
  }
}
