/**
 * Paginate Flat Factory
 * @export
 * @param {Array<HashMap<any>>} data
 * @param {number} page
 * @param {number} limit
 * @returns {Array<HashMap<any>>}
 */
export const CorePaginateFlatFactory = (data: Array<HashMap<any>>, page: number, limit: number): Array<HashMap<any>> => {
  try {
    if (!Array.isArray(data) || !data.length) {
      throw new ReferenceError('no data');
    }
    const start: number = page * limit;
    const end = start + limit;
    return data.slice(start, end);
  } catch (err: any) {}
  return [];
};
