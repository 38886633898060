import { isDocumentNode } from '@apollo/client/utilities/';
import { cloneDeep } from 'lodash';

import { IsDefined, IsFunction, IsHashMap, IsBoolean, IsNumber, IsNull } from '../../../../utils/';

/**
 * Graph Config To Props Transform
 * @export
 * @param {HashMap<any>} props
 * @param {CoreGraphConsumerConfigType} [config=null]
 * @return {*} {HashMap<any>}
 */
export function CoreGraphConfigToPropsTransform(props: HashMap<any>, config: CoreGraphConsumerConfigType = null): HashMap<any> {
  const copy = cloneDeep(props);

  /**
   * Config Failsafe
   */
  if (!IsNull(config)) {
    const configured = (config as CoreGraphConsumerConfigType)();
    for (const prop in configured) {
      if (!!~['query', 'mutation', 'subscription'].indexOf(prop)) {
        continue;
      }
      if (!IsDefined(copy[prop]) && IsDefined(configured[prop])) {
        copy[prop] = configured[prop];
      }
    }
  }

  return copy;
}

/**
 * Graph Query Config validations
 * @export
 * @param {HashMap<any>} [validation={}]
 */
export function GraphQueryConfigValidation({ query, variables, fetchPolicy, errorPolicy, skip, pollInterval, transform, params, onCompleted, onError }: HashMap<any> = {}): void {
  const message = (name: string, type: string, value: any): string => `optional property [${name}: ${type}] passed as improper type[${typeof value}].`;

  if (IsDefined(query)) {
    if (!isDocumentNode(query)) {
      throw new ReferenceError(message('query', 'DocumentNode', query));
    }
  }

  if (IsDefined(variables)) {
    if (IsDefined(variables) && !IsHashMap(variables)) {
      throw new ReferenceError(message('variables', 'HashMap<any>', variables));
    }
  }

  if (IsDefined(fetchPolicy)) {
    if (fetchPolicy && !~['cache-first', 'network-only', 'cache-only', 'no-cache', 'standby', 'cache-and-network'].indexOf(fetchPolicy)) {
      throw new ReferenceError(message('fetchPolicy', '(FetchPolicy | WatchQueryFetchPolicy)', fetchPolicy));
    }
  }

  if (IsDefined(errorPolicy)) {
    if (errorPolicy && !~['all', 'ignore', 'none'].indexOf(errorPolicy)) {
      throw new ReferenceError(message('errorPolicy', 'ErrorPolicy', errorPolicy));
    }
  }

  if (IsDefined(skip)) {
    if (!IsBoolean(skip)) {
      throw new ReferenceError(message('skip', 'boolean', skip));
    }
  }

  if (IsDefined(pollInterval)) {
    if (IsDefined(pollInterval) && !IsNumber(pollInterval)) {
      throw new ReferenceError(message('pollInterval', 'number', pollInterval));
    }
  }

  if (IsDefined(transform)) {
    if (!IsFunction(transform)) {
      throw new ReferenceError(message('transform', '(params: HashMap<any>) => HashMap<any>', transform));
    }
  }

  if (IsDefined(params)) {
    if (!IsHashMap(params)) {
      throw new ReferenceError(message('params', 'HashMap<any>', params));
    }
  }

  if (IsDefined(onCompleted)) {
    if (!IsFunction(onCompleted)) {
      throw new ReferenceError(message('onCompleted', '(data: HashMap<any>) => void', onCompleted));
    }
  }

  if (IsDefined(onError)) {
    if (!IsFunction(onError)) {
      throw new ReferenceError(message('onError', '(error: ApolloError) => void', onError));
    }
  }
}

/**
 * Graph Mutation Config validations
 * @export
 * @param {HashMap<any>} [validation={}]
 */
export function GraphMutationConfigValidation({ mutation, variables, transform, params, onCompleted, onError }: HashMap<any> = {}): void {
  const message = (name: string, type: string, value: any): string => `optional property [${name}: ${type}] passed as improper type[${typeof value}].`;

  if (IsDefined(mutation)) {
    if (!isDocumentNode(mutation)) {
      throw new ReferenceError(message('mutation', 'DocumentNode', mutation));
    }
  }

  if (IsDefined(variables)) {
    if (IsDefined(variables) && !IsHashMap(variables)) {
      throw new ReferenceError(message('variables', 'HashMap<any>', variables));
    }
  }

  if (IsDefined(transform)) {
    if (!IsFunction(transform)) {
      throw new ReferenceError(message('transform', '(params: HashMap<any>) => HashMap<any>', transform));
    }
  }

  if (IsDefined(params)) {
    if (!IsHashMap(params)) {
      throw new ReferenceError(message('params', 'HashMap<any>', params));
    }
  }

  if (IsDefined(onCompleted)) {
    if (!IsFunction(onCompleted)) {
      throw new ReferenceError(message('onCompleted', '(data: HashMap<any>) => void', onCompleted));
    }
  }

  if (IsDefined(onError)) {
    if (!IsFunction(onError)) {
      throw new ReferenceError(message('onError', '(error: ApolloError) => void', onError));
    }
  }
}


/**
 * Graph Socket Config validations
 * @export
 * @param {HashMap<any>} [validation={}]
 */
export function GraphSocketConfigValidation({ subscription, variables, fetchPolicy, errorPolicy, skip, transform, params, onCompleted, onError }: HashMap<any> = {}): void {
  const message = (name: string, type: string, value: any): string => `optional property [${name}: ${type}] passed as improper type[${typeof value}].`;

  if (IsDefined(subscription)) {
    if (!isDocumentNode(subscription)) {
      throw new ReferenceError(message('query', 'DocumentNode', subscription));
    }
  }

  if (IsDefined(variables)) {
    if (IsDefined(variables) && !IsHashMap(variables)) {
      throw new ReferenceError(message('variables', 'HashMap<any>', variables));
    }
  }

  if (IsDefined(fetchPolicy)) {
    if (fetchPolicy && !~['cache-first', 'network-only', 'cache-only', 'no-cache', 'standby', 'cache-and-network'].indexOf(fetchPolicy)) {
      throw new ReferenceError(message('fetchPolicy', '(FetchPolicy | WatchQueryFetchPolicy)', fetchPolicy));
    }
  }

  if (IsDefined(errorPolicy)) {
    if (errorPolicy && !~['all', 'ignore', 'none'].indexOf(errorPolicy)) {
      throw new ReferenceError(message('errorPolicy', 'ErrorPolicy', errorPolicy));
    }
  }

  if (IsDefined(skip)) {
    if (!IsBoolean(skip)) {
      throw new ReferenceError(message('skip', 'boolean', skip));
    }
  }

  if (IsDefined(transform)) {
    if (!IsFunction(transform)) {
      throw new ReferenceError(message('transform', '(params: HashMap<any>) => HashMap<any>', transform));
    }
  }

  if (IsDefined(params)) {
    if (!IsHashMap(params)) {
      throw new ReferenceError(message('params', 'HashMap<any>', params));
    }
  }

  if (IsDefined(onCompleted)) {
    if (!IsFunction(onCompleted)) {
      throw new ReferenceError(message('onCompleted', '(data: HashMap<any>) => void', onCompleted));
    }
  }

  if (IsDefined(onError)) {
    if (!IsFunction(onError)) {
      throw new ReferenceError(message('onError', '(error: ApolloError) => void', onError));
    }
  }
}