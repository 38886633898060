import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementDateTime } from '../../../components/';

/**
 * DatetimeLocal Input Controller
 * @export
 * @class DatetimeLocalInputController
 * @extends {InputBase}
 */
export default class DatetimeLocalInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof DatetimeLocalInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementDateTime;
}
