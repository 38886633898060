import EventEmitter from 'events';

import { CoreDialogInstance } from './factories/';
import { CoreDialogShape } from './interfaces/';

import { DIALOG_EVENT_CHANGE } from './constants';

/**
 * Dialog Service
 * @class CoreDialogService
 */
export class CoreDialogService {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreDialogService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Dialogs
   * @private
   * @type {CoreDialogInstance[]}
   * @memberof CoreDialogService
   */
  private _dialogs: Map<string, CoreDialogInstance> = new Map<string, CoreDialogInstance>();

  /**
   * Subscriber
   * @private
   * @type {(open: boolean, callback:CoreDialogShape) => void}
   * @memberof CoreDialogService
   */
  private _subscriber: (open: boolean, callback: CoreDialogShape) => void = null!;

  /**
   * Subscribe
   * @param {Func<void, boolean>} callback
   * @returns {void}
   * @memberof CoreDialogService
   */
  public subscribe(callback: (open: boolean, callback: CoreDialogShape) => void): void {
    this._subscriber = callback;
    this._emitter.on(DIALOG_EVENT_CHANGE, this._subscriber);
  }

  /**
   * Unsubscribe
   * @memberof CoreDialogService
   */
  public unsubscribe(): void {
    this._emitter.off(DIALOG_EVENT_CHANGE, this._subscriber);
  }

  /**
   * Add
   * @param {CoreDialogInstance} dialog
   * @memberof CoreDialogService
   */
  public add(dialog: CoreDialogInstance): void {
    if (!this._dialogs.has(dialog.name)) {
      this._dialogs.set(dialog.name, dialog);
      this.emit();
    }
  }

  /**
   * Apply
   * @param {CoreDialogInstance} dialog
   * @returns {(boolean | Error)}
   * @memberof CoreDialogService
   */
  public apply(dialog: CoreDialogInstance): boolean | Error {
    try {
      /* istanbul ignore next */
      if (!this._dialogs.has(dialog.name)) {
        this._dialogs.set(dialog.name, dialog);
      }
      this.emit();
      return true;
    } catch (err: any) {
      /* istanbul ignore next */
      return err;
    }
  }

  /**
   * Destroy
   * @param {CoreDialogInstance} dialog
   * @returns {(boolean | Error)}
   * @memberof CoreDialogService
   */
  public destroy(dialog: CoreDialogInstance): boolean | Error {
    try {
      /* istanbul ignore next */
      if (this._dialogs.has(dialog.name)) {
        this._dialogs.delete(dialog.name);
      }
      this.emit();
      return true;
    } catch (err: any) {
      /* istanbul ignore next */
      return err;
    }
  }

  /**
   * Emit
   * @private
   * @memberof CoreDialogService
   */
  private emit(): void {
    const open = this.opened;
    const current = this.current;
    this._emitter.emit(DIALOG_EVENT_CHANGE, open, current);
  }

  /**
   * CoreDialogs - getter
   * @readonly
   * @type {Map<string, CoreDialogInstance>}
   * @memberof CoreDialogService
   */
  get dialogs(): Map<string, CoreDialogInstance> {
    return this._dialogs;
  }

  /**
   * Current - getter
   * @readonly
   * @type {CoreDialogInstance}
   * @memberof CoreDialogService
   */
  get current(): CoreDialogInstance {
    const { dialogs } = this;
    let current: CoreDialogInstance = null;
    for (const d of dialogs.values()) {
      if (d.active) {
        current = d;
        break;
      }
    }
    return current;
  }

  /**
   * Opened
   * @readonly
   * @type {boolean}
   * @memberof CoreDialogService
   */
  get opened(): boolean {
    return this.current !== null;
  }
}

const CoreDialogs = new CoreDialogService();

export default CoreDialogs;
