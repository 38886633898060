import React, { PureComponent } from 'react';

import CoreTableContext from '../context';
import { CoreTableEmptyProps } from '../interfaces/';
import { IsNumber } from '../../../../utils/';

/**
 * Table Empty Container
 * @export
 * @class CoreTableEmptyContainer
 * @extends {PureComponent<CoreTableEmptyProps, {}>}
 */
export default class CoreTableEmptyContainer extends PureComponent<CoreTableEmptyProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableEmptyContainer
   */
  public static displayName = 'CoreTableEmpty';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableEmptyContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * LifeCycle Hook
   * @memberof CoreTableEmptyContainer
   */
  public componentDidMount() {
    const { context } = this;
    context.subscribe(CoreTableEmptyContainer.displayName, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableEmptyContainer
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(CoreTableEmptyContainer.displayName);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableEmptyContainer
   */
  public render() {
    const { props, context } = this;
    const { colSpan: COL_SPAN, children } = props;

    if (context.collection.show) {
      return null;
    }

    const colSpan = IsNumber(COL_SPAN) ? COL_SPAN : context.columns.count;

    return (
      <tbody>
        <tr>
          <td colSpan={colSpan}>
            <span>{children}</span>
          </td>
        </tr>
      </tbody>
    );
  }
}
