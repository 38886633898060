import React from 'react';

import CoreMenuComponentBase from './Base';

import { CoreHeaderMenusMask } from '../../Interop/enums/';
import { CoreNotificationsMenu } from '../../Notifications/';
import { CoreAnchor } from '../../shared/';

import { MergeClassNames } from '../../utils/';

/**
 * Notifications Menu Component
 * @export
 * @class CoreNotificationsMenuComponent
 * @extends {CoreMenuComponentBase}
 */
export default class CoreNotificationsMenuComponent extends CoreMenuComponentBase {
  /**
   * Creates an instance of CoreNotificationsMenuComponent.
   * @param {GenericPropType} props
   * @memberof CoreNotificationsMenuComponent
   */
  constructor(props: GenericPropType) {
    super(props);
    this.STATE = CoreHeaderMenusMask.NOTIFICATIONS;
  }

  /**
   * Render
   * @returns
   * @memberof CoreNotificationsMenuComponent
   */
  public render() {
    const { OnMenuClick } = this;

    const notifications = true;
    const active = this.STATE === this.state.value;
    const clz = MergeClassNames('', { notifications, active });

    return (
      <li className={clz}>
        <CoreAnchor onClick={OnMenuClick}>Notifications</CoreAnchor>
        <CoreNotificationsMenu />
      </li>
    );
  }
}
