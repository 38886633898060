import React, { PureComponent } from 'react';

import CoreToggleContext from '../context';

import { MergeClassNames } from '../../../../utils/';

/**
 * Core Toggle Title
 * @export
 * @class CoreToggleTitle
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreToggleTitle extends PureComponent<{}, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreToggleTitleMini
   */
  public static displayName = 'CoreToggleTitle';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreToggleContext>}
   * @memberof CoreToggleTitle
   */
  public static contextType: Readonly<typeof CoreToggleContext> = CoreToggleContext;

  /**
   * Render
   * @returns
   * @memberof CoreToggleTitle
   */
  public render() {
    const {
      toggle,
      context: { active },
      props: { children },
    } = this;

    const clz = MergeClassNames('', {'toggle-title': true, active });

    return (
      <dt className={clz}>
        <div className="toggle-title-container" onClick={toggle}>
          <mark />
          <output>{children}</output>
        </div>
      </dt>
    );
  }

  /**
   * Toggle
   * @protected
   * @memberof CoreToggleTitle
   */
  protected toggle = () => {
    const { context } = this;
    context.active = !context.active;
    this.forceUpdate();
  };
}
