import moment, { Moment } from 'moment';

import DateRangePickerService from './date_range';

import { DatePickerProps, DateTimeRangePickerServiceShape } from '../interfaces/';
import { DATE_TIME_RANGE_DISPLAY_LONG_FORMAT, DATE_TIME_RANGE_DISPLAY_SHORT_FORMAT, DATE_FORMAT_DATE, DATE_FORMAT_TIME } from '../constants';
import { IsDefined, IsEmpty, WithinYearOf, WaitForDelay } from '../../../../utils/';

/**
 * Date Time Range Picker Service
 * @export
 * @class DateTimeRangePickerService
 * @extends {DateRangePickerService}
 * @implements {DateTimeRangePickerServiceShape}
 */
export default class DateTimeRangePickerService extends DateRangePickerService implements DateTimeRangePickerServiceShape {
  /**
   * Min
   * @protected
   * @type {number}
   * @memberof DateTimeRangePickerService
   */
  protected _min: number = null!;

  /**
   * Max
   * @protected
   * @type {number}
   * @memberof DateTimeRangePickerService
   */
  protected _max: number = null!;

  /**
   * Days Span
   * @protected
   * @type {number}
   * @memberof DateTimeRangePickerService
   */
  protected _dayspan: number = null!;

  /**
   * Update
   * @override
   * @param {string} [start_date]
   * @param {string} [end_date]
   * @param {string} [start_time]
   * @param {string} [end_time]
   * @memberof DateTimeRangePickerService
   */
  public update(start_date?: string, end_date?: string, start_time?: string, end_time?: string): void {
    if (IsDefined(start_date)) {
      Object.assign(this.source, { start_date });
    }
    if (IsDefined(end_date)) {
      Object.assign(this.source, { end_date });
    }
    if (IsDefined(start_time)) {
      Object.assign(this.source, { start_time });
    }
    if (IsDefined(end_time)) {
      Object.assign(this.source, { end_time });
    }

    /* istanbul ignore next */
    WaitForDelay(100).then(() => {
      this.broadcast();
    });
  }

  /**
   * Init
   * @override
   * @protected
   * @param {DatePickerProps} props
   * @param {CoreFormServiceType} form
   * @memberof DateRangePickerService
   */
  public init(props: DatePickerProps, form: CoreFormServiceType): void {
    this._props = props;
    this._form = form;

    const { utc } = this;
    const { name, min, max, progressive } = props;

    try {
      if (min) {
        this._min = min;
      }

      if (max) {
        this._max = max;
      }

      /* istanbul ignore next */
      if (progressive) {
        if (!min || !max) {
          throw new ReferenceError('DateTimeRangePicker : must supply props[min & max] if prop[progressive] is true.');
        }
        this._dayspan = moment(max).diff(moment(min), 'days');
      }

      let value: string = form.value(name) || null;

      /* istanbul ignore next */
      if (!value && props.value) {
        value = props.value as string;
        form.update(name, value);
      }

      this._source = {
        start_date: undefined,
        start_time: undefined,
        end_date: undefined,
        end_time: undefined,
      };

      const [start, end] = value ? value.split(',') : [undefined, undefined];

      if (start) {
        const $start: Moment = moment(utc(start));
        if ($start.isValid) {
          this._source.start_date = $start.format(DATE_FORMAT_DATE);
          this._source.start_time = $start.format(DATE_FORMAT_TIME);
        }
      }

      if (end) {
        const $end: Moment = moment(utc(end));
        if ($end.isValid) {
          this._source.end_date = $end.format(DATE_FORMAT_DATE);
          this._source.end_time = $end.format(DATE_FORMAT_TIME);
        }
      }

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * Reset
   * @memberof DateTimeRangePickerService
   */
  public reset = (): void => {
    this.init(this.props, this.form);
    this.broadcast();
  };

  /**
   * Display Name
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get display_name() {
    const { id } = this;
    return `${id}-display`;
  }

  /**
   * Display Value - getter
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get display_value(): string {
    const { utc, source: { start_date, end_date, start_time, end_time } } = this;

    /* istanbul ignore next */
    if (start_date || end_date) {
      const format = WithinYearOf(start_date, end_date) ? DATE_TIME_RANGE_DISPLAY_SHORT_FORMAT : DATE_TIME_RANGE_DISPLAY_LONG_FORMAT;

      const $start: Date = start_date && start_time ? utc(`${start_date}T${start_time}`) : null;
      const $end: Date = end_date && end_time ? utc(`${end_date}T${end_time}`) : null;
      if (start_date === end_date || (start_date && !end_date)) {
        return moment($start).format(format);
      } else if (end_date && !start_date) {
        return moment($end).format(format);
      } else {
        return `${moment($start).format(format)} - ${moment($end).format(format)}`;
      }
    }
    /* istanbul ignore next */
    return '';
  }

  /**
   * Start Time Name
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get start_time_name() {
    const { id } = this;
    return `${id}-start-time`;
  }

  /**
   * Start Time Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get start_time_value() {
    const {
      source: { start_time },
    } = this;
    return start_time || '';
  }

  /**
   * End Time Name
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get end_time_name() {
    const { id } = this;
    return `${id}-end-time`;
  }

  /**
   * End Time Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get end_time_value() {
    const {
      source: { end_time },
    } = this;
    return end_time || '';
  }

  /**
   * Min Date Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get min_date_value() {
    const { min } = this;
    return min ? moment(min).format('YYYY-MM-DD') : null;
  }

  /**
   * Max Date Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get max_date_value() {
    const { max } = this;
    return max ? moment(max).format('YYYY-MM-DD') : null;
  }

  /**
   * Min Time Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get min_time_value() {
    const { min } = this;
    return min ? moment(min).format('HH:mm:ss') : null;
  }

  /**
   * Max Time Value
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get max_time_value() {
    const { max } = this;
    return max ? moment(max).format('HH:mm:ss') : null;
  }

  /**
   * Actual - getter
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get actual() {
    const { start_date, start_time, end_date, end_time } = this.source;
    /* istanbul ignore next */
    const start = start_date && start_time ? [start_date, start_time].join('T') : '';
    /* istanbul ignore next */
    const end = end_date && end_time ? [end_date, end_time].join('T') : '';
    return [start, end].join(',');
  }

  /**
   * Ready - getter
   * @override
   * @readonly
   * @memberof DateTimeRangePickerService
   */
  public get ready() {
    const {
      source: { start_date, start_time, end_date, end_time },
      props: { required },
    } = this;
    /* istanbul ignore next */
    if (IsEmpty(start_date) && IsEmpty(end_date) && IsEmpty(start_time) && IsEmpty(end_time) && !required) {
      return true;
    }
    /* istanbul ignore next */
    return !IsEmpty(start_date) && !IsEmpty(end_date) && !IsEmpty(start_time) && !IsEmpty(end_time);
  }
}
