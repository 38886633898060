import { CoreListSearchShape } from '../interfaces/';

import { IsDefined } from '../../../../utils/';

/**
 * Table Search Service
 * @export
 * @class CoreTableSearchService
 */
export default class CoreTableSearchService {
  /**
   * Term
   * @type {string}
   * @memberof CoreTableSearchService
   */
  public term: string = '';

  /**
   * Fields
   * @type {string[]}
   * @memberof CoreTableSearchService
   */
  public fields: string[] = [];

  /**
   * Update
   * @param {string} [term]
   * @param {string[]} [fields]
   * @memberof CoreTableSearchService
   */
  public update(term?: string, fields?: string[]): void {
    this.term = IsDefined(term) ? term : '';
    if (IsDefined(fields) && fields.length) {
      this.fields = fields;
    }
  }

  /**
   * To HashMap
   * @returns {CoreListSearchShape}
   * @memberof CoreTableSearchService
   */
  public toHashMap(): CoreListSearchShape {
    const { term, fields } = this;
    return { term, fields };
  }

  /**
   * Searchable - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreTableSearchService
   */
  public get searchable(): boolean {
    const { fields } = this;
    return fields.length > 0;
  }
}
