import React, { PureComponent, Suspense } from 'react';

import { CoreSuspenseBusy } from '../Activity/';

import { CoreAsideContainer } from './containers/';

import {
  CoreAnalyticsDirective,
  CoreLinkDirective,
  CoreRoutesDirective,
  CoreSessionDirective,
} from './directives/';

/**
 * Main Module
 * @export
 * @class CoreMainModule
 * @extends {PureComponent<{}, {}>}
 * @note Assembles Route main views next to Primary Navigation
 */
export default class CoreMainModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreMainModule
   */
  public render() {
    return (
      <main>
        <CoreAnalyticsDirective />
        <CoreLinkDirective />
        <CoreRoutesDirective />
        <CoreSessionDirective />
        <CoreAsideContainer />
        <Suspense fallback={<CoreSuspenseBusy />}>
          {this.props.children}
        </Suspense>
      </main>
    );
  }
}
