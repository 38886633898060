import React, { PureComponent } from 'react';

import { CoreFormInputDatalistProps } from '../interfaces/';

/**
 * Input Datalist
 * @export
 * @class CoreInputDatalistComponent
 * @extends {PureComponent<CoreFormInputDatalistProps, {}>}
 */
export default class CoreInputDatalistComponent extends PureComponent<CoreFormInputDatalistProps, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreInputDatalistComponent
   */
  public static displayName: string = 'CoreInputDatalist';

  /**
   * LifeCycle Hook
   * @memberof CoreInputDatalistComponent
   */
  public componentDidMount() {
    const { props } = this;

    try {
      /* istanbul ignore next */
      if (!('id' in props)) {
        throw new ReferenceError(`CoreInputDatalist: missing prop[id]`);
      }

    } catch(err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreInputDatalistComponent
   */
  public render() {
    const { props: { id, children }} = this;
    return <datalist id={id}>{children}</datalist>
  }
}
