import { CoreFormOptionsType } from '../types';
import { IsHashMap, IsNumber } from '../../../../utils/';

/**
 * Options Factory
 * @export
 * @param {any[]} src
 * @returns {CoreFormOptionsType}
 */
export function CoreFormOptionsFactory(src: any[], out: CoreFormOptionsType = []): CoreFormOptionsType {
  try {
    const sample = src[0];
    if (IsHashMap(sample)) {
      if (!('label' in sample) || !('value' in sample)) {
        throw new TypeError(`Select Options must conform to [CoreFormOptionsType]`);
      }
      return src;
    } else if (Array.isArray(src)) {
      let value: string | number;
      let label: string;
      src.reduce((d: CoreFormOptionsType, r: string | number) => {
        value = r;
        label = IsNumber(r) ? String(r) : r as string;
        d.push({ value, label });
        return d;
      }, out);
    }
  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOP ERROR:: ${err}`);
    }
  }
  return out;
}

/**
 * Options Map
 * @export
 * @param {CoreFormOptionsType} options
 * @returns {HashMap<any>}
 */
export function CoreFormOptionsMap(options: CoreFormOptionsType): HashMap<any> {
  return options.reduce((d: HashMap<any>, { value, label }: HashMap<any>) => {
    d[value] = label;
    return d;
  }, {});
}
