import { Children, ReactChild, ReactFragment, ReactPortal } from 'react';

/**
 * Children Contain
 * @export
 * @param {*} children
 * @param {string} match
 * @returns {*} {boolean}
 */
export function ChildrenContain(children: any, match: string): boolean {
  try {
    /* istanbul ignore next */
    if (!children) {
      throw new ReferenceError('must pass children.');
    }
    /* istanbul ignore next */
    if (!match) {
      throw new ReferenceError('must pass match value.');
    }

    const kids: Array<ReactChild | ReactFragment | ReactPortal> = Children.toArray(children);
    let child: any;
    let i: number = kids.length;

    while(i--) {
      child = kids[i];
      if (child?.type?.displayName === match) {
        return true;
      }
    }
  } catch (err: any) {}
  return false;
}