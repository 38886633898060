import { PERMANENT_DOMAIN } from '../../Config/';

/**
 * Get API Location
 * @export
 * @param {string} prefix
 * @param {string} [port]
 * @param {boolean} [secure=false]
 * @returns
 */
export function CoreGetApiLocation(prefix: string, port?: string, secure: boolean = false) {
  const hostname = (window as any).location.hostname;
  const protocol = secure ? 'https:' : (window as any).location.protocol;
  const domain = hostname
    .split('.')
    .slice(1)
    .join('.');
  return `${protocol}//${prefix}.${domain || PERMANENT_DOMAIN}${port ? ':' + port : ''}`;
}
