import { CoreConfig, CoreGraphSocketConsumer } from '@neustar/core-ui';

import { SUBSCRIPTION_TRAFFIC_SUMMARY } from './models';

/**
 * Get Traffic Summary Subscription
 */
export const GetTrafficSummarySubscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_TRAFFIC_SUMMARY,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
  };
});



