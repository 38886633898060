import React, { PureComponent, Children } from 'react';

import CoreToggleContext from '../context';

/**
 * Core Toggle List Container
 * @export
 * @class CoreToggleListContainer
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class CoreToggleListContainer extends PureComponent<GenericPropType, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreToggleContext>}
   * @memberof CoreToggleListContainer
   */
  public static contextType: Readonly<typeof CoreToggleContext> = CoreToggleContext;

  /**
   * LifeCycle Hook
   * @memberof CoreToggleListContainer
   */
  public componentDidMount() {
    const { children } = this.props;

    try {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        const kids = Children.toArray(children);
        let titles: number = 0;

        for (let i = 0; i < kids.length; i++) {
          if (kids[i]['type']['displayName'] === 'CoreToggleTitle') {
            titles++;
          }
          if (titles > 1) {
            throw new ReferenceError('Core Toggle may have only one `CoreToggleTitle` child. Use `CoreToggleTitleExtra`.');
          }
        }

        if (kids[0]['type']['displayName'] !== 'CoreToggleTitle') {
          throw new ReferenceError('`CoreToggleTitle` must be the first child of `CoreToggle`');
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreToggleListContainer
   */
  public render() {
    const { children } = this.props;
    return <dl className="toggle">{children}</dl>;
  }
}
