export const INPUT_TYPE_BUTTON = 'button';
export const INPUT_TYPE_CHECKBOX = 'checkbox';
export const INPUT_TYPE_COLOR = 'color';
export const INPUT_TYPE_DATE = 'date';
export const INPUT_TYPE_DATETIME = 'datetime-local';
export const INPUT_TYPE_EMAIL = 'email';
export const INPUT_TYPE_FILE = 'file';
export const INPUT_TYPE_HIDDEN = 'hidden';
export const INPUT_TYPE_IMAGE = 'image';
export const INPUT_TYPE_MONTH = 'month';
export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_PASSWORD = 'password';
export const INPUT_TYPE_RADIO = 'radio';
export const INPUT_TYPE_RANGE = 'range';
export const INPUT_TYPE_RESET = 'reset';
export const INPUT_TYPE_SEARCH = 'search';
export const INPUT_TYPE_SUBMIT = 'submit';
export const INPUT_TYPE_TEL = 'tel';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TIME = 'time';
export const INPUT_TYPE_URL = 'url';
export const INPUT_TYPE_WEEK = 'week';

export const SELECT_TYPE_SELECT = 'select';
export const SELECT_TYPE_MULTIPLE = 'multiple';
export const TEXT_TYPE_AREA = 'textarea';

export const TEXT_TYPES = [INPUT_TYPE_EMAIL, INPUT_TYPE_PASSWORD, INPUT_TYPE_TEL, INPUT_TYPE_TEXT, TEXT_TYPE_AREA];
export const NUMBER_TYPES = [INPUT_TYPE_NUMBER, INPUT_TYPE_RANGE];
export const CHECKED_TYPES = [INPUT_TYPE_CHECKBOX];
export const OPTIONS_TYPES = [SELECT_TYPE_SELECT, SELECT_TYPE_MULTIPLE];
export const RADIO_TYPES = [INPUT_TYPE_RADIO];
