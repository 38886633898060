import { LOGO, LOGO_TITLE, PRODUCT_NAME, HOSTNAME, SHORT_NAME, SUPPORT_URL, ACCOUNTS_DOMAIN } from '../constants';
import { CoreConfigHeaderShape, CoreConfigLinkShape } from '../interfaces/';

/**
 * Config Header Service
 * @export
 * @class CoreConfigHeader
 */
export class CoreConfigHeader implements CoreConfigHeaderShape {
  public static LOGO: string = LOGO;
  public static LOGO_TITLE: string = LOGO_TITLE;
  public static PRODUCT_NAME: string = PRODUCT_NAME;
  public static HOSTNAME: string = HOSTNAME;
  public static SHORT_NAME: string = SHORT_NAME;
  public static SUPPORT_URL: string = SUPPORT_URL;
  public static ACCOUNTS_DOMAIN: string = ACCOUNTS_DOMAIN;
  public static USER_LINKS: Array<CoreConfigLinkShape> = [];
  public get logo() { return CoreConfigHeader.LOGO; }
  public get logo_title() { return CoreConfigHeader.LOGO_TITLE; }
  public get product_name() { return CoreConfigHeader.PRODUCT_NAME; }
  public get hostname() { return CoreConfigHeader.HOSTNAME; }
  public get shortname() { return CoreConfigHeader.SHORT_NAME; }
  public get support_url() { return CoreConfigHeader.SUPPORT_URL; }
  public get accounts_domain() { return CoreConfigHeader.ACCOUNTS_DOMAIN; }
  public get user_links() { return CoreConfigHeader.USER_LINKS; }
}
