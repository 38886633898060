import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import FormContext from '../forms/contexts/Form';
import { CoreFormConsumer, CoreFormConsumerTypeEnum } from '../forms/';

import PickerContext from './context';
import { DateRangePickerService } from './services/';
import { DatePickerProps, DateRangePickerServiceShape } from './interfaces/';
import { DateRangeContainer } from './containers/';
import { PICKER_PROPS } from './constants';
import { WaitForDelay, IsDefined } from '../../../utils/';

/**
 * CoreDateRangePicker
 * @export
 * @class CoreDateRangePicker
 * @extends {PureComponent<DatePickerProps, {}>}
 */
export default class CoreDateRangePicker extends PureComponent<DatePickerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof FormContext>}
   * @memberof CoreDateRangePicker
   */
  public static contextType: Readonly<typeof FormContext> = FormContext;

  /**
   * Default Props
   * @static
   * @type {Omit<DatePickerProps, 'name'>}
   * @memberof CoreDateRangePicker
   */
  public static defaultProps: Omit<DatePickerProps, 'name'> = cloneDeep(PICKER_PROPS);

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreDateRangePicker
   */
  private listener: AbortController;

  /**
   * Service
   * @private
   * @type {DateRangePickerServiceShape}
   * @memberof CoreDateRangePicker
   */
  private service: DateRangePickerServiceShape = new DateRangePickerService();

  /**
   * LifeCycle Hook
   * @memberof CoreDateRangePicker
   */
  /* istanbul ignore next */
  public componentDidMount() {
    const { props: { name } } = this;
    this.listener = new AbortController();
    try {
      if (!name) {
        throw new ReferenceError('CoreDateRange requires prop[name].');
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreDateRangePicker
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    if (this.listener) {
      this.listener.abort();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreDateRangePicker
   */
  public render() {
    const { Recycle, service, props, context } = this;
    const { name } = props 

    /* istanbul ignore next */
    if (!IsDefined(name)) {
      return null;
    }

    service.init(props, context);

    return (
      <PickerContext.Provider value={service}>
        <DateRangeContainer />
        <CoreFormConsumer name={name} type={CoreFormConsumerTypeEnum.WATCHER}>
          {({ form, values: { value } }): void => {
            if (value) {
              service.init(props, context);
              /* istanbul ignore next */
              WaitForDelay().then(() => {
                service.broadcast();
              });
            }
            /* istanbul ignore next */
            if (form.dirty) {
              const matched: boolean = value === service.actual;
              if (!matched) {
                Recycle();
              }
            }
            return null;
          }}
        </CoreFormConsumer>
      </PickerContext.Provider>
    );
  }

  /**
   * Recycle
   * @protected
   * @memberof CoreDateRangePicker
   */
  protected Recycle = (): void => {
    /* istanbul ignore next */
    WaitForDelay(0).then(() => {
      if (!this.listener.signal.aborted) {
        this.service.reset();
      }
    });
  };
}
