export const FORM_DICT_BUILD_BUTTON_TITLE_ADD = 'Add Row';
export const FORM_DICT_BUILD_BUTTON_TITLE_REMOVE = 'Remove Row';

export const FORM_DICT_REORDER_BUTTON_TITLE_UP = 'Shift Up';
export const FORM_DICT_REORDER_BUTTON_TITLE_DOWN = 'Shift Down';

export const FORM_DICT_REORDER_BUTTON_TITLE_DISABLED_UP = 'At Top';
export const FORM_DICT_REORDER_BUTTON_TITLE_DISABLED_DOWN = 'At Bottom';

export const FORM_DICT_PROPS = {
  source: null,
  index: 0,
  limit: 5,
  add: null,
  remove: null,
  nullable: false,
};
