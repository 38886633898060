/* istanbul ignore file */
import React, { PureComponent, Fragment } from 'react';

import { CorePermissionsInstance } from '../factories/';
import { CorePermissionsServiceShape, CorePermissionProps } from '../interfaces/';

/**
 * Core Permissions Base
 * @export
 * @class CorePermissionsBase
 * @extends {PureComponent<CorePermissionProps, {}>}
 */
export default class CorePermissionsBase extends PureComponent<CorePermissionProps, {}> {
  /**
   * Service
   * @private
   * @type {CorePermissionsServiceShape}
   * @memberof CorePermissionsBase
   */
  private _service: CorePermissionsServiceShape;

  /**
   * Creates an instance of CorePermissionsBase.
   * @param {CorePermissionProps} props
   * @memberof CorePermissionsBase
   */
  constructor(props: CorePermissionProps) {
    super(props);
    const { type, instance } = props;
    if (type) {
      this._service = CorePermissionsInstance(type);
    } else if (instance) {
      this._service = instance;
    }
  }

  /**
   * Render
   * @returns
   * @memberof CorePermissionsBase
   */
  public render() {
    console.warn('must extend in sub-class.');
    return <Fragment />;
  }

  /**
   * Service - getter
   * @readonly
   * @memberof CorePermissionsBase
   */
  public get service() {
    return this._service;
  }
}
