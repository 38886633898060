
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_PASSWORD } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Password Input Element
 * @export
 * @class CoreFormElementPassword
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementPassword extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementPassword
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_PASSWORD,
    placeholder: '...',
    autoComplete: 'off'
  });
}
