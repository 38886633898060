import React, { Component, Fragment } from 'react';

import CoreFormMenuContext from '../../../../contexts/Menu';
import { CoreFormEventsEnum as E } from '../../../../enums/';
import { WaitForDelay } from '../../../../../../../utils/';

/**
 * Form Menu Base
 * @abstract
 * @export
 * @class CoreFormMenuBase
 * @extends {Component<P, {}>}
 * @template P
 * @template S
 */
export default abstract class CoreFormMenuBase<P = {}, S = {}> extends Component<P, S> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormMenuContext>}
   * @memberof CoreFormMenuBase
   */
  public static contextType: Readonly<typeof CoreFormMenuContext> = CoreFormMenuContext;

  /**
   * Listener
   * @private
   * @type {AbortController}
   * @memberof ControllerMenuContainer
   */
  protected _listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof CoreFormMenuBase
   */
  public componentDidMount() {
    const { MenuEventListener, context } = this;
    this.listener = new AbortController();
    context.emitter.on(E.MENU_EVENT_UPDATE, MenuEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormMenuBase
   */
  public componentWillUnmount() {
    const { MenuEventListener, context } = this;
    if (this.listener) {
      this.listener.abort();
    }
    context.emitter.off(E.MENU_EVENT_UPDATE, MenuEventListener);
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormMenuBase
   */
  public render() {
    /* istanbul ignore next */
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * Menu Event Listener
   * @protected
   * @param {any[]} value
   * @param {CoreFormOptionsType} options
   * @param {boolean} active
   * @param {string} term
   * @memberof CoreFormMenuBase
   */
  /* istanbul ignore next */
  protected MenuEventListener = (value: any[], options: CoreFormOptionsType, active: boolean, term: string): void => {
    WaitForDelay().then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };

  /**
   * Listener - getter
   * @readonly
   * @protected
   * @memberof CoreFormMenuBase
   */
  protected get listener() {
    return this._listener;
  }

  /**
   * Listener - setter
   * @protected
   * @memberof CoreFormMenuBase
   */
  protected set listener(listener) {
    this._listener = listener;
  }
}
