import React, { PureComponent, Fragment } from 'react';

import CoreNotificationsContext from '../context';

/**
 * Notification Base
 * @export
 * @class CoreNotificationsBase
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNotificationsBase extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreNotificationsContext>}
   * @memberof CoreNotificationsBase
   */
  public static contextType: Readonly<typeof CoreNotificationsContext> = CoreNotificationsContext;

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreNotificationsBase
   */
   private _subscriber: Func<void> = null!;

   /**
   * LifeCycle Hook
   * @memberof CoreNotificationsBase
   */
  public componentDidMount() {
    const { context, NotificationsBaseListener } = this;
    this.subscriber = context.subscribe(NotificationsBaseListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreNotificationsBase
   */
  public componentWillUnmount() {
    const { context, subscriber } = this;
    context.unsubscribe(subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreNotificationsBase
   */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * Notifications Base Listener
   * @memberof CoreNotificationsBase
   */
  /* istanbul ignore next */
  public NotificationsBaseListener = (): void => {
    this.forceUpdate();
  };

  /**
   * Subscriber - getter
   * @readonly
   * @memberof CoreNotificationsBase
   */
  public get subscriber() {
    return this._subscriber;
  }

  /**
   * Subscriber - setter
   * @memberof CoreNotificationsBase
   */
  public set subscriber(subscriber) {
    this._subscriber = subscriber;
  }
}
