import React, { PureComponent } from 'react';

import CoreListModalKids from './ModalKids';
import { CoreListModalProps } from '../interfaces/';
import CoreForm from "../../forms/module";

/**
 * List Modal Container
 * @export
 * @class CoreListModalContainer
 * @extends {PureComponent<CoreListModalProps, {}>}
 */
export default class CoreListModalContainer extends PureComponent<CoreListModalProps, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreListModalContainer
   */
  public render() {
    const { OnSubmit, props: { context, current, dialog, children } } = this;

    const source: HashMap<any> = context.source[current];

    return (
      <CoreForm source={source} onSubmit={OnSubmit}>
        {children}
        <CoreListModalKids dialog={dialog} />
      </CoreForm>
    );
  }

  /**
   * On Submit
   * @protected
   * @memberof CoreListModalContainer
   */
  /* istanbul ignore next */
  protected OnSubmit = (evt: any, values: HashMap<any>): void => {
    const dialog = this.props.dialog;
    if (dialog.valid) {
      dialog.onAffirmAction(evt);
    }
  };
}
