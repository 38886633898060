import { CoreConfigApp, CoreConfigHeader, CoreConfigFooter, DEFAULT_PDF_HEADER_IMAGE, DEFAULT_CONFIG_META_STORAGE_NAME } from '@neustar/core-ui';

/**
 * Site Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function SitePlugin(): Promise<boolean|Error> {
  try {
    CoreConfigApp.DEFAULT_ROUTE = '/home';
    CoreConfigApp.PDF_HEADER_IMAGE = DEFAULT_PDF_HEADER_IMAGE;
    CoreConfigApp.CONFIG_META_STORAGE_NAME = DEFAULT_CONFIG_META_STORAGE_NAME;
    CoreConfigHeader.ACCOUNTS_DOMAIN = 'sandbox';
    CoreConfigHeader.PRODUCT_NAME = 'UI Core Sandbox';
    CoreConfigHeader.LOGO_TITLE = 'UI Core Sandbox';
    CoreConfigHeader.SHORT_NAME = 'neustar';
    // CoreConfigApp.UAID = 'UA-112569582-1';
    CoreConfigFooter.COPYRIGHT = 'Copyright \u00a9 Vercara, LLC. All Rights Reserved';
    CoreConfigFooter.PRODUCT_NAME = 'UI Core Sandbox';

    return true;
  } catch (err: any) {
    throw Error(err);
  }
};
