import { FEDERATION_URL, QUERY_USER_GET } from 'Graph';
import { ApplyWhiteLabel } from './ApplyWhiteLabel';
import { CoreBootAuthPlugins, CoreConfig, CoreConfigCompany, CoreGraphLink, CoreGraphDataFactory } from '@neustar/core-ui';

/**
 * Authenticate Plugin
 * @export
 * @param {CoreAuthServiceShape} context
 * @returns {Promise<boolean>}
 */
async function AuthenticatePlugin(context: CoreAuthServiceType): Promise<any> {
  return true;
}

/**
 * User Plugin
 * @export
 * @param {CoreAuthServiceShape} context
 * @returns {Promise<boolean>}
 */
async function UserPlugin(context: any): Promise<void> {
  try {
    const client = CoreGraphLink({ uri: FEDERATION_URL, silent: true }).client;

    const result: any = await client.query({ query: QUERY_USER_GET }).catch(console.error);
    const { dName, whitelabel } = CoreGraphDataFactory(result, 'company') as HashMap<any>;

    if (dName) {
      context.user.profile.account = dName;
    }

    const masquerade: boolean = false;

    if (masquerade) {
      const user: HashMap<any> = {
        account: 'MYACCOUNT',
        name: 'MYNAME',
        shortname: 'MYSHORT',
      };

      context.user.profile.masquerade = true;
      context.user.profile.account = user.account;
      const { name, shortname } = user;

      Object.assign(context.user.profile, { shortname });
      Object.assign(context.user.profile.userinfo, { name });
    }

    /**
     * CoreConfigCompany
     * - operations must go together and be applied AFTER USER QUERY
     */
    CoreConfigCompany.DNAME = context.user.account;
    if (!CoreConfig.company.dName) {
      CoreConfig.company.clear();
    }

    return await ApplyWhiteLabel(whitelabel).catch(console.error);
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err);
    }
  }
  return void 0;
}

/**
 * Logout Plugin
 * @async
 * @export
 * @param {CoreAuthServiceShape} context
 * @returns {Promise<void>}
 */
async function LogoutPlugin(context: any): Promise<void> {
  try {
    CoreConfig.company.destroy();
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err);
    }
  }
  return void 0;
}

// Registered as Boot Plugins
CoreBootAuthPlugins.register('authenticate', AuthenticatePlugin);
CoreBootAuthPlugins.register('user', UserPlugin);
CoreBootAuthPlugins.register('logout', LogoutPlugin);
