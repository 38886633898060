import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementMonth } from '../../../components/';

/**
 * Month Input Controller
 * @export
 * @class MonthInputController
 * @extends {InputBase}
 */
export default class MonthInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof MonthInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementMonth;
}
