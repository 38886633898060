import { WebAuth } from 'auth0-js';

import { AUTH_SCOPE as scope } from '../constants';
import { CoreBootEventEnum } from '../enums/';
import { CoreAuth, CoreAuthService, InstallAuth0User, RemoveTransactionalCookies, IsAuthenticatedAsync } from '../../Auth/';

/**
 * Boot Async Loader
 * @export
 * @param {CoreAuthPluginType} AuthPlugin
 * @param {CoreBootPluginsType[]} plugins
 * @returns {Promise<boolean|Error>}
 */
export async function CoreBootAsyncLoader(AuthPlugin: CoreAuthPluginType, plugins: CoreBootPluginsType[] = []): Promise<boolean|Error> {
  try {
    const { clientID, domain, audience } = await AuthPlugin();

    // Validate Credentials
    /* istanbul ignore next */
    if (!CoreAuth.credentials.validate(clientID, domain, audience)) {
      throw new ReferenceError(`CoreBootAsyncLoader: invalid credentials.`);
    }

    // Instantiate WebAuth
    CoreAuthService.WEBAUTH = new WebAuth({
      clientID,
      domain,
      audience,
      scope,
    });

    // Run Boot Plugins
    while (plugins.length) {
      /* istanbul ignore next */
      await plugins.shift()().catch(err => {
        console.debug('CoreBootAsyncLoader->plugin->catch', err);
        throw err;
      });
    }

    // Check Authenticated
    await IsAuthenticatedAsync().catch(() => {
      throw new Error(CoreBootEventEnum.UNAUTHENTICATED);
    })

    /* istanbul ignore next */
    await InstallAuth0User();

    /* istanbul ignore next */
    await RemoveTransactionalCookies();

    /* istanbul ignore next */
    return true;
  } catch (err: any) {
    throw err;
  }
}
