import React, { PureComponent, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';

import CoreFormContext from '../../forms/contexts/Form';
import { CoreFormEventsEnum } from '../../forms/enums/';
import { FORM_EVENT_DELAY } from '../../forms/constants/';

import CoreButtonBase from '../components/ButtonBase';
import { HOCButtonProps, ButtonVariantType } from '../interfaces/';
import { FORM_BUTTON_PROPS } from '../constants';

import { IsFunction, WaitForDelay } from '../../../../utils/';

/**
 * Core Form Button
 * @export
 * @class CoreFormButton
 * @extends {PureComponent<HOCButtonProps, {}>}
 */
export default class CoreFormButton extends PureComponent<HOCButtonProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreFormButton
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default Props
   * @static
   * @type {*}
   * @memberof CoreFormButton
   */
  public static defaultProps: Omit<HOCButtonProps, 'variant' | 'disabled' | 'outline' | 'value'> = cloneDeep(FORM_BUTTON_PROPS);

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreFormButton
   */
  protected listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof CoreFormButton
   */
   public componentDidMount() {
    const { FormEventListener, context } = this;
    this.listener = new AbortController();
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormButton
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    listener.abort();
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormButton
   */
  public render() {
    const { OnClick, props: { variant: VARIANT, outline: OUTLINE, disabled: DISABLED, value: VALUE, onClick: ONCLICK, ...rest }, context } = this;

    const variant: ButtonVariantType = IsFunction(VARIANT) ? (VARIANT as any)(context) : VARIANT;
    const outline: boolean = IsFunction(OUTLINE) ? (OUTLINE as any)(context) : OUTLINE;
    const disabled: boolean = IsFunction(DISABLED) ? (DISABLED as any)(context) : DISABLED;
    const value: string = IsFunction(VALUE) ? (VALUE as any)(context) : VALUE;

    return <CoreButtonBase onClick={OnClick} variant={variant} outline={outline} disabled={disabled} value={value} {...rest} />;
  }

  /**
   * On Click
   * @protected
   * @param {MouseEvent<HTMLButtonElement>} evt
   * @memberof CoreFormButton
   */
  /* istanbul ignore next */
  protected OnClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    evt.persist();
    const { context, props: { onClick } } = this;
    if (IsFunction(onClick)) {
      onClick(evt, context);
    }
    return void 0;
  };

  /**
   * Form Event Listener
   * @protected
   * @memberof CoreFormButton
   */
  /* istanbul ignore next */
  protected FormEventListener = (): void => {
    WaitForDelay(FORM_EVENT_DELAY).then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };
}
