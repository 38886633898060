import { CoreConfigAccountShape } from '../interfaces/';

/**
 * Config Account Service
 * @export
 * @class CoreConfigHeader
 */
export class CoreConfigAccount implements CoreConfigAccountShape {
  public static PROXY: boolean = false;
  public static BGP: boolean = false;
  public static WAF: boolean = false;
  public static DNA: boolean = false;
  public static API_ACCESS: boolean = false;
  public static OPENHYBRID: boolean = false;
  public static RESELLER: boolean = false;
  public static ULTRAWAF: boolean = false;
  public static BOT: boolean = false;
  public static PROXY_TYPE: string = 'hardware';
  public static SSO: boolean = false;
  public get proxy() {
    return CoreConfigAccount.PROXY;
  }
  public get bgp() {
    return CoreConfigAccount.BGP;
  }
  public get waf() {
    return CoreConfigAccount.WAF;
  }
  public get dna() {
    return CoreConfigAccount.DNA;
  }
  public get api_access() {
    return CoreConfigAccount.API_ACCESS;
  }
  public get openhybrid() {
    return CoreConfigAccount.OPENHYBRID;
  }
  public get reseller() {
    return CoreConfigAccount.RESELLER;
  }
  public get ultrawaf() {
    return CoreConfigAccount.ULTRAWAF;
  }
  public get bot() {
    return CoreConfigAccount.BOT;
  }
  public get proxy_type() {
    return CoreConfigAccount.PROXY_TYPE;
  }
  public get sso() {
    return CoreConfigAccount.SSO;
  }
}
