import React, { ComponentClass } from 'react';

import ControllerBase from '../ControllerBase';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormElementTextArea, ControllerLabel, ControllerError } from '../../../components/';

/**
 * TextArea Base
 * @export
 * @class TextAreaBase
 * @extends {ControllerBase}
 */
export default class TextAreaBase extends ControllerBase {
  /**
   * Controlled Element
   * @static
   * @type {ComponentClass<any>}
   * @memberof TextAreaBase
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementTextArea;

  /**
   * Render
   * @returns
   * @memberof TextAreaBase
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, exceptions, controller, tooltip, description } = this;
    const { name, value } = controller;

    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel htmlFor={null} {...labels}>
          <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
          <ControllerError {...exceptions} />
          {description}{tooltip}
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
