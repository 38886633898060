import { ComponentClass } from 'react';

import TextAreaBase from './Base';
import { CoreFormElementTextArea } from '../../../components/';

/**
 * TextArea Controller
 * @export
 * @class TextAreaController
 * @extends {TextAreaBase}
 */
export default class TextAreaController extends TextAreaBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof TextAreaBase
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementTextArea;
}
