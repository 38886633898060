import React, { PureComponent } from 'react';

import CoreToggleContext from '../context';

/**
 * Toggle Title Extra
 * @export
 * @class CoreToggleTitleExtra
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreToggleTitleExtra extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreToggleContext>}
   * @memberof CoreToggleTitleExtra
   */
  public static contextType: Readonly<typeof CoreToggleContext> = CoreToggleContext;

  /**
   * Render
   * @returns
   * @memberof CoreToggleTitleExtra
   */
  public render() {
    const {
      props: { children },
    } = this;
    return <dt className="toggle-title-extra">{children}</dt>;
  }
}
