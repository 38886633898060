import React, { PureComponent } from 'react';

/**
 * Input Description
 * @export
 * @class CoreInputDescriptionComponent
 * @extends {PureComponent<CoreFormInputDescriptionProps, {}>}
 */
export default class CoreInputDescriptionComponent extends PureComponent<{}, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreInputDescriptionComponent
   */
  public static displayName: string = 'CoreInputDescription';

  /**
   * Render
   * @returns
   * @memberof CoreInputDescriptionComponent
   */
  public render() {
    const { props: { children }} = this;
    return <samp>{children}</samp>;
  }
}
