import { cloneElement } from 'react';

import CoreDialogsService from '../service';
import { CoreDialogShape, DialogLabelsShape } from '../interfaces/';
import { DIALOG_LABELS_MAP, DIALOG_MODES, DIALOG_MODE_DEFAULT, DIALOG_TYPES, DIALOG_TYPE_ALERT, DIALOG_TYPE_DEFAULT, DIALOG_TYPE_PROMPT, DIALOG_TYPE_CONFIRM } from '../constants';

import { WaitForDelay, IsHashMap } from '../../../utils/';

/**
 * Dialog Instance
 * @export
 * @class CoreDialogInstance
 * @implements {CoreDialogShape}
 */
export default class CoreDialogInstance implements CoreDialogShape {
  /**
   * Class Properties
   * @private
   * @memberof CoreDialogInstance
   */
  private _name = '';
  private _type: string = DIALOG_TYPE_DEFAULT;
  private _mode: string = DIALOG_MODE_DEFAULT;
  private _title: string = '';
  private _active: boolean = false;
  private _valid: boolean = true;
  private _labels: DialogLabelsShape = null!;
  private _source: HashMap<any> | Func<HashMap<any>> = {};
  private _content: JSX.Element = null!;
  private _resolver: (value?: HashMap<any> | PromiseLike<HashMap<any>>) => void = () => void 0;
  private _rejector: (reason?: any) => void = () => void 0;

  /**
   * Creates an instance of CoreDialogInstance.
   * @param {JSX.Element} content
   * @param {string} [type=DIALOG_TYPE_DEFAULT]
   * @memberof CoreDialogInstance
   */
  constructor(content: JSX.Element, type?: string, mode?: string) {
    this.init(content, type, mode);
  }

  /**
   * Promise
   * @returns {Promise<any>}
   * @memberof CoreDialogInstance
   */
  public promise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._resolver = resolve;
      this._rejector = reject;
    });
  }

  /**
   * Activate
   * @returns {CoreDialogInstance}
   * @memberof CoreDialogInstance
   */
  public activate(): CoreDialogInstance {
    WaitForDelay().then(() => {
      this._active = true;
      const applied = CoreDialogsService.apply(this);
      /* istanbul ignore next */
      if (applied instanceof Error) {
        throw applied;
      }
    }).catch(console.error);
    return this;
  }

  /**
   * Deactivate
   * @returns
   * @memberof CoreDialogInstance
   */
  public deactivate(): CoreDialogInstance {
    try {
      this._active = false;
      const destroyed = CoreDialogsService.destroy(this);
      /* istanbul ignore next */
      if (destroyed instanceof Error) {
        throw destroyed;
      }
    } catch (err: any) {
      /* istanbul ignore next */
      console.error(err.message||err);
    }
    return this;
  }

  /**
   * On Affirm Action
   * @param {any} evt
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public readonly onAffirmAction = (evt: any): void => {
    const { type, source } = this;
    let response: any;
    switch (type) {
      case DIALOG_TYPE_PROMPT:
        response = (source as HashMap<any>).value;
        break;
      case DIALOG_TYPE_ALERT:
      case DIALOG_TYPE_CONFIRM:
        response = true;
        break;
      default:
        if (IsHashMap(source)) {
          response = source as HashMap<any>;
        } else {
          response = (source as Func<HashMap<any>>)();
        }
        break;
    }

    /**
     * Timeout to allow for Content Component Unmount
     * which passes final state to dialog.
     */
    WaitForDelay(100).then(() => {
      this.resolve(response);
    });
    this.deactivate();
  };

  /**
   * On Deny Action
   * On Affirm Action
   * @param {any} evt
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public readonly onDenyAction = (evt: any): void => {
    const { type } = this;
    let reason: any;
    switch (type) {
      case DIALOG_TYPE_DEFAULT:
        reason = 'cancelled';
        break;
      default:
        reason = false;
        break;
    }

    this.deactivate();
    this.reject(reason);
  };

  /**
   * Name - getter
   * @readonly
   * @type {string}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get name() {
    return this._name;
  }

  /**
   * Name - setter
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public set name(name: string) {
    this._name = name;
  }

  /**
   * Type - getter
   * @readonly
   * @type {string}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get type() {
    return this._type;
  }

  /**
   * Mode - getter
   * @readonly
   * @type {string}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get mode() {
    return this._mode;
  }

  /**
   * Mode - setter
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public set mode(mode) {
    this._mode = mode;
  }

  /**
   * Title - getter
   * @readonly
   * @type {string}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get title() {
    return this._title;
  }

  /**
   * Title - setter
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public set title(title) {
    this._title = title;
  }

  /**
   * Active - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get active() {
    return this._active;
  }

  /**
   * Valid - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get valid() {
    return this._valid;
  }

  /**
   * Valid - setter
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public set valid(valid: boolean) {
    this._valid = valid;
  }

  /**
   * Labels - getter
   * @readonly
   * @type {DialogLabelsShape}
   * @memberof CoreDialogInstance
   */
  /* istanbul ignore next */
  public get labels() {
    return this._labels;
  }

  /**
   * Labels - setter
   * @memberof CoreDialogInstance
   */
  public set labels(labels: DialogLabelsShape) {
    this._labels = Object.assign({}, this.labels, labels);
  }

  /**
   * Content - getter
   * @readonly
   * @type {JSX.Element}
   * @memberof CoreDialogInstance
   */
  public get content() {
    return this._content;
  }

  /**
   * Source - getter
   * @readonly
   * @type {HashMap<any>}
   * @memberof CoreDialogInstance
   */
  public get source(): HashMap<any> | Func<HashMap<any>> {
    return this._source;
  }

  /**
   * Data - setter
   * @memberof CoreDialogInstance
   */
  public set source(source) {
    /* istanbul ignore next */
    if (IsHashMap(source)) {
      this._source = Object.assign({}, this.source, source);
    } else {
      this._source = source;
    }
  }

  /**
   * Init
   * @private
   * @param {JSX.Element} content
   * @param {string} type
   * @param {string} mode
   * @memberof CoreDialogInstance
   */
  private init(content: JSX.Element, type: string = DIALOG_TYPE_DEFAULT, mode: string = DIALOG_MODE_DEFAULT): void {
    try {
      /* istanbul ignore next */
      if (!~DIALOG_TYPES.indexOf(type)) {
        throw new ReferenceError(`must pass "type" one of [${DIALOG_TYPES.join(' | ')}]`);
      }
      /* istanbul ignore next */
      if (!~DIALOG_MODES.indexOf(mode)) {
        throw new ReferenceError(`must pass "mode" one of [${DIALOG_MODES.join(' | ')}]`);
      }

      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        if (!content.type.name) {
          throw new TypeError(`must use React Component as content [${content}]`);
        }
      }

      this._type = type;
      this._mode = mode;
      this._content = cloneElement(content, { dialog: this });
      this._labels = DIALOG_LABELS_MAP[type];
      this._name = `${type}_${mode}_${content.type.name}`;

      CoreDialogsService.add(this);
    } catch (err: any) {
      /* istanbul ignore next */
      console.error(err);
    }
  }

  /**
   * Resolve
   * @private
   * @param {HashMap<any> | PromiseLike<HashMap<any>>} [value]
   * @memberof CoreDialogInstance
   */
  private resolve(value: HashMap<any> | PromiseLike<HashMap<any>>): void {
    this._resolver(value);
  }

  /**
   * Reject
   * @private
   * @param {*} [reason]
   * @memberof CoreDialogInstance
   */
  private reject(reason: any): void {
    this._rejector(reason);
  }
}
