export const TABS_PROPS = {
  strict: false,
  closable: false,
  height: undefined,
  children: []
};

export const TABS_ITEM_PROPS = {
  item: null,
  label: null,
  active: false,
  strict: false,
  default: false,
  hide: false
};

export const TABS_ALLOWED_HEIGHTS = [20, 30, 40, 50, 60];

export const TABS_EVENT_CHANGE = 'TABS_EVENT_CHANGE';
