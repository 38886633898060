import { cloneDeep } from 'lodash';

import { CoreAuth } from '../../../../Auth/';
import { CoreConfig, CoreConfigPermissionsMask as MASK } from '../../../../Config/';
import { CorePermissionsServiceShape } from '../interfaces/';

import { PERMISSIONS_ALLOWED, PERMISSIONS_VALUE_MAP } from '../constants';

import { ObjectKeys } from '../../../../utils/';

/**
 * Permissions Service
 * @export
 * @class CorePermissionsService
 * @implements {CorePermissionsServiceShape}
 */
export default class CorePermissionsService implements CorePermissionsServiceShape {
  /**
   * Permissions Map
   * @private
   * @type {HashMap<MASK>}
   * @memberof CorePermissionsService
   */
  private _map: HashMap<MASK> = cloneDeep(CoreConfig.permissions.complex_map);

  /**
   * Type
   * @private
   * @type {string}
   * @memberof CorePermissionsService
   */
  private _type: string;

  /**
   * Permissions Inclusive
   * @private
   * @type {HashMap<HashMap<Array<MASK>>k>}
   * @memberof CorePermissionsService
   */
  private _inclusive: HashMap<Array<MASK>> = {};

  /**
   * Init
   * @param {string[]} [permissions]
   * @memberof CorePermissionsService
   */
  public init(permissions?: string[]): void {

    try {
      const { map, type } = this;

      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        if (!type) {
          throw new ReferenceError(`missing prop [type].`);
        }
        if (!(type in map)) {
          throw new ReferenceError(`prop 'type' must be of [${ObjectKeys(PERMISSIONS_VALUE_MAP).join(', ')}].`);
        }
      }

      if (!permissions) {
        permissions = CoreAuth.permissions;
      }

      let i: number = permissions.length;
      while (i--) {
        const [name, value]: string[] = permissions[i].split(':') || [];
        if (name in this.map) {
          if (!!~PERMISSIONS_ALLOWED.indexOf(value)) {
            this.map[name] = PERMISSIONS_VALUE_MAP[value];

            if (!(name in this.inclusive)) {
              this.inclusive[name] = [];
            }

            this.inclusive[name].push(PERMISSIONS_VALUE_MAP[value]);
          }
        }
      }

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Map - getter
   * @readonly
   * @memberof CorePermissionsService
   */
  public get map() {
    return this._map;
  }

  /**
   * Inclusive
   * @readonly
   * @memberof CorePermissionsService
   */
  public get inclusive() {
    return this._inclusive;
  }

  /**
   * Type - getter
   * @readonly
   * @memberof CorePermissionsService
   */
  public get type() {
    return this._type;
  }

  /**
   * Type - setter
   * @memberof CorePermissionsService
   */
  public set type(type: string) {
    this._type = type;
  }

  /**
   * Mask - getter
   * @readonly
   * @private
   * @memberof CorePermissionsService
   */
  private get mask() {
    return this.map[this.type];
  }

  /**
   * Included - getter
   * @readonly
   * @private
   * @memberof CorePermissionsService
   */
  private get included() {
    const { inclusive, type, } = this;
    return type in inclusive ? inclusive[type] : [];
  }

  /**
   * IsReadOnly - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionsService
   */
  public get IsReadOnly(): boolean {
    const { mask } = this;
    return mask === MASK.READ;
  }

  /**
   * IsReadWrite - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionsService
   */
  public get IsReadWrite(): boolean {
    const { mask } = this;
    return mask === MASK.WRITE;
  }

  /**
   * IsAdmin - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionsService
   */
  public get IsAdmin(): boolean {
    const { mask } = this;
    return mask === MASK.OTHER;
  }

  /**
   * Can Edit - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionsService
   */
  public get CanEdit(): boolean {
    const { included } = this;
    return !!~included.indexOf(MASK.WRITE) || !!~included.indexOf(MASK.OTHER);
  }

  /**
   * Can Create - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionsService
   */
  public get CanCreate(): boolean {
    const { included } = this;
    return !!~included.indexOf(MASK.WRITE) && !!~included.indexOf(MASK.OTHER);
  }
}
