import React, { FormEvent } from 'react';
import moment from 'moment';

import DatePickerBase from './Base';

import { CoreFormElementDate, CoreFormInputProps } from '../../forms/';
import { IsEmpty, IsFunction, IsChromeBrowser, AsyncComponentTimeout } from '../../../../utils/';

/**
 * Start Date Component
 * @export
 * @class StartDateComponent
 * @extends {DatePickerBase<CoreFormInputProp>}
 */
export default class StartDateComponent extends DatePickerBase<CoreFormInputProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof StartDateComponent
   */
  public static displayName: string = 'StartDate';

  /**
   * Watch
   * @private
   * @type {Required<AsyncComponentTimeout>}
   * @memberof StartDateComponent
   */
  private watch: Required<AsyncComponentTimeout> = new AsyncComponentTimeout();

  /**
   * Render
   * @returns
   * @memberof StartDateComponent
   */
  public render() {
    const { OnStartDateChange, OnStartDateBlur, context: { start_date_name, start_date_value, min_date_value, end_date_value }, props: { required: REQUIRED }} = this;
    const required: boolean = IsFunction(REQUIRED) ? (REQUIRED as any)() : REQUIRED;
    return <CoreFormElementDate key={`start.date.picker.${Date.now()}`} name={start_date_name} value={start_date_value} onChange={OnStartDateChange} onBlur={OnStartDateBlur} min={min_date_value} max={end_date_value} required={required} className="width-16" />
  }

  /**
   * On Start Date Change
   * @protected
   * @param {FormEvent<HTMLInputElement>} { target, currentTarget: { validity: { valid } }}
   * @param {CoreFormValueType} value
   * @memberof StartDateComponent
   */
  /* istanbul ignore next */
  protected OnStartDateChange = ({ target, currentTarget: { validity: { valid } }}: FormEvent<HTMLInputElement>, value: CoreFormValueType): void => {
    const { DoDateChange } = this;
    if (IsChromeBrowser()) {
      if (!this.context.ready) {
        this.watch.clear();
        this.watch.delay(2000).then(() => {
          (target as any).blur();
        });
      }
    } else {
      if (valid) {
        DoDateChange(value);
      }
    }
  };

  /**
   * On Start Date Blur
   * @protected
   * @param {FormEvent<HTMLInputElement>} { currentTarget: { validity: { valid } }}
   * @param {CoreFormValueType} value
   * @memberof StartDateComponent
   */
  /* istanbul ignore next */
  protected OnStartDateBlur = ({ currentTarget: { validity: { valid } }}: FormEvent<HTMLInputElement>, value: CoreFormValueType): void => {
    const { DoDateChange } = this;
    if (IsChromeBrowser()) {
      if (valid) {
        DoDateChange(value);
      }
    }
  };

  /**
   * Do Date Change
   * @protected
   * @param {CoreFormValueType} value
   * @memberof StartDateComponent
   */
  /* istanbul ignore next */
  protected DoDateChange = (value: CoreFormValueType): void => {
    const { dayspan, end_date_value, props: { required } } = this.context;
    const start_date_value = value as string;
    if (dayspan) {
      const diff = moment(end_date_value).diff(moment(start_date_value), 'days');

      if (diff < dayspan) {
        const min: number = moment(start_date_value).startOf('day').valueOf();
        const max: number = moment(start_date_value).add(dayspan, 'days').endOf('day').valueOf();
        this.context.min = min;
        this.context.max = max;
      }
    }

    if (IsEmpty(value) && required) {
      this.context.update('', '');
    } else {
      this.context.update(start_date_value);
    }
  };
}
