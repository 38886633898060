import React, { PureComponent } from 'react';

/**
 * Toggle Definition
 * @export
 * @class CoreToggleDefinition
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreToggleDefinition extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreToggleDefinition
   */
  public render() {
    const { children } = this.props;
    return (
      <dd>
        <div>{children}</div>
      </dd>
    );
  }
}
