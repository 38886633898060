import React from 'react';

import DTGBase from './DTGBase';

import { CoreFormGroup } from '../../forms/';

import { DisplayValue, StartDate, EndDate, ApplyButton } from '../components/';

import { MergeClassNames, IsDefined } from '../../../../utils/';

/**
 * Date Range Container
 * @export
 * @class DateRangeContainer
 * @extends {DTGBase<Component<GenericPropType, PickerState>}>
 */
export default class DateRangeContainer extends DTGBase {
  /**
   * Render
   * @returns
   * @memberof DateRangeContainer
   */
  public render() {
    const { OnToggleActive, OnApplyAction, state: { active }, context: { props } } = this;
    const { className, label, disabled } = props;

    const clz = MergeClassNames(className, { 'date-range-picker': true, labelled: IsDefined(label), active });

    return (
      <CoreFormGroup className={clz}>
        <DisplayValue toggle={OnToggleActive} {...props} />
        {disabled ? null : (
          <div>
            <CoreFormGroup>
              <StartDate {...props} />
            </CoreFormGroup>
            <CoreFormGroup>
              <EndDate {...props} />
            </CoreFormGroup>
            <ApplyButton action={OnApplyAction} />
          </div>
        )}
      </CoreFormGroup>
    );
  }
}
