import React, { Component, FormEvent, KeyboardEvent } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';
import { CoreTableSearchProps } from '../interfaces/';
import { TABLE_SEARCH_PROPS } from '../constants';

import { CoreTooltip } from '../../../components/';
import { CoreFormGroup, CoreInputLabel, CoreFormElementSearch } from '../../forms/';
import { AsyncComponentTimeout, MergeClassNames } from '../../../../utils/';

/**
 * Table Search Container
 * @export
 * @class CoreTableSearchContainer
 * @extends {Component<CoreTableSearchProps, {}>}
 */
export default class CoreTableSearchContainer extends Component<CoreTableSearchProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableSearchContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableSearchProps}
   * @memberof CoreTableSearchContainer
   */
  public static defaultProps: CoreTableSearchProps = cloneDeep(TABLE_SEARCH_PROPS);

  /**
   * Watch
   * @private
   * @type {Required<AsyncComponentTimeout>}
   * @memberof CoreTableSearchContainer
   */
  private watch: Required<AsyncComponentTimeout> = new AsyncComponentTimeout();

  /**
   * LiefCycle Hook
   * @memberof CoreTableSearchContainer
   */
  public componentDidMount() {
    const { searchable } = this.context.search;
    try {
      /* istanbul ignore next */
      if (!searchable) {
        throw new ReferenceError('Table must be configured with search params for this component.');
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * LiefCycle Hook
   * @memberof CoreTableSearchContainer
   */
  public componentWillUnmount() {
    this.watch.clear();
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableSearchContainer
   */
  public render() {
    const { OnInput, OnKeyDown, context: { search: { fields }}, props: { tooltip: USE_TOOLTIP, placeholder: PLACEHOLDER, className } } = this;
    const placeholder: string = PLACEHOLDER || 'filter...';
    const clz = MergeClassNames(className, {});
    const tooltip: string = fields.length > 1 ? `fields: ${fields.join(', ')}` : `field: ${fields[0]}`;
    return (
      <CoreFormGroup className="table-search">
        <CoreInputLabel>
          <CoreFormElementSearch name="term" onInput={OnInput} onKeyDown={OnKeyDown} className={clz} placeholder={placeholder} autoComplete="off" />
          {USE_TOOLTIP ? (<CoreTooltip tooltip={tooltip} />) : null}
        </CoreInputLabel>
      </CoreFormGroup>
    );
  }

  /**
   * On Input
   * @protected
   * @memberof CoreTableSearchContainer
   */
  /* istanbul ignore next */
  protected OnInput = (evt: FormEvent<HTMLInputElement>, value: any): void => {
    const { context } = this;
    const delay: number = value.length > 2 ? 300 : 600;
    this.watch.delay(delay).then(() => {
      context.search.update(value);
      context.process();
    });
  };

  /**
   * On Key Down
   * @protected
   * @memberof CoreTableSearchContainer
   */
  protected OnKeyDown = (evt: KeyboardEvent<HTMLInputElement>): void => {
    const { key } = evt;
    if (!!~[13, 'Enter'].indexOf(key)) {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };
}
