import { CoreHeaderMenuServiceShape, CoreHeaderMenuItemShape, CoreHeaderToolServiceShape } from '../interfaces/';
import { CoreHeaderToolService } from './tool';

/**
 * Header Menu Service
 * @export
 * @class CoreHeaderMenuService
 * @implements {CoreHeaderMenuServiceShape}
 */
export class CoreHeaderMenuService implements CoreHeaderMenuServiceShape {
  /**
   * Tool Service
   * @type {CoreHeaderToolServiceShape}
   * @memberof CoreHeaderMenuService
   */
  public tool: CoreHeaderToolServiceShape = new CoreHeaderToolService();

  /**
   * Items
   * @private
   * @type {Map<string, CoreHeaderMenuItemShape>}
   * @memberof CoreHeaderMenuService
   */
  private _items: Map<string, CoreHeaderMenuItemShape> = new Map<string, CoreHeaderMenuItemShape>();

  /**
   * Use
   * @param {Map<string, CoreHeaderMenuItemShape>} items
   * @memberof CoreHeaderMenuService
   */
  public use(items: Map<string, CoreHeaderMenuItemShape>): void {
    this._items = items;
  }

  /**
   * Items - getter
   * @type {string}
   * @memberof CoreHeaderMenuService
   */
  public get items(): Map<string, CoreHeaderMenuItemShape> {
    return this._items;
  }

  /**
   * Populated - getter
   * @readonly
   * @memberof CoreHeaderMenuService
   */
  public get populated() {
    return this.items.size > 0;
  }
}

/**
 * Header Menu Service
 * @export
 * @class CoreHeaderMenuService
 * @implements {CoreHeaderMenuServiceShape}
 */
const CoreHeaderMenu = new CoreHeaderMenuService();

export default CoreHeaderMenu;
