import React, { PureComponent } from 'react';

import CoreConfigContext from '../../../Config/context';

/**
 * Boot Logo Title
 * @export
 * @class CoreBootLogoTitleComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBootLogoTitleComponent extends PureComponent<{},{}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreBootLogoTitleComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Render
   * @returns
   * @memberof CoreBootLogoTitleComponent
   */
  public render() {
    const { logo_title } = this.context.header;
    return <h2>{logo_title}</h2>;
  }
}
