import React, { PureComponent, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

import CoreTableContext from '../../tables/context';

import CoreButtonBase from '../components/ButtonBase';
import { HOCButtonProps, ButtonVariantType } from '../interfaces/';
import { TABLE_BUTTON_PROPS } from '../constants';

import { IsFunction } from '../../../../utils/';

/**
 * Core Table Button
 * @export
 * @class CoreTableButton
 * @extends {PureComponent<HOCButtonProps, {}>}
 */
export default class CoreTableButton extends PureComponent<HOCButtonProps, {}> {
  /**
   * Subscriber Name
   * @protected
   * @type {string}
   * @memberof CoreTableButton
   */
  protected sub: string = `CoreTableButton${nanoid(3)}`;

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableButton
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {*}
   * @memberof CoreTableButton
   */
  public static defaultProps: Omit<HOCButtonProps, 'variant' | 'disabled' | 'outline' | 'value'> = cloneDeep(TABLE_BUTTON_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreTableButton
   */
   public componentDidMount() {
    const { context, sub } = this;
    context.subscribe(sub, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableButton
   */
  public componentWillUnmount() {
    const { context, sub } = this;
    context.unsubscribe(sub);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableButton
   */
  public render() {
    const { OnClick, context, props: { variant: VARIANT, outline: OUTLINE, disabled: DISABLED, value: VALUE, onClick: ONCLICK, ...rest } } = this;

    const variant: ButtonVariantType = IsFunction(VARIANT) ? (VARIANT as any)(context) : VARIANT;
    const outline: boolean = IsFunction(OUTLINE) ? (OUTLINE as any)(context) : OUTLINE;
    const disabled: boolean = IsFunction(DISABLED) ? (DISABLED as any)(context) : DISABLED;
    const value: string = IsFunction(VALUE) ? (VALUE as any)(context) : VALUE;

    return <CoreButtonBase onClick={OnClick} variant={variant} outline={outline} disabled={disabled} value={value} {...rest} />;
  }

  /**
   * On Click
   * @protected
   * @param {MouseEvent<HTMLButtonElement>} evt
   * @memberof CoreTableButton
   */
  /* istanbul ignore next */
  protected OnClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    evt.persist();
    const { context, props: { onClick } } = this;
    if (IsFunction(onClick)) {
      onClick(evt, context);
    }
    return void 0;
  };
}
