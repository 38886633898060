/* istanbul ignore file */
import 'unfetch/polyfill/index.js';

import { CoreBusy } from './Activity/Busy/';
import { CoreAuth } from './Auth/';
import { BUSY_MESSAGE_LOOKING, BUSY_MESSAGE_LOADING, BUSY_MESSAGE_DEFAULT } from './Config/';

export default (function CoreInterceptor() {
  enum Mask {
    UNSENT = 0,
    OPENED = 1,
    HEADERS_RECEIVED = 2,
    LOADING = 3,
    DONE = 4,
  }
  const pattern = /^c(\d{4}).report.gbss.io$/i;
  let hostname = "";
  /**
   * XHR::open Interception
   */
  ((open) => {
    (XMLHttpRequest as any).prototype.open = function(method: string, url: string, async: boolean, username?: string | null, password?: string | null): void {
      hostname = new URL(url).hostname;      
      if (pattern.test(hostname)) {
        // DO NOTHING
      }
      else {
        this.addEventListener(
          'readystatechange',
          (evt: Event) => {
            const { readyState } = this;
            if (readyState === Mask.OPENED) {
              CoreBusy.on(BUSY_MESSAGE_LOOKING);
            }
            if (readyState === Mask.LOADING) {
              CoreBusy.message = BUSY_MESSAGE_LOADING;
              CoreBusy.emit();
            }
            if (readyState === Mask.DONE) {
              CoreBusy.off(BUSY_MESSAGE_DEFAULT);
            }
          },
          false
        );        
      }
      open.call(this, method, url, async, username, password);
    };
  })(XMLHttpRequest.prototype.open);

  /**
   * XHR::send Interception
   */
  ((send) => {
    XMLHttpRequest.prototype.send = function(data: any): void {
      if (pattern.test(hostname)) {
        // DO NOTHING
      }
      else {
        const installed: boolean = CoreAuth.installed;
        const token: string = CoreAuth.token || null;
        if (installed && token) {
          this.setRequestHeader('authorization', `Bearer ${token}`);
        }        
      }
      send.call(this, data);
    };
  })(XMLHttpRequest.prototype.send);
})();