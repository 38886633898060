import '@nux/components';
import { NuxStylesThemes } from '@nux/styles';
import { NuxOverride } from '@nux/core';
import { CoreConfigMeta } from '@neustar/core-ui';

import './nux.scss';

/**
 * Nux Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function NuxPlugin(): Promise<boolean | Error> {
  try {
    if (CoreConfigMeta.whitelabeled) {
      NuxStylesThemes.use('light');
      NuxOverride.buttons.config({ angled: false });
    } else {
      NuxStylesThemes.use('dark');
    }
    return true;
  } catch (err: any) {
    throw Error(err);
  }
}
