import { FormEvent } from 'react';
import EventEmitter from 'events';
import { nanoid } from 'nanoid';
import { cloneDeep } from 'lodash';

import { CoreFormOnSubmitType, CoreFormControllerInstanceType } from '../types';
import { CoreFormServiceShape, CoreFormProps } from '../interfaces/';
import { CoreFormEventsEnum as E } from '../enums/';
import { FormRegistryTransformer, FormDictionarySync, FormArraySync, FormRegistrySorter, FormDeprecationWarning, InstallOrUpdateRegistry } from '../factories/';

import { GetDeepHashMap, UpdateDeepHashMap, WaitForDelay, IsDefined, IsDictionary, IsString, IsEqual, IsEmpty, IsSymbol, IsNull } from '../../../../utils/';

/**
 * Form Service
 * @export
 * @class CoreFormService
 * @implements {CoreFormServiceShape}
 */
export class CoreFormService implements CoreFormServiceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreDialogService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Registry
   * @private
   * @type {Map<string, CoreFormControllerInstanceType>}
   * @memberof CoreFormService
   */
  private _registry: Map<string, CoreFormControllerInstanceType> = new Map<string, CoreFormControllerInstanceType>();

  /**
   * ID
   * @private
   * @type {string}
   * @memberof CoreFormService
   */
  private _id: string = nanoid(10);

  /**
   * Vault - Freezable Source
   * @private
   * @type {HashMap<any>}
   * @memberof CoreFormService
   */
  private _vault: HashMap<any> = {};

  /**
   * Values - Frozen Source
   * @private
   * @type {HashMap<any>}
   * @memberof CoreFormService
   */
  private _values: Readonly<HashMap<any>>;

  /**
   * Validate
   * @private
   * @type {boolean}
   * @memberof CoreFormService
   */
  private _validate: boolean = true;

  /**
   * On Submit Handler
   * @private
   * @type {CoreFormOnSubmitType}
   * @memberof CoreFormService
   */
  private _onSubmit: CoreFormOnSubmitType = (evt: FormEvent<any>, values: HashMap<any>, formdata?: FormData, form?: CoreFormServiceShape): void => void 0;

  /**
   * Creates an instance of CoreFormService.
   * @param {CoreFormProps} [props]
   * @memberof CoreFormService
   */
  constructor(props?: CoreFormProps) {
    this._emitter.setMaxListeners(500);
    /* istanbul ignore next */
    if (props) {
      this.use(props);
    }
  }

  /**
   * Use
   * @param {CoreFormProps} { id, source, vault, noValidate, onSubmit }
   * @memberof CoreFormService
   */
  public use({ id, source, vault, noValidate, onSubmit }: CoreFormProps): void {
    const values = IsDefined(source) ? cloneDeep(source) : null;

    if (IsDefined(id)) {
      this._id = id;
    }

    if (IsDefined(vault)) {
      vault = cloneDeep(vault);
      this._vault = Object.freeze(vault);
    } else {
      if (values) {
        vault = cloneDeep(values);
        this._vault = Object.freeze(vault);
      }
    }

    if (IsDefined(noValidate)) {
      this._validate = noValidate;
    }

    if (IsDefined(onSubmit)) {
      this._onSubmit = onSubmit;
    }

    if (values) {
      this._values = Object.freeze(cloneDeep(values));
    }

    /**
     * Initialize Form
     */
    FormRegistryTransformer(this.registry, values);
  }

  /**
   * Add
   * @param {CoreFormControllerInstanceType} controller
   * @memberof CoreFormService
   */
  public add = (controller: CoreFormControllerInstanceType): void => {
    this.registry.set(controller.name, controller);
  };

  /**
   * Remove
   * @param {CoreFormControllerInstanceShape} { name }
   * @memberof CoreFormService
   */
  public remove = ({ name }: CoreFormControllerInstanceType): void => {
    this.registry.delete(name);
  };

  /**
   * Stash
   * @param {CoreFormControllerInstanceShape} { name, value }
   * @memberof CoreFormService
   */
  public stash = ({ name, value }: CoreFormControllerInstanceType): void => {
    const { vault } = this;
    /**
     * Ensure only the first amendment gets in.
     */
    if (vault && !Object.isFrozen(vault)) {  
      if (!GetDeepHashMap(vault, name, false, true)) {
        UpdateDeepHashMap(vault, name, value);
      }
    }
  };

  /**
   * Has
   * @param {string} name
   * @returns {boolean}
   * @memberof CoreFormService
   */
  public has = (name: string): boolean => {
    return this.registry.has(name);
  };

  /**
   * Contains
   * @param {string} name
   * @returns {boolean}
   * @memberof CoreFormService
   */
  public contains = (name: string): boolean => {
    const value = GetDeepHashMap(this.source, name, Symbol(), true);
    return !IsSymbol(value);
  };

  /**
   * Value
   * @param {string} name
   * @param {*} [def]
   * @returns {any}
   * @memberof CoreFormService
   * - value HAS to be cloned.
   */
  public value = (name: string, def?: any): any => {
    const { has, registry, source } = this;

    /**
     * Simple
     * - Screen for Dictionaries
     * 1. must break down to type & value.
     */
    if (has(name)) {
      let { type, value, pseudo } = registry.get(name);

      /**
       * covers default value 
       * when controller exists
       * but may be empty (i.e. [])
       */
      if (!IsDefined(def) && IsDefined(value)) {
        def = cloneDeep(value);
      }

      if (!IsEmpty(type) && !Array.isArray(value) && !IsEmpty(value)) {
        return cloneDeep(value);
      } else if (IsEmpty(type) && Array.isArray(value) && IsEmpty(value) && pseudo) {
        value = GetDeepHashMap(source, name, def, true);
        /* istanbul ignore if */
        if (!IsEmpty(value)) {
          return cloneDeep(value);
        }
      }

      return def;
    }

    /**
     * Intensive
     */
    const value = GetDeepHashMap(source, name, def, true);
    return cloneDeep(value);
  };

  /**
   * Update
   * @param {string} name
   * @param {*} value
   * @param {...any[]} args
   * @memberof CoreFormService
   */
  public update = (name: string, value: any, ...args: any[]): void => {
    /* istanbul ignore next */
    const [options]: any[] = FormDeprecationWarning(
      args,
      (arg): boolean => IsDictionary(arg) ? true : false,
      `form.update [args: <:${name}>, <:${value}>, ${args.join(', ')}] is deprecating the 'silent' argument. ex: form.update(name, value, options);`,
      4,
      2,
    );

    try {
      /**
       * Typecheck name
       */
      /* istanbul ignore next */
      if (!IsString(name)) {
        name = String(name);
      }

      const { has, registry, emit } = this;

      /**
       * Update Controller
       */
      if (has(name)) {
        const controller: CoreFormControllerInstanceType = registry.get(name);
        controller.install(value, options);
      }

      if (Array.isArray(value)) {
        if (IsDictionary(value)) {
          FormDictionarySync(this, name, value);
        } else {
          FormArraySync(this, name, value);
        }
      } else {
        InstallOrUpdateRegistry(registry, name, value);
      }

      /* istanbul ignore next */
      WaitForDelay(100).then(() => {
        emit(E.FORM_EVENT_UPDATE, name, value, options);
      });

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
    /* istanbul ignore next */
    return void 0;
  };

  /**
   * Clear
   * @param {string} name
   * @memberof CoreFormService
   */
  public clear = (name: string): void => {
    const { has, registry, emit } = this;
    try {     

      /**
       * Deregister if exists
       */
      if (has(name)) {
        registry.delete(name);
      }

      /* istanbul ignore next */
      WaitForDelay().then(() => {
        emit(E.FORM_EVENT_UPDATE);
      });
      
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  };

  /**
   * Emit
   * @param {string} [event=E.FORM_EVENT_UPDATE]
   * @param {...any[]} args
   * @memberof CoreFormService
   */
  public emit = (event: string = E.FORM_EVENT_UPDATE, ...args: any[]): void => {
    this._emitter.emit(event, ...args);
  };

  /**
   * On Submit
   * @param {FormEvent<any>} evt
   * @memberof CoreFormService
   */
  public OnSubmit = (evt: FormEvent<any>): void => {
    /**
     * Prevent - cuz of course.
     * Persist - because we use curried callbacks
     */
    evt.preventDefault();
    evt.persist();

    const { emit, source } = this;
    const formdata = new FormData(evt.currentTarget);

    /**
     * Emit to listeners
     */
    emit(E.FORM_EVENT_SUBMIT, evt, source, formdata, this);

    /**
     * Curry to Prop Callback
     */
    this._onSubmit(evt, source, formdata, this);
  };

  /**
   * ID - getter
   * @memberof CoreFormService
   */
  public get id() {
    return this._id;
  }

  /**
   * Emitter - getter
   * @readonly
   * @memberof CoreFormControllerInstance
   */
  public get emitter() {
    return this._emitter;
  }

  /**
   * Source - getter
   * @readonly
   * @type {HashMap<any>}
   * @memberof CoreFormService
   */
  public get source(): HashMap<any> {
    const { registry } = this;
    /**
     * So that form.source will get a correct value/read every time we sort the registry by name length.
     */
    const copy = FormRegistrySorter(registry);
    const source: HashMap<any> = {};
    for (const [name, { value }] of copy.entries()) {
      UpdateDeepHashMap(source, name, value);
    }
    return source;
  }

  /**
   * Vault - getter
   * @memberof CoreFormService
   */
  public get vault() {
    return this._vault;
  }

  /**
   * Values - getter
   * @memberof CoreFormService
   */
  public get values() {
    return this._values;
  }

  /**
   * Validate - getter
   * @memberof CoreFormService
   */
  public get validate() {
    return this._validate;
  }

  /**
   * Valid - getter
   * @memberof CoreFormService
   */
  public get valid() {
    const { registry } = this;
    for (const controller of registry.values()) {
      if (!controller.pseudo && !controller.valid) {
        return false;
      }
    }
    return true;
  }

  /**
   * Dirty - getter
   * @memberof CoreFormService
   */
  public get dirty() {
    const { registry, source, values, vault } = this;

    for (const controller of registry.values()) {
      if (!controller.pseudo && controller.dirty) {
        return true;
      }
    }

    /**
     * If we use props.vault it becomes the SoT
     */
    /* istanbul ignore next */
    if (!IsNull(vault) && Object.isFrozen(vault)) {
      return !IsEqual(vault, source);
    } else if (IsDefined(values) && !IsEmpty(values)) {
      return !IsEqual(values, source);
    }

    return false;
  }

  /**
   * Pristine - getter
   * @memberof CoreFormService
   */
  public get pristine() {
    return this.dirty === false;
  }

  /**
   * Registry - getter
   * @readonly
   * @memberof CoreFormService
   */
  public get registry() {
    return this._registry;
  }
}
;