import { CoreConfigHeader } from '../Config/';
import { CoreHeaderToggleMask, CoreHeaderMenusMask } from '../Interop/enums/';

export const HEADER_TOGGLE_STATE = {
  header: CoreHeaderToggleMask.OFF,
};

export const HEADER_MENUS_STATE = {
  value: CoreHeaderMenusMask.OFF,
};

export const MASQUERADE_PROPS = {
  masquerade: false,
  shortname: CoreConfigHeader.SHORT_NAME,
};

export const MASQUERADE_ACTIVE_BUTTON_TITLE = 'click to release masquerade session';

export const DEFAULT_NOTIFICATIONS = {
  results: [],
  count: 0,
};

export const NOTIFICATION_LEVEL_TYPE_ERROR = 'error';
export const NOTIFICATION_LEVEL_TYPE_SUCCESS = 'success';
export const NOTIFICATION_LEVEL_TYPE_WARNING = 'warning';
export const NOTIFICATION_LEVEL_TYPE_INFO = 'info';

export const NOTIFICATION_DEFAULT_STATE = {
  id: '',
  date: '',
  level: NOTIFICATION_LEVEL_TYPE_INFO,
  subject: '',
  type: '',
  from_soc: false,
  content: '',
  email_content: '',
  values: null,
};

export const NOTIFICATION_ITEM_PROPS = {
  item: {},
};

const DEFAULT_ITEM = {
  name: '',
  label: '',
  href: '',
  permissions: () => true,
  external: false
};

export const HEADER_MENU_ITEM_PROPS = {
  item: DEFAULT_ITEM
};
