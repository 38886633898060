import React from 'react';

import ControllerChildBase from './Base';

import { CoreFormValueType } from '../../types';
import { CoreFormControllerValueProps } from '../../interfaces/';
import { CONTROLLER_VALUE_PROPS } from '../../constants/';
import { HashMapMergeProps, IsEmpty, IsBoolean, IsNumber } from '../../../../../utils/';

/**
 * Controller Value
 * @export
 * @class CoreFormControllerValueComponent
 * @extends {ControllerChildBase<CoreFormControllerValueProps>}
 */
export default class CoreFormControllerValueComponent extends ControllerChildBase<CoreFormControllerValueProps> {
  /**
   * Default Props
   * @static
   * @type {CoreFormControllerValueProps}
   * @memberof CoreFormControllerValueComponent
   */
  public static defaultProps: CoreFormControllerValueProps = HashMapMergeProps(CONTROLLER_VALUE_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreFormControllerValueComponent
   */
  public render() {
    const { DisplayValue, context: { value }} = this;
    return <span>{DisplayValue(value)}</span>;
  }

  /**
   * Display Value
   * @protected
   * @memberof CoreFormControllerValueComponent
   */
  protected DisplayValue = (value: CoreFormValueType): string => {
    const { defaultValue } = this.props;
    if (!IsEmpty(value)) {
      if (IsBoolean(value)) {
        return value ? 'true' : 'false';
      }
      if (IsNumber(value)) {
        return String(value);
      }
      if (Array.isArray(value)) {
        return value[0] as string;
      }
      return value as string;
    }
    return defaultValue as any;
  };
}
