import React from 'react';

import CoreFormElementInputBase from './BaseInput';
import { FormElementSyncRestProps, FormUniqueKey } from '../../factories/';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_CHECKBOX } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Checkbox Input Element
 * @export
 * @class CoreFormElementCheckbox
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementCheckbox extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementCheckbox
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_CHECKBOX,
    value: false
  });

  /**
   * Render
   * @returns
   * @memberof CoreFormElementCheckbox
   */
  public render() {
    const { OnChangeOrInput, OnBlur, props } = this;
    const { type, name, value, options, onChange, onInput, onBlur, ...rest } = props;
    const synced = FormElementSyncRestProps(type, rest);
    const key = FormUniqueKey(type, name, value);
    return <input key={key} type={type} name={name} defaultChecked={value as boolean} onChange={OnChangeOrInput} onBlur={OnBlur} {...synced} />;
  }
}
