import React, { PureComponent, Fragment } from 'react';
import { cloneDeep } from 'lodash';

import { CoreFormOptionsType } from '../../types';
import { CoreFormControllerProps } from '../../interfaces/';
import { CORE_SELECT_PROPS } from '../../constants/';

import { HashMapToKeyValueArray } from '../../../../../utils/';

/**
 * Select Widget Base
 * @export
 * @class SelectWidgetBase
 * @extends {PureComponent<CoreFormControllerProps, {}>}
 */
export default class SelectWidgetBase extends PureComponent<CoreFormControllerProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreFormControllerProps}
   * @memberof SelectWidgetBase
   */
  public static defaultProps: Omit<CoreFormControllerProps, 'name' | 'options'> = cloneDeep(CORE_SELECT_PROPS);

  /**
   * SRC
   * @static
   * @type {HashMap<any>}
   * @memberof SelectWidgetBase
   */
  public static SRC: HashMap<any> = {};

  /**
   * Options
   * @protected
   * @type {CoreFormOptionsType[]}
   * @memberof SelectWidgetBase
   */
  protected options: CoreFormOptionsType;

  /**
   * Creates an instance of SelectWidgetBase.
   * @param {CoreFormSelectProps} props
   * @memberof SelectWidgetBase
   */
  constructor(props: CoreFormControllerProps) {
    super(props);
    this.init();
  }

  /**
   * Render
   * @returns
   * @memberof SelectWidgetBase
   */
  public render() {
    /* istanbul ignore next */
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * Init
   * @protected
   * @memberof SelectWidgetBase
   */
  protected init = (): void => {
    this.options = HashMapToKeyValueArray(this.src, 'value', 'label') as CoreFormOptionsType;
    this.options.map((option: CoreFormOptionType) => {
      option['title'] = option['label'];
      return option;
    });
  };

  /**
   * SRC - getter
   * @readonly
   * @memberof SelectWidgetBase
   */
  public get src() {
    return (this.constructor as any).SRC;
  }
}
