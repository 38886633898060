import React, { PureComponent } from 'react';

import CoreNavigationContext from './context';
import CoreNavigation from './service';

import { CoreNavigationContainer } from './containers/';

/**
 * Navigation Module
 * @export
 * @class CoreNavigationModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNavigationModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreNavigationModule
   */
  public render() {
    return (
      <CoreNavigationContext.Provider value={CoreNavigation}>
        <CoreNavigationContainer />
      </CoreNavigationContext.Provider>
    );
  }
}
