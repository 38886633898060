import { ReactNode } from 'react';

import { CoreListServiceShape } from './interfaces/';

import { CoreFormEventsEnum } from '../forms/';

import { DictionaryIndexOf, WaitForDelay } from '../../../utils/';

/**
 * List Service
 * @export
 * @class CoreListService
 * @implements {CoreListServiceShape}
 */
export default class CoreListService implements CoreListServiceShape {
  /**
   * Prop
   * @private
   * @type {string}
   * @memberof CoreListService
   */
  private _prop: string;

  /**
   * Form
   * @private
   * @type {CoreFormServiceType}
   * @memberof CoreListService
   */
  private _form: CoreFormServiceType;

  /**
   * Table
   * @protected
   * @type {CoreTableServiceType}
   * @memberof CoreListTableContainer
   */
  protected _table: CoreTableServiceType;

  /**
   * Modal
   * @private
   * @type {ReactNode}
   * @memberof CoreListService
   */
  public _modal: ReactNode;

  /**
   * Pre Update Mutation
   * @memberof CoreListService
   */
  public preUpdateMutation: (values: HashMap<any>, form?: CoreFormServiceType) => HashMap<any> = null!;

  /**
   * Post Filter Mutation
   * @memberof CoreListService
   */
  public postFilterMutation: (values: Array<HashMap<any>>, form?: CoreFormServiceType) => Array<HashMap<any>> = null!;

  /**
   * Creates an instance of CoreListService.
   * @param {CoreFormServiceType} form
   * @param {CoreTableServiceType} table
   * @param {string} prop
   * @param {ReactNode} modal
   * @param {(values: HashMap<any>) => HashMap<any>} onUpdateCallback
   * @param {(values: Array<HashMap<any>) => Array<HashMap<any>} onFilterCallback
   * @memberof CoreListService
   */
  constructor(form: CoreFormServiceType, table: CoreTableServiceType, prop: string, modal: ReactNode, onUpdateCallback: (values: HashMap<any>, form?: CoreFormServiceType) => HashMap<any>, onFilterCallback: (values: Array<HashMap<any>>, form?: CoreFormServiceType) => Array<HashMap<any>>) {
    this._form = form;
    this._table = table;
    this._prop = prop;
    this._modal = modal;

    if (onUpdateCallback) {
      this.preUpdateMutation = onUpdateCallback;
    }
    if (onFilterCallback) {
      this.postFilterMutation = onFilterCallback;
    }
  }

  /**
   * Filter
   * @param {Array<HashMap<any>>} marked
   * @memberof CoreListService
   */
  public filter(marked: Array<HashMap<any>>): void {
    const { postFilterMutation, prop, form, table } = this;
    let values = this.source.slice(0);
    values = values.filter((row: HashMap<any>) => !~DictionaryIndexOf(marked, row));

    /* istanbul ignore if */
    if (postFilterMutation) {
      values = postFilterMutation(values, form);
    }

    // Update Controller;
    const controller = form.registry.get(prop);
    controller.install(values, null);

    table.collection.update(values);
    table.collection.marked = [];

    /* istanbul ignore next */
    WaitForDelay(100).then(() => {
      form.emit(CoreFormEventsEnum.FORM_EVENT_UPDATE, prop, values);
    });
  }

  /**
   * Update
   * @param {number} index
   * @param {HashMap<any>} values
   * @memberof CoreListService
   */
  public update(index: number, values: HashMap<any>): void {
    const { preUpdateMutation, prop, form, table } = this;

    if (preUpdateMutation) {
      values = preUpdateMutation(values, form);
    }

    const dict: Array<HashMap<any>> = form.value(prop) || [];

    if (dict[index]) {
      dict[index] = values;
    } else {
      /* istanbul ignore next */
      dict.push(values);
    }

    // Update Controller;
    const controller = form.registry.get(prop);
    controller.install(dict, null);

    // Update Table;
    table.use(dict);

    /* istanbul ignore next */
    WaitForDelay(100).then(() => {
      form.emit(CoreFormEventsEnum.FORM_EVENT_UPDATE, prop, dict);
    });
  }

  /**
   * Prop - getter
   * @readonly
   * @memberof CoreListService
   */
  public get prop() {
    return this._prop;
  }

  /**
   * Form - getter
   * @readonly
   * @memberof CoreListService
   */
  public get form() {
    return this._form;
  }

  /**
   * Table - getter
   * @readonly
   * @memberof CoreListService
   */
  public get table() {
    return this._table;
  }

  /**
   * Modal - getter
   * @readonly
   * @memberof CoreListService
   */
  public get modal() {
    return this._modal;
  }

  /**
   * Source - getter
   * @readonly
   * @memberof CoreListService
   */
  public get source() {
    const source = this.form.value(this.prop) || [];
    return  source;
  }
}
