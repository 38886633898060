import { Options } from 'highcharts';
import CoreMapBase from './MapBase';
import { MAP_OPTIONS_COUNTRIES } from '../constants';

/**
 * Core Map Countries
 * @export
 * @class CoreMapCountries
 * @extends {CoreMapBase}
 */
export default class CoreMapCountries extends CoreMapBase {
  /**
   * Options
   * @type {Options}
   * @memberof CoreMapBase
   */
  options: Options = Object.assign({}, MAP_OPTIONS_COUNTRIES);
}
