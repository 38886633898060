import React, { Component, Fragment } from 'react';

import PickerContext from '../context';

/**
 * Date Picker Base
 * @export
 * @class DatePickerBase
 * @extends {PureComponent<P, {}>}
 * @template P
 */
export default abstract class DatePickerBase<P = {}> extends Component<P, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof DatePickerBase
   */
  public static displayName: string;

  /**
   * Context
   * @static
   * @type {Readonly<typeof PickerContext>}
   * @memberof DatePickerBase
   */
  public static contextType: Readonly<typeof PickerContext> = PickerContext;

  /**
   * LifeCycle Hook
   * @memberof DatePickerBase
   */
  public componentDidMount() {
    const { context, displayName, Subscriber } = this;
    context.subscribe(displayName, Subscriber);
  }

  /**
   * LifeCycle Hook
   * @memberof DatePickerBase
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    const { context, displayName } = this;
    context.unsubscribe(displayName);
  }

  /**
   * Render
   * @returns
   * @memberof DatePickerBase
   */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * Subscriber
   * @protected
   * @memberof DatePickerBase
   */
  /* istanbul ignore next */
  protected Subscriber = (): void => {
    this.forceUpdate();
  };

  /**
   * Display Name - getter
   * @readonly
   * @protected
   * @memberof DatePickerBase
   */
  protected get displayName() {
    return (this.constructor as any).displayName;
  }
}
