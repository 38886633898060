import React, { ReactNode } from 'react';

import ControllerChildBase from './Base';

import { CHECKBOX_LABEL_ON, CHECKBOX_LABEL_OFF, INPUT_TYPE_CHECKBOX } from '../../constants/';

import { IsSymbol } from '../../../../../utils/';

/**
 * Controller Boolean Label
 * @export
 * @class CoreFormControllerBooleanLabelComponent
 * @extends {ControllerChildBase<GenericPropType>}
 */
export default class CoreFormControllerBooleanLabelComponent extends ControllerChildBase<GenericPropType> {
  /**
   * Creates an instance of CoreFormControllerBooleanLabelComponent.
   * @param {GenericPropType} props
   * @memberof CoreFormControllerBooleanLabelComponent
   */
  constructor(props: GenericPropType) {
    super(props);
    this.init(props);
  }

  /**
   * Kids
   * @protected
   * @type {Symbol[]}
   * @memberof CoreFormControllerBooleanLabelComponent
   */
  protected kids: Symbol[] = [];

  /**
   * Render
   * @returns
   * @memberof CoreFormControllerBooleanLabelComponent
   */
  public render() {
    const { kids, context: { value } } = this;

    if (kids.length === 2) {
      return kids.map((child: ReactNode, ndx: number) => {
        if (Boolean(ndx) === value) {
          return null;
        }
        return <span key={`.${ndx}`}>{child}</span>;
      });
    }

    if (value) {
      return <span>{CHECKBOX_LABEL_ON}</span>;
    }

    return <span>{CHECKBOX_LABEL_OFF}</span>;
  }

  /**
   * Init
   * @private
   * @param {GenericPropType} { children }
   * @memberof CoreFormControllerBooleanLabelComponent
   */
  /* istanbul ignore next */
  private init = ({ children }: GenericPropType): void => {
    try {
      children.forEach((Child: any) => {
        if (IsSymbol(Child.type)) {
          this.kids.push(Child);
        }
      });
      if (this.kids.length > 0 && this.kids.length !== 2) {
        throw new ReferenceError(`CoreInput[type=${INPUT_TYPE_CHECKBOX}] requires 2 Child Nodes or 0.`);
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  };
}

