import { ComponentClass } from 'react';
import { ApolloError, ApolloQueryResult, DocumentNode, ErrorPolicy, FetchPolicy, NetworkStatus } from '@apollo/client';
import { isDocumentNode } from '@apollo/client/utilities/';
import { cloneDeep } from 'lodash';

import { CoreNoop } from '../../../components/';

import ConsumerBase from './ConsumerBase';
import { GraphMutationConfigValidation, CoreGraphConfigToPropsTransform } from '../helpers/';
import { CoreGraphMutationProps } from '../interfaces/';

import { IsFunction, IsDefined, IsNull, HashMapMergeProps } from '../../../../utils/';

/**
 * Core Graph Mutation Consumer
 * @export
 * @param {(CoreGraphConsumerConfigType | DocumentNode)} config
 * @return {*} {ComponentClass<CoreGraphMutationProps, never>}
 */
export default function CoreGraphMutationConsumer(config: CoreGraphConsumerConfigType | DocumentNode): ComponentClass<CoreGraphMutationProps, never> {
  try {

    let mutation: DocumentNode = undefined!;
    let variables: HashMap<any> = undefined!;
    let transform: (params: HashMap<any>) => HashMap<any> = undefined!;
    let onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;
    let onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

    if (!IsNull(config)) {
      /* istanbul ignore next */
      if (!isDocumentNode(config) && !IsFunction(config)) {
        throw new ReferenceError(`argument must be of type DocumentNode or a function returning 'CoreGraphMutationProps'`);
      }

      /**
       * Convert DocumentNode to CoreGraphConsumerConfigType
       */
      /* istanbul ignore next */
      if (isDocumentNode(config)) {
        mutation = (config as DocumentNode);
        config = () => ({ mutation });
      }

      const {
        mutation: MUTATION,
        variables: VARIABLES,
        transform: TRANSFORM,
        onCompleted: ON_COMPLETED,
        onError: ON_ERROR
      } = (config as CoreGraphConsumerConfigType)() as CoreGraphMutationProps;

      mutation = IsDefined(MUTATION) && isDocumentNode(MUTATION) ? MUTATION : mutation;
      variables = IsDefined(VARIABLES) ? VARIABLES : variables;
      transform = IsDefined(TRANSFORM) ? TRANSFORM : transform;
      onCompleted = IsDefined(ON_COMPLETED) ? ON_COMPLETED : onCompleted;
      onError = IsDefined(ON_ERROR) ? ON_ERROR : onError;

      /**
       * Config Validation
       */
      GraphMutationConfigValidation({ mutation, variables, transform, onCompleted, onError });
    }

    /**
     * Local interface
     * @interface CoreGraphMutationConsumerShape
     */

    /**
     * Anonymous Consumer Class
     * @export
     * @implements {CoreGraphMutationConsumerShape}
     * @return {*} {ConsumerBase<CoreGraphMutationProps, never>}
     */
    return class extends ConsumerBase<CoreGraphMutationProps> {
      /**
       * Display Name
       * @static
       * @type {string}
       */
      public static displayName: string = 'CoreGraphMutationConsumer';

      /**
       * Query
       * @protected
       * @type {DocumentNode}
       */
      protected _mutation: DocumentNode = mutation;

      /**
       * Fetch Policy
       * @protected
       * @type {FetchPolicy}
       */
      protected _fetchPolicy: FetchPolicy = 'network-only';

      /**
       * Error Policy
       * @protected
       * @type {ErrorPolicy}
       */
      protected _errorPolicy: ErrorPolicy = 'none';

      /**
       * Params
       * @protected
       * @type {HashMap<any>}
       */
      protected _params: HashMap<any> = null!;

      /**
       * Transform
       * @protected
       * @type {(params: HashMap<any>) => HashMap<any>}
       */
      protected _transform: (params: HashMap<any>) => HashMap<any> = transform;

      /**
       * On Completed
       * @protected
       * @type {(data: HashMap<any>) => void}
       */
      protected _onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;

      /**
       * On Error
       * @protected
       * @type {(error: ApolloError) => void}
       */
      protected _onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

      /**
       * Creates an instance of anon class.
       * @param {CoreGraphMutationProps} props
       * @param {CoreGraphConnectionType} context
       */
      constructor(props: CoreGraphMutationProps, context: CoreGraphConnectionType) {
        super(props);

        // apply config properties to base class
        this._variables = variables;

        // public methods

        // protected methods
        this.Mutate = this.Mutate.bind(this);

        // init
        this.init(props);
      }

      /**
       * LifeCycle Hook
       */
      public componentDidMount(): void {
        this.listener = new AbortController();
      }

      /**
       * LifeCycle Hook
       */
      /* istanbul ignore next */
      public componentWillUnmount(): void {
        const { listener } = this;
        listener.abort();
      }

      /**
       * Render
       * @return {JSX.Element} 
       * @notes
       * - this consumer is pecial because we need 
       * to apply 2 arguments to the result, as the 
       * hook does.
       */
      public render(): JSX.Element {
        const { Mutate, client, error, loading, data, variables, networkStatus, props: { children } } = this;
        return (children as Func<JSX.Element, any>)(Mutate, { client, error, loading, data, variables, networkStatus });
      }

      /**
       * Init
       * @protected
       * @param {CoreGraphMutationProps} props
       */
      protected init(props: CoreGraphMutationProps): void {
        try {
          /**
           * Config Transform
           */
          const { mutation, variables, params, onCompleted, onError } = CoreGraphConfigToPropsTransform(props, config as CoreGraphConsumerConfigType);

          /**
           * Props Validation
           */
          GraphMutationConfigValidation({ mutation, variables, params, onCompleted, onError });

          /* istanbul ignore next */
          if (IsDefined(this._mutation) && IsDefined(mutation)) {
            throw new ReferenceError(`built with defined [mutation: DocumentNode], mutation as prop is not allowed (use: CoreGraphMutate for generic consumers)`);
          }
          /* istanbul ignore next */
          if (!IsDefined(this.transform) && IsDefined(params)) {
            throw new ReferenceError(`a Mutation using prop[params: HashMap<any>] must be built with config[transform: (params: HashMap<any>) => HashMap<any>]`);
          }
          /* istanbul ignore next */
          if (IsDefined(this.transform) && !IsDefined(params)) {
            throw new ReferenceError(`a Mutation built with config[transform: (params: HashMap<any>) => HashMap<any>] must use prop[params: HashMap<any>].`);
          }

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developer to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          /* istanbul ignore next */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          /* istanbul ignore next */
          if (!IsDefined(this._mutation) && IsDefined(mutation)) {
            this._mutation = mutation;
          }
          /* istanbul ignore next */
          if (IsDefined(variables)) {
            this._variables = HashMapMergeProps(this.variables, variables);
          }
          /* istanbul ignore next */
          if (IsDefined(params)) {
            this._params = params;
          }

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphMutationConsumer.init() ${err.message || err}`);
          }
        }
      }

      /**
       * Mutate
       * @protected
       */
      /* istanbul ignore next */
      protected async Mutate(config: CoreGraphMutationProps = { mutation: null }): Promise<ApolloQueryResult<any>> {
        try {

          const { mutation: __MUTATION, variables: __VARIABLES, params } = config;
          const { client, mutation: _MUTATION, variables: _VARIABLES, fetchPolicy, errorPolicy, transform } = this;

          const mutation: DocumentNode = __MUTATION || _MUTATION;
          const variables: HashMap<any> = cloneDeep(__VARIABLES || _VARIABLES);

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developers to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          return client
            .mutate({ mutation, variables, fetchPolicy, errorPolicy })
            .then(({ loading, data, networkStatus }) => {
              this.error = null;
              this.loading = loading;
              this.data = data;
              this.networkStatus = networkStatus;
              return { loading, data, networkStatus };
            })
            .catch((err: any): void => {
              this.error = err;
              this.networkStatus = NetworkStatus.error;
              this.onError(err);
            }).finally(() => {
              this.onCompleted(this.data);
            });

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphMutationConsumer.fetch() ${err.message || err}`);
          }
          return Promise.reject(err);
        }
      }

      /**
       * Mutation - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get mutation() {
        return this._mutation;
      }

      /**
       * Fetch Policy - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get fetchPolicy() {
        return this._fetchPolicy;
      }

      /**
       * Error Policy - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get errorPolicy() {
        return this._errorPolicy;
      }

      /**
       * Params - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get params() {
        return this._params;
      }

      /**
       * Transform - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get transform() {
        return this._transform;
      }

      /**
       * On Completed - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onCompleted() {
        return this._onCompleted;
      }

      /**
       * On Error - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onError() {
        return this._onError;
      }
    }

  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR::CoreGraphMutationConsumer ${err.message || err}`);
    }
    /* istanbul ignore next */
    return CoreNoop<CoreGraphMutationProps, never>;
  }
}

