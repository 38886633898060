import React, { PureComponent } from 'react';

import { SampleTool } from './containers/';

/**
 * Sample Tool Module
 * @export
 * @class SampleToolsModule
 * @extends {PureComponent<{}, {}>}
 */
export default class SampleToolsModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof SampleToolsModule
   */
  public render() {
    return (
      <>
        <SampleTool />
      </>
    );
  }
}
