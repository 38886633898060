import React, { PureComponent, Fragment } from 'react';

import { CoreConfigApp } from '../../Config/';
import { CoreHistory, CoreNavigate } from '../../Router/';
import { APP_RESTRICTED_PATHS } from '../constants';

/**
 * Routes Directive
 * @export
 * @class CoreRoutesDirectiveComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreRoutesDirectiveComponent extends PureComponent<{}, {}> {
  /**
   * Unlisten
   * @protected
   * @type {Func<any>}
   * @memberof CoreRoutesDirectiveComponent
   */
  protected unlisten: Func<any> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreRoutesDirectiveComponent
   */
  /* istanbul ignore next */
  public componentDidMount() {
    const { ParsePath } = this;

    ParsePath((window as any).location.pathname);

    this.unlisten = CoreHistory.listen(({ location: { pathname }}: any) => {
      ParsePath(pathname);
    });
  }

  /**
   * LifeCycle Hook
   * @memberof CoreRoutesDirectiveComponent
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreRoutesDirectiveComponent
   */
  /* istanbul ignore next */
  public render() {
    return <Fragment />;
  }

  /**
   * Parse Path
   * @protected
   * @memberof CoreRoutesDirectiveComponent
   */
  /* istanbul ignore next */
  protected ParsePath = (path: string): void => {
    if (path.charAt(0) === '/') {
      path = path.slice(1);
    }
    const seg: string = path.split('/')[0];
    if (seg && !!~APP_RESTRICTED_PATHS.indexOf(seg)) {
      this.GoIndex();
    }
  };

  /**
   * Go Index
   * @protected
   * @memberof CoreRoutesDirectiveComponent
   */
  /* istanbul ignore next */
  protected GoIndex = (): void => {
    CoreNavigate(CoreConfigApp.DEFAULT_ROUTE, { state: null, replace: true });
  };
}

