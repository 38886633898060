import React, { Component, FormEvent, RefObject, createRef } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableActionProps, CoreTableActionState, CoreTableActionItemShape } from '../interfaces/';

import { TABLE_ACTION_PROPS, TABLE_ACTION_STATE, TABLE_ACTION_TITLE, TABLE_ACTION_MINI_DEFAULT } from '../constants';

import { MergeClassNames, IsHashMap, IsDefined, IsBoolean, IsFunction } from '../../../../utils/';

/**
 * Table Action Cell Component
 * @export
 * @class CoreTableActionCellComponent
 * @extends {Component<CoreTableActionProps, CoreTableActionState>}
 */
export default class CoreTableActionCellComponent extends Component<CoreTableActionProps, CoreTableActionState> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableActionCellComponent
   */
  public static displayName = 'CoreTableActionCell';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableActionCellComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableActionProps}
   * @memberof CoreTableActionCellComponent
   */
  public static defaultProps: CoreTableActionProps = cloneDeep(TABLE_ACTION_PROPS);

  /**
   * State
   * @type {CoreTableActionState}
   * @memberof CoreTableActionCellComponent
   */
  public readonly state: CoreTableActionState = cloneDeep(TABLE_ACTION_STATE);

  /**
   * Ref Object
   * @private
   * @type {RefObject<HTMLDivElement>}
   * @memberof CoreTableActionCellComponent
   */
  private _ref: RefObject<HTMLDivElement> = null;

  /**
   * Creates an instance of CoreTableActionCellComponent.
   * @param {CoreTableActionProps} props
   * @memberof CoreTableActionCellComponent
   */
  constructor(props: CoreTableActionProps) {
    super(props);
    this._ref = createRef();
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableActionCellComponent
   */
  public componentDidMount() {
    const {
      ref: { current },
      props: { actions },
    } = this;
    try {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        if (!actions || !actions.length || !IsHashMap(actions[0])) {
          throw new ReferenceError(`must supply a populated Array<HashMap<any>> to CoreTableActionCell.`);
        }
      }
      /* istanbul ignore next */
      current.onmouseleave = (): void => {
        if (this.state.active) {
          this.setState({ active: false });
        }
      };
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableActionCellComponent
   */
  public render() {
    const { OnClickToggle, OnActionTransform, IsItemDisabled, ref, props, state, context: { collection } } = this;
    const { actions, data, className } = props;

    if (!actions.length) {
      return <td className="cell-empty"></td>;
    }

    const { marked, highlighted, MarkedIndexOf } = collection;
    const { active } = state;

    const clz = MergeClassNames(className, { 'cell-menu': true });
    const mclz = MergeClassNames('', { active });

    const minis = actions.filter(({ name, mini }: CoreTableActionItemShape) => {
      return !!~TABLE_ACTION_MINI_DEFAULT.indexOf(name) || mini;
    });

    const menus = actions.filter(({ name }: CoreTableActionItemShape) => name !== 'mark');

    const menu_disabled: boolean = menus.length === 0;

    return (
      <td className={clz}>
        <div ref={ref} className={mclz}>
          {menus.length ? (            
            <button type="button" onClick={OnClickToggle} disabled={menu_disabled} title={menu_disabled ? null : TABLE_ACTION_TITLE}>
              &nbsp;
            </button>
          ) : null}
          {minis.length ? (
            <ul>
              {minis.map((item: CoreTableActionItemShape, MiniNdx: number) => {
                let mmclz = '';
                if (item.name === 'mark' && marked.length) {
                  mmclz = MergeClassNames('', { marked: !!~MarkedIndexOf(data) });
                } else if (IsHashMap(data) && 'id' in data) {
                  /* istanbul ignore next */
                  mmclz = MergeClassNames('', { highlight: item.highlights && data.id === highlighted });
                }
                const disabled: boolean = IsItemDisabled(item);
                return (
                  <li key={`ctam.${MiniNdx}`} className={mmclz}>
                    <button type="button" className={item.name} onClick={OnActionTransform(item, props)} title={item.label} disabled={disabled}></button>
                  </li>
                );
              })}
            </ul>
          ) : null}
          {menus.length ? (
            <ol>
              {menus.map((item: CoreTableActionItemShape, ActionNdx: number) => {
                const cname = item.name || null;
                const disabled: boolean = IsItemDisabled(item);
                return (
                  <li key={`cta.${ActionNdx}`}>
                    <button type="button" className={cname} onClick={OnActionTransform(item, props)} disabled={disabled}>
                      <span>{item.label}</span>
                    </button>
                  </li>
                );
              })}
            </ol>
          ) : null}
        </div>
      </td>
    );
  }

  /**
   * Is Item Disabled
   * @protected
   * @param {CoreTableActionItemShape} item
   * @memberof CoreTableActionCellComponent
   */
  protected IsItemDisabled = (item: CoreTableActionItemShape): boolean => {
    if (IsDefined(item.disabled)) {
      if (IsBoolean(item.disabled)) {
        return item.disabled as boolean;
      } else if (IsFunction(item.disabled)) {
        const $this = this;
        return (item as any).disabled($this);
      }
    }
    return false;
  };

  /**
   * On Action Transform
   * @protected
   * @param {CoreTableActionItemShape} item
   * @param {GenericPropType} props
   * @memberof CoreTableActionCellComponent
   */
  protected OnActionTransform = (item: CoreTableActionItemShape, props: GenericPropType) => {
    const $this = this;
    const context = this.context;
    const active = false;
    const { collection } = context;
    const { MarkedIndexOf } = collection;

    return (): void => {
      $this.setState({ active });

      if (item.name === 'mark') {
        const marked: boolean = !!~MarkedIndexOf(props.data);
        if (marked) {
          collection.unmark(props.data);
        } else {
          collection.mark(props.data);
        }
      }

      if (item.highlights) {
        if (IsHashMap(props.data) && 'id' in props.data) {
          collection.highlighted = props.data.id;
          context.broadcast();
        }
      }

      item.action(props, $this);
    };
  };

  /**
   * On Click Toggle
   * @protected
   * @param {FormEvent<HTMLButtonElement>} evt
   * @memberof CoreTableActionCellComponent
   */
  protected OnClickToggle = (evt: FormEvent<HTMLButtonElement>): void => {
    const active = !this.state.active;
    this.setState({ active });
  };

  /**
   * Ref - getter
   * @readonly
   * @memberof CoreTableActionCellComponent
   */
  public get ref() {
    return this._ref;
  }
}
