import React, { Component, MouseEvent, Fragment } from 'react';
import { cloneDeep } from 'lodash';
import CoreNavigationContext from '../context';
import { CoreNavItemProps } from '../interfaces/';
import { ITEM_PROPS } from '../constants';

/**
 * Nav Item Base
 * @export
 * @class CoreNavItemBase
 * @extends {Component<CoreNavItemProps, {}>}
 */
export default class CoreNavItemBase extends Component<CoreNavItemProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreNavigationContext>}
   * @memberof CoreNavItemBase
   */
  public static contextType: Readonly<typeof CoreNavigationContext> = CoreNavigationContext;

  /**
   * Default Props
   * @static
   * @type {CoreNavItemProps}
   * @memberof CoreNavItemBase
   */
  public static defaultProps: CoreNavItemProps = cloneDeep(ITEM_PROPS);

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreNavItemBase
   */
  private _subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreNavItemBase
   */
  public componentDidMount() {
    const { context, OnNavigationChange } = this;
    this._subscriber = context.subscribe(OnNavigationChange);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreNavItemBase
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(this._subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreNavItemBase
   */
  public render() {
    console.error(new ReferenceError('must be extended in subclass'));
    return <Fragment />;
  }

  /**
   * On Navigation Change
   * @protected
   * @memberof CoreNavItemToggleComponent
   */
  protected OnNavigationChange = (current: string): void => void 0;

  /**
   * On Item Click
   * @protected
   * @param {any} evt
   * @memberof CoreNavItemBase
   */
  protected OnItemClick = (evt: MouseEvent<HTMLAnchorElement>): void => {
    const { context, props: { name } } = this;
    context.change(name);
  };
}
