import React, { PureComponent } from 'react';

import { CoreFormInputKbdProps } from '../interfaces/';
import { INPUT_KBD_PROPS } from '../constants/';

import { HashMapMergeProps } from '../../../../utils/';

/**
 * Input Kbd - Keyboard
 * @export
 * @class CoreInputKbdComponent
 * @extends {PureComponent<CoreFormInputKbdProps, {}>}
 */
export default class CoreInputKbdComponent extends PureComponent<CoreFormInputKbdProps, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreInputKbdComponent
   */
  public static displayName: string = 'CoreInputKbd';

  /**
   * Default Props
   * @static
   * @type {CoreFormInputKbdProps}
   * @memberof CoreInputKbdComponent
   */
  public static defaultProps: CoreFormInputKbdProps = HashMapMergeProps(INPUT_KBD_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreInputKbdComponent
   */
  public render() {
    const { props: { value, children }} = this;
    return <kbd>{value||children}</kbd>;
  }
}
