import { CoreAuthCredentialsServiceShape } from '../interfaces/';

/**
 * Auth Credentials Service
 * @export
 * @class CoreAuthCredentialsService
 * @implements {CoreAuthCredentialsServiceShape}
 */
export class CoreAuthCredentialsService implements CoreAuthCredentialsServiceShape {
  /**
   * Client ID
   * @static
   * @type {string}
   * @memberof CoreAuthCredentialsService
   */
  public static CLIENTID: string;

  /**
   * Domain
   * @static
   * @type {string}
   * @memberof CoreAuthCredentialsService
   */
  public static DOMAIN: string;

  /**
   * Audience
   * @static
   * @type {string}
   * @memberof CoreAuthCredentialsService
   */
  public static AUDIENCE: string;

  /**
   * MultiFactor Authentication
   * @static
   * @type {string}
   * @memberof CoreAuthCredentialsService
   */
  public static MFA: boolean = true;

  /**
   * Validate
   * @param {string} clientID
   * @param {string} domain
   * @param {string} audience
   * @returns {boolean}
   * @memberof CoreAuthCredentialsService
   */
  public validate(clientID: string, domain: string, audience: string): boolean {
    return clientID === this.clientID && domain === this.domain && audience === this.audience;
  }

  /**
   * Client ID - getter
   * @readonly
   * @memberof CoreAuthCredentialsService
   */
  public get clientID() {
    return CoreAuthCredentialsService.CLIENTID;
  }

  /**
   * Domain - getter
   * @readonly
   * @memberof CoreAuthCredentialsService
   */
  public get domain() {
    return CoreAuthCredentialsService.DOMAIN;
  }

  /**
   * Audience - getter
   * @readonly
   * @memberof CoreAuthCredentialsService
   */
  public get audience() {
    return CoreAuthCredentialsService.AUDIENCE;
  }

  /**
   * MultiFactor Authentication - getter
   * @readonly
   * @memberof CoreAuthCredentialsService
   */
  public get mfa() {
    return CoreAuthCredentialsService.MFA;
  }
}
