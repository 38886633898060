import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableLinkProps } from '../interfaces/';

import { TABLE_LINK_PROPS } from '../constants';

import { MergeClassNames } from '../../../../utils/';

/**
 * Pagination Link Component
 * @export
 * @class CorePaginationLinkComponent
 * @extends {Component<CoreTableLinkProps, {}>}
 */
export default class CorePaginationLinkComponent extends Component<CoreTableLinkProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CorePaginationLinkComponent
   */
  public static displayName = 'CorePaginationLink';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CorePaginationLinkComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableLinkProps}
   * @memberof CorePaginationLinkComponent
   */
  public static defaultProps: CoreTableLinkProps = cloneDeep(TABLE_LINK_PROPS);

  /**
   * Render
   * @returns
   * @memberof CorePaginationLinkComponent
   */
  public render() {
    const { props, active, handler } = this;
    const { page } = props;
    const clz = MergeClassNames('', { active });
    return (
      <li>
        <button type="button" className={clz} onClick={handler(page - 1)}>
          {page}
        </button>
      </li>
    );
  }

  /**
   * Handler
   * @protected
   * @param {number} page
   * @returns {(evt: any) => void}
   * @memberof CorePaginationLinkComponent
   */
  protected handler = (page: number): ((evt: any) => void) => {
    const { context } = this;
    return (evt: any): void => {
      context.pagination.update(page);
      context.process();
    };
  };

  /**
   * Active - getter
   * @readonly
   * @private
   * @type {boolean}
   * @memberof CorePaginationLinkComponent
   */
  private get active(): boolean {
    const { props, context } = this;
    const { pagination } = context;
    const { page } = props;
    return pagination.page + 1 === page;
  }
}
