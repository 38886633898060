import React, { PureComponent, Fragment } from 'react';
import { Router, RouterProps } from '@reach/router';

/**
 * Core Router
 * @export
 * @class CoreRouter
 * @extends {PureComponent<RouterProps, {}>}
 */
export default class CoreRouter extends PureComponent<RouterProps, {}> {
  /**
   * Default Props
   * @static
   * @type {RouterProps}
   * @memberof CoreRouter
   */
  public static defaultProps: RouterProps = { primary: false, component: Fragment };
  /**
   * Render
   * @returns
   * @memberof CoreRouter
   */
  public render() {
    const { props } = this;
    return <Router {...props}>{props.children}</Router>
  }
}
