import {
  CoreConfigAccountShape,
  CoreConfigPermissionsShape,
  CoreConfigCompanyShape,
  CoreConfigMetaShape,
  CoreConfigAppShape,
  CoreConfigHeaderShape,
  CoreConfigFooterShape,
  CoreConfigServiceShape
} from './interfaces/';
import { 
  CoreConfigAccount, 
  CoreConfigPermissions, 
  CoreConfigCompany, 
  CoreConfigMeta, 
  CoreConfigApp,
  CoreConfigHeader, 
  CoreConfigFooter
} from './services/';

/**
 * Config Service
 * @export
 * @class CoreConfigService
 * @implements {CoreConfigServiceShape}
 */
export class CoreConfigService implements CoreConfigServiceShape {
  /**
   * Account
   * @type {CoreConfigAccountShape}
   * @memberof CoreConfigService
   */
  public account: CoreConfigAccountShape = new CoreConfigAccount();

  /**
   * App
   * @type {CoreConfigAppShape}
   * @memberof CoreConfigService
   */
  public app: CoreConfigAppShape = new CoreConfigApp();

  /**
   * Company
   * @type {CoreConfigCompanyShape}
   * @memberof CoreConfigService
   */
  public company: CoreConfigCompanyShape = new CoreConfigCompany();

  /**
   * Footer Service
   * @type {CoreConfigFooterShape}
   * @memberof CoreConfigService
   */
  public footer: CoreConfigFooterShape = new CoreConfigFooter();

  /**
   * Header
   * @type {CoreConfigHeaderShape}
   * @memberof CoreConfigService
   */
  public header: CoreConfigHeaderShape = new CoreConfigHeader();

  /**
   * Meta
   * @type {CoreConfigMetaShape}
   * @memberof CoreConfigService
   */
  public meta: CoreConfigMetaShape = new CoreConfigMeta();

  /**
   * Permissions
   * @type {CoreConfigPermissionsShape}
   * @memberof CoreConfigService
   */
  public permissions: CoreConfigPermissionsShape = new CoreConfigPermissions();

  /**
   * Subscribers
   * @private
   * @type {Map<string, Func<void, any>>}
   * @memberof CoreConfigService
   */
  private _subscribers: Map<string, Func<void, any>> = new Map<string, Func<void, any>>();

  /**
   * Subscribe
   * @param {string} name
   * @param {Func<void, any>} subscriber
   * @memberof CoreConfigService
   */
  public subscribe(name: string, subscriber: Func<void, any>): void {
    try {
      this._subscribers.set(name, subscriber);
    } catch (err: any) {
      /* istanbul ignore next */
      console.error(err);
    }
  }

  /**
   * Unsubscribe
   * @param {string} name
   * @memberof CoreConfigService
   */
  public unsubscribe(name: string): void {
    try {
      if (this._subscribers.has(name)) {
        this._subscribers.delete(name);
      }
    } catch (err: any) {
      /* istanbul ignore next */
      console.error(err);
    }
  }

  /**
   * Emit
   * @memberof CoreConfigService
   */
  public emit(): void {
    if (this._subscribers.size) {
      for (const subscriber of this._subscribers.values()) {
        subscriber(this.services);
      }
    }
  }

  /**
   * Services - getter
   * @readonly
   * @memberof CoreConfigService
   */
  public get services() {
    const { account, app, company, footer, header, meta, permissions } = this;
    return { account, app, company, footer, header, meta, permissions };
  }
}

const CoreConfig = new CoreConfigService();

export default CoreConfig;
