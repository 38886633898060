import Auth0Lock from 'auth0-lock';

import { CoreAuthCredentialsShape } from '../../Auth/interfaces/';

import {
  AUTH_RESPONSE_TYPE as responseType,
  AUTH_SCOPE as scope,
  AUTH_THEME_PRIMARY_COLOR as primaryColor,
  AUTH_CONFIG_BASE_URL as configurationBaseUrl,
  AUTH_TITLE as title,
  AUTH_REMEMBER as rememberLastLogin,
  AUTH_LEEWAY as leeway,
  AUTH_LOCK_FORGOT_PATH,
} from '../constants';

/**
 * Auth Lock Instance
 * @export
 * @param {string} container
 * @param {CoreAuthCredentialsShape} { clientID, domain, audience }
 * @param {boolean} [redirect=true]
 * @returns {Auth0LockStatic}
 * @note - 
 * 1. redirect is true because the default value for CoreAuthCredentialsService.MFA is also true
 */
export function AuthLockInstance(container: string, { clientID, domain, audience }: CoreAuthCredentialsShape, redirect: boolean = true): Auth0LockStatic {
  const forgotPasswordLink: string = `${AUTH_LOCK_FORGOT_PATH}`;
  const options = {
    container,
    auth: { params: { scope, audience }, redirect },
    responseType,
    avatar: null,
    theme: { primaryColor },
    languageDictionary: { title },
    allowLogin: true,
    allowSignUp: false,
    forgotPasswordLink,
    configurationBaseUrl,
    rememberLastLogin,
    leeway
  };
  return new Auth0Lock(clientID, domain, options);
}
