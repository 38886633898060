import { CoreListSortShape } from '../interfaces/';
import { CoreTableSortMethodMask } from '../enums/';

/**
 * Table Sort Service
 * @export
 * @class CoreTableSortService
 */
export default class CoreTableSortService {
  /**
   * Columns
   * @type {string[]}
   * @memberof CoreTableSortService
   */
  public columns: string[] = [];

  /**
   * Column
   * @type {string}
   * @memberof CoreTableSortService
   */
  public column: string = '';

  /**
   * Method
   * @type {number}
   * @memberof CoreTableSortService
   */
  public method: number = CoreTableSortMethodMask.UNSORTED;

  /**
   * Add [Column]
   * @param {string} column
   * @memberof CoreTableSortService
   */
  public add(column: string): void {
    if (!~this.columns.indexOf(column)) {
      this.columns.push(column);
    }
  }

  /**
   * Init
   * @param {string} column
   * @param {CoreTableSortMethodMask} [method=CoreTableSortMethodMask.UNSORTED]
   * @memberof CoreTableSortService
   */
  public init(column: string, method: number = CoreTableSortMethodMask.UNSORTED): void {
    this.column = column;
    this.method = method;
  }

  /**
   * Update
   * @param {string} column
   * @returns {CoreTableSortService}
   * @memberof CoreTableSortService
   */
  public update(column: string): void {
    try {
      if (!~this.columns.indexOf(column)) {
        throw new ReferenceError(`table sorting has not registered column [${column}]`);
      }

      // switching columns applies method = ASCENDING
      if (this.column !== column) {
        return this.init(column, CoreTableSortMethodMask.ASCENDING);
      }

      /*  istanbul ignore next */
      switch (this.method) {
        case CoreTableSortMethodMask.ASCENDING:
          return this.init(column, CoreTableSortMethodMask.DESCENDING);
        case CoreTableSortMethodMask.DESCENDING:
          return this.init(column, CoreTableSortMethodMask.UNSORTED);
        default:
          return this.init(column, CoreTableSortMethodMask.ASCENDING);
      }

    } catch (err: any) {
      console.error(err);
    }
  }

  /**
   * To HashMap
   * @returns {CoreListSortShape}
   * @memberof CoreTableSortService
   */
  public toHashMap(): CoreListSortShape {
    const { column, method } = this;
    return { column, method };
  }

  /**
   * Sortable - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreTableSortService
   */
  public get sortable(): boolean {
    const { columns } = this;
    return columns.length > 0;
  }
}
