import React, { Component } from 'react';

import { CoreTooltipProps } from '../interfaces/';

/**
 * Tooltip Component
 * @export
 * @class CoreTooltipComponent
 * @extends {Component<CoreTooltipProps, {}>}
 */
export default class CoreTooltipComponent extends Component<CoreTooltipProps, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreTooltipComponent
   */
  public static displayName: string = 'CoreTooltip';

  /**
   * Render
   * @returns
   * @memberof CoreTooltipComponent
   */
  public render() {
    const {
      props: { tooltip, children },
    } = this;
    return (
      <i className="tooltip">
        {children}
        <var data-tooltip={tooltip} />
      </i>
    );
  }
}
