import React, { FormEvent } from 'react';

import CoreFormMenuBase from './Base';

import { CoreFormOptionShape } from '../../../../interfaces/';
import { MergeClassNames, StringToKebabCase } from '../../../../../../../utils/';

/**
 * Menu Options
 * @export
 * @class MenuOptionsComponent
 * @extends {CoreFormMenuBase}
 */
export default class MenuOptionsComponent extends CoreFormMenuBase {
  /**
   * Render
   * @returns
   * @memberof MenuOptionsComponent
   */
  public render() {
    const { OnValueSelect, context: { ValueSelected, options, disabled }} = this;
    return (
      <ul>
        {options.map(({ label, value, title }: CoreFormOptionShape, ndx: number) => {
          const selected: boolean = ValueSelected(value);
          const kc: string = Array.isArray(value) ? StringToKebabCase(value.join('-')) : StringToKebabCase(String(value));
          const clz = MergeClassNames('', { selected });
          return (
            <li key={`o.${ndx}`}>
              <button type="button" data-value={kc} title={title} className={clz} disabled={disabled} onClick={OnValueSelect(value)}>
                <span>{label}</span>
              </button>
            </li>
          );
        })}
      </ul>
    );
  }

  /**
   * On Value Select
   * @protected
   * @param {string | number | boolean | readonly string[]} candidate;
   * @memberof MenuOptionsComponent
   * @returns {Func<void, FormEvent<HTMLButtonElement>>}
   */
  /* istanbul ignore next */
  protected OnValueSelect = (candidate: string | number | boolean | readonly string[]): Func<void, FormEvent<HTMLButtonElement>> => {
    const { SelectValue } = this.context;
    return (evt: FormEvent<HTMLButtonElement>): void => {
      SelectValue(candidate);
    };
  };
}
