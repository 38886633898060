import React, { Component, FormEvent, RefObject, createRef } from 'react';
import { cloneDeep } from 'lodash';

import { CoreDOMEvent } from '../../../directives/';

import { CoreHelpContainerProps, CoreHelpContainerState } from '../interfaces/';
import { HTMLQuadrantEnum } from '../../../enums/';
import { HELP_CONTAINER_PROPS, HELP_CONTAINER_STATE } from '../constants';

import { GetElementQuadrant } from '../../../factories/';

import { MergeClassNames } from '../../../../utils/';


/**
 * Help Container
 * @export
 * @class CoreHelpContainer
 * @extends {Component<CoreHelpContainerProps, CoreHelpContainerState>}
 */
export default class CoreHelpContainer extends Component<CoreHelpContainerProps, CoreHelpContainerState> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreHelpContainer
   */
  public static displayName: string = 'CoreHelp';

  /**
   * Default Props
   * @static
   * @type {CoreHelpContainerProps}
   * @memberof CoreHelpContainer
   */
  public static defaultProps: CoreHelpContainerProps = cloneDeep(HELP_CONTAINER_PROPS);

  /**
   * State
   * @type {CoreHelpContainerState}
   * @memberof CoreHelpContainer
   */
  public readonly state: CoreHelpContainerState = cloneDeep(HELP_CONTAINER_STATE);

  /**
   * Ref
   * @private
   * @type {RefObject<HTMLElement>}
   * @memberof CoreHelpContainer
   */
  private _ref: RefObject<HTMLElement>;

  /**
   * Creates an instance of CoreHelpContainer.
   * @param {GenericPropType} props
   * @memberof CoreHelpContainer
   */
  constructor(props: GenericPropType) {
    super(props);
    this._ref = createRef();
  }

  /**
   * LifeCycle Hook
   * @memberof CoreHelpContainer
   */
  public componentDidMount() {
    const { AdjustPosition, props: { quadrant } } = this;
    if (!HTMLQuadrantEnum[quadrant]) {
      AdjustPosition();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreHelpContainer
   */
  public render() {
    const {
      ref,
      Toggle,
      OnBodyClick,
      AdjustPosition,
      props: { title, quadrant, children },
      state: { placement, active },
    } = this;

    const help: boolean = true;
    const pos: string = quadrant || placement;
    const clz = MergeClassNames('', { help, active, [pos]: true });

    return (
      <span className={clz}>
        <var ref={ref} onClick={Toggle}>
          ?
        </var>
        <CoreDOMEvent listener={OnBodyClick} />
        <CoreDOMEvent base={window} event={'resize'} listener={AdjustPosition} />
        <span>
          <span>
            {title ? (
              <header>
                <h4>{title}</h4>
              </header>
            ) : null}
            <span>{children}</span>
          </span>
        </span>
      </span>
    );
  }

  /**
   * Toggle
   * @protected
   * @memberof CoreHelpContainer
   */
  protected Toggle = (evt: FormEvent<HTMLElement>): void => {
    evt.stopPropagation();
    const { state } = this;
    const active = !state.active;
    this.setState({ active });
  };

  /**
   * On Body Click
   * @protected
   * @memberof CoreHelpContainer
   */
  protected OnBodyClick = (evt: Event) => {
    if (evt.target !== this.ref.current) {
      this.setState({ active: false });
    }
  };

  /**
   * Adjust Position
   * @protected
   * @memberof CoreHelpContainer
   */
  protected AdjustPosition = (evt?: Event): void => {
    const { ref, state } = this;
    const placement = GetElementQuadrant(ref.current);
    if (placement !== state.placement) {
      this.setState({ placement });
    }
  };

  /**
   * Ref
   * @readonly
   * @type {RefObject<HTMLElement>}
   * @memberof CoreHelpContainer
   */
  get ref(): RefObject<HTMLElement> {
    return this._ref;
  }
}
