import React, { Component, ReactNode, Children } from 'react';
import { cloneDeep } from 'lodash';

import CoreFormContext from '../forms/contexts/Form';
import { CoreFormControllerInstance } from '../forms/services/';
import { CoreFormControllerInstanceType } from '../forms/types';
import { CoreFormEventsEnum } from '../forms/';

import CoreListContext from './context';
import CoreListService from './service';

import { CoreListModuleProps, CoreListServiceShape } from './interfaces/';
import CoreListTable from './containers/Table';

import { LIST_MODULE_PROPS } from './constants';

import { CoreTableService } from '../tables/';

import { GetDeepHashMap, IsDictionary, IsEqual, WaitForDelay } from '../../../utils/';

/**
 * List Builder
 * @export
 * @class CoreListBuilder
 * @extends {Component<CoreListModuleProps, {}>}
 */
export default class CoreListBuilder extends Component<CoreListModuleProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreListBuilder
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default props;
   * @static
   * @type {CoreListModuleProps}
   * @memberof CoreListBuilder
   */
  public static defaultProps: CoreListModuleProps = cloneDeep(LIST_MODULE_PROPS);

  /**
   * Service
   * @private
   * @type {CoreListServiceShape}
   * @memberof CoreListBuilder
   */
  private service: CoreListServiceShape = null!;

  /**
   * Modal
   * @private
   * @type {ReactNode}
   * @memberof CoreListBuilder
   */
  private modal: ReactNode = null;

  /**
   * Kids
   * @private
   * @type {ReactNode[]}
   * @memberof CoreListBuilder
   */
  private kids: ReactNode[] = [];

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreListBuilder
   */
  private _listener: AbortController;

  /**
   * Creates an instance of CoreListBuilder.
   * @param {CoreListModuleProps} props
   * @memberof CoreListBuilder
   */
  constructor(props: CoreListModuleProps, context: CoreFormServiceType) {
    super(props);
    this.init(props, context);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreListBuilder
   */
  public componentDidMount() {
    const { Rollup, OnResetListener, context, service: { table }, props: { name } } = this;
    this._listener = new AbortController();

    try {
      /* istanbul ignore if */
      if (!context) {
        throw new ReferenceError(`CoreListBuilder - must be wrapped in CoreForm.`);
      }

       /* istanbul ignore if */
      if (!name) {
        throw new ReferenceError(`CoreListBuilder - missing prop [name].`);
      }

      /* istanbul ignore if */
      if (!this.modal) {
        throw new ReferenceError('CoreListBuilder - must contain a Child of type CoreListEditor.');
      }

      context.emitter.on(CoreFormEventsEnum.FORM_EVENT_RESET, OnResetListener);

      if (context.has(name)) {
        /* istanbul ignore next */
        WaitForDelay().then(() => {
          const value = context.value(name) || [];
          if (!Array.isArray(value) || (value.length > 0 && !IsDictionary(value))) {
            throw new ReferenceError(`CoreListBuilder - parent form source for [${name}] must be of Dictionary type.`);
          }

          if (value.length && !table.collection.show) {
            const source = Object.assign({}, table.source, { results: value });
            table.use(source);
          }
        });
      }

      // Roll Up
      Rollup(name, context);

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreListBuilder
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    const { OnResetListener, context } = this;
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_RESET, OnResetListener);
    if (this.listener) {
      this.listener.abort();
    }
  }

  /**
   * On Reset Listener
   * @memberof CoreListBuilder
   */
  /* istanbul ignore next */
  public OnResetListener = (): void => {
    this.forceUpdate();
  };

  /**
   * Render
   * @returns
   * @memberof CoreListBuilder
   */
  public render() {
    const {
      service,
      kids,
      context,
      props: { title, rows, search, limit, addable, editable, onAddPromise, onRemovePromise },
    } = this;

    /* istanbul ignore if */
    if (!context) {
      return null;
    }

    return (
      <CoreListContext.Provider value={service}>
        <CoreListTable title={title} rows={rows} search={search} limit={limit} addable={addable} editable={editable} onAddPromise={onAddPromise} onRemovePromise={onRemovePromise}>
          {kids}
        </CoreListTable>
      </CoreListContext.Provider>
    );
  }

  /**
   * Listener - getter
   * @readonly
   * @memberof CoreListBuilder
   */
  /* istanbul ignore next */
  public get listener() {
    return this._listener;
  }

  /**
   * Rollup
   * @private
   * @param {string} name
   * @param {CoreFormServiceType} form
   * @memberof CoreListBuilder
   */
  private Rollup = (name: string, form: CoreFormServiceType): void => {
    for (const NAME of form.registry.keys()) {
      if (!!~NAME.indexOf(`${name}.`)) {
        form.clear(NAME);
      }
    }
  };

  /**
   * Init
   * @private
   * @param {CoreListModuleProps} props
   * @param {CoreFormServiceType} foorm
   * @memberof CoreListBuilder
   */
  private init = (props: CoreListModuleProps, form: CoreFormServiceType): void => {
    const { Rollup, props: { name, onUpdate, onFilter } } = this;

    let modal: ReactNode;
    const value = GetDeepHashMap(form.source, name, []);

    let controller: CoreFormControllerInstanceType;

    if (!form.has(name)) {
      controller = new CoreFormControllerInstance();
      controller.init(name, 'dict', value, undefined, false, false);
      form.add(controller);
    } else {
      controller = form.registry.get(name);
      if (controller.type !== 'dict') {
        controller.type = 'dict';
      }
      if (!Array.isArray(form.value(name))) {
        /* istanbul ignore next */
        controller.install(value, null, true);
      }
    }

    /* istanbul ignore next */
    WaitForDelay().then(() => {
      const fvalue = form.value(name);
      const dvalue = GetDeepHashMap(form.source, name, []);
      if (!IsEqual(fvalue, dvalue)) {
        controller.install(dvalue, null, true);
      }
      // Roll Up
      Rollup(name, form);
    });

    this.kids = Children.toArray((props as any).children).filter((child: any) => {
      if (child.type.displayName === 'CoreListEditor') {
        modal = child;
        return false;
      }
      return child;
    });

    this.modal = modal;

    const results: Array<HashMap<any>> = value.slice(0);

    const source = props.search && props.search.length ? {search: { term: '', fields: props.search }, results } : { results };

    const table: CoreTableServiceType = new CoreTableService(source);

    this.service = new CoreListService(form, table, name, modal, onUpdate, onFilter);
  };
}
