
import { CoreItemTypeMask } from './enums/';
import { cloneDeep } from 'lodash';

export const ITEMS_PROPS = {
  items: null,
  top: false,
};

export const ITEM_DEFAULTS = {
  label: '',
  path: null,
  url: null,
  children: null,
};

export const ITEM_PROPS = {
  name: '',
  item: cloneDeep(ITEM_DEFAULTS),
};

export const ITEM_SWITCH_STATE = {
  type: CoreItemTypeMask.ROUTE,
};

export const ITEM_TOGGLE_STATE = {
  expanded: false,
};

export const NAVIGATION_EVENT_CHANGE = 'NAVIGATION_EVENT_CHANGE';
