import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementText } from '../../../components/';

/**
 * Text Input Controller
 * @export
 * @class TextInputController
 * @extends {InputBase}
 */
export default class TextInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof TextInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementText;
}
