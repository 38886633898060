import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import { ButtonProps, ButtonState } from '../interfaces/';
import { BUTTON_PROPS, BUTTON_STATE, BUTTON_TYPES, BUTTON_VARIANTS } from '../constants';

import { StringToHashMap, IsNull, IsBoolean, MergeClassNames } from '../../../../utils/';

/**
 * Core Button Base
 * @export
 * @class CoreButtonBase
 * @extends {Component<ButtonProps, ButtonState>}
 */
export default class CoreButtonBase extends Component<ButtonProps, ButtonState> {
  /**
   * Default Props
   * @static
   * @type {*}
   * @memberof CoreButtonBase
   */
  public static defaultProps: ButtonProps = cloneDeep(BUTTON_PROPS);

  /**
   * State
   * @type {ButtonState}
   * @memberof CoreButtonBase
   */
  public readonly state: ButtonState = cloneDeep(BUTTON_STATE);

  /**
   * State From Props
   * @static
   * @param {ButtonProps} props
   * @param {ButtonState} state
   * @returns
   * @memberof CoreButtonBase
   */
  public static getDerivedStateFromProps(props: ButtonProps, state: ButtonState) {
    const { variant, outline, disabled, value } = props;
    if (!IsNull(variant)) {
      Object.assign(state, { variant });
    }
    if (IsBoolean(disabled)) {
      Object.assign(state, { disabled });
    }
    if (IsBoolean(outline)) {
      Object.assign(state, { outline });
    }
    if (!IsNull(value)) {
      Object.assign(state, { value });
    }
    return state;
  }

  /**
   * LifeCycle Hook
   * @memberof CoreButtonBase
   */
  public componentDidMount() {
    const { type } = this.props;
    try {
      if (process.env.NODE_ENV !== 'production') {
        if (!~BUTTON_TYPES.indexOf(type)) {
          throw new ReferenceError(`button type must one be of [${BUTTON_TYPES.join(', ')}]`);
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreButtonBase
   */
  public render() {
    const {
      props: { type, variant: VARIANT, outline: OUTLINE, disabled: DISABLED, value: VALUE, className, children, ...rest },
      state: { variant, disabled, outline, value }
    } = this;

    const v = outline ? `btn-outline-${variant}`: `btn-${variant}`;
    const fcn = { btn: true, [v]: true };

    // cleans improper class names
    const cn: HashMap<boolean> = StringToHashMap(className);
    for (const prop in cn) {
      if (/^btn-/.test(prop)) {
        if (!!~BUTTON_VARIANTS.indexOf(prop.split('-')[1])) {
          delete cn[prop];
        }
      }
    }

    const clz = MergeClassNames('', {...fcn, ...cn});

    return (
      <button type={type as any} className={clz} disabled={disabled} {...rest}>
        {value || children}
      </button>
    );
  }
}
