import React, { FormEvent } from 'react';

import CoreFormMenuBase from './Base';

import { CoreFormElementSearch } from '../../../../components/';
import { CoreFormValueType } from '../../../../types';

/**
 * Menu Filter
 * @export
 * @class MenuFilterComponent
 * @extends {CoreFormMenuBase}
 */
export default class MenuFilterComponent extends CoreFormMenuBase {
  /**
   * Render
   * @returns
   * @memberof MenuFilterComponent
   */
  public render() {
    const { OnChange, context: { filter, disabled } } = this;
    if (!filter) {
      return null;
    }
    return (
      <label className="filter">
        <CoreFormElementSearch name="" placeholder="Filter..." disabled={disabled} onChange={OnChange} />
      </label>
    );
  }

  /**
   * On Change
   * @protected
   * @param {FormEvent<any>} evt
   * @param {CoreFormValueType} value
   * @memberof MenuFilterComponent
   */
  /* istanbul ignore next */
  protected OnChange = (evt: FormEvent<any>, value: CoreFormValueType): void => {
    const { context: { emit } } = this;
    this.context.term = value;
    emit();
  };

  /**
   * Menu Event Listener
   * @protected
   * @memberof CoreFormMenuBase
   */
  /* istanbul ignore next */
  protected MenuEventListener = (): void => {
    return void 0;
  };
}
