import React from 'react';

import { CoreAnchor } from '../../shared/';

import CoreNavItemBase from './ItemBase';

import { ParseURL, MergeClassNames } from '../../utils/';

/**
 * Nav Item Anchor Component
 * @export
 * @class CoreNavItemAnchorComponent
 * @extends {CoreNavItemBase<Component<CoreNavItemProps, any>>}
 */
export default class CoreNavItemAnchorComponent extends CoreNavItemBase {
  /**
   * Render
   * @returns
   * @memberof CoreNavItemAnchorComponent
   */
  public render() {
    const {
      OnItemClick,
      props: {
        name,
        item: { label, url },
      },
    } = this;

    const clz = name ? MergeClassNames('', { [name]: true }) : '';

    const $hostname: string = (window as any).location.hostname;
    const parsed = ParseURL(url);

    if ($hostname === parsed.hostname) {
      const href: string = parsed.pathname.charAt(0) === '/' ? parsed.pathname : `/${parsed.pathname}`;

      return (
        <CoreAnchor className={clz} href={href} onClick={OnItemClick}>
          <span>{label}</span>
        </CoreAnchor>
      );
    }

    /* istanbul ignore next */
    return (
      <CoreAnchor className={clz} href={url}>
        <span>{label}</span>
      </CoreAnchor>
    );
  }
}
