
import { IsHashMap, IsNumber, IsBoolean } from '../../../../utils/';

import { SyncWebStorageShape } from '../interfaces/';

/**
 * Sync Web Storage
 * @export
 * @class CoreSyncWebStorage
 * @implements {SyncWebStorageShape}
 */
export default class CoreSyncWebStorage implements SyncWebStorageShape {
  /**
   * Name
   * @private
   * @type {string}
   * @memberof CoreSyncWebStorage
   */
  private _name: string;

  /**
   * Engine
   * @private
   * @memberof CoreSyncWebStorage
   */
  private _engine: Storage;

  /**
   * Creates an instance of CoreSyncWebStorage.
   * @param {string} name
   * @param {CoreSyncWebStorageEngineType} type
   * @memberof CoreSyncWebStorage
   */
  constructor(name: string, type: CoreSyncWebStorageEngineType = 'LOCAL') {
    this.init = this.init.bind(this);
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.remove = this.remove.bind(this);
    this.check = this.check.bind(this);
    this.check(name, type);
  }

  /**
   * Init
   * @param {CoreWebStorageValueType} [value=null]
   * @memberof CoreSyncWebStorage
   */
  public init(value: CoreWebStorageValueType = null): void {
    try {
      this.set(value);
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * Get
   * @memberof CoreSyncWebStorage
   */
  public get(): CoreWebStorageValueType {
    const { engine, name } = this;
    let value: string = engine.getItem(name);
    try {
      return JSON.parse(value);
    } catch (err: any) {
      return value;
    }
  }

  /**
   * Set
   * @param {CoreWebStorageValueType} value
   * @memberof CoreSyncWebStorage
   */
  public set(value: CoreWebStorageValueType): void {
    const { engine, name } = this;
    if (IsHashMap(value) || Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (IsNumber(value)) {
      value = String(value);
    } else if (IsBoolean(value)) {
      value = value === true ? 'true' : 'false';
    }
    engine.setItem(name, value as string);
  }

  /**
   * Remove
   * @memberof CoreSyncWebStorage
   */
  public remove(): void {
    const { engine, name } = this;
    engine.removeItem(name);
  }

  /**
   * Name - getter
   * @readonly
   * @protected
   * @memberof CoreSyncWebStorage
   */
  protected get name() {
    return this._name;
  }

  /**
   * Engine - getter
   * @readonly
   * @protected
   * @memberof CoreSyncWebStorage
   */
  protected get engine() {
    return this._engine;
  }

  /**
   * Check
   * @private
   * @memberof CoreSyncWebStorage
   */
  private check(name: string, type: CoreSyncWebStorageEngineType): void {
    try {

      this._name = name;

      switch (type) {
        case 'LOCAL':
          this._engine = (window as any).localStorage;
          break;
        case 'SESSION':
          this._engine = (window as any).sessionStorage;
          break;
        /* istanbul ignore next */
        default:
          this._engine = (window as any).localStorage;
          break;
      }

      /* istanbul ignore next */
      if (!this.engine.length) {
        const test: string = 'test';
        let result: string;
        this.engine.setItem(test, test);
        result = this.engine.getItem(test);
        this.engine.removeItem(test);
        if (result !== test) {
          throw new Error('Please use a browser that is configured to accept Local Storage.');
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreSyncWebStorage.init: ${err.message || err}`);
      }
    }
    return void 0;
  }
}