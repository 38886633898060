import React from 'react';

import CoreTabsItemBase from './Base';

import { CoreAnchor } from '../../../components/';
import { CoreTabItemTitleProps } from '../interfaces/';

import { MergeClassNames } from '../../../../utils/';

/**
 * Tabs Item Titles Container
 * @export
 * @class CoreTabItemTitleContainer
 * @extends {CoreTabsItemBase<CoreTabItemTitleProps>}
 */
export default class CoreTabItemTitleContainer extends CoreTabsItemBase<CoreTabItemTitleProps> {
  /**
   * Render
   * @return {*}
   * @memberof CoreTabItemTitleContainer
   */
  public render() {
    const { OnChange, context, props: { item } } = this;

    const [ name, child ] = item as any;
    const { props: { hide, label }} = child;

    if (hide) {
      return null;
    }

    const marked: boolean = !!~context.marked.indexOf(name);
    const active: boolean = context.current === name;
    const aclz = MergeClassNames('', { active, marked });

    return (
      <dt>
        <CoreAnchor onClick={OnChange(name)} className={aclz}>
          {label}
        </CoreAnchor>
      </dt>
    );
  }

  /**
   * On Change
   * @protected
   * @memberof CoreTabItemTitleContainer
   */
  protected OnChange = (current: string) => {
    const { context } = this;
    return (evt: Event): void => {
      evt.preventDefault();
      context.current = current;
      context.emit();
    };
  };
}
