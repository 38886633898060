import React, { Component, ComponentClass } from 'react';
import { cloneDeep } from 'lodash';

import CoreNavigationItems from './Items';

import { CoreNavItemAnchor, CoreNavItemRoute, CoreNavItemToggle } from '../components/';
import { CoreNavItemProps, CoreNavItemSwitchState } from '../interfaces/';
import { CoreItemTypeMask } from '../enums/';
import { ITEM_PROPS, ITEM_SWITCH_STATE } from '../constants';

/**
 * Navigation Item Switch Container
 * @export
 * @class CoreNavItemSwitchContainer
 * @extends {Component<CoreNavItemProps, CoreNavItemSwitchState>}
 */
export default class CoreNavItemSwitchContainer extends Component<CoreNavItemProps, CoreNavItemSwitchState> {
  /**
   * Default Props
   * @static
   * @type {CoreNavItemProps}
   * @memberof CoreNavItemSwitchContainer
   */
  public static defaultProps: CoreNavItemProps = cloneDeep(ITEM_PROPS);

  /**
   * State
   * @type {CoreNavItemSwitchState}
   * @memberof CoreNavItemSwitchContainer
   */
  public readonly state: CoreNavItemSwitchState = cloneDeep(ITEM_SWITCH_STATE);

  /**
   * LifeCycle Hook
   * @memberof CoreNavItemSwitchContainer
   */
  public componentDidMount() {
    const { item } = this.props;

    let type = CoreItemTypeMask.ROUTE;

    if (item.children) {
      type = CoreItemTypeMask.TOGGLE;
    } else if (item.url) {
      type = CoreItemTypeMask.ANCHOR;
    }

    this.setState({ type });
  }

  /**
   * Render
   * @returns
   * @memberof CoreNavItemSwitchContainer
   */
  public render() {
    const { props, state } = this;
    const { type } = state;
    const { name, item } = props;
    const { children } = item;

    let Item: ComponentClass<CoreNavItemProps, {}>;
    /* istanbul ignore next */
    switch (type) {
      case CoreItemTypeMask.ROUTE:
        Item = CoreNavItemRoute;
        break;
      case CoreItemTypeMask.ANCHOR:
        Item = CoreNavItemAnchor;
        break;
      case CoreItemTypeMask.TOGGLE:
        Item = CoreNavItemToggle;
        break;
    }

    return (
      <li>
        <Item name={name} item={item} />
        {/* istanbul ignore next */}
        {children && children.size ? <CoreNavigationItems items={children} /> : null}
      </li>
    );
  }
}
