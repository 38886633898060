import React, { ComponentClass } from 'react';

import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormControllerProps } from '../../../interfaces/';
import { CoreFormElementRange, ControllerLabel, ControllerValue } from '../../../components/';
import { INPUT_TYPE_RANGE, FORM_ELEMENT_PROPS } from '../../../constants/';
import { HashMapMergeProps } from '../../../../../../utils/';

/**
 * Range Input Controller
 * @export
 * @class RangeInputController
 * @extends {InputBase}
 */
export default class RangeInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof RangeInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementRange;

  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormControllerProps, 'name'>}
   * @memberof RangeInputController
   */
  public static defaultProps: Omit<CoreFormControllerProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_RANGE,
    min: 0,
    max: 1,
    value: 0.5,
    step: 0.1
  });

  /**
   * Render
   * @returns
   * @memberof InputBase
   */
   public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, controller, props: { min, max } } = this;
    const { name, value } = controller;

    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel htmlFor={null} {...labels}>
          <label data-min={min} data-max={max}>
            <var><ControllerValue /></var>
            <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
          </label>
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
