import React, { PureComponent, Fragment } from 'react';

import { CoreHistory } from '../../Router/';

import CoreNavigationContext from '../context';
import { WaitForDelay, PathToDots } from '../../utils/';

/**
 * Navigation History Directive Component
 * @class CoreNavigationHistoryDirectiveComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNavigationHistoryDirectiveComponent extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreNavigationContext>}
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  public static contextType: Readonly<typeof CoreNavigationContext> = CoreNavigationContext;

  /**
   * Unlisten
   * @protected
   * @type {Func<any>}
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  protected unlisten: Func<any>;

  /**
   * LifeCycle Hook
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  public componentDidMount() {
    const { AssessPath } = this;

    const { listen, location: { pathname }} = CoreHistory;

    /* istanbul ignore next */
    WaitForDelay().then(() => {
      AssessPath(pathname);
    });

    /* istanbul ignore next */
    this.unlisten = listen(({ location: { pathname } }: any) => {
      AssessPath(pathname);
    });
  }

  /**
   * LifeCycle Hook
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  public componentWillUnmount() {
    this.unlisten();
  }

  /**
   * Render
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  public render() {
    return <Fragment />;
  }

  /**
   * Assess Path
   *
   * @protected
   * @memberof CoreNavigationHistoryDirectiveComponent
   */
  protected AssessPath = (pathname: string): void => {
    const { context } = this;
    if (context.populated) {
      const current: string = PathToDots(pathname);
      if (current && context.current !== current) {
        context.current = current;
        context.emit();
      }
    }
  };
}
