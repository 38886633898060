import { HTMLQuadrantEnum } from '../../enums/';

export const HELP_CONTAINER_STATE = {
  active: false,
  placement: HTMLQuadrantEnum.TOP_LEFT,
};

export const HELP_CONTAINER_PROPS = {
  title: '',
  quadrant: HTMLQuadrantEnum.TOP_LEFT
};
