import React, { Fragment } from 'react';

import { CoreConfigBounce } from '../../Config/';
import { CoreConfigServiceShape } from '../../Config/interfaces/';
import { CoreLabelFromPath } from '../factories/';

/**
 * Title Directive Component
 * @export
 * @class CoreTitleDirectiveComponent
 * @extends {CoreConfigBounce}
 */
export default class CoreTitleDirectiveComponent extends CoreConfigBounce {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreTitleDirectiveComponent
   */
  public static displayName: string = 'OnTitleDirectiveBounce';

  /**
   * Title
   * @private
   * @static
   * @type {HTMLTitleElement}
   * @memberof CoreTitleDirectiveComponent
   */
  private static title: HTMLTitleElement = null;

  /**
   * Creates an instance of TitleDirective.
   * @param {CoreRouteProps} props
   * @param {CoreConfigServiceShape} context
   * @memberof CoreTitleDirectiveComponent
   */
  /* istanbul ignore next */
  constructor(props: CoreRouteProps, context: CoreConfigServiceShape) {
    super(props);

    CoreTitleDirectiveComponent.title = document.querySelector('title');

    if (CoreTitleDirectiveComponent.title) {
      const product_name = context && context.header && context.header.product_name ? context.header.product_name : '';
      CoreTitleDirectiveComponent.title.innerHTML = product_name;
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreTitleDirectiveComponent
   */
  /* istanbul ignore next */
  public render() {
    const { context, props: { children } } = this;
    const path: string = /^\/([^/]*).*$/.exec((window as any).location.pathname)[1];
    const label: string = path ? CoreLabelFromPath(path) : '';

    if (CoreTitleDirectiveComponent.title && (children || label)) {

      const product_name = context && context.header && context.header.product_name ? context.header.product_name : '';

      CoreTitleDirectiveComponent.title.innerHTML = `${product_name} | ${children ? children : label}`;
    }
    return <Fragment />;
  }
}
