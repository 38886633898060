import React, { PureComponent } from 'react';

import {
  CheckboxInputController,
  ColorInputController,
  DateInputController,
  DateTimeInputController,
  EmailInputController,
  FileInputController,
  HiddenInputController,
  MonthInputController,
  NumberInputController,
  PasswordInputController,
  RadioInputController,
  RangeInputController,
  SearchInputController,
  TelInputController,
  TextInputController,
  TimeInputController,
  UrlInputController,
  WeekInputController,
} from './Inputs';

import { CoreFormControllerProps } from '../../interfaces/';
import {
  CORE_INPUT_PROPS,
  INPUT_TYPE_BUTTON,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_COLOR,
  INPUT_TYPE_DATE,
  INPUT_TYPE_DATETIME,
  INPUT_TYPE_EMAIL,
  INPUT_TYPE_FILE,
  INPUT_TYPE_HIDDEN,
  INPUT_TYPE_IMAGE,
  INPUT_TYPE_MONTH,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_PASSWORD,
  INPUT_TYPE_RADIO,
  INPUT_TYPE_RANGE,
  INPUT_TYPE_RESET,
  INPUT_TYPE_SEARCH,
  INPUT_TYPE_SUBMIT,
  INPUT_TYPE_TEL,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_TIME,
  INPUT_TYPE_URL,
  INPUT_TYPE_WEEK,
} from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Core Input Container
 * @export
 * @class ContainerInputController
 * @extends {PureComponent<CoreFormControllerProps, {}>}
 */
export default class ContainerInputController extends PureComponent<CoreFormControllerProps, {}> {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormControllerProps, 'name'>}
   * @memberof ContainerInputController
   */
  public static defaultProps: Omit<CoreFormControllerProps, 'name'> = HashMapMergeProps(CORE_INPUT_PROPS);

  /**
   * Render
   * @returns
   * @memberof ContainerInputController
   */
  public render() {
    const { props } = this;

    switch (props.type) {
      case INPUT_TYPE_CHECKBOX:
        return <CheckboxInputController {...props} />;
      case INPUT_TYPE_COLOR:
        return <ColorInputController {...props} />;
      case INPUT_TYPE_DATE:
        return <DateInputController {...props} />;
      case INPUT_TYPE_DATETIME:
        return <DateTimeInputController {...props} />;
      case INPUT_TYPE_EMAIL:
        return <EmailInputController {...props} />;
      case INPUT_TYPE_FILE:
        return <FileInputController {...props} />;
      case INPUT_TYPE_HIDDEN:
        return <HiddenInputController {...props} />;
      case INPUT_TYPE_MONTH:
        return <MonthInputController {...props} />;
      case INPUT_TYPE_NUMBER:
        return <NumberInputController {...props} />;
      case INPUT_TYPE_PASSWORD:
        return <PasswordInputController {...props} />;
      case INPUT_TYPE_RADIO:
        return <RadioInputController {...props} />;
      case INPUT_TYPE_RANGE:
        return <RangeInputController {...props} />;
      case INPUT_TYPE_SEARCH:
        return <SearchInputController {...props} />;
      case INPUT_TYPE_TEL:
        return <TelInputController {...props} />;
      case INPUT_TYPE_TEXT:
        return <TextInputController {...props} />;
      case INPUT_TYPE_TIME:
        return <TimeInputController {...props} />;
      case INPUT_TYPE_URL:
        return <UrlInputController {...props} />;
      case INPUT_TYPE_WEEK:
        return <WeekInputController {...props} />;
      case INPUT_TYPE_BUTTON:
      case INPUT_TYPE_IMAGE:
      case INPUT_TYPE_RESET:
      case INPUT_TYPE_SUBMIT:
        /* istanbul ignore next */
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`DEVELOPER ERROR:: input[type="${props.type}"] not supported.`);
        }
        return null;
      /* istanbul ignore next */
      default:
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`DEVELOPER ERROR:: input[type="${props.type}"] unknown.`);
        }
        return null;
    }
  }
}
