import { CoreGraphResultsRemoveTypeNotation } from './results';

import { GetDeepHashMap, IsDefined } from '../../../../utils/';

/**
 * Core Graph Data Factory
 * @export
 * @param {*} client
 * @param {string} [dots='']
 * @param {*} [def=[]]
 * @param {(value: any, key: string) => string} [callback]
 * @returns {(HashMap<any> | Array<HashMap<any>>)}
 */
export function CoreGraphDataFactory(client: any, dots: string = '', def: any = [], callback?: (value: any, key: string) => string): HashMap<any> | Array<HashMap<any>> {
  const { loading, data } = client;

  /* istanbul ignore next */
  if (loading || !data) {
    return IsDefined(def) ? def : [];
  }

  const results: HashMap<any> | Array<HashMap<any>> = GetDeepHashMap(data, dots, def, true);

  return CoreGraphResultsRemoveTypeNotation(results, callback);
}