import React, { ComponentClass, Children } from 'react';
import ControllerWrapper from '../ControllerWrapper';

import InputBase from './Base';
import { CoreFormElementCheckbox, ControllerLabel, ControllerBooleanLabel } from '../../../components/';

/**
 * Checkbox Input
 * @export
 * @class CheckboxInputController
 * @extends {InputBase}
 */
export default class CheckboxInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof CheckboxInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementCheckbox;

  /**
   * Render
   * @returns
   * @memberof CheckboxInputController
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, controller, props: { children } } = this;
    const { name, value } = controller;
    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel {...labels} htmlFor="_">
          <label>
            <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
            <ControllerBooleanLabel value={value}>{Children.toArray(children)}</ControllerBooleanLabel>
          </label>
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
