import React, { ComponentClass } from 'react';

import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormElementFile, ControllerLabel, ControllerError } from '../../../components/';

/**
 * File Input Controller
 * @export
 * @class FileInputController
 * @extends {InputBase}
 */
export default class FileInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof FileInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementFile;

  /**
   * Render
   * @returns
   * @memberof FileInputController
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, exceptions, controller } = this;
    const { name, value } = controller;

    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel htmlFor={null} {...labels}>
          <label>
            <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
            <ControllerError {...exceptions} />
          </label>
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
