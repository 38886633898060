import gql from 'graphql-tag';

export const UserWhiteLabelFrag = gql`
  fragment UserWhiteLabelFields on Company {
    whiteLabel {
      enabled
      favicon
      footerLinks {
        url
        label
      }
      productName
      loginMarqueeItems
      supportURL
      headerLogo
      bottomLeftLabel
      theme
      bottomRightLabel
      domain
      email {
        welcomeOutroText
        welcomeIntroText
        resetOutroText
        resetIntroText
        fromAddr
        resetSignatureText
        welcomeSignatureText
      }
    }
  }
`;

export const UserAccountFrag = gql`
  fragment UserAccountFields on Company {
    isReseller
    proxyPackage {
      enabled
      proxyType
      networkEnabled
      wafEnabled
      botEnabled
      enableHTTPSPacketInspection
    }
    bgpPackage {
      enabled
      routing
    }
    detectionAndAlertingPackage {
      enabled
    }
    apiPackage {
      enabled
      openHybridEnabled
    }
  }
`;
