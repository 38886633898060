import { createInstance, supports, INDEXEDDB } from 'localforage';

import { CryptoInstance, IsValidMeta } from '../helpers';
import { AsyncWebStorageShape, CryptoInstanceShape, CryptoMetaType } from '../interfaces/';
import { IsNull, IsString, Noop } from '../../../../utils/';

/**
 * Async Web Storage
 * @export
 * @class CoreAsyncWebStorage
 * @implements {AsyncWebStorageShape}
 */
export default class CoreAsyncWebStorage implements AsyncWebStorageShape {
  /**
   * Instance
   * @private
   * @type {LocalForage}
   * @memberof CoreAsyncWebStorage
   */
  private _instance: LocalForage = null;

  /**
   * Name
   * @private
   * @type {string}
   * @memberof CoreAsyncWebStorage
   */
  private _name: string = null!;

  /**
   * Store Name
   * @private
   * @type {string}
   * @memberof CoreAsyncWebStorage
   */
  private _storeName: string = null!;

  /**
   * Encrypted
   * @private
   * @type {boolean}
   * @memberof CoreAsyncWebStorage
   */
  private _encrypted: boolean = false;

  /**
   * Crypto Instance
   * @private
   * @type {CryptoInstanceShape}
   * @memberof CoreAsyncWebStorage
   */
  private crypto_instance: CryptoInstanceShape = new CryptoInstance();

  /**
   * Creates an instance of CoreAsyncWebStorage.
   * @param {string} name
   * @param {string} storeName
   * @param {boolean} encrypted
   * @param {string} driver
   * @memberof CoreAsyncWebStorage
   */
  constructor(name: string, storeName: string, encrypted: boolean = false, driver: string = INDEXEDDB) {
    this.ready = this.ready.bind(this);
    this.get = this.get.bind(this);
    this.set = this.set.bind(this);
    this.remove = this.remove.bind(this);
    this.init = this.init.bind(this);
    this.init(name, storeName, encrypted, driver);
  }

  /**
   * Make
   * @param {string} [key]
   * @return {*}  {Promise<void>}
   * @memberof CoreAsyncWebStorage
   */
  /* istanbul ignore next */
  public async make(key: string): Promise<void> {
    await this.ready();
    const { crypto_instance, encrypted, instance } = this;
    if (crypto_instance.cryptable && encrypted && key) {
      const meta: CryptoMetaType = await crypto_instance.make(key).catch(Noop);
      await instance.setItem('m', meta);
    }
    return void 0;
  }
  
  /**
   * Ready
   * @return {*}  {Promise<any>}
   * @memberof CoreAsyncWebStorage
   */
  public async ready(): Promise<void> {
    return this.instance?.ready();
  }
  
  /**
   * Get
   * @return {*}  {Promise<any>}
   * @memberof CoreAsyncWebStorage
   */
  public async get(): Promise<any> {
    try {
      const { crypto_instance, encrypted, instance } = this;

      let value = await instance?.getItem(this.storeName);

      /* istanbul ignore next */
      if (crypto_instance.cryptable && encrypted && !IsNull(value)) {
        if (!(value instanceof ArrayBuffer)) {
          throw new Error(`[${this.name}][${this.storeName}] decrypted value was malformed.`);
        }

        const meta: CryptoMetaType = await instance.getItem('m');

        if (!IsValidMeta(meta)) {
          throw new Error(`[${this.name}][${this.storeName}]:get() secure meta malformed.`);
        }

        value = await crypto_instance.decrypt(value, meta);
      }
      
      return value;

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreAsyncWebStorage.get: ${err.message || err}`);
      }
      /* istanbul ignore next */
      return Promise.reject(err);
    }
  }

  /**
   * Set
   * @param {CoreWebStorageValueType} value
   * @return {*}  {*}
   * @memberof CoreAsyncWebStorage
   */
  public async set(value: CoreWebStorageValueType): Promise<void> {
    try {
      const { crypto_instance, encrypted, instance } = this;

      /* istanbul ignore next */
      if (crypto_instance.cryptable && encrypted && IsString(value)) {
        const meta: CryptoMetaType = await instance.getItem('m');

        if (!IsValidMeta(meta)) {
          throw new Error(`[${this.name}][${this.storeName}]:set() secure meta malformed.`);
        }

        value = await crypto_instance.encrypt(value as string, meta);
        if (!(value instanceof ArrayBuffer)) {
          throw new Error(`[${this.name}][${this.storeName}] encrypted value was malformed.`);
        }
      }
      
      return instance?.setItem(this.storeName, value as any);

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreAsyncWebStorage.set: ${err.message || err}`);
      }
      /* istanbul ignore next */
      return Promise.reject(err);
    } 
  }

  /**
   * Remove
   * @return {*}  {Promise<void>}
   * @memberof CoreAsyncWebStorage
   */
  public async remove(): Promise<void> {
    const { crypto_instance, encrypted, instance } = this;
    /* istanbul ignore next */
    if (crypto_instance.cryptable && encrypted) {
      await instance?.removeItem('m');
    }
    await instance?.removeItem(this.storeName);
    return void 0;
  }

  /**
   * Init
   * @private
   * @param {string} name
   * @param {string} storeName
   * @param {boolean} encrypted
   * @param {string} driver
   * @return {*}  {Promise<void>}
   * @memberof CoreAsyncWebStorage
   */
  private async init(name: string, storeName: string, encrypted: boolean, driver: string): Promise<void> {
    try {

      /* istanbul ignore next */
      if (!name) {
        throw new Error(`Must supply 'name' argument.`);
      }

      /* istanbul ignore next */
      if (!storeName ) {
        throw new Error(`Must supply 'storeName' argument.`);
      }

      /* istanbul ignore next */
      if (!supports(driver)) {
        throw new Error(`This browser doesnt support storage driver [${driver}].`);
      }

      /* istanbul ignore next */
      if (encrypted && driver !== INDEXEDDB) {
        throw new Error(`eEncrypted data storage requires driver[INDEXEDDB].`);
      }

      /* istanbul ignore next */
      this._name = name;
      /* istanbul ignore next */
      this._storeName = storeName;
      /* istanbul ignore next */
      this._encrypted = encrypted;
      /* istanbul ignore next */
      this._instance = createInstance({ name, driver, storeName });
      
      /* istanbul ignore next */
      await this.instance.ready();
      /* istanbul ignore next */
      const test: string = 'test';
      /* istanbul ignore next */
      await this.instance.setItem(test, test);
      /* istanbul ignore next */
      const result = await this.instance.getItem(test);
      /* istanbul ignore next */
      this.instance.removeItem(test);

      /* istanbul ignore next */
      if (result !== test) {
        throw new Error(`Please use a browser that is configured to accept Storage[${driver}] and has Private Browsing disabled.`);
      }
 
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreAsyncWebStorage.init: ${err.message || err}`);
      }
    }
    return void 0;
  }

  /**
   * Name - getter
   * @readonly
   * @memberof CoreAsyncWebStorage
   */
  public get name() {
    return this._name;
  }

  /**
   * Store Name - getter
   * @readonly
   * @private
   * @memberof CoreAsyncWebStorage
   */
  private get storeName() {
    return this._storeName;
  }

  /**
   * Instance - getter
   * @readonly
   * @private
   * @memberof CoreAsyncWebStorage
   */
  private get instance() {
    return this._instance;
  }

  /**
   * Encrypted - getter
   * @readonly
   * @private
   * @memberof CoreAsyncWebStorage
   */
  private get encrypted() {
    return this._encrypted;
  }
}
