import React, { PureComponent } from 'react';

import CoreFormContext from '../../../contexts/Form';
import CoreFormMenuContext from '../../../contexts/Menu';

import { ControllerMenuContainer } from './Menu/';

import { CoreFormServiceType, CoreFormValueType, CoreFormOptionsType } from '../../../types';
import { ControllerMenuProps, CoreFormMenuInstanceShape } from '../../../interfaces/';
import { CoreFormEventsEnum as E } from '../../../enums/';
import { CoreFormMenuInstance } from '../../../services/';
import { WaitForDelay } from '../../../../../../utils/';

/**
 * Controller Menu
 * @export
 * @class ControllerMenu
 * @extends {PureComponent<ControllerMenuProps, {}>}
 */
export default class ControllerMenu extends PureComponent<ControllerMenuProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof ControllerMenu
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Menu
   * @protected
   * @type {CoreFormMenuInstanceShape}
   * @memberof ControllerMenu
   */
  protected menu: CoreFormMenuInstanceShape;

  /**
   * Creates an instance of ControllerMenu.
   * @param {ControllerMenuProps} props
   * @param {CoreFormServiceType} context
   * @memberof ControllerMenu
   */
  constructor(props: ControllerMenuProps, context: CoreFormServiceType) {
    super(props);
    this.init(context.id, props);
  }

  /**
   * LifeCycle Hook
   * @memberof ControllerMenu
   */
  public componentDidMount() {
    const { FormEventResetListener, ControllerEventUpdateListener, context, menu: { controller } } = this;
    controller.emitter.on(E.CONTROLLER_EVENT_UPDATE, ControllerEventUpdateListener);
    context.emitter.on(E.FORM_EVENT_RESET, FormEventResetListener);
  }

  /**
   * LifeCycle Hook
   * @memberof ControllerMenu
   */
  public componentWillUnmount() {
    const { FormEventResetListener, ControllerEventUpdateListener, context, menu: { controller } } = this;
    controller.emitter.off(E.CONTROLLER_EVENT_UPDATE, ControllerEventUpdateListener);
    context.emitter.off(E.FORM_EVENT_RESET, FormEventResetListener);
  }

  /**
   * Render
   * @returns
   * @memberof ControllerMenu
   */
  public render() {
    const { menu, props } = this;
    return (
      <CoreFormMenuContext.Provider value={menu}>
        <ControllerMenuContainer {...props} />
      </CoreFormMenuContext.Provider>
    );
  }

  /**
   * Form Event Reset Listener
   * @protected
   * @memberof ControllerMenu
   */
  /* istanbul ignore next */
  protected FormEventResetListener = (): void => {
    const { menu } = this;
    WaitForDelay().then(() => {
      menu.reset();
    });
  };

  /**
   * Form Event Update Listener
   * @protected
   * @param {string} name
   * @param {CoreFormValueType} value
   * @param {CoreFormOptionsType} options
   * @memberof ControllerMenu
   */
  /* istanbul ignore next */
  protected ControllerEventUpdateListener = (name: string, value: CoreFormValueType, options: CoreFormOptionsType): void => {
    const { menu } = this;
    WaitForDelay().then(() => {
      menu.value = value as any;
      menu.emit();
    });
  };

  /**
   * Init
   * @protected
   * @param {string} id
   * @param {ControllerMenuProps} { controller, filter, allornone, required, disabled }
   * @memberof ControllerMenu
   */
  protected init = (id: string, { controller, filter, allornone, required, disabled }: ControllerMenuProps): void => {
    this.menu = new CoreFormMenuInstance(id, controller, filter, allornone, required, disabled);
  };
}
