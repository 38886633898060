export const COUNTER_BADGE_PROPS = {
  count: 0,
};

export const BADGE_PROPS = {
  type: 'default',
  outline: false
};

export const DELAYED_LINK_PROPS = {
  link: '',
  selector: '',
  add: '',
  delay: 0,
};

export const SECTION_HEADER_PROPS = {
  type: '',
};

export const SECTION_HEADER_STATE = {
  label: '',
  items: [] as any,
};

export const LISTENER_PROPS = {
  base: document.body,
  event: 'click',
  listener: (evt: Event) => {},
};
