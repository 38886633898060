import React, { Component, Fragment } from 'react';

import { CoreNavigate } from '../../../Router/';

import CoreBootMainContext from '../context';
import { AUTH_ROUTE_PATH } from '../constants';

/**
 * Boot Base Container
 * @export
 * @class CoreBootBase
 * @extends {Component<CoreRouteProps, S>}
 * @template S
 */
export default class CoreBootBase<S = {}> extends Component<CoreRouteProps, S> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreBootMainContext>}
   * @memberof CoreBootBase
   */
  public static contextType: Readonly<typeof CoreBootMainContext> = CoreBootMainContext;

  /**
   * Render
   * @returns
   * @memberof CoreBootBase
   */
  public render() {
    console.warn('must extend in sub-class.');
    return <Fragment />;
  }

  /**
   * Go Base
   * @protected
   * @memberof CoreBootBase
   */
  /* istanbul ignore next */
  protected GoBase = (): void => {
    CoreNavigate(AUTH_ROUTE_PATH, { replace: true });
  };
}
