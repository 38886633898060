import { SentenceCap, WaitForDelay } from '../../../../utils/';

/**
 * Graph Error Handler
 * @async
 * @export
 * @param {*} errors
 * @returns {Promise<any>}
 */
export async function CoreGraphErrorHandler(errors: any): Promise<any> {
  const { graphQLErrors } = errors;

  if (graphQLErrors && graphQLErrors.length) {
    let i: number = graphQLErrors.length;
    let error: any;
    while(i--) {
      error = graphQLErrors[i];
      /* istanbul ignore next */
      if (error.extensions && error.extensions.code === 'UNAUTHENTICATED') {
        return null;
      }
    }
  }

  return WaitForDelay().then(() => {
    /* istanbul ignore next */
    return (window as any).alert(SentenceCap(errors.message.replace('GraphQL error: ', '')));
  });
}
