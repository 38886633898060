import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { CoreDialogsProps } from '../interfaces/';
import { DIALOGS_PROPS } from '../constants';

import CoreDialogFooterContainer from './Footer';

/**
 * Dialogs Container
 * @export
 * @class CoreDialogsContainer
 * @extends {Component<GenericPropType, {}>}
 */
export default class CoreDialogsContainer extends Component<CoreDialogsProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreDialogsProps}
   * @memberof CoreDialogsContainer
   */
  public static defaultProps: CoreDialogsProps = cloneDeep(DIALOGS_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreDialogsContainer
   */
  public render() {
    const { dialog } = this.props;

    if (!dialog) {
      return (
        <article>
          <header />
          <section />
        </article>
      );
    }

    const { mode, title, content } = dialog;

    return (
      <article className={`mode-${mode}`}>
        <header>{title ? <h4>{title}</h4> : null}</header>
        <section>
          <div>{content}</div>
          <CoreDialogFooterContainer dialog={dialog} />
        </section>
      </article>
    );
  }
}
