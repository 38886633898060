
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_FILE } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * File Input Element
 * @export
 * @class CoreFormElementFile
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementFile extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementFile
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_FILE
  });
}
