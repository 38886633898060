import EventEmitter from 'events';

import { CoreFormControllerInstanceType } from '../types';
import { CoreFormMenuInstanceShape, CoreFormOptionShape } from '../interfaces/';
import { MenuSyncSelectElement } from '../factories/';
import { CoreFormEventsEnum as E } from '../enums/';
import { IsDefined, IsString } from '../../../../utils/';

/**
 * Menu Instance
 * @export
 * @class CoreFormMenuInstance
 * @implements {CoreFormMenuInstanceShape}
 */
export class CoreFormMenuInstance implements CoreFormMenuInstanceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreDialogService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Form ID
   * @private
   * @type {string}
   * @memberof CoreFormMenuInstance
   */
  private _formid: string;

  /**
   * Name
   * @private
   * @type {string}
   * @memberof CoreFormMenuInstance
   */
  private _controller: CoreFormControllerInstanceType;

  /**
   * Value
   * @private
   * @type {any[]}
   * @memberof CoreFormMenuInstance
   */
  private _value: any[] = [];

  /**
   * Active
   * @private
   * @type {boolean}
   * @memberof CoreFormMenuInstance
   */
  private _active: boolean = false;

  /**
   * Term
   * @private
   * @type {string}
   * @memberof CoreFormMenuInstance
   */
  private _term: string = '';

  /**
   * Filter
   * @private
   * @type {boolean}
   * @memberof CoreFormMenuInstance
   */
  private _filter: boolean = false;

  /**
   * All Or None
   * @private
   * @type {boolean}
   * @memberof CoreFormMenuInstance
   */
  private _allornone: boolean = false;

  /**
   * Required
   * @private
   * @type {boolean}
   * @memberof CoreFormMenuInstance
   */
  private _required: boolean = false;

  /**
   * Disabled
   * @private
   * @type {boolean}
   * @memberof CoreFormMenuInstance
   */
  private _disabled: boolean = false;

  /**
   * Creates an instance of CoreFormMenuInstance.
   * @param {string} formid
   * @param {CoreFormControllerInstanceType} controller
   * @param {boolean} filter
   * @param {boolean} allornone
   * @param {boolean} required
   * @param {boolean} disabled
   * @memberof CoreFormMenuInstance
   */
  constructor(formid: string, controller: CoreFormControllerInstanceType, filter: boolean, allornone: boolean, required: boolean, disabled: boolean) {
    this._emitter.setMaxListeners(7);
    this.init(formid, controller, filter, allornone, required, disabled);
  }

  /**
   * Emit
   * @param {...any[]} args
   * @memberof CoreFormControllerInstance
   */
  public emit = (): void => {
    const { value, options, active, term } = this;
    this._emitter.emit(E.MENU_EVENT_UPDATE, value, options, active, term);
  };

  /**
   * Reset
   * @memberof CoreFormMenuInstance
   */
  public reset = (): void => {
    const { commit, emit, controller: { value } } = this;
    this._term = '';
    this.value = value as any;

    /**
     * Apply Value to Form
     */
    commit();

    /**
     * Emit Menu
     */
    emit();
  };

  /**
   * Toggle Active
   * @memberof CoreFormMenuInstance
   */
  public ToggleActive = (): void => {
    const { active, emit } = this;
    this.active = !active;
    emit();
  };

  /**
   * Toggle All Or None
   * @memberof CoreFormMenuInstance
   */
  public ToggleAllOrNone = (): void => {
    const { commit, emit, value, options } = this;

    /**
     * Apply options to value.
     */
    if (value.length === options.length) {
      this._value = [];
    } else {
      this._value = options.slice(0).map(({ value }: CoreFormOptionShape) => value);
    }

    /**
     * Apply Value to Form
     */
    commit();

    /**
     * Emit Menu
     */
    emit();
  };

  /**
   * Select Value
   * @param {string | number | boolean | readonly string[]} candidate
   * @memberof CoreFormMenuInstance
   */
  public SelectValue = (candidate: string | number | boolean | readonly string[]): void => {
    const { transform, commit, emit } = this;

    /**
     * Clear Term
     */
    this._term = '';

    /**
     * Set Properly Typed Value
     */
    transform(candidate);

    /**
     * Apply Value to Form
     */
    commit();

    /**
     * Emit Menu
     */
    emit();
  };

  /**
   * Value Selected
   * @param {string | number | boolean | readonly string[]} VALUE
   * @memberof CoreFormMenuInstance
   */
  public ValueSelected = (candidate: string | number | boolean | readonly string[]): boolean => {
    const { value } = this;
    return !!~value.indexOf(candidate);
  };

  /**
   * Commit
   * @private
   * @memberof CoreFormMenuInstance
   * @notes
   * Why do it this way?
   *
   * Of all the elements we use, only options have the element obfuscated.
   *
   * Reason: Options are ugly & inconsistently implemented by browsers and CSS engines.
   *
   * In its place we put a cross browser verified menu HOC.
   *
   * Doing it this way means we lose the native element events which
   * drive all of the changes/updates to controller instances and form services.
   *
   * Here we reach in & programatically call an event on the hidden select menus.
   *
   * For mobile devices: the css hides the menu, allowing native options to be visible/actionable.
   */
  private commit = (): void => {
    const { formid, name } = this;

    try {
      const form = document.getElementById(formid);

      /* istanbul ignore next */
      if (!form) {
        throw new ReferenceError(`CoreFormMenuInstance:: No form in document registered as form[id="${formid}"].`);
      }

      const select: HTMLSelectElement = form.querySelector(`select[name="${name}"]`);

      /* istanbul ignore next */
      if (!select) {
        throw new ReferenceError(`CoreFormMenuInstance:: Save Failed. Cannot find select[name="${name}"].`);
      }

      /**
       * Shallow copy values
       */
      let value: any[] = this.value.slice(0);

      /**
       * Switch to strings if necessary
       */
      /* istanbul ignore next */
      if (value.length) {
        value = value.map((v: CoreFormValueType) => IsString(v) ? v : String(v));
      }

      /**
       * Sync Menu Options
       */
      MenuSyncSelectElement(select, value);

      /**
       * Dispatch Event as if we had directly modified.
       */
      select.dispatchEvent(new Event('change', { bubbles: true }));

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  };

  /**
   * Selection Transform
   * @private
   * @memberof CoreFormMenuInstance
   */
  private transform = (candidate: any): void => {
    const { single } = this;

    if (single) {
      this._value = [candidate];
      this.active = false;
    } else {
      const index: number = this.value.indexOf(candidate);
      if (!!~index) {
        this._value.splice(index, 1);
      } else {
        this._value.push(candidate);
      }
    }
  };

  /**
   * Init
   * @private
   * @param {string} formid
   * @param {CoreFormControllerInstanceType} controller
   * @param {boolean} [filter=false]
   * @param {boolean} [allornone=false]
   * @param {boolean} [required=false]
   * @param {boolean} [disabled=false]
   * @memberof CoreFormMenuInstance
   */
  private init = (formid: string, controller: CoreFormControllerInstanceType, filter: boolean = false, allornone: boolean = false, required: boolean = false, disabled: boolean = false): void => {
    try {

      /* istanbul ignore next */
      if (!controller) {
        throw new ReferenceError('Menu requires a `controller` prop.');
      }

      const single: boolean = controller.type !== 'multiple';

      /**
       * All or None applies only to multiple selectors,
       */
      /* istanbul ignore next */
      if (single && allornone) {
        throw new ReferenceError(`Select type[:not(multiple)] may not have prop[allornone=true]`);
      }

      this._formid = formid;
      this._controller = controller;
      this._filter = filter;

      this.allornone = allornone;
      this.required = required;
      this.disabled = disabled;
      this.value = controller.value;

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  };

  /**
   * Emitter - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get emitter() {
    return this._emitter;
  }

  /**
   * Form ID - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get formid() {
    return this._formid;
  }

  /**
   * Name - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get name() {
    return this.controller.name;
  }

  /**
   * Single - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get single() {
    return this.controller.type !== 'multiple';
  }

  /**
   * Active - getter
   * @memberof CoreFormMenuInstance
   */
  public get active() {
    return this._active;
  }

  /**
   * Active - setter
   * @memberof CoreFormMenuInstance
   */
  public set active(active: boolean) {
    this._active = active;
  }

  /**
   * Filter - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get filter() {
    return this._filter;
  }

  /**
   * All Or None - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get allornone() {
    return this._allornone;
  }

  /**
   * All Or None - setter
   * @memberof CoreFormMenuInstance
   */
  public set allornone(allornone: boolean) {
    this._allornone = this.single ? false : allornone;
  }

  /**
   * Required - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  /* istanbul ignore next */
  public get required() {
    return this._required;
  }

  /**
   * Required - setter
   * @memberof CoreFormMenuInstance
   */
  public set required(required: boolean) {
    this._required = required;
  }

  /**
   * Disabled - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  /* istanbul ignore next */
  public get disabled() {
    return this._disabled;
  }

  /**
   * Disabled - setter
   * @memberof CoreFormMenuInstance
   */
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  /**
   * Controller - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get controller() {
    return this._controller;
  }

  /**
   * Options - getter
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get options() {
    const { term, controller: { options } } = this;
    const TERM = String(term).toLowerCase();
    let LABEL: string;
    const compare: any = ({ label }: CoreFormOptionShape): boolean => {
      LABEL = String(label).toLowerCase();
      return !!~LABEL.indexOf(TERM);
    };
    if (Array.isArray(options)) {
      return options.slice(0).filter(compare);
    }
    /* istanbul ignore next */
    return options;
  }

  /**
   * Term - getter
   * @memberof CoreFormMenuInstance
   */
  public get term() {
    return this._term;
  }

  /**
   * Term - setter
   * @memberof CoreFormMenuInstance
   */
  public set term(term: string) {
    this._term = term;
  }

  /**
   * Value - getter;
   * @readonly
   * @memberof CoreFormMenuInstance
   */
  public get value() {
    return this._value;
  }

  /**
   * Value - setter
   * @memberof CoreFormMenuInstance
   */
  public set value(value: any) {
    this._value = Array.isArray(value) ? value : !IsDefined(value) ? [] : [value];
  }
}
