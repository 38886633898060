import { Component, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';

import CoreInteropContext from '../../Interop/context';
import { CoreHeaderMenusMask } from '../../Interop/enums/';
import { CoreHeaderMenusState } from '../interfaces/';
import { HEADER_MENUS_STATE } from '../constants';

/**
 * Menu Component Base
 * @export
 * @class CoreMenuComponentBase
 * @extends {Component<{}, CoreHeaderMenusState>}
 */
export default class CoreMenuComponentBase extends Component<GenericPropType, CoreHeaderMenusState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreMenuComponentBase
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * Default Props
   * @static
   * @type {GenericPropType}
   * @memberof CoreMenuComponentBase
   */
  public static defaultProps: GenericPropType = {};

  /**
   * State
   * @type {CoreHeaderMenusState}
   * @memberof CoreMenuComponentBase
   */
  public readonly state: CoreHeaderMenusState = cloneDeep(HEADER_MENUS_STATE);

  /**
   * STATE
   * @protected
   * @static
   * @type {number}
   * @memberof CoreMenuComponentBase
   */
  protected STATE: number = CoreHeaderMenusMask.OFF;

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreMenuComponentBase
   */
  private subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreMenuComponentBase
   */
  public componentDidMount() {
    const { context } = this;
    this.subscriber = context.subscribe(this.MenuBaseListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreMenuComponentBase
   */
  public componentWillUnmount() {
    const { context, subscriber } = this;
    context.unsubscribe(subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreMenuComponentBase
   */
  public render() {
    console.error(new TypeError('must override render in sub class'));
    return null;
  }

  /**
   * Menu Base Listener
   * @memberof CoreMenuComponentBase
   */
  public MenuBaseListener = (): void => {
    const value = this.context.menus;
    if (value !== this.state.value) {
      this.setState({ value });
    }
  };

  /**
   * On Menu Click
   * @protected
   * @memberof CoreMenuComponentBase
   */
  protected OnMenuClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    const {
      STATE,
      context,
      state: { value },
    } = this;
    context.menus = value === STATE ? CoreHeaderMenusMask.OFF : STATE;
    context.emit();
  };
}
