import React, { PureComponent } from 'react';

// import { Query } from '@apollo/client/react/components/';

import CoreNotificationsContext from './context';
import CoreNotificationsService from './service';
import { CoreNotificationsGraphStatic } from './graph/';

import { CoreGraphQuery } from '../shared/modules/graph/';

/**
 * Notifications Module
 * @export
 * @class CoreNotificationsModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNotificationsModule extends PureComponent<{}, {}> {
  /**
   * Tick
   * @protected
   * @type {number}
   * @memberof CoreNotificationsModule
   */
  protected tick: number = 0;

  /**
   * Service
   * @protected
   * @memberof CoreNotificationsModule
   */
  protected service = new CoreNotificationsService();

  /**
   * LifeCycle Hook
   * @memberof CoreNotificationsModule
   */
  public componentWillUnmount() {
    this.service.stop();
  }

  /**
   * Render
   * @returns
   * @memberof CoreNotificationsModule
   */
  public render() {
    const { service } = this;

    const query = CoreNotificationsGraphStatic.NOTIFICATIONS_QUERY;

    return (
      <CoreNotificationsGraphStatic.Provider>
        <CoreGraphQuery query={query} skip={true}>
          {(graph: any) => {
            service.init(graph);
            return (
              <CoreNotificationsContext.Provider value={service}>
                {this.props.children}
              </CoreNotificationsContext.Provider>
            );
          }}
        </CoreGraphQuery>
      </CoreNotificationsGraphStatic.Provider>
    );
  }
}
