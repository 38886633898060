import { ReactElement } from 'react';
import EventEmitter from 'events';

import { CoreTabItemProps, CoreTabsServiceShape } from './interfaces/';

import { TABS_EVENT_CHANGE } from './constants';

import { WaitForDelay } from '../../../utils/';

/**
 * Tabs Service
 * @export
 * @class CoreTabsService
 * @implements {CoreTabsServiceShape}
 */
export default class CoreTabsService implements CoreTabsServiceShape {
    /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreDialogService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Items
   * @private
   * @type {Map<string, ReactElement<CoreTabItemProps>>}
   * @memberof CoreTabsService
   */
  private _items: Map<string, ReactElement<CoreTabItemProps>> = new Map<string, ReactElement<CoreTabItemProps>>();

  /**
   * Current
   * @private
   * @type {string}
   * @memberof CoreTabsService
   */
  private _current: string;

  /**
   * Marked
   * @private
   * @type {Array<string>}
   * @memberof CoreTabsService
   */
  private _marked: Array<string> = [];

  /**
   * Emit
   * @memberof CoreTabsService
   */
  public emit(...args: any[]): void {
    WaitForDelay().then(() => {
      this._emitter.emit(TABS_EVENT_CHANGE, ...args);
    });
  }

  /**
   * Subscribe
   * @param {Func<void, any[]>} listener
   * @memberof CoreTabsService
   */
  public subscribe(listener: Func<void, any[]>): void {
    this._emitter.on(TABS_EVENT_CHANGE, listener);
  }

  /**
   * Unsubscribe
   * @param {Func<void, any[]>} listener
   * @memberof CoreTabsService
   */
  public unsubscribe(listener: Func<void, any[]>): void {
    this._emitter.off(TABS_EVENT_CHANGE, listener);
  }

  /**
   * Items - getter
   * @readonly
   * @memberof CoreTabsService
   */
  public get items() {
    return this._items;
  }

  /**
   * Current - getter
   * @readonly
   * @memberof CoreTabsService
   */
  public get current() {
    return this._current;
  }

  /**
   * Current - setter
   * @memberof CoreTabsService
   */
  public set current(current: string) {
    this._current = current;
  }

  /**
   * Marked - getter
   * @readonly
   * @memberof CoreTabsService
   */
  public get marked() {
    return this._marked;
  }

  /**
   * Marked - setter
   * @memberof CoreTabsService
   */
  public set marked(marked: Array<string>) {
    this._marked = marked;
  }
}
