import { ComponentClass } from 'react';

import SelectBase from './Base';
import { CoreFormElementSelectMultiple } from '../../../components/';

/**
 * Select Multiple Controller
 * @export
 * @class SelectMultipleController
 * @extends {SelectBase}
 */
export default class SelectMultipleController extends SelectBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof SelectMultipleController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementSelectMultiple;
}
