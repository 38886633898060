import { CoreConfigLinkShape } from '../interfaces/';

export const FOOTER_LINK_LEGAL = {
  label: 'Legal',
  url: 'https://vercara.com/legal',
  title: 'Legal Notice'
};

export const FOOTER_LINK_PRIVACY = {
  label: 'Privacy',
  url: 'https://vercara.com/privacy-policy',
  title: 'Privacy Center'
};

export const FOOTER_LINKS: CoreConfigLinkShape[] = [
  FOOTER_LINK_LEGAL,
  FOOTER_LINK_PRIVACY
];

export const CONTACT_INFORMATION: string = '24x7 Customer Support:+1.844.929.0808|International:+1.540.835.5462';


