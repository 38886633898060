import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions Read Only
 * @export
 * @class CorePermissionsReadOnly
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsReadOnly extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsReadOnly
   */
  public render() {
    const {
      service: { IsReadOnly },
      props: { children },
    } = this;
    return IsReadOnly ? <Fragment>{children}</Fragment> : null;
  }
}
