import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_USERS = gql`
  query GetUsers(
    $company: CompanyFilterInput,
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 100
  ) {
    company(filter: $company) {
      id
      users(filter: { includeDisabled: true }, page: $page, perPage: $perPage) {
        results {
          id
          enabled
          userName
          email
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_USER_LIST = gql`
  query GetUserList(
    $company: CompanyFilterInput,
    $users: CompanyUsersFilterInput = { emailSubstring: null, userNameSubstring: null, includeDisabled: true, includeDescendants: false }, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      dName
      users(filter: $users, page: $page, perPage: $perPage) {
        results {
          id
          userName
          email
          lastLogin
          enabled
          company {
            id
            dName
            corporateName
            isReseller
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;


// export const GET_USERS_REST = gql`
//   query GetUsersRest {
//     users @rest(type: "Users", path: "/users", endpoint: "api", method: "GET") {
//       data
//     }
//   }
// `;
