import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from './context';

import { CoreEnsureTableStyle } from './factories/';
import { CoreTableProps } from './interfaces/';
import { CoreTableService } from './services/';
import { TABLE_PROPS } from './constants';

/**
 * Core Table Container
 * @export
 * @class CoreTableModule
 * @extends {Component<CoreTableProps, {}>}
   @description
A variety of tabular data displays
   @options
CoreTableProps ../interfaces/table.ts
   @example
<CoreTable source={[{...}, {...}]}>
  ...
</CoreTable>
 */
export default class CoreTableModule extends Component<CoreTableProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreTableProps}
   * @memberof CoreTableModule
   */
  public static defaultProps: CoreTableProps = cloneDeep(TABLE_PROPS);

  /**
   * Table Service
   * @private
   * @type {CoreTableServiceType}
   * @memberof CoreTableModule
   */
  private service: CoreTableServiceType = new CoreTableService();

  /**
   * Creates an instance of CoreTableModule.
   * @param {CoreTableProps} props
   * @memberof CoreTableModule
   */
  constructor(props: CoreTableProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableModule
   */
  public render() {
    const { props, service } = this;
    const { source, mode, className, children, ...rest } = props;

    service.use(source, mode);

    return (
      <CoreTableContext.Provider value={service}>
        <table className={CoreEnsureTableStyle(className)} {...rest}>
          {children}
        </table>
      </CoreTableContext.Provider>
    );
  }

  /**
   * Init
   * @protected
   * @param {CoreTableProps} props
   * @memberof CoreTableModule
   */
  protected init = ({ source, mode }: CoreTableProps): void => {
    if (source instanceof CoreTableService) {
      this.service = source;
    } else {
      this.service = new CoreTableService();
    }
  };
}
