import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import CoreDialogPromptWrapper from './Prompt';
import { CoreDialogContentProps } from '../interfaces/';

import { DIALOG_CONTENT_PROPS, DIALOG_TYPE_PROMPT } from '../constants';

/**
 * Dialog Content Wrapper
 * @export
 * @class CoreDialogContentWrapper
 * @extends {Component<CoreDialogContentProps, {}>}
 */
export default class CoreDialogContentWrapper extends Component<CoreDialogContentProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreDialogContentProps}
   * @memberof CoreDialogContentWrapper
   */
  public static defaultProps: CoreDialogContentProps = cloneDeep(DIALOG_CONTENT_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreDialogContentWrapper
   */
  public render() {
    const { message, value, dialog } = this.props;
    const { type } = dialog;

    switch (type) {
      case DIALOG_TYPE_PROMPT:
        dialog.source = { value };
        return <CoreDialogPromptWrapper message={message} value={value} dialog={dialog} />;
      default:
        return <var dangerouslySetInnerHTML={{ __html: message }} />;
    }
  }
}
