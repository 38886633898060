import { ClickTypeEnum } from '../enums/';

/**
 * Core Click Counter
 * @export
 * @class CoreClickCounter
 */
export class CoreClickCounter {
  /**
   * Tolerance
   * @private
   * @type {number}
   * @memberof CoreClickCounter
   */
  private _tolerance: number;

  /**
   * Type
   * @private
   * @type {ClickTypeEnum}
   * @memberof CoreClickCounter
   */
  private _type: ClickTypeEnum = ClickTypeEnum.SINGLE;

  /**
   * Last Now
   * @private
   * @type {number}
   * @memberof CoreClickCounter
   */
  private _last: number;

  /**
   * Creates an instance of CoreClickCounter.
   * @param {number} [tolerance=300]
   * @memberof CoreClickCounter
   */
  constructor(tolerance: number = 300) {
    this._tolerance = tolerance;
  }

  /**
   * Update
   * @memberof CoreClickCounter
   */
  public update(): CoreClickCounter {
    const { last, tolerance } = this;
    const now = Date.now();
    if (!this._last) {
      this._last = now;
    } else {
      this._type =  (now - last < tolerance) ? ClickTypeEnum.DOUBLE : ClickTypeEnum.SINGLE;
      this._last = now;
    }
    return this;
  }

  /**
   * Single - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreClickCounter
   */
  public get single(): boolean {
    return this._type === ClickTypeEnum.SINGLE;
  }

  /**
   * Double - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreClickCounter
   */
  public get double(): boolean {
    return this._type === ClickTypeEnum.DOUBLE;
  }

  /**
   * Tolerance - getter
   * @readonly
   * @private
   * @memberof CoreClickCounter
   */
  private get tolerance () {
    return this._tolerance;
  }

  /**
   * Last - getter
   * @readonly
   * @private
   * @memberof CoreClickCounter
   */
  private get last () {
    return this._last;
  }

}
