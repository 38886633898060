import { cloneDeep } from 'lodash';
import { CoreUserInfoShape, CoreUserInfoAppMetadataShape } from '../interfaces/';

export const USER_ACCOUNT_DEFAULT = 'SP_NEUSTAR';

export const APP_METATDATA_PERMISSIONS_DEFAULT = { accounts: null, siteprotect: null };
export const APP_METATDATA_DEFAULT: CoreUserInfoAppMetadataShape = { enabled: false, shortname: '', reseller: false, permissions: APP_METATDATA_PERMISSIONS_DEFAULT };

export const USERINFO_DEFAULT: CoreUserInfoShape = {
  uid: '',
  name: '',
  email: '',
  nickname: '',
  picture: '',
  sub: '',
  updated_at: '',
  family_name: '',
  given_name: '',
  nsr: { dnm: '', uid: '' },
  app_metadata: cloneDeep(APP_METATDATA_DEFAULT),
  user_metadata: { first_name: '', last_name: '' }
};
