import React, { PureComponent } from 'react';

import CoreBusyWrapper from './Wrapper';

import { CoreBusyComponent } from './components/';

/**
 * Busy Module
 * @export
 * @class CoreBusyModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBusyModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBusyModule
   */
  public render() {
    return (
      <CoreBusyWrapper>
        <CoreBusyComponent />
      </CoreBusyWrapper>
    );
  }
}
