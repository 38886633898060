import React from 'react';

import CoreNotificationsBase from './Base';

import { CoreCounterBadge } from '../../shared/';

/**
 * Notification Count Badge Component
 * @export
 * @class CoreNotificationsCountBadgeComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNotificationsCountBadgeComponent extends CoreNotificationsBase {
  /**
   * Render
   * @returns
   * @memberof CoreNotificationsCountBadgeComponent
   */
  public render() {
    const { context: { count } } = this;
    return <CoreCounterBadge count={count} />;
  }
}
