import React from 'react';
import { CoreGraphMutation } from '../../shared/modules/graph/';

import { Interop } from '../../Interop/';
import CoreNotificationsBase from './Base';

import { CoreNotificationsGraphStatic } from '../graph/';
import { CoreAnchor } from '../../shared/';
import { WaitForDelay } from '../../utils/';

/**
 * Notifications Menu Mark As Read Component
 * @export
 * @class CoreNotificationsMenuMarkAsReadComponent
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class CoreNotificationsMenuMarkAsReadComponent extends CoreNotificationsBase {
  /**
   * Render
   * @returns
   * @memberof CoreNotificationsMenuMarkAsReadComponent
   */
  public render() {
    const { context: { count, collection } } = this;

    const mutation = CoreNotificationsGraphStatic.NOTIFICATIONS_MUTATION;
    const TEST_MODE = CoreNotificationsGraphStatic.TEST_MODE;
    const resolver = CoreNotificationsGraphStatic.MUTATION_VARIABLES_RESOLVER;
    /* grab variables for mutation */
    const variables = resolver(collection);
    return (
      <dt>
        Notifications
        {count ? (
          <CoreGraphMutation mutation={mutation} variables={variables}>
            {(MutationFn: any) => {
              /* istanbul ignore next */
              let MarkAllAsRead = MutationFn;

              /* istanbul ignore next */
              if (TEST_MODE) {
                MarkAllAsRead = () => Promise.reject(new Error('test mode.'));
              }

              /* istanbul ignore next */
              return (
                <CoreAnchor
                  onClick={() => {
                    MarkAllAsRead().then(() => {
                      Interop.menus = 0;
                      Interop.emit();
                      this.context.count = 0;
                      this.context.collection = [];
                      this.context.emit();
                      WaitForDelay(500).then(() => {
                        this.context.start();
                      });
                    }).catch((err: Error) => {
                      if (process.env.NODE_ENV !== 'production') {
                        console.error(err.message||err);
                      }
                    });
                  }}
                  className="float-right"
                >
                  Mark as read
                </CoreAnchor>
              );
            }}
          </CoreGraphMutation>
        ) : null}
      </dt>
    );
  }
}
