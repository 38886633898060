import EventEmitter from 'events';

import { CoreNotificationsGraphStatic } from './graph/';
import { CoreNotificationsServiceShape, CoreNotificationMenuItemShape } from './interfaces/';
import { NOTIFICATIONS_UPDATE_EVENT } from './constants';
import { CoreGraphDataFactory } from '../shared/modules/graph/';
import { GetDeepHashMap } from '../utils';

/**
 * Notifications Service
 * @export
 * @class CoreNotificationsService
 * @implements {CoreNotificationsServiceShape}
 */
export default class CoreNotificationsService implements CoreNotificationsServiceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreNotificationsService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Graph
   * @protected
   * @type {CoreGraphResultType}
   * @memberof CoreNotificationsService
   */
  private _graph: any;

  /**
   * Count
   * @private
   * @type {number}
   * @memberof CoreNotificationsService
   */
  private _count: number = 0;

  /**
   * Collection
   * @private
   * @type {Array<any>}
   * @memberof CoreNotificationsService
   */
  private _collection: Array<CoreNotificationMenuItemShape> = [];

  /**
   * Subscription
   * @private
   * @type {*}
   * @memberof CoreNotificationsService
   */
  private subscription: any = null

  /**
   * Init
   * @param {any} graph
   * @memberof CoreNotificationsService
   */
  public init = (graph: any): void => {
    this._graph = graph;
    this.start();
  }

  /**
   * Start
   * @private
   * @memberof CoreNotificationsService
   */
  private start() {
    this.stop();
    const query = CoreNotificationsGraphStatic.NOTIFICATIONS_QUERY;
    const pollInterval: number = CoreNotificationsGraphStatic.INTERVAL;
    const count_path: string = CoreNotificationsGraphStatic.COUNT_PATH;
    const results_path: string = CoreNotificationsGraphStatic.RESULTS_PATH;
    const fetchPolicy = 'network-only';

    /* istanbul ignore next */
    this.subscription = this.graph.client.watchQuery({ query, pollInterval, fetchPolicy }).subscribe((response: any) => {
      const res = CoreGraphDataFactory(response, results_path, { count: 0, results: []}) as HashMap<any>;
      this.count = GetDeepHashMap(res, count_path, 0);
      this.collection = res.results;
      this.emit();
    });
  }

  /**
   * Stop
   * @private
   * @memberof CoreNotificationsService
   */
  public stop = (): void => {
    if (this.subscription) {
      /* istanbul ignore next */
      this.subscription.unsubscribe();
    }
  }

  /**
   * Subscribe
   * @param {Func<void>} callback
   * @param {string} [event=NOTIFICATIONS_UPDATE_EVENT]
   * @memberof CoreNotificationsService
   */
  /* istanbul ignore next */
  public subscribe(callback: Func<void>, event: string = NOTIFICATIONS_UPDATE_EVENT): Func<void> {
    this._emitter.on(event, callback);
    return callback;
  }

  /**
   * Unsubscribe
   * @param {Func<void>} callback
   * @param {string} [event=NOTIFICATIONS_UPDATE_EVENT]
   * @memberof CoreNotificationsService
   */
  /* istanbul ignore next */
  public unsubscribe(callback: Func<void>, event: string = NOTIFICATIONS_UPDATE_EVENT): void {
    this._emitter.off(event, callback);
    callback = undefined;
  }

  /**
   * Emit
   * @param {*} [value=undefined]
   * @param {string} [event=NOTIFICATIONS_UPDATE_EVENT]
   * @memberof CoreNotificationsService
   */
  /* istanbul ignore next */
  public emit(value: any = undefined, event: string = NOTIFICATIONS_UPDATE_EVENT): void {
    if (this._emitter.listenerCount(event)) {
      this._emitter.emit(event, value);
    }
  }

  /**
   * Graph - getter
   * @readonly
   * @type {any}
   * @memberof CoreNotificationsService
   */
  public get graph(): any {
    return this._graph;
  }

  /**
   * Count - getter
   * @readonly
   * @memberof CoreNotificationsService
   */
  public get count() {
    return this._count;
  }

  /**
   * Count - setter
   * @memberof CoreNotificationsService
   */
  /* istanbul ignore next */
  public set count(count: number) {
    this._count = count;
  }

  /**
   * Collection - getter
   * @readonly
   * @memberof CoreNotificationsService
   */
   public get collection() {
    return this._collection;
  }

  /**
   * Collection - setter
   * @memberof CoreNotificationsService
   */
  /* istanbul ignore next */
  public set collection(collection: Array<CoreNotificationMenuItemShape>) {
    this._collection = collection;
  }
}
