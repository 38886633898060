import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { INPUT_TYPE_COLOR, FORM_ELEMENT_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Color Input Element
 * @export
 * @class CoreFormElementColor
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementColor extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementColor
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_COLOR,
    value: '#000000',
    placeholder: null
  });
}
