import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { INPUT_TYPE_DATE, FORM_ELEMENT_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Date Input Element
 * @export
 * @class CoreFormElementDate
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementDate extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementDate
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, { type: INPUT_TYPE_DATE });
}
