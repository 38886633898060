import React, { PureComponent, Fragment } from 'react';

import CoreTabsContext from '../context';

/**
 * Tabs Item Base
 * @export
 * @abstract
 * @class CoreTabsItemBase
 * @extends {PureComponent<P, {}>}
 * @template P
 */
export default abstract class CoreTabsItemBase<P = GenericPropType> extends PureComponent<P, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTabsContext>}
   * @memberof CoreTabsItemBase
   */
  public static contextType: Readonly<typeof CoreTabsContext> = CoreTabsContext;

  /**
   * LifeCycle Hook
   * @memberof CoreTabsItemBase
   */
  /* istanbul ignore next */
  public componentDidMount() {
    const { context, Listener } = this;
    context.subscribe(Listener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTabsItemBase
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    const { context, Listener } = this;
    context.unsubscribe(Listener);
  }

  /**
   * Render
   * @return {*}
   * @memberof CoreTabsItemBase
   */
  /* istanbul ignore next */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * Listener
   * @protected
   * @memberof CoreTabsItemBase
   */
  /* istanbul ignore next */
  protected Listener = (): void => {
    this.forceUpdate();
  };
}
