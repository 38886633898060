import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions Edit
 * @export
 * @class CorePermissionsCanEdit
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsCanEdit extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsCanEdit
   */
  public render() {
    const {
      service: { CanEdit },
      props: { children },
    } = this;
    return CanEdit? <Fragment>{children}</Fragment> : null;
  }
}
