import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import Highlight from 'react-highlight';
import pretty from 'pretty';

import CoreFormContext from '../../contexts/Form';

import { CoreDefaultButton } from '../../../buttons/';

import { CoreFormGroup, CoreFormGroupInline } from '../../containers/';

import { CoreToggle, CoreToggleTitle, CoreToggleTitleMini, CoreToggleDefinition } from '../../../toggle/';

import { CoreFormEventsEnum as E } from '../../enums/';

import { DEBUGGER_PROPS } from './constants';
import { CoreFormDebuggerProps } from './interfaces';

import { WaitForDelay, IsDefined, IsNull, IsBoolean, IsNumber, IsHashMap } from '../../../../../utils/';

/**
 * Form Debugger
 * @export
 * @class CoreDictionaryOrder
 * @extends {PureComponent<CoreFormDebuggerProps, {}>}
 */
export default class CoreFormDebugger extends PureComponent<CoreFormDebuggerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreFormDebugger
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default Props
   * @static
   * @type {CoreFormDebuggerProps}
   * @memberof CoreFormDebugger
   */
  public static defaultProps: CoreFormDebuggerProps = cloneDeep(DEBUGGER_PROPS);

  /**
   * Listener
   * @private
   * @type {AbortController}
   * @memberof CoreFormDebugger
   */
  private listener: AbortController;

  /**
   * Attempts
   * @private
   * @type {number}
   * @memberof CoreFormDebugger
   */
  private attempts: number = 0;

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentDidMount() {
    const { FormEventListener, context } = this;
    this.listener = new AbortController();
    context.emitter.on(E.FORM_EVENT_UPDATE, FormEventListener);
    context.emitter.on(E.FORM_EVENT_RESET, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    listener.abort();
    context.emitter.off(E.FORM_EVENT_UPDATE, FormEventListener);
    context.emitter.off(E.FORM_EVENT_RESET, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentDidCatch(err: Error) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
    }
  }

  /**
   * Render
   * @return {*}
   * @memberof CoreFormDebugger
   */
  public render() {
    if (!this.context) {
      throw new Error('CoreFormDebugger MUST be used inside a <CoreForm .../> container.');
    }

    const { Shower, context: { source, vault, values, id, valid, dirty, registry } } = this;

    const controllers: any[] = Array.from(registry);

    const frozen: boolean = Object.isFrozen(vault);

    return (
      <CoreFormGroup className="well debugger">
        <header>
          <div className="float-right">
            <CoreDefaultButton className="btn-sm" onClick={() => {
              this.forceUpdate();
            }}>refresh</CoreDefaultButton>
          </div>
          <h4>Core Form Debugger</h4>
        </header>
        <CoreToggle active={Shower('source')}>
          <CoreToggleTitle>Service</CoreToggleTitle>
          <CoreToggleDefinition>
            <CoreFormGroupInline className="tight">
              <CoreFormGroup className="well">
                <dl className="table"><dt>id</dt><dd><span>{id}</span></dd></dl>
                <dl className="table"><dt>valid</dt><dd><span className={valid ? 'success' : 'danger' }>{valid ? 'valid' : 'invalid'}</span></dd></dl>
                <dl className="table"><dt>dirty</dt><dd><span className={dirty ? 'warning' : 'trivial' }>{dirty ? 'dirty' : 'pristine'}</span></dd></dl>
                <dl className="table"><dt>vault</dt><dd><span className={frozen ? 'warning' : 'trivial' }>{frozen ? 'frozen' : 'unfrozen'}</span></dd></dl>
                <dl className="table"><dt>controllers</dt><dd><span>{registry.size}</span></dd></dl>
              </CoreFormGroup>
              <CoreFormGroup className="well">
                <dl>
                  <dt>source</dt>
                  <dd>
                    <pre>{MakePretty(source)}</pre>
                  </dd>
                </dl>
              </CoreFormGroup>
              <CoreFormGroup className="well">
                <dl>
                  <dt>vault</dt>
                  <dd>
                    <pre>{MakePretty(vault)}</pre>
                  </dd>
                </dl>
              </CoreFormGroup>
              <CoreFormGroup className="well">
                <dl>
                  <dt>values</dt>
                  <dd>
                    <pre>{MakePretty(values)}</pre>
                  </dd>
                </dl>
              </CoreFormGroup>
            </CoreFormGroupInline>
          </CoreToggleDefinition>
        </CoreToggle>
        <CoreToggle active={Shower('controllers')}>
          <CoreToggleTitle>Controllers</CoreToggleTitle>
          <CoreToggleDefinition>
            <CoreFormGroup className="tight">
              {controllers.map(([name, { type, value, valid, dirty, pseudo, error, ref }]: any[], ndx: number) => {

                let vtype: string;
                let vvalue: string;
                if (IsNull(value)) {
                  vtype = 'Null';
                  vvalue = 'null';
                } else if (IsBoolean(value)) {
                  vtype = 'Boolean';
                  vvalue = value ? 'true' : 'false';
                } else if (IsHashMap(value)) {
                  vtype = 'HashMap';
                  vvalue = '{}';
                } else if (IsNumber(value)) {
                  vtype = 'Number';
                  vvalue = value;
                } else {
                  if (Array.isArray(value)) {
                    if (type === 'dict') {
                      vtype = `HashMap<any>`;
                      vvalue = `[{...}]`;  
                    } else {
                      const array_type = IsNumber(value[0]) ? 'number' : IsBoolean(value[0]) ? 'boolean' : 'string';
                      vtype = `Array<${array_type}>`;
                      vvalue = `[${value.join(',')}]`;                   
                    }
                  } else {
                    vtype = `String`;
                    vvalue = IsDefined(value) ? `'${value}'` : `''`;
                  }
                }

                const html: string = ref.current ? ref.current.outerHTML : 'empty';

                let validity: string = null;

                for (const prop in error.validity) {
                  if (prop === 'valid') {
                    continue;
                  }
                  if (error.validity[prop]) {
                    validity = prop;
                    break;
                  }
                }

                return (
                  <CoreToggle key={`controller.${ndx}`} active={true}>
                    <CoreToggleTitleMini>{name}</CoreToggleTitleMini>
                    <CoreToggleDefinition>
                      <CoreFormGroupInline className="tight">
                        <CoreFormGroup className="well">
                          <dl className="table"><dt className="dim">name</dt><dd><span>{name}</span></dd></dl>
                          <dl className="table"><dt className="dim">type</dt><dd><span className={type ? '' : 'warning' }>{type||'placeholder'}</span></dd></dl>
                          <dl className="table"><dt className="dim">value</dt><dd><span>{vvalue} <span className="trivial">[{vtype}]</span></span></dd></dl>
                          <dl className="table"><dt className="dim">valid</dt><dd><span className={valid ? 'success' : 'danger' }>{valid ? 'valid' : 'invalid'}</span></dd></dl>
                          <dl className="table"><dt className="dim">dirty</dt><dd><span className={dirty ? 'warning' : 'trivial' }>{dirty ? 'dirty' : 'pristine'}</span></dd></dl>
                          <dl className="table"><dt className="dim">pseudo</dt><dd><span className={pseudo ? 'warning' : 'success' }>{pseudo ? 'true' : 'false'}</span></dd></dl>
                          <dl className="table"><dt className="dim">error type</dt><dd><span className={valid ? 'success' : 'danger' }>{validity || '--'}</span></dd></dl>
                          <dl className="table"><dt className="dim">error msg</dt><dd><span className={valid ? 'success' : 'danger' }>{error.validationMessage || '--'}</span></dd></dl>
                        </CoreFormGroup>
                        <CoreFormGroup className="html">
                          {type === 'dict' ? (<dl><dd><pre>{MakePretty(value)}</pre></dd></dl>) : (<Highlight className="html">{pretty(html)}</Highlight>)}
                        </CoreFormGroup>
                      </CoreFormGroupInline>
                    </CoreToggleDefinition>
                  </CoreToggle>
                );
              })}
            </CoreFormGroup>
          </CoreToggleDefinition>
        </CoreToggle>
        <CoreToggle active={Shower('notes')}>
          <CoreToggleTitle>Notes</CoreToggleTitle>
          <CoreToggleDefinition>
            <CoreFormGroup className="well">
              <ul>
                <li>Use <span className="info">prop[show="all|source|controllers|notes"]</span> <span className="trivial">[default="all"]</span>.</li>
                <li>Remove Debugger before committing your work.</li>
                <li><span className="warning">placeholder</span> types are controllers generated from source, but aren&rsquo;t (yet) represented by a corresponding CoreInput, CoreSelect or CoreTextarea.</li>
              </ul>
            </CoreFormGroup>
          </CoreToggleDefinition>
        </CoreToggle>
      </CoreFormGroup>
    );
  }

  /**
   * Shower
   * @protected
   * @param {string} which
   * @return {boolean}
   * @memberof FormConsumer
   */
  protected Shower = (which: string): boolean => {
    const { show } = this.props;
    return show === 'all' || show === which;
  }

  /**
   * Form Event Listener
   * @protected
   * @callback
   * @memberof FormConsumer
   */
  protected FormEventListener = () => {
    this.attempts++;
    WaitForDelay(100).then(() => {
      if (!this.listener.signal.aborted) {
        if (this.attempts === 1) {
          this.forceUpdate();
        }
        this.attempts--;
      }
    });
  };
}

const MakePretty = (src: HashMap<any>): string => {
  return JSON.stringify(src, (k, v) => v === undefined ? '' : v, 2);
}
