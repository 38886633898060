import React, { PureComponent } from 'react';

import CoreTabsContext from '../context';

/**
 * Tab Item Consumer
 * @export
 * @class CoreTabItemConsumer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreTabItemConsumer extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreTabItemConsumer
   */
  public render() {
    const { children } = this.props;
    return (
      <CoreTabsContext.Consumer>
        {children as any}
      </CoreTabsContext.Consumer>
    );
  }
}
