import { WaitForDelay } from '../../utils/';

import { BUSY_MESSAGE_DEFAULT } from '../../Config/';

import { CoreBusyServiceShape } from './interfaces/';

/**
 * Busy Service
 * @export
 * @class CoreBusyService
 * @implements {CoreBusyServiceShape}
 */
export class CoreBusyService implements CoreBusyServiceShape {
  /**
   * Active
   * @private
   * @type {boolean}
   * @memberof CoreBusyService
   */
  private _active: boolean = false;

  /**
   * Keep
   * @private
   * @type {boolean}
   * @memberof CoreBusyService
   */
  private _keep: boolean = false;

  /**
   * Deny
   * @private
   * @type {boolean}
   * @memberof CoreBusyService
   */
  private _deny: boolean = false;

  /**
   * Message
   * @private
   * @type {string}
   * @memberof CoreBusyService
   */
  private _message: string = BUSY_MESSAGE_DEFAULT.slice(0);

  /**
   * Subscriber
   * @private
   * @type {Func<void, any>}
   * @memberof CoreBusyService
   */
  private _subscriber: Func<void, any> = null!;

  /**
   * On
   * @param {string} [message=BUSY_MESSAGE_DEFAULT]
   * @returns {Promise<void>}
   * @memberof CoreBusyService
   */
  public on(message: string = BUSY_MESSAGE_DEFAULT): Promise<void> {
    return new Promise((resolve) => {
      /* istanbul ignore next */
      WaitForDelay()
        .then(() => {
          if (this._deny) {
            if (this.ready) {
              return this.subscriber();
            }
            return void 0;
          }
          this.message = message;
          if (!this.active) {
            this._active = true;
          }
          if (this.ready) {
            return this.subscriber();
          }
        })
        .finally(() => {
          resolve();
        });
    });
  }

  /**
   * Off
   * @memberof CoreBusyService
   */
  public off(message?: string): void {
    if (message) {
      this.message = message;
    }
    /* istanbul ignore next */
    WaitForDelay(10)
      .then(() => {
        if (this._keep) {
          this._keep = false;
        }
        if (this._deny) {
          this._deny = false;
        }
        if (this.active) {
          this._active = false;
        }
        if (this.ready) {
          return this.subscriber();
        }
      })
      .finally(() => {
        if (this.message !== BUSY_MESSAGE_DEFAULT) {
          this.message = BUSY_MESSAGE_DEFAULT.slice(0);
        }
      });
  }

  /**
   * Keep
   * @returns {CoreBusyService}
   * @memberof CoreBusyService
   */
  public keep(): CoreBusyService {
    this._keep = true;
    return this;
  }

  /**
   * Deny
   * @returns {CoreBusyService}
   * @memberof CoreBusyService
   */
  public deny(deny: boolean = true): CoreBusyService {
    this._deny = deny;
    return this;
  }

  /**
   * Subscribe
   * @param {Func<void>} subscriber
   * @memberof CoreBusyService
   */
  public subscribe(subscriber: Func<void>): void {
    this._subscriber = subscriber;
  }

  /**
   * Unsubscribe
   * @memberof CoreBusyService
   */
  public unsubscribe(): void {
    this._subscriber = null;
  }

  /**
   * Emit
   * @memberof CoreBusyService
   */
  public emit(): void {
    if (this.ready) {
      this.subscriber();
    }
  }

  /**
   * Active - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreBusyService
   */
  public get active(): boolean {
    return this._active;
  }

  /**
   * Ready - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreBusyService
   */
  public get ready(): boolean {
    return this._subscriber !== null;
  }

  /**
   * Message - getter
   * @readonly
   * @type {string}
   * @memberof CoreBusyService
   */
  public get message(): string {
    return this._message;
  }

  /**
   * Message - setter
   * @memberof CoreBusyService
   */
  public set message(message: string) {
    this._message = message;
  }

  /**
   * Subsciber - getter
   * @readonly
   * @type {Func<void, any>}
   * @memberof CoreBusyService
   */
  public get subscriber(): Func<void, any> {
    return this._subscriber;
  }
}

const CoreBusy = new CoreBusyService();

export default CoreBusy;
