import React from 'react';

import { CoreConfig, CoreConfigMeta, CoreConfigLinkShape } from '../../Config/';

import { CoreAuth } from '../../Auth/';
import { CoreHeaderMenusMask } from '../../Interop/enums/';

import CoreMenuComponentBase from './Base';
import CoreUserLinkComponent from './UserLink';

import { CoreAnchor } from '../../shared/';
import { MergeClassNames } from '../../utils/';

/**
 * User Menu Component
 * @export
 * @class CoreUserMenuComponent
 * @extends {CoreMenuComponentBase}
 */
export default class CoreUserMenuComponent extends CoreMenuComponentBase {
  /**
   * Creates an instance of CoreUserMenuComponent.
   * @param {GenericPropType} props
   * @memberof CoreUserMenuComponent
   */
  constructor(props: GenericPropType) {
    super(props);
    this.STATE = CoreHeaderMenusMask.USER;
  }

  /**
   * Render
   * @returns
   * @memberof CoreUserMenuComponent
   */
  public render() {
    const { props, OnMenuClick, OnThemeClick, OnRefreshClick } = this;
    const { name, picture } = props;

    const user = true;
    const active = this.STATE === this.state.value;
    const clz = MergeClassNames('', { user, active });

    return (
      <li className={clz}>
        <CoreAnchor onClick={OnMenuClick}>
          <CoreUserLinkComponent name={name} picture={picture} />
        </CoreAnchor>
        <dl>
          {process.env.NODE_ENV !== 'production' ? (
            <dd>
              <CoreAnchor className="sync" onClick={OnThemeClick}>
                Toggle Theme
              </CoreAnchor>
            </dd>
          ) : null}
          {process.env.NODE_ENV !== 'production' ? (
            <dd>
              <CoreAnchor className="cog" onClick={OnRefreshClick}>
                Refresh Token
              </CoreAnchor>
            </dd>
          ) : null}
          {CoreConfig.header.user_links.map(({ label, url, title, name }: CoreConfigLinkShape, ndx: number) => {
            /* istanbul ignore next */
            return (
              <dd key={`user.link.${ndx}`} className="divider">
                <CoreAnchor href={url} className={name||null} title={title||null}>
                  {label}
                </CoreAnchor>
              </dd>
            );
          })}
          <dd className="divider">
            <CoreAnchor className="signout" onClick={() => { /* istanbul ignore next */CoreAuth.logout(); }}>Sign Out</CoreAnchor>
          </dd>
        </dl>
      </li>
    );
  }

  /**
   * On Theme Click
   * @protected
   * @memberof CoreUserMenuComponent
   */
  /* istanbul ignore next */
  protected OnThemeClick = (): void => {
    CoreConfigMeta.THEME = CoreConfigMeta.THEME === 'theme-dark' ? 'theme-light' : 'theme-dark';
    CoreConfig.emit();
  };

  /**
   * On Refresh Click
   * @protected
   * @memberof CoreUserMenuComponent
   */
  /* istanbul ignore next */
  protected OnRefreshClick = (): void => {
    CoreAuth.refresh();
  };
}
