
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_SEARCH } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Search Input Element
 * @export
 * @class CoreFormElementSearch
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementSearch extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementSearch
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_SEARCH,
    placeholder: 'Search...'
  });
}
