import { CoreSyncWebStorage } from '../../shared/modules/storage/';

import { CONFIG_DNAME_STORAGE } from '../constants/';
import { CoreConfigCompanyShape } from '../interfaces/';
import { IsDefined } from '../../utils/';

/**
 * Config Company Service
 * @export
 * @class CoreConfigCompany
 */
export class CoreConfigCompany implements CoreConfigCompanyShape {
  /**
   * DNAME
   * @static
   * @type {string}
   * @memberof CoreConfigCompany
   */
  public static DNAME: string = null;

  /**
   * Storage
   * @private
   * @type {CoreSyncWebStorage}
   * @memberof CoreConfigCompany
   */
  private _storage: CoreSyncWebStorage = new CoreSyncWebStorage(CONFIG_DNAME_STORAGE);

  /**
   * Creates an instance of CoreConfigCompany.
   * @memberof CoreConfigCompany
   */
  constructor() {
    if (!this._storage.get()) {
      this._storage.init(CoreConfigCompany.DNAME);
    }
  }

  /**
   * Clear
   * @returns {Promise<string>}
   * @memberof CoreConfigCompany
   */
  public async clear(): Promise<string> {
    this.dName = CoreConfigCompany.DNAME;
    return this.dName;
  }

  /**
   * Destroy
   * @memberof CoreConfigCompany
   */
  /* istanbul ignore next */
  public destroy(): void {
    this._storage.remove();
  }

  /**
   * Current - getter
   * @readonly
   * @memberof CoreConfigCompany
   */
  /* istanbul ignore next */
  public get dName() {
    return this._storage.get() as string;
  }

  /**
   * Current - setter
   * @memberof CoreConfigCompany
   */
  /* istanbul ignore next */
  public set dName(dName: string) {
    this._storage.set(dName);
  }

  /**
   * Initted - getter
   * @readonly
   * @memberof CoreConfigCompany
   */
  /* istanbul ignore next */
  public get initted() {
    return IsDefined(this.dName);
  }

  /**
   * Matched - getter
   * @readonly
   * @memberof CoreConfigCompany
   */
  /* istanbul ignore next */
  public get matched() {
    return this.dName === CoreConfigCompany.DNAME;
  }
}
