import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions Read Write
 * @export
 * @class CorePermissionsReadWrite
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsReadWrite extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsReadWrite
   */
  public render() {
    const {
      service: { IsReadWrite, IsAdmin },
      props: { children },
    } = this;
    return IsReadWrite || IsAdmin ? <Fragment>{children}</Fragment> : null;
  }
}
