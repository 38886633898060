import { COPYRIGHT, PRODUCT_NAME, FOOTER_LINKS, CONTACT_INFORMATION } from '../constants/';
import { CoreConfigFooterShape, CoreConfigLinkShape } from '../interfaces/';

/**
 * Config Footer Service
 * @export
 * @class CoreConfigFooter
 */
export class CoreConfigFooter implements CoreConfigFooterShape {
  public static COPYRIGHT: string = COPYRIGHT;
  public static PRODUCT_NAME: string = PRODUCT_NAME;
  public static LINKS: CoreConfigLinkShape[] = FOOTER_LINKS;
  public static CONTACT_INFORMATION: string = CONTACT_INFORMATION;
  public get copyright() { return CoreConfigFooter.COPYRIGHT; }
  public get product_name() { return CoreConfigFooter.PRODUCT_NAME; }
  public get links() { return CoreConfigFooter.LINKS; }
  public get contact_information() { return CoreConfigFooter.CONTACT_INFORMATION; }
}
