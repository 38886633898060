import React from 'react';

import ControllerChildBase from './Base';

import { CoreFormControllerErrorProps } from '../../interfaces/';
import { FORM_CONTROLLER_ERROR_PROPS, VALIDITY_BOOLEANS } from '../../constants/';
import { HashMapMergeProps, MergeClassNames, IsNull, IsDefined } from '../../../../../utils/';

/**
 * Controller Error Component
 * @export
 * @class CoreFormControllerErrorComponent
 * @extends {ControllerChildBase<CoreFormControllerErrorProps>}
 * @notes
 * 1. Renders intrinsic or custom error output.
 */
export default class CoreFormControllerErrorComponent extends ControllerChildBase<CoreFormControllerErrorProps> {
  /**
   * Default Props
   * @static
   * @type {CoreFormControllerErrorProps}
   * @memberof CoreFormControllerErrorComponent
   */
  public static defaultProps: CoreFormControllerErrorProps = HashMapMergeProps(FORM_CONTROLLER_ERROR_PROPS);

  /**
   * Creates an instance of CoreFormControllerErrorComponent.
   * @param {CoreFormControllerErrorProps} props
   * @memberof CoreFormControllerErrorComponent
   */
  constructor(props: CoreFormControllerErrorProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormControllerErrorComponent
   */
  public render() {
    const { context: { error, dirty, valid }, props: { errors } } = this;

    /**
     * Wait until error is populated
     */
    /* istanbul ignore next */
    if (IsNull(error)) {
      return null;
    }

    const { validationMessage, validity } = error;

    let message: string = validationMessage;

    if (IsDefined(errors)) {
      let i: number = VALIDITY_BOOLEANS.length;
      let e: string;

      /**
       * Apply available custom error messages
       */
      while(i--) {
        e = VALIDITY_BOOLEANS[i];
        if (validity[e] && (e in errors)) {
          message = errors[e];
          break;
        }
      }
    }

    /**
     * Set style properties
     */
    const active: boolean = dirty && valid === false ? true : false;
    const clz = MergeClassNames('', { active });

    return <output className={clz}>{message}</output>;
  }

  /**
   * Init
   * @private
   * @param {CoreFormControllerErrorProps} { errors }
   * @memberof CoreFormControllerErrorComponent
   */
  private init = ({ errors }: CoreFormControllerErrorProps): void => {
    if (IsDefined(errors)) {
      /**
       * Warn developers
       */
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        let exist: boolean = false;
        let i: number = VALIDITY_BOOLEANS.length;
        while(i--) {
          if (VALIDITY_BOOLEANS[i] in errors) {
            exist = true;
            break;
          }
        }
        /* istanbul ignore next */
        if (exist) {
          console.debug(`DEVELOPER WARNING:: an overridden error message loses i18n capability.`)
        }
      }
    }
  };
}
