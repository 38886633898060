import React, { PureComponent } from 'react';

import { CoreHeaderToolService } from '../services/';

/**
 * Tools Menu Container
 * @export
 * @class CoreToolsMenuContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreToolsMenuContainer extends PureComponent<GenericPropType, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreToolsMenuContainer
   */
  public render() {
    return (
      <div>
        <CoreHeaderToolService.Tool />
      </div>
    );
  }
}
