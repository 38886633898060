import { WaitForDelayPromise } from './async';

/**
 * Has Shared Parent Element
 * @export
 * @param {HTMLElement} search
 * @param {HTMLElement} target
 * @returns {boolean}
 */
export const HasSharedParentElement = (search: HTMLElement, target: HTMLElement): boolean => {
  // why travel all the way up to the html tag?
  /* istanbul ignore next */
  if (search.tagName === 'ROOT') {
    return false;
  }

  const parent = search.parentElement;

  // if no parent, then no children,
  // therefore not a co-sibling with the searched object
  if (!parent) {
    return false;
  }

  // simplest, most performant way to interate children.
  const children = parent.children;
  let i: number = children.length;

  while (i--) {
    if (children[i] === target) {
      return true;
    }
  }

  // no joy, go up a node
  return HasSharedParentElement(parent, target);
};

/**
 * Make Heights Match
 * @export
 * @param {any} a
 * @param {any} b
 * @returns {void}
 */
export const MakeHeightsMatch = (a: any, b: any): void => {
  /* istanbul ignore next */
  const w: any = window;
  let deferred: WaitForDelayPromise;

  function OnResize() {
    /* istanbul ignore next */
    if (deferred) {
      deferred.reject();
    }
    /* istanbul ignore next */
    deferred = new WaitForDelayPromise(100);
    /* istanbul ignore next */
    deferred.promise.then(() => {
      if (a.current && b.current) {
        b.current.style.height = `${a.current.offsetHeight}px`;
      }
    });
  }
  /* istanbul ignore next */
  w.addEventListener('resize', OnResize, false);

  /* istanbul ignore next */
  const evt: any = new Event('resize', { bubbles: false });
  /* istanbul ignore next */
  evt.simulated = true;
  /* istanbul ignore next */
  w.dispatchEvent(evt);
};
