import { CoreNavigation } from '../../Navigation/';

/**
 * Label From Path Factory
 * @param {string} path
 * @returns {string}
 */
export const CoreLabelFromPath = (path: string): string => {
  let label: string;
  for (const item of CoreNavigation.items.values()) {
    if (item.path && item.path === path) {
      label = item.label;
      break;
    }
  }
  return label;
};
