import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import CoreBusyContext from '../context';
import { BUSY_PROPS } from '../constants';
import { CoreBusyProps, CoreBusyState } from '../interfaces/';

import CoreBusyIconWithMessage from './BusyIconWithMessage';
import { CoreLogo } from '../../../shared/';

import { MergeClassNames } from '../../../utils/';

/**
 * Busy Component
 * @export
 * @class CoreBusyComponent
 * @extends {Component<BusyProps, {}>}
 */
export default class CoreBusyComponent extends PureComponent<CoreBusyProps, CoreBusyState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreBusyContext>}
   * @memberof CoreBusyComponent
   */
  public static contextType: Readonly<typeof CoreBusyContext> = CoreBusyContext;

  /**
   * Default Props
   * @static
   * @type {CoreBusyProps}
   * @memberof CoreBusyComponent
   */
  public static defaultProps: CoreBusyProps = cloneDeep(BUSY_PROPS);

  /**
   * State
   * @type {CoreBusyState}
   * @memberof CoreBusyComponent
   */
  public readonly state: CoreBusyState = cloneDeep(BUSY_PROPS);

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof CoreBusyComponent
   */
  private listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof CoreBusyComponent
   */
  public componentDidMount() {
    const {
      context,
      OnBusySubscriber,
      props: { message, active },
    } = this;
    this.listener = new AbortController();
    context.subscribe(OnBusySubscriber);
    this.setState({ message, active });
  }

  /**
   * LifeCycle Hook
   * @memberof CoreBusyComponent
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    const { listener } = this;
    listener.abort();
  }

  /**
   * Render
   * @returns
   * @memberof CoreBusyComponent
   */
  public render() {
    const { message, active } = this.state;

    const clz = MergeClassNames('', { active });

    return (
      <div id="busy" className={clz}>
        <div>
          <figure>
            <CoreLogo />
            <CoreBusyIconWithMessage message={message} />
          </figure>
        </div>
      </div>
    );
  }

  /**
   * On Busy Subscriber
   * @private
   * @memberof CoreBusyComponent
   */
  /* istanbul ignore next */
  private OnBusySubscriber = (): void => {
    const { listener, context: { message, active } } = this;
    if (!listener.signal.aborted) {
      this.setState({ message, active });
    }
  };
}
