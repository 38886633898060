import React, { FormEvent, Fragment } from 'react';

import CoreBootBase from './Base';

import { CoreNavigate } from '../../../Router/';
import { CoreBootAuthPlugins } from '../../services/';

import { CoreDelayedLink, CoreTitle, CoreForm, CoreFormGroup, CoreInput, CoreFormSave } from '../../../shared/';

import { ON_FORGOT_SENT_MESSAGE, AUTH_ROUTE_PATH, PLUGIN_REQUIRED } from '../constants';

/**
 * Boot Forgot Container
 * @export
 * @class CoreBootForgotContainer
 * @extends {CoreBootBase<CoreRouteProps, S = {}>}
 */
export default class CoreBootForgotContainer extends CoreBootBase {
  /**
   * Render
   * @returns
   * @memberof CoreBootForgotContainer
   */
  public render() {
    const { OnSubmit } = this;

    return (
      <Fragment>
        <CoreTitle>Forgot Password?</CoreTitle>
        <CoreForm id="forgot_form" className="forgot auth-form-wrapper" onSubmit={OnSubmit} autoComplete={'off'}>
          <header>
            <h4>Reset your password</h4>
            <p>Please enter your email address. We will send you an email to reset your password.</p>
          </header>
          <section>
            <CoreFormGroup className="stack row">
              <CoreInput type="email" name="email" title="Email" defaultValue="" className="col" />
            </CoreFormGroup>
            <p>
              <CoreDelayedLink link={'/auth/login'} selector="#forgot_form" add="exit" delay={250}>
                Return to login form
              </CoreDelayedLink>
            </p>
          </section>
          <footer>
            <label>
              <CoreFormSave>Send Email</CoreFormSave>
            </label>
          </footer>
        </CoreForm>
      </Fragment>
    );
  }

  /**
   * On Submit
   * @private
   * @memberof CoreBootForgotContainer
   */
  /* istanbul ignore next */
  private OnSubmit = (evt: FormEvent, values: HashMap<any>): void => {
    CoreBootAuthPlugins.apply('forgot', values)
      .then(() => {
        (window as any).alert(ON_FORGOT_SENT_MESSAGE).then(() => {
          CoreNavigate(AUTH_ROUTE_PATH);
        });
      })
      .catch(() => {
        (window as any).alert(PLUGIN_REQUIRED).then(() => {
          CoreNavigate(AUTH_ROUTE_PATH);
        });
      });
  };
}
