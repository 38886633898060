import React, { PureComponent } from 'react';

import FormConsumer from './FormConsumer';
import ManualConsumer from './ManualConsumer';
import WatcherConsumer from './WatcherConsumer';

import { CoreFormConsumerProps } from '../../interfaces/';
import { CoreFormConsumerTypeEnum } from '../../enums/';
import { CONSUMER_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Core Form Consumers
 * @export
 * @class CoreFormConsumer
 * @extends {PureComponent<CoreFormConsumerProps, {}>}
 */
export default class CoreFormConsumer extends PureComponent<CoreFormConsumerProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreFormConsumerProps}
   * @memberof CoreFormConsumer
   */
  public static defaultProps: CoreFormConsumerProps = HashMapMergeProps(CONSUMER_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreFormConsumer
   */
  public render() {
    const { props } = this;
    switch(props.type) {
      case CoreFormConsumerTypeEnum.FORM:
        return <FormConsumer {...props} />;
      case CoreFormConsumerTypeEnum.MANUAL:
        return <ManualConsumer {...props} />;
      case CoreFormConsumerTypeEnum.WATCHER:
        return <WatcherConsumer {...props} />;
      default:
        /* istanbul ignore next */
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`DEVELOPER ERROR: unknown type for <CoreFormConsumer type={${props.type}}>`);
        }
    }
    /* istanbul ignore next */
    return null;
  }
}
