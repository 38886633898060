import React, { Component } from 'react';
import { Router, RouterProps } from '@reach/router';

/**
 * Core Main Router
 * @export
 * @class CoreMainRouter
 * @extends {Component<RouterProps, {}>}
 */
export default class CoreMainRouter extends Component<RouterProps, {}> {
  /**
   * Default Props
   * @static
   * @type {RouterProps}
   * @memberof CoreMainRouter
   */
  public static defaultProps: RouterProps = { basepath: '/', component: 'section' };
  /**
   * Render
   * @returns
   * @memberof CoreMainRouter
   */
  /* istanbul ignore next */
  public render() {
    const { children, ...rest } = this.props;
    return <Router {...rest}>{children}</Router>
  }
}
