import React, { Component, Fragment, FormEvent, FocusEvent } from 'react';

import { CoreFormValueType } from '../../types';
import { CoreFormElementBaseProps } from '../../interfaces/';
import { FormElementValueFromEvent, FormElementNumericFromProps, FormElementBinaryFromProps } from '../../factories/';
import { FORM_ELEMENT_PROPS } from '../../constants/';

import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Form Element Base Component
 * @export
 * @abstract
 * @class FormElementBase
 * @extends {Component<P, {}>}
 * @template P
 * @priority - PRIMARY
 * @notes
 * 1. Uncontrolled component.
 * 2. Delivers properly typed value in callbacks.
 * 3. Accepts externally delivered updates via declared value prop.
 * 4. No async operations.
 */
export default abstract class FormElementBase<P extends CoreFormElementBaseProps> extends Component<P, {}> {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementBaseProps, 'name'>}
   * @memberof FormElementBase
   */
  public static defaultProps: Omit<CoreFormElementBaseProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS);

  /**
   * Listener
   * @private
   * @type {AbortController}
   * @memberof FormElementBase
   */
  private _listener: AbortController;

  /**
   * Numeric
   * @private
   * @type {boolean}
   * @memberof FormElementBase
   */
  private _numeric: boolean = false;

  /**
   * Binary
   * @private
   * @type {boolean}
   * @memberof FormElementBase
   */
  private _binary: boolean = false;

  /**
   * Creates an instance of FormElementBase.
   * @param {P} props
   * @memberof FormElementBase
   */
  constructor(props: P) {
    super(props);
    this.init(props);
  }

  /**
   * LifeCycle Hook
   * @memberof FormElementBase
   */
  public componentDidMount() {
    /**
     * Set Listener
     */
    this._listener = new AbortController();
  }

  /**
   * LifeCycle Hook
   * @memberof FormElementBase
   */
  public componentWillUnmount() {
    /**
     * Abort Listener
     */
    if (this.listener) {
      this.listener.abort();
    }
  }

  /**
   * Render
   * @returns
   * @memberof FormElementBase
   */
  /* istanbul ignore next */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * On Change
   * @protected
   * @param {FormEvent<any>} evt
   * @memberof FormElementBase
   */
  protected OnChangeOrInput = (evt: FormEvent<any>) => {
    const { listener, props: { onChange, onInput, custom } } = this;

    /* istanbul ignore next */
    if (!listener.signal.aborted) {
      /**
       * Conformed Value Typed from Event.
       */
      const value: CoreFormValueType = FormElementValueFromEvent(this, evt);

      /**
       * Process custom errors if any
       */
      if (custom) {
        custom(evt.currentTarget, value);
      }

      /* istanbul ignore next */
      if (onChange) {
        evt.persist();
        onChange(evt, value);
      }

      /* istanbul ignore next */
      if (onInput) {
        evt.persist();
        onInput(evt, value);
      }
    }
  };

  /**
   * On Blur
   * @protected
   * @param {FocusEvent<any>} evt
   * @memberof FormElementBase
   */
  protected OnBlur = (evt: FocusEvent<any>) => {
    const { listener, props: { onBlur, custom } } = this;

    /* istanbul ignore next */
    if (!listener.signal.aborted) {
      /**
       * Conformed Value Typed from Event.
       */
      const value: CoreFormValueType = FormElementValueFromEvent(this, evt);

      /**
       * Process custom errors if any
       */
      if (custom) {
        custom(evt.currentTarget, value);
      }

      /* istanbul ignore next */
      if (onBlur) {
        evt.persist();
        onBlur(evt, value);
      }
    }
  };

  /**
   * Init
   * @protected
   * @param {Omit<CoreFormElementBaseProps, 'name'>} props
   * @memberof FormElementBase
   */
  protected init = (props: Omit<CoreFormElementBaseProps, 'name'>): void => {
    /**
     * Establish Numeric Property
     */
    this.numeric = FormElementNumericFromProps(props);
    /**
     * Establish Binary Property
     */
    this.binary = FormElementBinaryFromProps(props);
    return void 0;
  };

  /**
   * Numeric - getter
   * @readonly
   * @type {boolean}
   * @memberof FormElementBase
   */
  public get numeric(): boolean {
    return this._numeric;
  }

  /**
   * Numeric - setter
   * @memberof FormElementBase
   */
  public set numeric(numeric: boolean) {
    this._numeric = numeric;
  }

  /**
   * Binary - getter
   * @readonly
   * @type {boolean}
   * @memberof FormElementBase
   */
  public get binary(): boolean {
    return this._binary;
  }

  /**
   * Binary - setter
   * @memberof FormElementBase
   */
  public set binary(binary: boolean) {
    this._binary = binary;
  }

  /**
   * Listener - getter
   * @readonly
   * @private
   * @type {AbortController}
   * @memberof FormElementBase
   */
  private get listener(): AbortController {
    return this._listener;
  }
}


/*
NOTES
=============
Flow - As Element.
-------------
Initial: constructor -> init -> render -> componentDidMount
Primary: shouldComponentUpdate -> render
Secondary: none.
=============

Flow - As Child of Controller.
-------------
Initial: constructor -> init -> render -> componentDidMount - [same]
Primary: shouldComponentUpdate -> render -> shouldComponentUpdate -> render
Secondary: shouldComponentUpdate -> render
=============
*/
