/**
 * Bytes to MegaBytes
 * @export
 * @param {number} value
 * @returns {number}
 */
export const BytesToMB = (value: number): number => {
  return Math.round((value / 1000 / 1000) * 100) / 100;
};

/**
 * Map Number To Modifier
 * @param {number} value
 * @param {string[]} map
 * @param {number} decimals
 * @returns {boolean}
 */
export const MapNumberToModifier = (value: number, map: string[], decimals: number = 2): HashMap<any> => {
  try {
    if (!map.length) {
      throw new TypeError('map not populated');
    }

    const m: number = value < 0 ? -1 : 1;
    const l: number = map.length - 1;
    const k: number = 1000;
    let i = 0;

    value *= m;

    while (value > k && i < l) {
      value /= k;
      i++;
    }

    const size: number = Number(value.toFixed(decimals)) * m;
    const rate: string = map[i];

    return { size, rate };
  } catch (err: any) {
    console.warn(`MapNumberToModifier(${value}, ${map}, ${decimals})-> ${err}`);
  }
};

/**
 * Format PPS
 * @export
 * @param {number} packets
 * @returns {HashMap<any>}
 */
export const FormatPPS = (packets: number): HashMap<any> => {
  return MapNumberToModifier(packets, ['PPS', 'kPPS', 'mPPS', 'gPPS', 'tPPS', 'pPPS', 'ePPS', 'zPPS', 'yPPS']);
};

/**
 * Format BPS
 * @export
 * @param {number} bits
 * @returns {HashMap<any>}
 */
export const FormatBPS = (bits: number): HashMap<any> => {
  return MapNumberToModifier(bits, ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps', 'Ebps', 'Zbps', 'Ybps']);
};

/**
 * Format Packets
 * @export
 * @param {number} packets
 * @returns {HashMap<any>}
 */
export const FormatPackets = (packets: number): HashMap<any> => {
  return MapNumberToModifier(packets, ['P', 'kP', 'mP', 'gP', 'tP', 'pP', 'eP', 'zP', 'yP']);
};

/**
 * Format Bits
 * @export
 * @param {number} bits
 * @returns {HashMap<any>}
 */
export const FormatBits = (bits: number): HashMap<any> => {
  return MapNumberToModifier(bits, ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']);
};

/**
 * Bits / sec -> MegaBits / sec
 * @export
 * @param {number} bps
 * @returns {number}
 */
export const BpsToMbps = (bps: number): number => {
  return Math.round((bps / 1000 / 1000) * 100) / 100;
};

/**
 * Get Max Scale
 * @export
 * @param {number} n
 * @returns {number}
 */
export const GetMaxScale = (n: number): number => {
  const s: string = String(Math.floor(Math.abs(n)));
  return parseInt(String(Number(s.charAt(0)) + 1).padEnd(s.length, '0'), 10);
};

/**
 * Rounded Sum Args
 * @export
 * @param {any[]} args
 * @returns {number}
 */
export const RoundedSumArgs = (...args: any[]): number => {
  const t = (v: any) => (typeof v === 'string' ? parseFloat(v) : v);
  return args.reduce((n: number, v: any) => n + Math.round(t(v)), 0);
};

/**
 * Random Number
 * @export
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const RandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Get Hypotenuse
 * @export
 * @param {number} a
 * @param {number} b
 * @param {number} [precision=4]
 * @returns {number}
 */
export const GetHypotenuse = (a: number, b: number, precision: number = 4): number => {
  // never put Math expressions as arguments to Math expressions
  // js may get ooo incorrect
  const aa = Math.pow(a, 2);
  const bb = Math.pow(b, 2);
  const sq = Math.sqrt(aa + bb);
  return Number(sq.toFixed(precision));
};

/**
 * Ratio To Radian
 * @export
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export const RatioToRadian = (a: number, b: number): number => {
  return (a / b) * 360;
};

/**
 * NumberToPercentage
 * @export
 * @param {number} a
 * @param {number} [precision=2]
 * @returns {number}
 */
export const NumberToPercentage = (a: number, precision: number = 2): string => {
  const value: string = (a * 100).toFixed(precision);
  return `${value}%`;
};

/**
 * Number to Human Readable
 * @export
 * @param {number} a
 * @returns {string}
 */
export const NumberToHumanReadable = (a: number): string => {
  return a.toLocaleString();
};
