import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import { CoreTabItemProps } from '../interfaces/';

import { TABS_ITEM_PROPS } from '../constants';

import { MergeClassNames } from '../../../../utils/';

/**
 * Tab Item
 * @export
 * @class CoreTabItem
 * @extends {Component<CoreTabItemProps, {}>}
 */
export default class CoreTabItem extends Component<CoreTabItemProps, {}> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreTabItem
   */
  public static displayName: string = 'CoreTabItem';

  /**
   * Default Props
   * @static
   * @type {CoreTabItemProps}
   * @memberof CoreTabItem
   */
  public static defaultProps: CoreTabItemProps = cloneDeep(TABS_ITEM_PROPS);

  /**
   * Tab Item
   * @returns
   * @memberof CoreTabItem
   */
  public render() {
    const { children, active, strict } = this.props;
    if (strict && !active) {
      return null;
    }
    const item = true;
    const clz = MergeClassNames('', { item, active });
    return <div className={clz}>{children}</div>;
  }
}
