import { ApolloClient, ApolloClientOptions, InMemoryCache, DefaultOptions, ErrorPolicy } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient, ClientOptions } from 'subscriptions-transport-ws';

import { CoreAuth } from '../../../../Auth';

import CoreGraphBase from './base';

import { CoreGraphConnectionShape } from '../interfaces/';

/**
 * Core Graph Socket
 * @export
 * @param {CoreGraphSocketProviderType} { uri, authenticate = true, cache = new InMemoryCache() }
 * @param {CoreGraphAuthType} [auth=CoreAuth]
 * @return {*} {CoreGraphConnectionShape}
 */
export default function CoreGraphSocket({ uri, authenticate = true, cache = new InMemoryCache() }: CoreGraphSocketProviderType, auth: CoreGraphAuthType = CoreAuth): CoreGraphConnectionShape {
  /**
   * Type
   * @type {CoreGraphProviderType}
   */
  const type: CoreGraphProviderType = 'socket';

  /**
  * Core Graph Socket
  * @export
  * @class CoreGraphSocket
  * @extends {CoreGraphBase<CoreGraphClientType, CoreGraphSocketProviderType>}
  */
  class CoreGraphSocketInstance extends CoreGraphBase<CoreGraphClientType, CoreGraphSocketProviderType> {
    /**
     * Make
     * @override
     * @protected
     * @return {*} 
     * @memberof CoreGraphSocket
     */
    protected make() {
      const { cache, uri } = this;
      let { token } = this;

      /* istanbul ignore next */
      if (authenticate) {
        if (!token && CoreAuth.token) {
          this.token = CoreAuth.token;
          token = this.token;
        }
      }

      /**
       * Build Connection
       */
      const wsuri: string = uri.replace(/^http?s/i, 'wss');

      const config: ClientOptions = { reconnect: true, lazy: true, reconnectionAttempts: 3 };
      const protocols: string | string[] = authenticate && token ? ['graphql-ws', token] : undefined;
      const connection: SubscriptionClient = new SubscriptionClient(wsuri, config, undefined, protocols);

      this.connection = connection;

      /**
       * Build Client
       */
      const link: any = new WebSocketLink(this.connection);

      let errorPolicy: ErrorPolicy = 'ignore';
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        errorPolicy = 'all';
      }

      const defaultOptions: DefaultOptions = { query: { errorPolicy }, watchQuery: { fetchPolicy: 'cache-and-network', errorPolicy } };
      const options: ApolloClientOptions<any> = { link, cache, defaultOptions };

      return new ApolloClient<any>(options);
    }
  }

  const { client, connection, open, close } = new CoreGraphSocketInstance({ uri, authenticate, cache }, auth);

  return { client, connection, open, close, type, uri, cache, authenticate };
}