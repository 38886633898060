import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import CoreNavItemSwitch from './ItemSwitch';
import { CoreNavItemsProps, CoreNavItemShape } from '../interfaces/';
import { ITEMS_PROPS } from '../constants';

/**
 * Navigation Items Container
 * @export
 * @class CoreNavItemsContainer
 * @extends {Component<CoreNavItemsProps, {}>}
 */
export default class CoreNavItemsContainer extends Component<CoreNavItemsProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreNavItemsProps}
   * @memberof CoreNavItemsContainer
   */
  public static defaultProps: CoreNavItemsProps = cloneDeep(ITEMS_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreNavItemsContainer
   */
  public render() {
    const { Iterator, props: { items, top } } = this;
    if (top) {
      return <ul>{Iterator(items)}</ul>;
    } else {
      return <ol>{Iterator(items)}</ol>;
    }
  }

  /**
   * Iterator
   * @protected
   * @param {Map<string, CoreNavItemShape>} [items=new Map()]
   * @returns {JSX.Element[]}
   * @memberof CoreNavItemsContainer
   */
  protected Iterator = (items: Map<string, CoreNavItemShape> = new Map()): JSX.Element[] => {
    return Array.from(items).map(([name, item], ndx: number) => {
      return <CoreNavItemSwitch key={`${name}.${ndx}`} name={name} item={item} />;
    });
  };
}
