import { 
  QUERY_USER_GET, 
  QUERY_USER_DNAME_GET, 
  QUERY_USER_SHORTNAME_GET 
} from './models';

import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

/**
 * Get Current User
 */
export const GetCurrentUserQuery = CoreGraphQueryConsumer(QUERY_USER_GET);


export const GetCurrentUserDnameQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_USER_DNAME_GET,
    variables: { company: { dName: CoreConfig.company.dName } },
    // transform: ({ dName }) => ({ company: { dName } }),
    fetchPolicy: 'network-only'
  };
});

export const GetCurrentUserShortnameQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_USER_SHORTNAME_GET,
    variables: { company: { dName: CoreConfig.company.dName } }
  };
});