import React, { PureComponent } from 'react';

import CoreBoot from '../service';
import CoreBootMainContext from './context';
import { CoreBootMainWrapperProps } from './interfaces/';

/**
 * Boot Main Wrapper
 * @export
 * @class CoreBootMainWrapper
 * @extends {PureComponent<CoreBootMainWrapperProps, {}>}
 */
export default class CoreBootMainWrapper extends PureComponent<CoreBootMainWrapperProps, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBootMainWrapper
   */
  public render() {
    const { credentials } = this.props;
    if (credentials) {
      CoreBoot.credentials = credentials;
    }
    return <CoreBootMainContext.Provider value={CoreBoot}>{this.props.children}</CoreBootMainContext.Provider>;
  }
}
