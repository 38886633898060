import React, { FormEvent } from 'react';

import CoreFormMenuBase from './Base';
import MenuValue from './Value';

import { ControllerMenuProps } from '../../../../interfaces/';

/**
 * Menu Toggle
 * @export
 * @class MenuToggleComponent
 * @extends {CoreFormMenuBase<ControllerMenuProps>}
 */
export default class MenuToggleComponent extends CoreFormMenuBase<ControllerMenuProps> {
  /**
   * Render
   * @returns
   * @memberof MenuToggleComponent
   */
  public render() {
    const { OnToggleActive, props: { placeholder, tabIndex } } = this;
    return (
      <button type="button" onClick={OnToggleActive} tabIndex={tabIndex}>
        <MenuValue placeholder={placeholder} />
      </button>
    );
  }

  /**
   * On Toggle Active
   * @protected
   * @param {FormEvent<HTMLButtonElement>} evt
   * @memberof MenuToggleComponent
   */
  /* istanbul ignore next */
  protected OnToggleActive = (evt: FormEvent<HTMLButtonElement>): void => {
    const { ToggleActive } = this.context;
    ToggleActive();
  };
}
