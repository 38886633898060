import React, { Component, Fragment } from 'react';
import { cloneDeep } from 'lodash';
import { CoreDOMEventDirectiveProps } from '../interfaces/';

import { LISTENER_PROPS } from '../constants';

/**
 * Event Listner Directive
 * @export
 * @class CoreDOMEventDirective
 * @extends {Component<CoreDOMEventDirectiveProps, {}>}
 * @props
 * - {Window | Element}: [base=document.body]
 * - {string}: [event=click]
 * - {(evt: Event): void}: listener;
 */
export default class CoreDOMEventDirective extends Component<CoreDOMEventDirectiveProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreDOMEventDirectiveProps}
   * @memberof CoreDOMEventDirective
   */
  public static defaultProps: CoreDOMEventDirectiveProps = cloneDeep(LISTENER_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreDOMEventDirective
   */
  public componentDidMount() {
    const {
      props: { base, event, listener },
    } = this;
    base.addEventListener(event, listener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreDOMEventDirective
   */
  public componentWillUnmount() {
    const {
      props: { base, event, listener },
    } = this;
    base.removeEventListener(event, listener);
  }

  /**
   * Render
   * @returns
   * @memberof CoreDOMEventDirective
   */
  public render() {
    return <Fragment />;
  }
}
