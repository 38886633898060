import { HTMLQuadrantEnum } from '../enums/';

/**
 * Get Element Quadrant
 * @param {HTMLElement} elem
 * @param {HTMLElement} [body=document.body]
 * @returns {HTMLQuadrantEnum}
 */
export const GetElementQuadrant = (elem: HTMLElement, body: HTMLElement = document.body): HTMLQuadrantEnum => {
  const width: number = body.scrollWidth;
  const height: number = body.scrollHeight;
  const box = elem.getBoundingClientRect();
  const top: number = box.top + box.height / 2;
  const left: number = box.left + box.width / 2;

  if (top <= height / 2) {
    if (left <= width / 2) {
      return HTMLQuadrantEnum.TOP_LEFT;
    } else {
      return HTMLQuadrantEnum.TOP_RIGHT;
    }
  } else {
    if (left <= width / 2) {
      return HTMLQuadrantEnum.BOTTOM_LEFT;
    } else {
      return HTMLQuadrantEnum.BOTTOM_RIGHT;
    }
  }
};
