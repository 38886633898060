import React, { PureComponent, FormEvent, Fragment } from 'react';
import { cloneDeep } from 'lodash';
import { CoreFormGroup, CoreFormElementText } from '../../../shared/';

import { CoreDialogContentProps } from '../interfaces/';
import { DIALOG_CONTENT_PROPS } from '../constants';

/**
 * Dialog Instance Form Wrapper
 * @export
 * @class CoreDialogPromptWrapper
 * @extends {PureComponent<CoreDialogContentProps, {}>}
 */
export default class CoreDialogPromptWrapper extends PureComponent<CoreDialogContentProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreDialogContentProps}
   * @memberof CoreDialogPromptWrapper
   */
  public static defaultProps: CoreDialogContentProps = cloneDeep(DIALOG_CONTENT_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreDialogPromptWrapper
   */
  public render() {
    const { props, OnChange } = this;
    const { message, value } = props;
    return (
      <Fragment>
        <p>
          {message}
        </p>
        <CoreFormGroup>
          <CoreFormElementText name="" value={value} onChange={OnChange} placeholder="enter your answer" className="full-width"  />
        </CoreFormGroup>
      </Fragment>
    );
  }

  /**
   * On Change
   * @private
   * @param {FormEvent<any>} evt
   * @param {CoreFormValueType} value
   * @memberof CoreDialogPromptWrapper
   */
  /* istanbul ignore next */
  private OnChange = (evt: FormEvent<any>, value: CoreFormValueType): void => {
    const { dialog } = this.props;
    dialog.source = { value };
  };
}
