import { ErrorTypesEnum } from '../enums/';
import { EnumToArray } from '../../../../utils/';

export const FORM_CONTROLLER_ERROR_PROPS = {
  error: null,
  dirty: false
};

export const VALIDITY_BOOLEANS = ['badInput', 'patternMismatch', 'rangeOverflow', 'rangeUnderflow', 'stepMismatch', 'tooLong', 'tooShort', 'typeMismatch', 'valueMissing'];
export const EMPTY_VALIDITY: ValidityState = EnumToArray(ErrorTypesEnum).reduce((d: HashMap<boolean>, r: string) => { d[r] = false; return d; }, { valid: true });
export const DEFAULT_VALIDATION_MESSAGE: string = 'Please enter a valid value.';
