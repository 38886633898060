import React, { ComponentClass } from 'react';

import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';
import { CoreFormElementNumber, ControllerLabel, ControllerError } from '../../../components/';

/**
 * Number Input Controller
 * @export
 * @class NumberInputController
 * @extends {InputBase}
 */
export default class NumberInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof NumberInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementNumber;

  /**
   * Render
   * @returns
   * @memberof NumberInputController
   */
  public render() {
    const { OnIncrement, OnDecrement, ControlledElement, OnChangeOrInput, labels: LABELS, elements, exceptions, controller, kbd, tooltip, description } = this;
    const { name, value } = controller;
    const { htmlFor, ...labels } = LABELS; 
    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel htmlFor="_" {...labels}>
          <span className="as-input crements">
            <button className="increment" type="button" onClick={OnIncrement} title="+"/>
            <button className="decrement" type="button" onClick={OnDecrement} title="-"/>
            <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
          </span>
          <ControllerError {...exceptions} />
          {kbd}{tooltip}{description}
        </ControllerLabel>
      </ControllerWrapper>
    );
  }

  /**
   * On Increment
   * @protected
   * @memberof NumberInputController
   */
  /* istanbul ignore next */
  protected OnIncrement = (): void => {
    const { name, ref: { current }} = this.controller;
    const $: HTMLInputElement = current as HTMLInputElement;
    $.stepUp();
    const value: number = $.valueAsNumber;
    this.context.update(name, value);;
  }

  /**
   * On Decrement
   * @protected
   * @memberof NumberInputController
   */
  /* istanbul ignore next */
  protected OnDecrement = (): void => {
    const { name, ref: { current }} = this.controller;
    const $: HTMLInputElement = current as HTMLInputElement;
    $.stepDown();
    const value: number = $.valueAsNumber;
    this.context.update(name, value);;
  }
}
