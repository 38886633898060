export const AUTH_RETURN_TO: string = (() => {
  const location = (window as any).location;
  if (location.origin) {
    return location.origin;
  } else {
    return `${location.protocol}//${location.host}`;
  }
})();

export const AUTH_RESPONSE_TYPE : string= 'token id_token';
export const AUTH_SCOPE: string = 'openid email profile';
export const AUTH_THEME_PRIMARY_COLOR: string = '#E01F78';
export const AUTH_CONFIG_BASE_URL: string = 'https://cdn.auth0.com';
export const AUTH_REMEMBER: boolean = false;
export const AUTH_TITLE: string = 'Log In';
export const AUTH_LEEWAY: number = 120;
export const AUTH_LOCK_FORGOT_PATH: string = '/auth/forgot';

export const AUTH_LOCK_CONTAINER: string = 'auth_lock_container';
