import { FormEvent, FocusEvent } from 'react';

import { CoreFormControllerInstanceType, CoreFormElementType, CoreFormValueType, CoreFormOptionsType } from '../types';

import { CoreFormControllerInstance } from '../services/';
import { CoreFormControllerProps } from '../interfaces/';

import { CoreFormEventsEnum as E } from '../enums/';

import {
  TIMEZONES,
  OPTIONS_TYPES,
  NUMBER_TYPES,
  CHECKED_TYPES,
  RADIO_TYPES,
  INPUT_TYPE_CHECKBOX,
  INPUT_TYPE_COLOR,
  INPUT_TYPE_FILE,
  INPUT_TYPE_HIDDEN,
  INPUT_TYPE_NUMBER,
  INPUT_TYPE_RADIO,
  INPUT_TYPE_RANGE,
  INPUT_TYPE_TEXT,
  INPUT_TYPE_DATETIME,
  SELECT_TYPE_SELECT,
  SELECT_TYPE_MULTIPLE,
  TEXT_TYPE_AREA
} from '../constants/';

import { HashMapMergeProps, GetDeepHashMap, IsHashMap, IsDictionary, IsEqual, IsNull, IsDefined, IsEmpty, IsNumber, IsNumeric, IsBoolean, IsFunction, WaitForDelay } from '../../../../utils/';

/**
 * Controller Value From Props
 * @export
 * @param {GenericPropType} { type, value, options }
 * @returns {CoreFormValueType}
 */
export function ControllerValueFromProps({ type, value, options }: GenericPropType): CoreFormValueType {
  /**
   * Derive numeric status from props
   */
  let numeric: boolean = false;
  if (IsDefined(value) || (Array.isArray(options) && options.length)) {
    if (IsDefined(value)) {
      numeric = IsNumber(value) || IsNumeric(value);
    } else {
      numeric = IsNumber(options[0].value) || IsNumeric(options[0].value);
    }
  }

  /**
   * For datetime-local
   */
  if (type === INPUT_TYPE_DATETIME && IsDefined(value)) {
    if (!!~TIMEZONES.indexOf(value.slice(-1))) {
      value = value.slice(0, -1);
    }
  }

  /**
   * For options types
   */
  if (!!~OPTIONS_TYPES.indexOf(type)) {
    // multiple options need special handling
    if (type === SELECT_TYPE_MULTIPLE) {
      if (IsEmpty(value)) {
        return [];
      }
      if (numeric) {
        if (Array.isArray(value)) {
          value = value.map(v => !IsNumber(v) && IsNumeric(v) ? Number(v) : v);
        } else if (!IsNumber(value)) {
          value = IsNumeric(value) ? Number(value) : value;
        }
      }
      return Array.isArray(value) ? value : [ value ];
    } else {
      // special handling *if numeric
      if (numeric && !IsNumber(value)) {
        value = IsNumeric(value) ? Number(value) : value;
      }
    }
    return value;
  }

  /**
   * For number types
   */
  if (!!~NUMBER_TYPES.indexOf(type)) {
    if (!IsNumber(value)) {
      return IsNumeric(value) ? Number(value) : '';
    }
    return value;
  }

  /**
   * For checkbox types
   */
  if (!!~CHECKED_TYPES.indexOf(type)) {
    return IsBoolean(value) ? value : value === 'true';
  }

  /**
   * For Radio Types
   * - basic boolean value conversions
   */
  if (!!~RADIO_TYPES.indexOf(type)) {
    if (value === 'true' || value === 'false') {
      return value === 'true';
    }
  }

  /**
   * Set Default value;
   */
  if (!IsDefined(value)) {
    value = '';
  }

  return value;
}

/**
 * Controller Element Props Filter
 * @export
 * @param {CoreFormControllerProps} props
 * @param {(evt: FormEvent<any>, value: CoreFormValueType) => void} OnInput
 * @param {(evt: FocusEvent<any>, value: CoreFormValueType) => void} OnBlur
 * @returns
 */
export function ControllerElementPropsFilter(
    props: any,
    OnInput: (evt: FormEvent<any>, value: CoreFormValueType) => void,
    OnBlur: (evt: FocusEvent<any>, value: CoreFormValueType) => void
  ) {

  /**
   * Clone Props to make them mutable with global exceptions
   */
  const { type, value, options, label, filter, allornone, errors, children, onChange, onInput: ONINPUT, onBlur: ONBLUR, ...rest } = HashMapMergeProps(props);

  /**
   * Global adds
   * - OnBlur must be global since some elements only validate on blur.
   */
  rest['onBlur'] = OnBlur;

  if (IsDefined(ONINPUT)) {
    rest['onInput'] = OnInput;
  } 

  /**
   * Cover Transformative Boolean Props.
   */
  if (IsFunction(rest['required'])) {
    rest['required'] = rest['required']();
  }
  if (IsFunction(rest['disabled'])) {
    rest['disabled'] = rest['disabled']();
  }
  if (IsFunction(rest['readOnly'])) {
    rest['readOnly'] = rest['readOnly']();
  }

  switch(type) {
    case INPUT_TYPE_COLOR:
    case INPUT_TYPE_FILE:
    case INPUT_TYPE_RANGE:
      delete rest['onInput'];
      delete rest['options'];
      delete rest['placeholder'];
    break;
    case INPUT_TYPE_HIDDEN:
      delete rest['onInput'];
      delete rest['onBlur'];
      delete rest['options'];
      delete rest['placeholder'];
    break;
    case INPUT_TYPE_CHECKBOX:
      delete rest['onInput'];
      delete rest['onBlur'];
      delete rest['options'];
      delete rest['value'];
      delete rest['title'];
      delete rest['checked'];
      delete rest['placeholder'];
      break;
    case INPUT_TYPE_RADIO:
      delete rest['onInput'];
      delete rest['onBlur'];
      delete rest['options'];
      delete rest['value'];
      delete rest['title'];
      delete rest['checked'];
      delete rest['placeholder'];
      rest['tabIndex'] = -1;
      break;
    // don't delete options here.
    case SELECT_TYPE_MULTIPLE:
    case SELECT_TYPE_SELECT:
      delete rest['onInput'];
      delete rest['onBlur'];
      rest['tabIndex'] = -1;
    break;
    default:
      delete rest['options'];
      delete rest['checked'];
      break;
  }

  return rest;
}

/**
 * Controller Type Resolver
 * @export
 * @param {string} type
 * @param {CoreFormValueType} value
 * @param {CoreFormOptionsType} options
 * @param {boolean} [pseudo=false]
 * @returns {string}
 */
export function ControllerTypeResolver(type: string, value: CoreFormValueType, options: CoreFormOptionsType, pseudo: boolean = false): string {
  /* istanbul ignore next */
  if (pseudo) {
    return '';
  }
  if (!IsDefined(type)) {
    if (Array.isArray(options)) {
      if (Array.isArray(value)) {
        type = SELECT_TYPE_MULTIPLE;
      } else {
        type = SELECT_TYPE_SELECT;
      }
    } else if (IsBoolean(value)) {
      type = INPUT_TYPE_CHECKBOX;
    } else if (IsNumber(value)) {
      type = INPUT_TYPE_NUMBER;
    } else {
      type = INPUT_TYPE_TEXT;
    }
  }
  return type;
}

/**
 * Controller Value Resolver
 * @export
 * @param {string} type
 * @param {CoreFormValueType} value
 * @param {CoreFormValueType} existing
 * @returns {CoreFormValueType}
 */
export function ControllerValueResolver(type: string, value: CoreFormValueType, existing?: CoreFormValueType): CoreFormValueType {
  /**
   * Null Value
   */  
  if (IsNull(value)) {
    value = IsDefined(existing) ? existing : null;
  }
  /**
   * Checked Value
   */
  if (!!~CHECKED_TYPES.indexOf(type)) {
    if (!IsBoolean(value)) {
      value = value === 'true';
    }
    return value;
  }
  /**
   * For number
   */
  if (type === INPUT_TYPE_NUMBER) {
    if (IsNumber(value)) {
      return value;
    }
    return IsNumeric(value) ? Number(value) : '';
  }
  /**
   * For radios
   */
  if (!!~RADIO_TYPES.indexOf(type)) {
    return IsNumeric(value) && !IsNumber(value) ? Number(value) : value;
  }
  /**
   * For datetime-local
   */
  if (type === INPUT_TYPE_DATETIME && IsDefined(value)) {
    if (!!~TIMEZONES.indexOf((value as string).slice(-1))) {
      value = (value as string).slice(0, -1);
    }
  }
  return value;
}

/**
 * Controller Dirty Resolver
 * @export
 * @param {string} type
 * @param {boolean} dirty
 * @param {CoreFormValueType} value
 * @param {CoreFormOptionsType} options
 * @param {CoreFormValueType} VALUE
 * @param {CoreFormOptionsType} OPTIONS
 * @returns {boolean}
 */
export function ControllerDirtyResolver(type: string, dirty: boolean, value: CoreFormValueType, options: CoreFormOptionsType, VALUE: CoreFormValueType, OPTIONS: CoreFormOptionsType): boolean {
  if (dirty === false) {
    if (!!~OPTIONS_TYPES.indexOf(type)) {
      /* istanbul ignore next */
      if (value !== VALUE || !IsEqual(options, OPTIONS)) {
        dirty = true;
      }
    } else if (!!~CHECKED_TYPES.indexOf(type)) {
      /* istanbul ignore next */
      if (!IsEmpty(value)) {
        if (value !== VALUE) {
          dirty = true;
        }
      }
    } else {
      if (value !== VALUE) {
        dirty = true;
      }
    }
  }
  return dirty;
}

/**
 * Controller Generate Element
 * @export
 * @param {string} name
 * @param {*} [value='']
 * @param {CoreFormOptionsType} [options=null]
 * @param {boolean} [required=false]
 * @param {string} [type=INPUT_TYPE_TEXT]
 * @return {CoreFormElementType}
 */
export function ControllerGenerateElement(name: string, value: any = '', options: CoreFormOptionsType = null, required: boolean = false, type: string = INPUT_TYPE_TEXT): CoreFormElementType {
  let elem: string;
  switch(type) {
    case SELECT_TYPE_SELECT:
    case SELECT_TYPE_MULTIPLE:
      elem = 'select';
    break;
    case TEXT_TYPE_AREA:
      elem = 'textarea';
    break;
    default:
      elem = 'input';
      break;
  }

  const element: HTMLElement = document.createElement(elem);

  /**
   * Apply params to element.
   */
  element['name'] = name;
  element['value'] = value;
  element['required'] = required;

  /**
   * Options maker
   */
  let option: HTMLOptionElement;
  const MakeOptions = (opts: CoreFormOptionsType, selected: any) => {
    selected = Array.isArray(selected) ? selected : [selected];
    opts.forEach(({ label, value: VALUE }: CoreFormOptionType) => {
      option = document.createElement('option');
      option.value = VALUE as any;
      option.label = label;
      option.selected = !!~selected.indexOf(VALUE as any);
      (element as HTMLSelectElement).add(option);
    });
    element.setAttribute('value', selected.join(','));
  };

  /**
   * Must use accessor assignment to avoid false `valueMissing` ValidityState error when textarea is required.
   */
  switch(type) {
    case SELECT_TYPE_SELECT:
      MakeOptions(options, value);
    break;
    case SELECT_TYPE_MULTIPLE:
      element['multiple'] = true;
      MakeOptions(options, value);
    break;
    case INPUT_TYPE_CHECKBOX:
      element.setAttribute('type', type);
      element['checked'] = value === true;
    break;
    default:
      element.setAttribute('type', type);
      break;
  }

  return element as CoreFormElementType;
}

/**
 * Get/Make -> Init Controller Instance.
 * @param {CoreFormControllerProps} props
 * @param {CoreFormServiceType} context
 * @notes
 *  New controller instances MUST be initted before adding to registry.
 *  This ensures the controller is populated with the props not
 *  delivered by the source -> form -> controller pathway [Priority: Initial]
 *
 * 1. Default Values are applied 100% to new controllers
 * 2. Same is applied to existing controllers only if:
 *    a. static value is not declared.
 *    b. controller value is not supplied by source.
 */
export function ControllerGetMakeInit({ name, type, value, defaultValue, options, custom, required: REQUIRED }: CoreFormControllerProps, context: CoreFormServiceType): CoreFormControllerInstanceType {
  /**
   * Fix declared values for type.
   */
  if (IsDefined(value)) {
    value = ControllerValueFromProps({ type, value, options });
  } else if (IsDefined(defaultValue)) {
    defaultValue = ControllerValueFromProps({ type, value: defaultValue, options });
  }

  let controller: CoreFormControllerInstanceType;

  /**
   * Convert Mutable Props.
   */
  /* istanbul ignore next */
  const required: boolean = IsFunction(REQUIRED) ? (REQUIRED as any)() : REQUIRED;

  if (context.has(name)) {
    controller = context.registry.get(name);
    if (!IsDefined(value)) {
      /* istanbul ignore next */
      value = IsDefined(controller.value) ? controller.value : defaultValue || undefined;
    }
    controller.init(name, type, value, options, required);

    WaitForDelay().then(() => {
      context.emit(E.FORM_EVENT_UPDATE, name, value);
    });
  } else {
    controller = new CoreFormControllerInstance();
    if (IsDefined(defaultValue) && !IsDefined(value)) {
      value = defaultValue;
    }
    controller.init(name, type, value, options, required);
    context.add(controller);
  }

  /**
   * Artificially trigger custom error from fabricated controller event target.
   */
  if (IsDefined(custom)) {
    custom(controller.ref.current, controller.value);
    /* istanbul ignore next */
    WaitForDelay().then(() => {
      controller.emit(name, value);
    });
  }

  return controller;
}

/**
 * Controller Validate Props
 * @export
 * @param {CoreFormControllerProps} { name }
 * @param {CoreFormServiceType} context
 * @return {Error}
 */
export function ControllerValidateProps({ name }: CoreFormControllerProps, { source }: CoreFormServiceType): Error {
  const value: any = GetDeepHashMap(source, name);
  if(IsHashMap(value) || IsDictionary(value)) {
    return new TypeError(`A controller may not have a prop[name: ${name}] that conflicts with the supplied source map of type [HashMap||Dictionary].`);
  }
  return null;
}

/**
 * Controller Should Stash
 * @export
 * @param {CoreFormControllerProps} { name }
 * @param {CoreFormServiceType} form
 * @return {*} {boolean}
 */
export function ControllerShouldStash({ type, name }: CoreFormControllerProps, form: CoreFormServiceType): boolean {
  if (type === 'dict') {
    return false;
  }

  const segs: Array<string> = name.split('.');
  let seg: string;
  while (segs.length > 1) {
    segs.pop();
    seg = segs.join('.');
    if (form.has(seg)) {
      if (form.registry.get(seg).type === 'dict') {
        return false;
      }
    }
  }
  return true;
}