import React, { ComponentClass } from 'react';

import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormElementRadio, ControllerLabel } from '../../../components/';
import { CoreFormOptionShape } from '../../../interfaces/';
import { FormUniqueKey } from '../../../factories/';
import { MergeClassNames } from '../../../../../../utils/';

/**
 * Radio Input Controller
 * @export
 * @class RadioInputController
 * @extends {InputBase}
 */
export default class RadioInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof RadioInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementRadio;

  /**
   * Render
   * @returns
   * @memberof InputBase
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, controller } = this;
    const { name, options, value: VALUE } = controller;
    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel {...labels} htmlFor="_">
          {options.map(({ label, value, title }: CoreFormOptionShape, ndx: number) => {
            const checked: boolean = value === VALUE;
            const key = FormUniqueKey('rb', name, value, checked);
            const clz = MergeClassNames('', { checked });
            return (
              <label key={key} className={clz}>
                <span>{label}</span>
                <ControlledElement name={name} value={value} checked={checked} title={title} onChange={OnChangeOrInput} {...elements} />
              </label>
            );
          })}
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
