import React from 'react';

import CODES from '../../../../json/status_codes.json';

import SelectWidgetBase from './SelectWidgetBase';

import { CoreSelect } from '../../containers';

/**
 * Select Status Code Container
 * @export
 * @class CoreSelectStatusCodeContainer
 * @extends {SelectWidgetBase}
 */
export default class CoreSelectStatusCodeContainer extends SelectWidgetBase {
  /**
   * SRC
   * @override
   * @static
   * @memberof CoreSelectCountryContainer
   */
  public static SRC = CODES;

  /**
   * Render
   * @returns
   * @memberof CoreSelectStatusCodeContainer
   */
  public render() {
    const { options, props: { type, options: OPTIONS, value: VALUE, children, ...rest}} = this;

    let opts: CoreFormOptionsType = [].concat(options.slice(0) as any);

    /* istanbul ignore next */
    if (Array.isArray(OPTIONS) && OPTIONS.length) {
      opts = OPTIONS.concat(opts);
    }

    return <CoreSelect options={opts} {...rest} />;
  }
}
