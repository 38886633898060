export const DATE_RANGE_DISPLAY_SHORT_FORMAT: string = 'MMM Do';
export const DATE_RANGE_DISPLAY_LONG_FORMAT: string = 'MMM Do, YYYY';

export const DATE_TIME_RANGE_DISPLAY_LONG_FORMAT = `MM.DD.YY, HH:mm`;
export const DATE_TIME_RANGE_DISPLAY_SHORT_FORMAT = `MMM D. HH:mm`;

export const DATE_FORMAT_DATE = 'YYYY-MM-DD';
export const DATE_FORMAT_TIME = 'HH:mm:ss';


export const PICKER_STATE = {
  active: false
};

export const PICKER_PROPS = {
  progressive: false,
  className: ''
};
