import React, { PureComponent, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';
import CoreAnchor from './Anchor';
import { CoreNavigate } from '../../Router/';

import { DelayedLinkProps } from '../interfaces/';

import { DELAYED_LINK_PROPS } from '../constants';

import { WaitForDelay, Noop } from '../../utils/';

/**
 * Delayed Link
 * @export
 * @class CoreDelayedLinkComponent
 * @extends {PureComponent<DelayedLinkProps, {}>}
 */
export default class CoreDelayedLinkComponent extends PureComponent<DelayedLinkProps, {}> {
  /**
   * Default Props
   * @static
   * @type {DelayedLinkProps}
   * @memberof CoreDelayedLinkComponent
   */
  public static defaultProps: DelayedLinkProps = cloneDeep(DELAYED_LINK_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreDelayedLinkComponent
   */
  public render() {
    const { props, OnClick } = this;
    const { children } = props;
    return <CoreAnchor onClick={OnClick}>{children}</CoreAnchor>;
  }

  /**
   * On Click
   * @protected
   * @param {MouseEvent<HTMLAnchorElement>} evt
   * @memberof CoreDelayedLinkComponent
   */
  /* istanbul ignore next  */
  protected OnClick = (evt: MouseEvent<HTMLAnchorElement>): void => {
    const { selector, link, delay, add } = this.props;
    const $elem = document.querySelector(selector);
    $elem.classList.toggle(add);
    WaitForDelay(delay).then(() => {
      CoreNavigate(link);
    }).catch(Noop);
  };
}
