export const Noop: Func<any> = (): void => void 0;
export const NooPromiseResolve: { <T>(value: T | PromiseLike<T>): Promise<T>; (): Promise<void> } = Promise.resolve;
export const NooPromiseReject: <T = never>(reason?: any) => Promise<T> = Promise.reject;

/**
 * Wait For Delay
 * @param {number} [delay=0]
 * @returns {Promise<void>}
 */
export const WaitForDelay = (delay: number = 0): Promise<void> => {
  return new Promise((resolve) => {
    const T: number = (window as any).setTimeout(() => {
      (window as any).clearTimeout(T);
      resolve();
    }, delay);
  });
};

/**
 * Deferred Wait For Delay
 * @export
 * @class WaitForDelayPromise
 */
export class WaitForDelayPromise {
  /**
   * Promise
   * @private
   * @type {Promise<void>}
   * @memberof WaitForDelayPromise
   */
  private _promise: Promise<void>;
  /**
   * Delay
   * @private
   * @type {number}
   * @memberof WaitForDelayPromise
   */
  private delay: number;
  /**
   * Resolver
   * @private
   * @memberof WaitForDelayPromise
   */
  private _resolve: (value?: void | PromiseLike<void>) => void;
  /**
   * Rejector
   * @private
   * @memberof WaitForDelayPromise
   */
  private _reject: (reason?: any) => void;

  /**
   * Creates an instance of WaitForDelayPromise.
   * @param {number} [delay=0]
   * @memberof WaitForDelayPromise
   */
  constructor(delay: number = 0) {
    const $this = this;
    $this.delay = delay;
    $this._promise = new Promise<any>((resolve, reject) => {
      $this._resolve = resolve;
      $this._reject = reject;
      const T: number = (window as any).setTimeout(() => {
        (window as any).clearTimeout(T);
        $this.resolve();
      }, $this.delay);
    });
  }

  /**
   * promise - getter
   * @readonly
   * @memberof WaitForDelayPromise
   */
  public get promise(): Promise<void> {
    // apply a catch here to prevent anon error handling.
    return this._promise.catch(Noop);
  }

  /**
   * resolve - getter
   * @readonly
   * @memberof WaitForDelayPromise
   */
  public get resolve(): (value?: void | PromiseLike<void>) => void {
    return this._resolve;
  }

  /**
   * reject - getter
   * @readonly
   * @memberof WaitForDelayPromise
   */
  public get reject(): (reason?: any) => void {
    return this._reject;
  }
}

/**
 * Async Delay Execution
 * @export
 * @class AsyncDelayExecution
 */
export class AsyncDelayExecution {
  /**
   * Delay
   * @private
   * @type {number}
   * @memberof AsyncDelayExecution
   */
  private _delay: number; // ms

  /**
   * Timeout
   * @private
   * @type {number}
   * @memberof AsyncDelayExecution
   */
  private T: number = null; //

  /**
   * Operation
   * @private
   * @type {Func<any>}
   * @memberof AsyncDelayExecution
   */
  private _operation: Func<any>;

  /**
   * Creates an instance of AsyncDelayExecution.
   * @param {number} [delay=100]
   * @memberof AsyncDelayExecution
   */
  constructor(delay: number = 100) {
    this._delay = delay;
  }

  /**
   * Use
   * @param {Func<any>} operation
   * @returns {AsyncDelayExecution}
   * @memberof AsyncDelayExecution
   */
  public use(operation: Func<any>): AsyncDelayExecution {
    if (!this.operation) {
      this.operation = operation;
    }
    return this;
  }

  /**
   * Exec
   * @memberof AsyncDelayExecution
   */
  public exec() {
    try {
      if (!this._operation) {
        throw new ReferenceError('missing operation.');
      }

      const { delay } = this;

      if (this.T) {
        /* istanbul ignore next */
        this.clear();
      }

      this.T = (window as any).setTimeout(() => {
        this.operation();
        this.clear();
      }, delay);
    } catch (err: any) {
      console.error(err);
    }
  }

  /**
   * Clear
   * @private
   * @memberof AsyncDelayExecution
   */
  private clear() {
    (window as any).clearTimeout(this.T);
    this.T = null;
  }

  /**
   * Operation - getter
   * @memberof AsyncDelayExecution
   */
  public get operation() {
    return this._operation;
  }

  /**
   * Operation - setter
   * @memberof AsyncDelayExecution
   */
  public set operation(operation: Func<any>) {
    this._operation = operation;
  }

  /**
   * Delay - getter
   * @readonly
   * @protected
   * @memberof AsyncDelayExecution
   */
  protected get delay() {
    return this._delay;
  }
}

/**
 * Async Component Timeout
 * @export
 * @class AsyncComponentTimeout
 */
export class AsyncComponentTimeout {
  /**
   * Timeout
   * @protected
   * @type {number}
   * @memberof AsyncComponentTimeout
   */
  protected _timeout: number = null;

  /**
   * Delay
   * @protected
   * @type {number}
   * @memberof AsyncComponentTimeout
   */
  protected _delay: number;

  /**
   * Creates an instance of AsyncComponentTimeout.
   * @param {number} [delay=0]
   * @memberof AsyncComponentTimeout
   */
  constructor(delay: number = 0) {
    this._delay = delay;
  }

  /**
   * Delay
   * @param {number} delay
   * @returns {AsyncComponentTimeout}
   * @memberof AsyncComponentTimeout
   */
  public delay(delay: number): AsyncComponentTimeout {
    this._delay = delay;
    return this;
  }

  /**
   * Then
   * @param {Func<any>} callback
   * @returns {AsyncComponentTimeout}
   * @memberof AsyncComponentTimeout
   */
  public then(callback: Func<any>): AsyncComponentTimeout {
    try {
      /* istanbul ignore next */
      if (typeof this._delay !== 'number') {
        throw new TypeError(`AsyncComponentTimeout - must set delay before calling 'then'`);
      }
      /* istanbul ignore next */
      if (this._timeout) {
        this.clear();
      }
      this._timeout = (window as any).setTimeout(() => {
        callback();
        this.clear();
      }, this._delay);
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
    return this;
  }

  /**
   * Clear
   * @memberof AsyncComponentTimeout
   */
  public clear(): void {
    (window as any).clearTimeout(this._timeout);
    this._timeout = null;
  }
}
