import React from 'react';

import CoreToggleTitle from './Title';
import { MergeClassNames } from '../../../../utils/';

/**
 * Core Toggle Title Mini
 * @export
 * @class CoreToggleTitleMini
 * @extends {Component<CoreToggleProps, CoreToggleState>}
 */
export default class CoreToggleTitleMini extends CoreToggleTitle {
  /**
   * Display Name
   * @static
   * @memberof CoreToggleTitleMini
   */
  public static displayName = 'CoreToggleTitle';

  /**
   * Render
   * @returns
   * @memberof CoreToggleTitleMini
   */
  public render() {
    const {
      toggle,
      context: { active },
      props: { children },
    } = this;

    const clz = MergeClassNames('', { 'toggle-title': true, active });

    return (
      <dt className={clz}>
        <div className="toggle-title-mini-container" onClick={toggle}>
          <mark />
          <output>{children}</output>
        </div>
      </dt>
    );
  }
}
