import React, { PureComponent } from 'react';

import { CoreFormGroup } from '../Groups/';
import CoreFormReset from './Reset';
import CoreFormSave from './Save';

import { CoreFormSaveGroupProps } from '../../interfaces/';
import { SAVE_GROUP_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Save Group Container
 * @export
 * @class CoreFormSaveGroupContainer
 * @extends {PureComponent<CoreFormSaveGroupProps, {}>}
 */
export default class CoreFormSaveGroupContainer extends PureComponent<CoreFormSaveGroupProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreFormSaveGroupProps}
   * @memberof CoreFormSaveGroupContainer
   */
  public static defaultProps: CoreFormSaveGroupProps = HashMapMergeProps(SAVE_GROUP_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreFormSaveGroupContainer
   */
  public render() {
    const { reset, submit } = this.props;
    return (
      <CoreFormGroup className="save-group">
        <nux-button-group angled={true}>
          <CoreFormReset label={reset} />
          <CoreFormSave label={submit} />
        </nux-button-group>
      </CoreFormGroup>
    );
  }
}
