import React from 'react';

import { CoreLink } from '../../Router/';

import CoreNavItemBase from './ItemBase';

import { ArePathsMatched } from '../factories/';

import { MergeClassNames } from '../../utils/';

/**
 * Nav Item Route Component
 * @export
 * @class CoreNavItemRouteComponent
 * @extends {CoreNavItemBase<Component<CoreNavItemProps, any>>}
 */
export default class CoreNavItemRouteComponent extends CoreNavItemBase {
  /**
   * Render
   * @returns
   * @memberof CoreNavItemRouteComponent
   */
  public render() {
    const {
      GetProps,
      OnItemClick,
      props: {
        name,
        item: { label, path },
      },
    } = this;

    return (
      <CoreLink to={`/${path}`} getProps={GetProps(name)} onClick={OnItemClick}>
        <span>{label}</span>
      </CoreLink>
    );
  }

  /**
   * Get Props
   * @protected
   * @returns {Func<HashMap<string>, CoreLinkGetProps>}
   * @memberof CoreNavItemRouteComponent
   */
  protected GetProps = (name: string): Func<HashMap<string>, CoreLinkGetProps> => ({ isCurrent, location: { pathname } }: CoreLinkGetProps): HashMap<string> => {
    const active: boolean = isCurrent ? true : ArePathsMatched(pathname, name);
    const clz = MergeClassNames('', { [name]: true, active });
    return { className: clz };
  };
}
