import React, { PureComponent, Fragment } from 'react';

import BootMainModuleRouter from '../routes';

import { CoreBootLogoTitle } from '../components/';

import { CoreConfig } from '../../../Config/';
import CoreConfigContext from '../../../Config/context';
import { CoreFooterModule } from '../../../Footer/';
import { CoreLogo, CoreMarqueeModule } from '../../../shared/';

import FusionLoginLoader from './FusionLogin';

class StandardLogin extends React.Component {
  render() {
    return (
      <section className="auth">
        <header>
          <h1>
            <CoreLogo />
          </h1>
        </header>
        <article>
          <div>
            <div className="auth-main">
              <CoreBootLogoTitle />
              <CoreMarqueeModule items={CoreConfig.meta.marquee} />
            </div>
            <div className="auth-form router-animation-container">
              <BootMainModuleRouter />
            </div>
          </div>
        </article>
      </section>
    );
  }
}

/**
 * Boot Page
 * @export
 * @class BootMainPage
 * @extends {PureComponent<{}, {}>}
 */
export default class BootMainPage extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof BootMainPage
   */
  state = {
    showFusionLogin: !CoreConfig.meta.whitelabeled,
  };

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof FusionLoginLoader
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  handleFusionError() {
    this.setState({ showFusionLogin: false });
  }

  public render() {
    const {
      context: {
        app: { fusion_endpoint, fusion_timeout },
      },
    } = this;
    return (
      <Fragment>
        <main>
          {this.state.showFusionLogin ? (
            <FusionLoginLoader url={fusion_endpoint} timeout={fusion_timeout} onError={this.handleFusionError.bind(this)}>
              <div className="fusion-auth-form router-animation-container">
                <BootMainModuleRouter />
              </div>
            </FusionLoginLoader>
          ) : (
            <StandardLogin />
          )}
        </main>
        <CoreFooterModule />
      </Fragment>
    );
  }
}
