import React, { PureComponent, StrictMode } from 'react';

import { CoreAuthWrapper } from '../../Auth/';
import { CoreActivityModule } from '../../Activity/';
import { CoreConfigWrapper } from '../../Config/';
import { CoreFooterModule } from '../../Footer/';
import { CoreInteropWrapper } from '../../Interop/';
import { CoreHeaderModule } from '../../Header/';
import { CoreMainModule } from '../../Main/';

import { CoreBootAppProps } from './interfaces/';

// ignore
class Noop extends PureComponent<{}, {}> {}

/**
 * Boot App Module
 * @export
 * @class CoreBootAppModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBootAppModule extends PureComponent<CoreBootAppProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreBootAppProps}
   * @memberof CoreBootAppModule
   */
  public static defaultProps: CoreBootAppProps = { App: Noop };

  /**
   * Render
   * @returns
   * @memberof CoreBootAppModule
   */
  public render() {
    const { App } = this.props;
    // console.debug('** APP **');

    return (
      <StrictMode>
        <CoreConfigWrapper>
          <CoreAuthWrapper>
            <CoreInteropWrapper>
              <CoreHeaderModule />
              <CoreMainModule>
                <App />
              </CoreMainModule>
              <CoreFooterModule />
              <CoreActivityModule />
            </CoreInteropWrapper>
          </CoreAuthWrapper>
        </CoreConfigWrapper>
      </StrictMode>
    );
  }
}
