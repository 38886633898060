import { CoreSectionHeaderState } from '../interfaces/';
import { CoreLabelFromPath } from './LabelFromPath';

/**
 * State From Navigation
 * @param {string} type
 * @returns {CoreSectionHeaderState}
 */
export const CoreStateFromNavigationFactory = (type: string): CoreSectionHeaderState => {
  const label: string = CoreLabelFromPath(type);
  const items: any[] = [];
  return { label, items };
};
