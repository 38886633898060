import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import { CoreDialogsProps } from '../interfaces/';

import { DIALOGS_PROPS, DIALOG_TYPE_ALERT } from '../constants';

/**
 * Dialog Instance Footer Container
 * @export
 * @class CoreDialogFooterContainer
 * @extends {Component<CoreDialogsProps, {}>}
 */
export default class CoreDialogFooterContainer extends Component<CoreDialogsProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreDialogsProps}
   * @memberof CoreDialogFooterContainer
   */
  public static defaultProps: CoreDialogsProps = cloneDeep(DIALOGS_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreDialogFooterContainer
   */
  public render() {
    const { dialog } = this.props;
    const { type, labels } = dialog;

    if (type === DIALOG_TYPE_ALERT) {
      return (
        <footer>
          <label>
            <nux-button-primary angled={true} onClick={dialog.onAffirmAction} disabled={!dialog.valid}>
              {labels.yes}
            </nux-button-primary>
          </label>
        </footer>
      );
    }

    return (
      <footer>
        <nux-button-group angled={true}>
          <nux-button-secondary onClick={dialog.onDenyAction}>
            {labels.no}
          </nux-button-secondary>
          <nux-button-primary onClick={dialog.onAffirmAction} disabled={!dialog.valid}>
            {labels.yes}
          </nux-button-primary>
        </nux-button-group>
      </footer>
    );
  }
}
