import React, { PureComponent, lazy } from 'react';

import { CoreMainRouter, CoreRedirect } from '@neustar/core-ui';

const HomeModule = lazy(() => import('./Home'));
const ArchitectureModule = lazy(() => import('./Architecture'));
const FeaturesModule = lazy(() => import('./Features/'));
const GraphModule = lazy(() => import('./Graph/'));
const StylesModule = lazy(() => import('./Styles/'));

/**
 * App Module
 * @class App
 * @extends {PureComponent<{}, {}>}
 */
export default class AppModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof AppModule
   */
  public render() {
    return (
      <CoreMainRouter>
        <HomeModule path="home/*" />
        <ArchitectureModule path="architecture/*" />
        <FeaturesModule path="features/*" />
        <GraphModule path="graph/*" />
        <StylesModule path="styles/*" />
        <CoreRedirect from="/" to="home" />
      </CoreMainRouter>
    );
  }
}
