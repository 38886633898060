import React, { ComponentClass } from 'react';
import ReactDOM from 'react-dom';

import CoreBoot from './service';
import { CoreBootAsyncLoader, CoreBootError } from './factories/';
import { CoreBootEventEnum } from './enums/';

import { CoreBootEmptyModule } from './Empty/';
import { CoreBootMainModule } from './Main/';
import { CoreBootAppModule } from './App/';

/**
 * Core Boot Loader
 * @export
 * @param {ComponentClass<any, any>} App
 * @param {CoreAuthPluginType} AuthPlugin
 * @param {...CoreBootPluginsType[]} plugins
 */
export default function CoreBootLoader(App: ComponentClass<any, any>, AuthPlugin: CoreAuthPluginType, ...plugins: CoreBootPluginsType[]): void {
  // Establish root element
  /* istanbul ignore next */
  const $root = document.getElementById('root') as HTMLElement;


  /* istanbul ignore next */
  try {
    /**
     * Load Empty Module
     * @notes - Minimal display with busy screen while plugins process
     */
    ReactDOM.render(<CoreBootEmptyModule />, $root);

    /**
     * Event Callback
     * @notes - Loads App once authenticated
     */
    const OnAuthenticate = () => {
      ReactDOM.render(<CoreBootAppModule key={`.${Date.now()}`} App={App} />, $root);
    };

    /**
     * Event Callback
     * @notes - Unloads App once un-authenticated
     */
    const OnUnauthenticate = () => {
      ReactDOM.render(<CoreBootMainModule key={`.${Date.now()}`} />, $root);
    };

    /**
     * Apply Event Callbacks
     */
    CoreBoot.OnAuthenticate(OnAuthenticate);
    CoreBoot.OnUnauthenticate(OnUnauthenticate);

    /**
     * Start Async Loader
     */
    CoreBootAsyncLoader(AuthPlugin, plugins)
      .then(() => {
        CoreBoot.emit(CoreBootEventEnum.AUTHENTICATED);
      })
      .catch((err: any) => {
        if (err.message === CoreBootEventEnum.UNAUTHENTICATED) {
          CoreBoot.emit(CoreBootEventEnum.UNAUTHENTICATED);
        } else {
          CoreBootError(err);
        }
      });
  } catch (err: any) {
    console.error(err);
  }
}
