import React, { PureComponent } from 'react';

import { CoreFooterNavLeft, CoreFooterNavRight } from '../components/';

/**
 * Footer Nav Container
 * @export
 * @class CoreFooterNavContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreFooterNavContainer extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreFooterNavContainer
   */
  public render() {
    return (
      <nav>
        <ul>
          <li>
            <CoreFooterNavLeft />
          </li>
          <li>
            <CoreFooterNavRight />
          </li>
        </ul>
      </nav>
    );
  }
}
