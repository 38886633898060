import { CoreUserInfoShape } from '../interfaces/';

import { ObjectKeys } from '../../utils/';

/**
 * Connect User Profile
 * @export
 * @param {HashMap<any>} userinfo
 * @returns {CoreUserInfoShape}
 */
export const CoreAuth0UserProfile = (userinfo: HashMap<any>): CoreUserInfoShape => {
  // Normalize raw userinfo into CoreUserInfoShape
  let value: any = null;
  const profile: CoreUserInfoShape = ObjectKeys(userinfo).reduce((d: CoreUserInfoShape, prop: string) => {
    value = userinfo[prop];
    if (/^https/.test(prop)) {
      prop = prop.split(/\//).pop()!;
    }
    d[prop] = value;
    return d;
  }, {});

  return profile;
};
