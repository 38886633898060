import React, { PureComponent } from 'react';

import CoreFormControllerContext from '../../contexts/Controller';

import { CoreFormControllerWrapperProps } from '../../interfaces/';

/**
 * Controller Wrapper
 * @export
 * @class CoreFormControllerWrapper
 * @extends {PureComponent<CoreFormControllerWrapperProps, {}>}
 */
export default class CoreFormControllerWrapper extends PureComponent<CoreFormControllerWrapperProps, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreFormControllerWrapper
   */
  public render() {
    const { controller, children } = this.props;
    return (
      <CoreFormControllerContext.Provider value={controller}>
        {children}
      </CoreFormControllerContext.Provider>
    );
  }
}
