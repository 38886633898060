import React, { Component } from 'react';

import CoreDialogContext from './context';
import { CoreDialogProviderProps } from './interfaces/';
import { CoreDialogInstance } from './factories';

/**
 * Dialog Provider
 * @export
 * @class CoreDialogProvider
 * @extends {Component<CoreDialogProviderProps, {}>}
 */
export class CoreDialogProvider extends Component<CoreDialogProviderProps, {}> {
  /**
   * Dialog
   * @private
   * @type {CoreDialogInstance}
   * @memberof CoreDialogProvider
   */
  private dialog: CoreDialogInstance;

  /**
   * Creates an instance of CoreDialogProvider.
   * @param {CoreDialogProviderProps} props
   * @memberof CoreDialogProvider
   */
  constructor(props: CoreDialogProviderProps) {
    super(props);
    const type: string = props.type || undefined!;
    const mode: string = props.mode || undefined!;

    this.dialog = new CoreDialogInstance(props.content, type, mode);
    if (props.title) {
      this.dialog.title = props.title;
    }
    if (props.labels) {
      this.dialog.labels = props.labels;
    }
    if (props.name) {
      this.dialog.name = props.name;
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreDialogProvider
   */
  public render() {
    const { children } = this.props;
    return (
      <CoreDialogContext.Provider value={this.dialog}>
        <CoreDialogContext.Consumer children={children as any} />
      </CoreDialogContext.Provider>
    );
  }
}

export const CoreDialogConsumer = CoreDialogContext.Consumer;
