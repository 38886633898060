import React, { PureComponent, Fragment } from 'react';

import CoreFormContext from '../../contexts/Form';

import { CoreFormEventsEnum as E } from '../../enums/';
import { FORM_EVENT_DELAY } from '../../constants/';

import { CoreFormToggleProps } from './interfaces';
import { FORM_TOGGLE_PROPS } from './constants';

import { IsFunction, IsDefined, WaitForDelay, HashMapMergeProps } from '../../../../../utils/';

/**
 * Toggle Component
 * @export
 * @class CoreFormToggleComponent
 * @extends {PureComponent<CoreFormToggleProps, {}>}
 */
export default class CoreFormToggleComponent extends PureComponent<CoreFormToggleProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof CoreFormToggleComponent
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Default Props
   * @static
   * @type {CoreFormToggleProps}
   * @memberof CoreFormToggleComponent
   */
  public static defaultProps: CoreFormToggleProps = HashMapMergeProps<CoreFormToggleProps>(FORM_TOGGLE_PROPS);

  /**
   * Hidden
   * @protected
   * @type {boolean}
   * @memberof CoreFormToggleComponent
   */
  protected hidden: boolean;

  /**
   * Abort Controller
   * @protected
   * @type {AbortController}
   * @memberof CoreFormToggleComponent
   */
  protected listener: AbortController;

  /**
   * Creates an instance of CoreFormToggleComponent.
   * @param {CoreFormToggleProps} props
   * @param {CoreFormServiceType} context
   * @memberof Fuck
   */
  constructor(props: CoreFormToggleProps, context: CoreFormServiceType) {
    super(props);
    if (props.onChangeHide && IsFunction(props.onChangeHide)) {
      this.hidden = props.onChangeHide(context);
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CoreFormToggleComponent
   */
  public componentDidMount() {
    const { FormEventListener, context, props } = this;
    const { watch, onChangeHide } = props;

    this.listener = new AbortController();
    context.emitter.on(E.FORM_EVENT_UPDATE, FormEventListener);

    try {
      /* istanbul ignore next */
      if (!IsDefined(watch)) {
        throw new ReferenceError('CoreFormToggle - missing required prop [watch: string]');
      }
      /* istanbul ignore next */
      if (!IsDefined(onChangeHide)) {
        throw new ReferenceError('CoreFormToggle - missing required prop [onChangeHide: Func<boolean>]');
      }
      /* istanbul ignore next */
      if (!IsFunction(onChangeHide)) {
        throw new ReferenceError('CoreFormToggle - required prop [onChangeHide: Func<boolean>] must be a function.');
      }

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    context.emitter.off(E.FORM_EVENT_UPDATE, FormEventListener);
    listener.abort();
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  /* istanbul ignore next */
  public componentDidCatch() {
    const { FormEventListener, context, listener } = this;
    context.emitter.off(E.FORM_EVENT_UPDATE, FormEventListener);
    listener.abort();
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormToggleComponent
   */
  public render() {
    const { props: { children } } = this;

    if (this.hidden) {
      return null;
    }
    /* istanbul ignore next */
    return <Fragment>{children}</Fragment>;
  }

  /**
   * Form Event Listener
   * @protected
   * @callback
   * @memberof CoreFormToggleComponent
   */
  /* istanbul ignore next */
  protected FormEventListener = (name: string) => {
    const { props } = this;

    /**
     * Restrict listener reponse to supplied name.
     */
    if (name !== props.watch) {
      return void 0;
    }

    if (this.listener) {
      this.listener.abort();
    }
    this.listener = new AbortController();
    /* istanbul ignore next */
    WaitForDelay(FORM_EVENT_DELAY).then(() => {
      if (!this.listener.signal.aborted) {
        this.hidden = this.props.onChangeHide(this.context);
        this.forceUpdate();
      }
    });
  };
}
