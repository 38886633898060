import CoreTableCollectionService from './collection';
import CoreTableColumnsService from './columns';
import CoreTableSearchService from './search';
import CoreTableSortService from './sort';
import CoreTablePaginationService from './pagination';
import CoreTableSubscription from './subscription';
import { CoreTableSourceType } from '../types';

import {
  CoreTableServiceShape,
  CoreTableModeType,
  CoreListShape,
  CoreListPaginateShape,
  CoreListSortShape,
  CoreListSearchShape,
} from '../interfaces/';

import { WaitForDelayPromise, IsHashMap } from '../../../../utils/';

/**
 * Table Service
 * @export
 * @class CoreTableService
 * @implements {CoreTableServiceShape}
 */
export default class CoreTableService extends CoreTableSubscription implements CoreTableServiceShape {
  /**
   * Columns
   * @private
   * @memberof CoreTableService
   */
  private _columns = new CoreTableColumnsService();

  /**
   * Collection
   * @private
   * @memberof CoreTableService
   */
  private _collection = new CoreTableCollectionService();

  /**
   * Search Service
   * @private
   * @memberof CoreTableService
   */
  private _search = new CoreTableSearchService();

  /**
   * Sorting
   * @private
   * @memberof CoreTableService
   */
  private _sort = new CoreTableSortService();

  /**
   * Pagination
   * @private
   * @memberof CoreTableService
   */
  private _pagination = new CoreTablePaginationService();

  /**
   * Creates an instance of CoreTableService.
   * @param {CoreTableSourceType} [source]
   * @memberof CoreTableService
   */
  constructor(source?: CoreTableSourceType) {
    super();
    this.use(source);
  }

  /**
   * Use
   * @param {CoreTableSourceType} source
   * @param {CoreTableModeType} [mode]
   * @returns {CoreTableService}
   * @memberof CoreTableService
   */
  public use(source: CoreTableSourceType, mode?: CoreTableModeType): CoreTableService {
    try {
      if (mode) {
        this._mode = mode;
      }

      if (source) {
        /**
         * When a table service instance is passed, all this work is already done.
         */
        if (source instanceof CoreTableService) {
          return this;
        }

        if (Array.isArray(source)) {
          this._collection.update(source);
        } else {
          if ('results' in source) {
            const isList = (src: any): src is CoreListShape => src.results !== undefined && Array.isArray(src.results);
            /* istanbul ignore next */
            if (!isList(source)) {
              throw new TypeError(`ensure source conforms to the 'CoreListShape' interface.`);
            }
            this._collection.update(source.results);
          }

          if ('marked' in source) {
            const isList = (src: any): src is CoreListShape => src.marked !== undefined && Array.isArray(src.marked);
            /* istanbul ignore next */
            if (!isList(source)) {
              throw new TypeError(`ensure source conforms to the 'CoreListShape' interface.`);
            }
            /* istanbul ignore next */
            if (source.marked.length) {
              const sample: HashMap<any> = source.marked[0];
              if (!IsHashMap(sample)) {
                throw new TypeError(`marked values in 'CoreListShape' must be HashMaps.`);
              }
            }
            this._collection.marked = source.marked;
          }

          if ('search' in source) {
            const isListSearch = (src: any): src is CoreListSearchShape => src.search.term !== undefined && src.search.fields !== undefined;
            /* istanbul ignore next */
            if (!isListSearch(source)) {
              throw new TypeError(`ensure source.search conforms to the 'CoreListSearchShape' interface.`);
            }
            const { term, fields } = (source as any).search;
            this._search.update(term, fields);
          }

          if ('sort' in source) {
            const isListSort = (src: any): src is CoreListSortShape => src.sort.column !== undefined && src.sort.method !== undefined;
            /* istanbul ignore next */
            if (!isListSort(source)) {
              throw new TypeError(`ensure source.sort conforms to the 'CoreListSortShape' interface.`);
            }
            const { column, method } = (source as any).sort;
            this._sort.add(column);
            this._sort.init(column, method);
          }

          if ('paginate' in source) {
            const isListPaginate = (src: any): src is CoreListPaginateShape => src.paginate.page !== undefined && src.paginate.limit !== undefined;
            /* istanbul ignore next */
            if (!isListPaginate(source)) {
              throw new TypeError(`ensure source.paginate conforms to the 'CoreListPaginateShape' interface.`);
            }
            const { page, limit, count } = (source as any).paginate;
            this._pagination.update(page, limit, count);
          }

          /**
           * Deferred Promise
           */
          /* istanbul ignore next */
          if (this.deferred) {
            this.deferred.reject();
          }

          this.deferred = new WaitForDelayPromise(100);

          this.promise.then(() => {
            this.process();
          });
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      console.error(err);
    }
    return this;
  }

  /**
   * Process
   * @memberof CoreTableService
   */
  public process(): void {
    const { mode, pagination, collection: { results } } = this;
    const { pageable, page, limit } = pagination;

    if (mode === 'flat') {
      if (pageable || pagination.count === 0) {
        pagination.update(page, limit, results.length);
      }
    }

    // tell table
    this.broadcast();
  }

  /**
   * Columns - getter
   * @readonly
   * @type {number}
   * @memberof CoreTableService
   */
  public get columns() {
    return this._columns;
  }

  /**
   * Collection - getter
   * @readonly
   * @type {CoreTableCollectionService}
   * @memberof CoreTableService
   */
  public get collection() {
    return this._collection;
  }

  /**
   * Search - getter
   * @readonly
   * @type {CoreTableSearchService}
   * @memberof CoreTableService
   */
  public get search() {
    return this._search;
  }

  /**
   * Sort - getter
   * @readonly
   * @type {CoreTableSortService}
   * @memberof CoreTableService
   */
  public get sort() {
    return this._sort;
  }

  /**
   * Pagination - getter
   * @readonly
   * @type {CoreTablePaginationService}
   * @memberof CoreTableService
   */
  public get pagination() {
    return this._pagination;
  }

  /**
   * List - getter
   * @readonly
   * @type {CoreListConfigShape}
   * @memberof CoreTableService
   */
  /* istanbul ignore next */
  public get list() {
    const search = this.search.toHashMap();
    const sort = this.sort.toHashMap();
    const paginate = this.pagination.toHashMap();
    return { search, sort, paginate };
  }

  /**
   * Source - getter
   * @readonly
   * @type {CoreListShape}
   * @memberof CoreTableService
   */
  /* istanbul ignore next */
  public get source() {
    const results = this.collection.results;
    return Object.assign({}, this.list, { results });
  }
}
