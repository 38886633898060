import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { INPUT_TYPE_DATETIME, FORM_ELEMENT_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * DateTime Input Element
 * @export
 * @class CoreFormElementDateTime
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementDateTime extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementDateTime
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_DATETIME,
    placeholder: 'mm/dd/yyyy, --:--'
  });
}
