import jwtDecode from 'jwt-decode';
import { cloneDeep } from 'lodash';

import { CoreUserRoleServiceShape, CoreUserInfoShape } from '../interfaces/';
import { MatchPermsFactory } from '../factories/';

import { USERINFO_DEFAULT, APP_METATDATA_DEFAULT, SOC_ADMIN_NEUSTAR_PERMS, SOC_ADMIN_PERMS, ADMIN_PERMS, RESELLER_PERMS, CSR_PERMS, SSSE_PERMS, AUDIT_PERMS } from '../constants/';

/**
 * User Role Service
 * @export
 * @class CoreUserRoleService
 * @implements {CoreUserRoleServiceShape}
 */
export default class CoreUserRoleService implements CoreUserRoleServiceShape {
  /**
   * Is Super User
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isSuperUser: boolean = false;

  /**
   * Is Soc Admin
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isSocAdmin: boolean = false;

  /**
   * Is Admin
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isAdmin: boolean = false;

  /**
   * Is Reseller
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isReseller: boolean = false;

  /**
   * Is CSR
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isCSR: boolean = false;

  /**
   * Is SSSE
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isSSSE: boolean = false;

  /**
   * Is Audit
   * @private
   * @type {boolean}
   * @memberof CoreUserRoleService
   */
  private _isAudit: boolean = false;

  /**
   * Userinfo
   * @memberof CoreUserRoleService
   */
  private _userinfo = cloneDeep(USERINFO_DEFAULT);

  /**
   * Permissions
   * @private
   * @type {Array<string>}
   * @memberof CoreUserRoleService
   */
  private _permissions: Array<string> = null;

  /**
   * Install
   * @param {CoreUserInfoShape} userinfo
   * @param {string} token
   * @memberof CoreUserRoleService
   */
  public install(userinfo: CoreUserInfoShape, token: string): void {
    this._userinfo = userinfo;

    const { permissions }: HashMap<Array<string>> = jwtDecode(token) as HashMap<any>;

    this._permissions = permissions;

    // start roles
    const { sub } = this._userinfo;

    this._isSocAdmin = MatchPermsFactory(SOC_ADMIN_NEUSTAR_PERMS, permissions);
    if (!this._isSocAdmin) {
      /* istanbul ignore next */
      this._isSocAdmin = MatchPermsFactory(SOC_ADMIN_PERMS, permissions);
    }

    this._isSuperUser = this._isSocAdmin && /^ad|neustar-ldap/.test(sub);
    this._isAdmin = MatchPermsFactory(ADMIN_PERMS, permissions);
    this._isReseller = MatchPermsFactory(RESELLER_PERMS, permissions);
    this._isCSR = MatchPermsFactory(CSR_PERMS, permissions);
    this._isSSSE = MatchPermsFactory(SSSE_PERMS, permissions);
    this._isAudit = MatchPermsFactory(AUDIT_PERMS, permissions);
  }

  /**
   * Is Super User
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isSuperUser() {
    return this._isSuperUser;
  }

  /**
   * Is Soc Admin
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isSocAdmin() {
    return this._isSocAdmin;
  }

  /**
   * Is Admin
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isAdmin() {
    return this._isAdmin;
  }

  /**
   * Is Reseller
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isReseller() {
    return this._isReseller;
  }

  /**
   * Is CSR
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isCSR() {
    return this._isCSR;
  }

  /**
   * Is SSSE
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isSSSE() {
    return this._isSSSE;
  }

  /**
   * Is Audit
   * @readonly
   * @memberof CoreUserRoleService
   */
  public get isAudit() {
    return this._isAudit;
  }

  /**
   * App Metadata
   * @readonly
   * @memberof CoreUserRoleService
   */
  /* istanbul ignore next */
  public get app_metadata() {
    const { app_metadata } = this._userinfo;
    return app_metadata || cloneDeep(APP_METATDATA_DEFAULT);
  }

  /**
   * Permissions
   * @readonly
   * @memberof CoreUserRoleService
   */
  /* istanbul ignore next */
  public get permissions() {
    return this._permissions;
  }
}
