import { CoreConfigPermissionsMask } from '../../../Config/';

export const PERMISSIONS_ALLOWED_TYPE_READ: string = 'read';
export const PERMISSIONS_ALLOWED_TYPE_WRITE: string = 'write';
export const PERMISSIONS_ALLOWED_TYPE_OTHER: string = 'other';

export const PERMISSIONS_ALLOWED = [
  PERMISSIONS_ALLOWED_TYPE_READ,
  PERMISSIONS_ALLOWED_TYPE_WRITE,
  PERMISSIONS_ALLOWED_TYPE_OTHER,
];

export const PERMISSIONS_VALUE_MAP: HashMap<CoreConfigPermissionsMask> = {
  [PERMISSIONS_ALLOWED_TYPE_READ]: CoreConfigPermissionsMask.READ,
  [PERMISSIONS_ALLOWED_TYPE_WRITE]: CoreConfigPermissionsMask.WRITE,
  [PERMISSIONS_ALLOWED_TYPE_OTHER]: CoreConfigPermissionsMask.OTHER,
};

export const PERMISSIONS_NO_READ_NOTICE: string = 'User does not have privileges to view this content.';
