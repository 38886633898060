import React, { Component, Children, HTMLAttributes } from 'react';

import CoreTableContext from '../context';

/**
 * Table Head Container
 * @export
 * @class CoreTableHeadContainer
 * @extends {Component<HTMLAttributes<any>, {}>}
 */
export default class CoreTableHeadContainer extends Component<HTMLAttributes<any>, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableHeadContainer
   */
  public static displayName = 'CoreTableHead';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableHeadContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Render
   * @returns
   * @memberof CoreTableHeadContainer
   */
  public render() {
    const { props, context } = this;
    const { children, ...rest } = props;

    context.columns.count = Children.count(children);

    return (
      <thead {...rest}>
        <tr>{children}</tr>
      </thead>
    );
  }
}
