import React from 'react';

import CoreNotificationsBase from './Base';

import { CoreNotificationsGraphStatic } from '../graph/';
import { CoreAnchor } from '../../shared/';

import { CoreNotificationMenuItem, CoreNotificationsMenuMarkAsRead } from '../components/';
import { CoreNotificationMenuItemShape } from '../interfaces/';

/**
 * Notification List Component
 * @export
 * @class CoreNotificationsListComponent
 * @extends {CoreNotificationsBase<PureComponent<{}, {}>>}
 */
export default class CoreNotificationsListComponent extends CoreNotificationsBase {
  /**
   * Render
   * @returns
   * @memberof CoreNotificationsListComponent
   */
  public render() {
    const { context: { count, collection } } = this;

    const ready = count > 0;

    return (
      <dl>
        <CoreNotificationsMenuMarkAsRead />
        <dd>
          <ol>
            {ready ? (
              collection.map((item: CoreNotificationMenuItemShape, ndx: number) => {
                /* istanbul ignore next */
                return (
                  <li key={`notification.${ndx}`}>
                    <CoreNotificationMenuItem item={item} />
                  </li>
                );
              })
            ) : (
              <li>
                <span>No unread notifications</span>
              </li>
            )}
          </ol>
        </dd>
        {CoreNotificationsGraphStatic.ALL ? (
          <dd className="divider">
            <CoreAnchor href={CoreNotificationsGraphStatic.ALL}>See all notifications</CoreAnchor>
          </dd>
        ) : null}
      </dl>
    );
  }
}
