import React, { Component, Children } from 'react';

import CoreTableContext from '../context';

/**
 * Table Footer Container
 * @export
 * @class CoreTableFooterContainer
 * @extends {Component<CoreTableFooterProps, {}>}
 */
export default class CoreTableFooterContainer extends Component<{}, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableFooterContainer
   */
  public static displayName = 'CoreTableFooter';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableFooterContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * LifeCycle Hook
   * @memberof CoreTableFooterContainer
   */
  public componentDidMount() {
    this.context.subscribe(CoreTableFooterContainer.displayName, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableFooterContainer
   */
  public componentWillUnmount() {
    /* istanbul ignore next */
    this.context.unsubscribe(CoreTableFooterContainer.displayName);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableFooterContainer
   */
  public render() {
    const { props: { children } } = this;

    if (Children.count(children) === 0) {
      return null;
    }

    return (
      <tfoot>
        <tr>
          {children}
        </tr>
      </tfoot>
    );
  }
}
