import React, { ComponentClass, createContext } from 'react';
import { ApolloQueryResult, ApolloError, DocumentNode, NetworkStatus, FetchPolicy, WatchQueryFetchPolicy, ObservableQuery, ErrorPolicy } from '@apollo/client';
import { isDocumentNode } from '@apollo/client/utilities/';
import { cloneDeep } from 'lodash';

import { CoreNoop } from '../../../components/';

import ConsumerBase from './ConsumerBase';
import { GraphQueryConfigValidation, CoreGraphConfigToPropsTransform } from '../helpers/';
import { CoreGraphQueryProps } from '../interfaces/';

import { IsFunction, IsDefined, IsNull, HashMapMergeProps, WaitForDelay } from '../../../../utils/';

/**
 * Core Graph Query Consumer
 * @export
 * @param {(CoreGraphConsumerConfigType | DocumentNode)} config
 * @return {*} {ComponentClass<CoreGraphQueryProps, never>}
 */
export default function CoreGraphQueryConsumer(config: CoreGraphConsumerConfigType | DocumentNode): ComponentClass<CoreGraphQueryProps, never> {
  try {

    let query: DocumentNode = undefined!;
    let variables: HashMap<any> = undefined!;
    let fetchPolicy: (FetchPolicy | WatchQueryFetchPolicy) = 'cache-and-network';
    let errorPolicy: ErrorPolicy = undefined!;
    let skip: boolean = false;
    let pollInterval: number = 0;
    let transform: (params: HashMap<any>) => HashMap<any> = undefined!;
    let onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;
    let onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

    if (!IsNull(config)) {
      /* istanbul ignore next */
      if (!isDocumentNode(config) && !IsFunction(config)) {
        throw new ReferenceError(`argument must be of type DocumentNode or a function returning 'CoreGraphQueryProps'`);
      }

      /**
       * Convert DocumentNode to CoreGraphConsumerConfigType
       */
      /* istanbul ignore next */
      if (isDocumentNode(config)) {
        query = (config as DocumentNode);
        config = () => ({ query });
      }

      const {
        query: QUERY,
        variables: VARIABLES,
        fetchPolicy: FETCH_POLICY,
        errorPolicy: ERROR_POLICY,
        skip: SKIP,
        pollInterval: POLL_IINTERVAL,
        transform: TRANSFORM,
        onCompleted: ON_COMPLETED,
        onError: ON_ERROR
      } = (config as CoreGraphConsumerConfigType)() as CoreGraphQueryProps;

      query = IsDefined(QUERY) && isDocumentNode(QUERY) ? QUERY : query;
      variables = IsDefined(VARIABLES) ? VARIABLES : variables;
      fetchPolicy = IsDefined(FETCH_POLICY) ? FETCH_POLICY : fetchPolicy;
      errorPolicy = IsDefined(ERROR_POLICY) ? ERROR_POLICY : errorPolicy;
      skip = IsDefined(SKIP) ? SKIP : skip;
      pollInterval = IsDefined(POLL_IINTERVAL) ? POLL_IINTERVAL : pollInterval;
      transform = IsDefined(TRANSFORM) ? TRANSFORM : transform;
      onCompleted = IsDefined(ON_COMPLETED) ? ON_COMPLETED : onCompleted;
      onError = IsDefined(ON_ERROR) ? ON_ERROR : onError;

      /**
       * Config Validation
       */
      GraphQueryConfigValidation({ query, variables, fetchPolicy, errorPolicy, skip, pollInterval, transform, onCompleted, onError });
    }

    /**
     * Local interface
     * @interface CoreGraphQueryConsumerShape
     */
    interface ConsumerShape {
      refetch: (config: CoreGraphQueryProps) => Promise<ApolloQueryResult<any>>;
      startPolling: (pollInterval?: number) => void;
      stopPolling: () => void;
    }

    /**
     * Anonymous Consumer Class
     * @export
     * @implements {CoreGraphQueryConsumerShape}
     * @return {*} {ConsumerBase<CoreGraphQueryProps, never>}
     */
    return class extends ConsumerBase<CoreGraphQueryProps> implements ConsumerShape {
      /**
       * Display Name
       * @static
       * @type {string}
       */
      public static displayName: string = 'CoreGraphQueryConsumer';

      /**
       * Query
       * @protected
       * @type {DocumentNode}
       */
      protected _query: DocumentNode = query;

      /**
       * Fetch Policy
       * @protected
       * @type {(FetchPolicy | WatchQueryFetchPolicy)}
       */
      protected _fetchPolicy: (FetchPolicy | WatchQueryFetchPolicy) = fetchPolicy;

      /**
       * Error Policy
       * @protected
       * @type {ErrorPolicy}
       */
      protected _errorPolicy: ErrorPolicy = errorPolicy;

      /**
       * Skip
       * @protected
       * @type {boolean}
       */
      protected _skip: boolean = skip;

      /**
       * Poll Interval
       * @protected
       * @type {number}
       */
      protected _pollInterval: number = pollInterval;

      /**
       * Params
       * @protected
       * @type {HashMap<any>}
       */
      protected _params: HashMap<any> = null!;

      /**
       * Transform
       * @protected
       * @type {(params: HashMap<any>) => HashMap<any>}
       */
      protected _transform: (params: HashMap<any>) => HashMap<any> = transform;

      /**
       * On Completed
       * @protected
       * @type {(data: HashMap<any>) => void}
       */
      protected _onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;

      /**
       * On Error
       * @protected
       * @type {(error: ApolloError) => void}
       */
      protected _onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

      /**
       * Watcher
       * @protected
       * @type {ObservableQuery}
       */
      protected watcher: ObservableQuery = null!;

      /**
       * Creates an instance of anon class.
       * @param {CoreGraphQueryProps} props
       * @param {CoreGraphConnectionType} context
       */
      constructor(props: CoreGraphQueryProps, context: CoreGraphConnectionType) {
        super(props);

        // apply config properties to base class
        this._variables = variables || {};

        // public methods
        this.refetch = this.refetch.bind(this);
        this.fetchMore = this.fetchMore.bind(this);
        this.startPolling = this.startPolling.bind(this);
        this.stopPolling = this.stopPolling.bind(this);
        // protected methods
        this.cancel = this.cancel.bind(this);
        this.fetch = this.fetch.bind(this);
        this.watch = this.watch.bind(this);

        // init
        this.init(props);
      }

      /**
       * LifeCycle Hook
       */
      public componentDidMount(): void {
        const { skip } = this;

        this.listener = new AbortController();

        /**
         * Skip
         * When (skip) is true, it means we avoid initial fetch and 
         * automatic polling.
         * 
         * Skip is used to avoid a possibly large network request 
         * on instantiation of component. This allows us to curry 
         * the request engine (client) to a sub-component where it 
         * can be used. 
         * (for example: in the sub-component's `componentDidMount` lifecycle hook)
         */
        if (!skip) {
          this.watch();
        }
      }

      /**
       * LifeCycle Hook
       */
      /* istanbul ignore next */
      public componentWillUnmount(): void {
        const { listener } = this;
        listener.abort();
        this.cancel();
      }

      /**
       * Render
       * @return {JSX.Element} 
       */
      public render(): JSX.Element {
        const { client, error, loading, data, variables, networkStatus, polling, refetch, fetchMore, startPolling, stopPolling } = this;
        const CoreGraphConsumerContext = createContext({ client, error, loading, data, variables, networkStatus, polling, refetch, fetchMore, startPolling, stopPolling });
        return <CoreGraphConsumerContext.Consumer children={this.props.children} />;
      }

      /**
       * Refetch
       * @param {CoreGraphQueryProps} [config={ query: null }]
       * @return {*} {Promise<ApolloQueryResult<any>>}
       */
      /* istanbul ignore next */
      public async refetch(config: any = {}): Promise<ApolloQueryResult<any>> {
        if (this.skip) {
          return this.fetch(config);
        }
        return this.fetch(config).finally(() => {
          if (!this.listener.signal.aborted) {
            this.forceUpdate();
          }
        });
      }

      /**
       * Fetch More
       * @param {HashMap<any>} params
       * @return {*} {Promise<ApolloQueryResult<any>>}
       */
      /* istanbul ignore next */
      public async fetchMore(variables: HashMap<any> = {}): Promise<ApolloQueryResult<any>> {
        return WaitForDelay().then(() => {
          if (!this.listener.signal.aborted) {
            return this.fetch({ variables, query: null });
          }
        });
      }

      /**
       * Start Polling
       * @param {number} [pollInterval=1000]
       */
      /* istanbul ignore next */
      public startPolling(pollInterval: number = 1000): void {
        WaitForDelay().then(() => {
          if (!this.listener.signal.aborted) {
            if (pollInterval !== this.pollInterval) {
              this.pollInterval = pollInterval;
              if (this.watcher) {
                this.watcher.startPolling(this.pollInterval);
                this.forceUpdate();
              } else {
                this.watch();
              }
            }
          }
        });
      }

      /**
       * Stop Polling
       */
      /* istanbul ignore next */
      public stopPolling(): void {
        WaitForDelay().then(() => {
          if (!this.listener.signal.aborted) {
            if (this.polling) {
              this.pollInterval = 0;
              if (this.watcher) {
                this.watcher.stopPolling();
                this.forceUpdate();
              } else {
                this.watch();
              }
            }
          }
        });
      }

      /**
       * Init
       * @protected
       * @param {CoreGraphQueryProps} props
       */
      protected init(props: CoreGraphQueryProps): void {
        try {
          /**
           * Config Transform
           */
          const { query, variables, fetchPolicy, errorPolicy, skip, pollInterval, params, onCompleted, onError } = CoreGraphConfigToPropsTransform(props, config as CoreGraphConsumerConfigType);

          /**
           * Props Validation
           */
          GraphQueryConfigValidation({ query, variables, fetchPolicy, skip, pollInterval, params, onCompleted, onError });

          if (IsDefined(this._query) && IsDefined(query)) {
            throw new ReferenceError(`built with defined [query: DocumentNode], query as prop is not allowed (use: CoreGraphQuery for generic consumers)`);
          }
          /* istanbul ignore next */
          if (!IsDefined(this._transform) && IsDefined(params)) {
            throw new ReferenceError(`a Query using prop[params: HashMap<any>] must be built with config[transform: (params: HashMap<any>) => HashMap<any>]`);
          }
          /* istanbul ignore next */
          if (IsDefined(this._transform) && !IsDefined(params)) {
            throw new ReferenceError(`a Query built with config[transform: (params: HashMap<any>) => HashMap<any>] must use prop[params: HashMap<any>].`);
          }

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developer to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          /* istanbul ignore next */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          /* istanbul ignore next */
          if (!IsDefined(this._query) && IsDefined(query)) {
            this._query = query;
          }
          /* istanbul ignore next */
          if (IsDefined(variables)) {
            this._variables = HashMapMergeProps(this.variables, variables);
          }
          /* istanbul ignore next */
          if (IsDefined(fetchPolicy)) {
            this._fetchPolicy = fetchPolicy;
          }
          /* istanbul ignore next */
          if (IsDefined(errorPolicy)) {
            this._errorPolicy = errorPolicy;
          }
          /* istanbul ignore next */
          if (IsDefined(skip)) {
            this._skip = skip;
          }
          /* istanbul ignore next */
          if (IsDefined(pollInterval)) {
            this._pollInterval = pollInterval;
          }
          /* istanbul ignore next */
          if (IsDefined(params)) {
            this._params = params;
          }

          /**
           * Set Defaults
           */
          /* istanbul ignore next */
          if (skip) {
            this.loading = false;
          }

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphQueryConsumer.init() ${err.message || err}`);
          }
        }
      }

      /**
       * Watch
       * @protected
       */
      /* istanbul ignore next */
      protected watch(): void {
        try {
          const { client, query, variables, fetchPolicy, errorPolicy, transform, params, pollInterval } = this;

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developer to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          this.watcher = client.watchQuery({ query, variables, fetchPolicy, errorPolicy, pollInterval });

          const onNext = ({ loading, data, networkStatus }: any): void => {
            this.error = void 0;
            this.loading = loading;
            this.data = data;
            this.networkStatus = this.pollInterval > 0 ? NetworkStatus.poll : networkStatus;
            this.onCompleted(data);

            // re-render
            if (this.subscribed && !this.subscribed.closed) {
              if (!this.listener.signal.aborted) {
                this.forceUpdate();
              }
            }
          };

          const onError = (error: any): void => {
            this.loading = false;
            this.data = null!;
            this.error = error;
            this.networkStatus = NetworkStatus.error;
            this.onError(error);

            // re-render
            if (this.subscribed && !this.subscribed.closed) {
              if (!this.listener.signal.aborted) {
                this.forceUpdate();
              }
            }
          };

          this.subscribed = this.watcher.subscribe(onNext, onError);

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphConsumer watch() ${err.message || err}`);
          }
        }

        return void 0;
      }

      /**
       * Fetch
       * @protected
       */
      /* istanbul ignore next */
      protected async fetch(config: CoreGraphQueryProps = { query: null }): Promise<ApolloQueryResult<any>> {
        try {
          const { query: __QUERY, variables: __VARIABLES, fetchPolicy: __FETCH_POLICY, errorPolicy: __ERROR_POLICY } = config;
          const { client, query: _QUERY, variables: _VARIABLES, fetchPolicy: _FETCH_POLICY, errorPolicy: _ERROR_POLICY, transform, params } = this;

          const query: DocumentNode = __QUERY || _QUERY;
          const variables: HashMap<any> = cloneDeep(__VARIABLES || _VARIABLES);
          let fetchPolicy: (FetchPolicy | WatchQueryFetchPolicy) = __FETCH_POLICY || _FETCH_POLICY;
          let errorPolicy: ErrorPolicy = __ERROR_POLICY || _ERROR_POLICY;

          /**
           * The cache-and-network fetchPolicy does not work with client.query, because client.query can only return a single result.
           */
          if (fetchPolicy === 'cache-and-network') {
            fetchPolicy = 'cache-first';
          }

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developers to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          return client
            .query({ query, variables, fetchPolicy, errorPolicy })
            .then(({ loading, data, networkStatus }) => {
              this.error = null;
              this.loading = loading;
              this.data = data;
              this.networkStatus = networkStatus;
              return { loading, data, networkStatus };
            })
            .catch((err: any): void => {
              this.error = err;
              this.networkStatus = NetworkStatus.error;
              this.onError(err);
            }).finally(() => {
              this.onCompleted(this.data);
            });

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphQueryConsumer.fetch() ${err.message || err}`);
          }
          return Promise.reject(err);
        }
      }

      /**
       * Cancel
       * @protected
       */
      /* istanbul ignore next */
      protected cancel(): void {
        if (this.subscribed && !this.subscribed.closed) {
          this.subscribed.unsubscribe();
        }
      }

      /**
       * Polling
       * @readonly
       * @type {boolean}
       */
      public get polling(): boolean {
        const { pollInterval } = this;
        return pollInterval > 0;
      }

      /**
       * Query - getter
       * @protected
       */
      protected get query() {
        return this._query;
      }

      /**
       * FetchPolicy - getter
       * @protected
       */
      protected get fetchPolicy() {
        return this._fetchPolicy;
      }

      /**
       * FetchPolicy - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set fetchPolicy(fetchPolicy) {
        this._fetchPolicy = fetchPolicy;
      }

      /**
       * ErrorPolicy - getter
       * @protected
       */
      protected get errorPolicy() {
        return this._errorPolicy;
      }

      /**
       * ErrorPolicy - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set errorPolicy(errorPolicy) {
        this._errorPolicy = errorPolicy;
      }

      /**
       * Skip - getter
       * @protected
       */
      protected get skip() {
        return this._skip;
      }

      /**
       * Skip - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set skip(skip) {
        this._skip = skip;
      }

      /**
       * Poll Interval - getter
       * @protected
       */
      protected get pollInterval() {
        return this._pollInterval;
      }

      /**
       * Poll Interval - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set pollInterval(pollInterval) {
        this._pollInterval = pollInterval;
      }

      /**
       * Params - getter
       * @protected
       */
      protected get params() {
        return this._params;
      }

      /**
       * Transform - getter
       * @protected
       */
      protected get transform() {
        return this._transform;
      }

      /**
       * On Completed - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onCompleted() {
        return this._onCompleted;
      }

      /**
       * On Error - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onError() {
        return this._onError;
      }
    }

  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR::CoreGraphQueryConsumer ${err.message || err}`);
    }
    /* istanbul ignore next */
    return CoreNoop<CoreGraphQueryProps, never>;
  }
}

