import { CorePermissionsService } from '../services';
import { CorePermissionsServiceShape } from '../interfaces/';

/**
 * Permissions Instance
 * @export
 * @param {string} type
 * @returns {CorePermissionsServiceShape}
 */
export function CorePermissionsInstance(type: string): CorePermissionsServiceShape {
  const service = new CorePermissionsService();
  service.type = type;
  service.init();
  return service;
}
