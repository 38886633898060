import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementTel } from '../../../components/';

/**
 * Tel Input Controller
 * @export
 * @class TelInputController
 * @extends {InputBase}
 */
export default class TelInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof TelInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementTel;
}
