import React, { PureComponent } from 'react';

import { CoreRouter, CoreRedirect } from '../../Router/';

import { CoreBootActivate, CoreBootAuth, CoreBootForgot,  CoreBootLogin, CoreBootReset } from './containers/';

/**
 * Boot Main Module Router
 * @export
 * @class BootMainModuleRouter
 * @extends {PureComponent<CoreRouterProps, {}>}
 */
export default class BootMainModuleRouter extends PureComponent<CoreRouterProps, {}> {
  /**
   * Render
   * @returns
   * @memberof BootMainModuleRouter
   */
  public render() {
    return (
      <CoreRouter basepath="/auth">
        <CoreBootAuth path="/" default />
        <CoreBootLogin path="login" />
        <CoreBootForgot path="forgot" />
        <CoreBootReset path="reset" />
        <CoreBootActivate path="activate" />
        <CoreRedirect from="/auth" to="/" />
      </CoreRouter>
    );
  }
}
