import { SELECT_TYPE_SELECT } from './config';

export const CORE_SELECT_PROPS = {
  type: SELECT_TYPE_SELECT,
  value: undefined,
  options: [],
  label: null,
  title: null
};

export const SELECT_DEFAULT_LABEL = 'Select...';

export const SELECT_ALL = 'Select All';
export const DESELECT_ALL = 'Deselect All';
