import { ComponentClass } from 'react';

import SelectBase from './Base';

import { CoreFormElementSelect } from '../../../components/';

/**
 * Select Single Controller
 * @export
 * @class SelectSingleController
 * @extends {SelectBase}
 */
export default class SelectSingleController extends SelectBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof SelectSingleController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementSelect;
}
