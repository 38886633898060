import React from 'react';

import COUNTRIES from '../../../../json/countries.json';

import SelectWidgetBase from './SelectWidgetBase';

import { CoreSelect } from '../../containers/';

/**
 * Select ISO3166 Country Container
 * @export
 * @class CoreSelectISO3166CountryContainer
 * @extends {SelectWidgetBase}
 */
export default class CoreSelectISO3166CountryContainer extends SelectWidgetBase {
  /**
   * SRC
   * @override
   * @static
   * @memberof CoreSelectCountryContainer
   */
  public static SRC = COUNTRIES;

  /**
   * Render
   * @returns
   * @memberof CoreSelectISO3166CountryContainer
   */
  public render() {
    const { options, props: { type, options: OPTIONS, value: VALUE, className: CLASSNAME, children, ...rest}} = this;

    let opts: CoreFormOptionsType = [
      { value: 'A1', label: 'Anonymous Proxy'},
      { value: 'A2', label: 'Satellite Provider'},
      { value: 'O1', label: 'Other Country'},
    ].concat(options.slice(0) as any);

    /* istanbul ignore next */
    if (Array.isArray(OPTIONS) && OPTIONS.length) {
      opts = OPTIONS.concat(opts);
    }

    return <CoreSelect className="countries" options={opts} {...rest} />;
  }
}
