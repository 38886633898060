import React, { PureComponent } from 'react';

import { CoreAuthWrapper } from '../../Auth/';
import { CoreConfigWrapper, BUSY_MESSAGE_BOOT } from '../../Config/';
import { CoreDialogsModule, CoreBusyWrapper, CoreBusyIconWithMessage } from '../../Activity/';

/**
 * Boot Empty Module
 * @export
 * @class CoreBootEmptyModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBootEmptyModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBootEmptyModule
   */
  public render() {
    // console.debug('** EMPTY **');
    return (
      <CoreAuthWrapper>
        <CoreConfigWrapper>
          <CoreBusyWrapper>
            <div id="activity">
              <div id="busy" className="active">
                <div>
                  <figure>
                    <CoreBusyIconWithMessage message={BUSY_MESSAGE_BOOT} />
                  </figure>
                </div>
              </div>
              <CoreDialogsModule />
            </div>
          </CoreBusyWrapper>
        </CoreConfigWrapper>
      </CoreAuthWrapper>
    );
  }
}
