import React, { PureComponent, Fragment } from 'react';

import CoreAuthContext from '../../Auth/context';
import { CoreHeaderMenu } from '../services/';
import CoreHeaderMenuItems from './MenuItems';

import { CoreNotificationsModule } from '../../Notifications/';

import { CoreMasqueradeComponent, CoreNotificationsMenuComponent, CoreToolsMenuComponent, CoreUserMenuComponent } from '../components/';

import { MergeClassNames } from '../../utils/';

/**
 * Menus Container
 * @export
 * @class CoreMenusContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreMenusContainer extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreAuthContext>}
   * @memberof CoreMenusContainer
   */
  public static contextType: Readonly<typeof CoreAuthContext> = CoreAuthContext;

  /**
   * Render
   * @returns
   * @memberof CoreMenusContainer
   */
  public render() {
    const { context: { user: { profile }}} = this;

    const { username, picture, masquerade, shortname } = profile;

    const clz = MergeClassNames('', { masquerade });

    return (
      <Fragment>
        <ul className={clz}>
          <CoreNotificationsModule>
            <CoreNotificationsMenuComponent />
          </CoreNotificationsModule>
          {CoreHeaderMenu.tool.ready ? (<CoreToolsMenuComponent />) : null}
          {CoreHeaderMenu.populated ? (<CoreHeaderMenuItems items={CoreHeaderMenu.items}/>): null}
          <CoreUserMenuComponent name={username} picture={picture} />
        </ul>
        <CoreMasqueradeComponent masquerade={masquerade} shortname={shortname} />
      </Fragment>
    );
  }
}
