import React, { PureComponent } from 'react';

import CoreInteropContext from '../Interop/context';
import { CoreHeaderToggleMask } from '../Interop/enums/';
import { CoreNavigation } from '../Navigation/';

import {
  CoreHgroupContainer,
  CoreHeaderFormContainer,
  CoreHeaderNavContainer
} from './containers/';

/**
 * Header Module
 * @export
 * @class CoreHeaderModule
 * @extends {PureComponent<{}, CoreHeaderToggleState>}
 */
export default class CoreHeaderModule extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreHeaderModule
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * LifeCycle Hook
   * @memberof CoreMainModule
   */
  public componentDidMount() {
    const { context } = this;
    if (!CoreNavigation.items.size) {
      context.header = CoreHeaderToggleMask.NONE;
      context.emit();
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreHeaderModule
   */
  public render() {
    return (
      <header className="global">
        <CoreHgroupContainer />
        <CoreHeaderFormContainer />
        <CoreHeaderNavContainer />
      </header>
    );
  }
}
