import React, { PureComponent, FormEvent, FC } from 'react';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

import CoreFormContext from './contexts/Form';

import { CoreFormProps, CoreFormServiceShape } from './interfaces/';
import { CoreFormService } from './services/';
import { FORM_PROPS, FORM_RESET_DIALOG_TITLE, FORM_RESET_DIALOG_MESSAGE } from './constants/';

import { CoreDialogInstance } from '../../../Activity/Dialogs/';
import { HashMapMergeProps, MergeClassNames, IsDefined, Noop } from '../../../utils/';

/**
 * Form Module
 * @export
 * @class CoreFormModule
 * @extends {PureComponent<CoreFormProps, {}>}
 * @priority - INITIAL
 * @notes
 * 1. Defines Form Props
 * 2. Generates Controllers based on source
 * 3. Manages & Shares Form Application State via events.
 */
export default class CoreFormModule extends PureComponent<CoreFormProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreFormProps}
   * @memberof CoreFormModule
   */
  public static defaultProps: CoreFormProps = HashMapMergeProps(FORM_PROPS);

  /**
   * Service
   * @protected
   * @type {CoreFormServiceShape}
   * @memberof CoreFormModule
   */
  protected service: CoreFormServiceShape = null!;

  /**
   * Creates an instance of CoreFormModule.
   * @param {CoreFormProps} props
   * @memberof CoreFormModule
   */
  constructor(props: CoreFormProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormModule
   */
  public render() {
    const { OnReset, service, props: { noValidate, autoComplete, className, children }} = this;
    const { id, OnSubmit } = service;

    const clz = MergeClassNames(className, {});
    const key: string = nanoid(10);

    return (
      <CoreFormContext.Provider key={key} value={service}>
        <form id={id} className={clz} onSubmit={OnSubmit} onReset={OnReset} noValidate={noValidate} autoComplete={autoComplete}>
          {children}
        </form>
      </CoreFormContext.Provider>
    );
  }

  /**
   * On Reset
   * @protected
   * @param {FormEvent<HTMLFormElement>} evt
   * @memberof CoreFormModule
   */
  /* istanbul ignore next */
  protected OnReset = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    evt.persist();
    const { service, props: { onReset } } = this;
    const ResetModal: FC<any> = () => (<p>{FORM_RESET_DIALOG_MESSAGE}</p>);
    const reset_dialog = new CoreDialogInstance(<ResetModal />, 'confirm', 'warning');
    reset_dialog.name = `form.reset.dialog.${service.id}`;
    reset_dialog.title = FORM_RESET_DIALOG_TITLE;
    reset_dialog
      .activate()
      .promise()
      .then(() => {
        const props: CoreFormProps = cloneDeep(this.props);
        const vault: any = cloneDeep(service.vault);
        if ('source' in props && props.source instanceof CoreFormService) {
          Object.assign(props, { source: vault });
        }
        this.init(props);

        if (IsDefined(onReset)) {
          onReset(evt, this.service);
        }

        this.forceUpdate();
      })
      .catch(Noop);
  };

  /**
   * Init
   * @protected
   * @param {CoreFormProps} props
   * @memberof CoreFormModule
   */
  protected init = (props: CoreFormProps): void => {
    if ('source' in props && props.source instanceof CoreFormService) {
      this.service = props.source as CoreFormServiceShape;
      props = cloneDeep(props);
      delete props.source;
      this.service.use(props);
    } else {
      this.service = new CoreFormService(props);
    }
  };
}


