import React from 'react';
import { PureComponent } from 'react';

interface FusionLoginLoaderPropsShape {
  url: string;
  timeout: number;
  onError: () => void;
}
type StatusType = 'null' | 'loading' | 'ready' | 'error';

interface FusionLoginLoaderStateShape {
  status: StatusType;
}

const timeoutFactory = (timeout) => {
  return new Promise((resolve, reject) => {
    if (timeout > 0) {
      setTimeout(reject, timeout);
    } else {
      reject();
    }
  });
};

const loadExternalWebComponent = (url: string, tagName: string) => {
  return new Promise((resolve, reject) => {
    // Check if this script has already been loaded
    const preloadedScript: any = document.querySelector(`script[src="${url}"]`);
    if (preloadedScript) {
      if (customElements.get(tagName)) {
        // We can bail because we have what we need
        resolve('ready');
        return;
      }
    }

    // We are here because a preload script tag was not present
    // or failed for some reason so we need to load dynamically
    const handleScriptLoaded = (event) => {
      if (event.type === 'load') {
        // Ensure the component was registered
        if (!customElements.get(tagName)) {
          // for some reason the tag isnt registered so we have to fail
          return reject();
        }
        return resolve('ready');
      } else {
        // Something went wrong bail
        return reject();
      }
    };

    // Add the script tag to the page
    const script = document.createElement('script');
    script.src = url;
    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', handleScriptLoaded);
    script.addEventListener('error', handleScriptLoaded);
  });
};

export default class FusionLoginLoader extends PureComponent<FusionLoginLoaderPropsShape, {}> {
  public state: FusionLoginLoaderStateShape = {
    status: 'loading',
  };

  private _abortListener: AbortController;

  async componentDidMount(): Promise<void> {
    try {
      if (this.props.timeout < 1) {
        throw new Error('Fusion declined');
      }
      // Load the component subject to a timeout
      this._abortListener = new AbortController();
      await Promise.race([timeoutFactory(this.props.timeout), loadExternalWebComponent(this.props.url, 'fusion-login')]);

      if (!this._abortListener.signal.aborted) {
        this.setState({ status: 'ready' });
      }
      return void 0;
    } catch (e) {
      if (this.props.onError) {
        this.props.onError();
      }
      return void 0;
    }
  }

  render() {
    return this.state.status === 'ready' ? (
      <fusion-login application={`Security`} applicationSubTitle="Always On. Always Secure.">
        {this.props.children}
      </fusion-login>
    ) : (
      <></>
    );
  }
}
