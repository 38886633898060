import React, { PureComponent, createContext } from 'react';

import CoreFormContext from '../../contexts/Form';
import { CoreFormConsumerProps } from '../../interfaces/';
import { CoreFormEventsEnum } from '../../enums/';
import { FORM_EVENT_DELAY } from '../../constants/';
import { WaitForDelay } from '../../../../../utils/';

/**
 * Form Consumer
 * @export
 * @class ConsumerBase
 * @extends {PureComponent<CoreFormConsumerProps, {}>}
 */
export default class FormConsumer extends PureComponent<CoreFormConsumerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof FormConsumer
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof FormConsumer
   */
  protected listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentDidMount() {
    const { FormEventListener, context } = this;
    this.listener = new AbortController();
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof FormConsumer
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_UPDATE, FormEventListener);
    listener.abort();
  }

  /**
   * Render
   * @returns
   * @memberof FormConsumer
   */
  public render() {
    const { ConsumerContext, props: { children } } = this;
    return <ConsumerContext.Consumer children={children as any} />;
  }

  /**
   * Form Event Listener
   * @protected
   * @callback
   * @memberof FormConsumer
   */
  /* istanbul ignore next */
  protected FormEventListener = () => {
    if (this.listener) {
      this.listener.abort();
    }
    this.listener = new AbortController();
    /* istanbul ignore next */
    WaitForDelay(FORM_EVENT_DELAY).then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };

  /**
   * ConsumerContext - getter
   * @readonly
   * @memberof FormConsumer
   */
  public get ConsumerContext() {
    const { context } = this;
    const { validate, valid, pristine, dirty } = context;
    const values = { validate, valid, pristine, dirty };
    return createContext({ form: context, values });
  }
}
