/**
 * Themes
 * @note These must have corollaries in `Core/assets/sass/core/themes/`
 * coverage failures for any themed declaration will result in sass compile errors.
 */
export const THEME_DARK = 'theme-dark';
export const THEME_LIGHT = 'theme-light';
export const THEME_DEFAULT = THEME_DARK;
export const THEMES = [THEME_DARK, THEME_LIGHT];

export const FAVICONS = {
  '57': null,
  '60': null,
  '72': null,
  '76': null,
  '114': null,
  '120': null,
  '144': null,
  '152': null,
  '180': null,
  '16': null,
  '32': null,
  '194': null,
};
