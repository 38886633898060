import { CoreListPaginateShape } from '../interfaces/';

import { IsDefined } from '../../../../utils/';

/**
 * Table Pagination Service
 * @export
 * @class CoreTablePaginationService
 */
export default class CoreTablePaginationService {
  /**
   * Page
   * @type {number}
   * @memberof CoreTablePaginationService
   */
  public page: number = 0;

  /**
   * Limit
   * @type {number}
   * @memberof CoreTablePaginationService
   */
  public limit: number = 0;

  /**
   * Count
   * @type {number}
   * @memberof CoreTablePaginationService
   */
  public count: number = 0;

  /**
   * Update
   * @param {number} [page]
   * @param {number} [limit]
   * @param {number} [count]
   * @memberof CoreTablePaginationService
   */
  public update(page?: number, limit?: number, count?: number): void {
    if (IsDefined(page)) {
      this.page = page;
    }
    if (IsDefined(limit)) {
      this.limit = limit;
    }
    if (IsDefined(count)) {
      this.count = count;
    }
  }

  /**
   * To HashMap
   * @returns {CoreListPaginateShape}
   * @memberof CoreTablePaginationService
   */
  public toHashMap(): CoreListPaginateShape {
    const { page, limit, count } = this;
    return { page, limit, count };
  }

  /**
   * Pageable - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreTablePaginationService
   */
  public get pageable(): boolean {
    const { count, limit } = this;
    return limit > 0 && count > 0;
  }
}
