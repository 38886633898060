import React, { PureComponent, Fragment } from 'react';
import { cloneDeep } from 'lodash';
import BusyIcon from './BusyIcon';

import { CoreBusyMessageWithIconProps } from '../interfaces/';
import { BUSY_WITH_ICON_PROPS } from '../constants';

/**
 * Busy Icon With Message
 * @export
 * @class CoreBusyIconWithMessageComponent
 * @extends {PureComponent<CoreBusyMessageWithIconProps, {}>}
 */
export default class CoreBusyIconWithMessageComponent extends PureComponent<CoreBusyMessageWithIconProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreBusyMessageWithIconProps}
   * @memberof CoreBusyIconWithMessageComponent
   */
  public static defaultProps: CoreBusyMessageWithIconProps = cloneDeep(BUSY_WITH_ICON_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreBusyIconWithMessageComponent
   */
  public render() {
    const { icon, message } = this.props;
    return (
      <Fragment>
        {icon ? <BusyIcon /> : null}
        <figcaption>{message}</figcaption>
      </Fragment>
    );
  }
}
