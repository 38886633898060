import React, { ComponentClass } from 'react';

import InputBase from './Base';
import ControllerWrapper from '../ControllerWrapper';

import { ControllerLabel, CoreFormElementHidden, CoreFormElementText } from '../../../components/';

/**
 * Hidden Input Controller
 * @export
 * @class HiddenInputController
 * @extends {InputBase}
 */
export default class HiddenInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof HiddenInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementHidden;

  /**
   * Render
   * @returns
   * @memberof HiddenInputController
   */
  public render() {
    const { ControlledElement, ControllerRef, kbd, labels, elements: { required, className }, controller } = this;
    const { name, value } = controller;

    if (kbd) {
      return (
        <ControllerWrapper controller={controller}>
          <ControllerLabel {...labels}>
            <CoreFormElementText name="" value={value} readOnly={true} className={className} />
            {kbd}
            <ControlledElement name={name} value={value} required={required} ControllerRef={ControllerRef} />
          </ControllerLabel>
        </ControllerWrapper>
      );
    }

    return (
      <ControllerWrapper controller={controller}>
        <ControlledElement name={name} value={value} required={required} ControllerRef={ControllerRef} />
      </ControllerWrapper>
    );
  }
}
