import EventEmitter from 'events';

import { CoreBootEventEnum } from './enums/';
import { CoreBootServiceShape } from './interfaces/';

import CoreAuth from '../Auth/service';
import { CoreAuthCredentialsShape } from '../Auth/interfaces/';

/**
 * Boot Service
 * @export
 * @class CoreBootService
 * @implements {CoreBootServiceShape}
 */
export class CoreBootService implements CoreBootServiceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreBootService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Credentials
   * @private
   * @type {CoreAuthCredentialsShape}
   * @memberof CoreBootService
   */
  private _credentials: CoreAuthCredentialsShape = CoreAuth.credentials;

  /**
   * On Authenticate
   * @param {Func<void>} callback
   * @memberof CoreBootService
   */
  public OnAuthenticate(callback: Func<void>) {
    this._emitter.on(CoreBootEventEnum.AUTHENTICATED, callback);
  }

  /**
   * On Unauthenticate
   * @param {Func<void>} callback
   * @memberof CoreBootService
   */
  public OnUnauthenticate(callback: Func<void>) {
    this._emitter.on(CoreBootEventEnum.UNAUTHENTICATED, callback);
  }

  /**
   * Emit
   * @param {CoreBootEventEnum} evt
   * @param {...any[]} args
   * @memberof CoreBootService
   */
  public emit(evt: CoreBootEventEnum, ...args: any[]) {
    this._emitter.emit(evt, ...args);
  }

  /**
   * Credentials
   * @memberof CoreBootService
   */
  public get credentials() {
    return this._credentials;
  }

  /**
   * Credentials
   * @memberof CoreBootService
   */
  public set credentials(credentials: CoreAuthCredentialsShape) {
    this._credentials = credentials;
  }

  /**
   * Authenticated - curried
   * @readonly
   * @memberof CoreBootService
   */
  /* istanbul ignore next */
  public get authenticated() {
    return CoreAuth.authenticated;
  }
}

const CoreBoot = new CoreBootService();

export default CoreBoot;
