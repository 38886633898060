import React, { ComponentClass, createContext } from 'react';
import { ApolloError, DocumentNode, NetworkStatus, FetchPolicy, WatchQueryFetchPolicy, Observable, FetchResult, ErrorPolicy } from '@apollo/client';
import { isDocumentNode } from '@apollo/client/utilities/';

import { CoreAuth, AUTH_REFRESH_EVENT } from '../../../../Auth/';
import { CoreNoop } from '../../../components/';

import ConsumerBase from './ConsumerBase';
import { GraphSocketConfigValidation, CoreGraphConfigToPropsTransform } from '../helpers/';
import { CoreGraphSocketProps } from '../interfaces/';

import { IsFunction, IsDefined, IsNull, IsEqual, HashMapMergeProps, WaitForDelay } from '../../../../utils/';

/**
 * Core Graph Socket Consumer
 * @export
 * @param {(CoreGraphConsumerConfigType | DocumentNode)} config
 * @return {*} {ComponentClass<CoreGraphSocketProps, never>}
 */
export default function CoreGraphSocketConsumer(config: CoreGraphConsumerConfigType | DocumentNode): ComponentClass<CoreGraphSocketProps, never> {
  try {

    let subscription: DocumentNode = undefined!;
    let variables: HashMap<any> = undefined!;
    let fetchPolicy: (FetchPolicy | WatchQueryFetchPolicy) = 'cache-and-network';
    let errorPolicy: ErrorPolicy = undefined!;
    let skip: boolean = false;
    let transform: (params: HashMap<any>) => HashMap<any> = undefined!;
    let onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;
    let onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

    if (!IsNull(config)) {
      /* istanbul ignore next */
      if (!isDocumentNode(config) && !IsFunction(config)) {
        throw new ReferenceError(`argument must be of type DocumentNode or a function returning 'CoreGraphSocketProps'`);
      }

      /**
       * Convert DocumentNode to CoreGraphConsumerConfigType
       */
      /* istanbul ignore next */
      if (isDocumentNode(config)) {
        subscription = (config as DocumentNode);
        config = () => ({ subscription });
      }

      const {
        subscription: SUBSCRIPTION,
        variables: VARIABLES,
        fetchPolicy: FETCH_POLICY,
        errorPolicy: ERROR_POLICY,
        skip: SKIP,
        transform: TRANSFORM,
        onCompleted: ON_COMPLETED,
        onError: ON_ERROR
      } = (config as CoreGraphConsumerConfigType)() as CoreGraphSocketProps;

      subscription = IsDefined(SUBSCRIPTION) && isDocumentNode(SUBSCRIPTION) ? SUBSCRIPTION : subscription;
      variables = IsDefined(VARIABLES) ? VARIABLES : variables;
      fetchPolicy = IsDefined(FETCH_POLICY) ? FETCH_POLICY : fetchPolicy;
      errorPolicy = IsDefined(ERROR_POLICY) ? ERROR_POLICY : errorPolicy;
      skip = IsDefined(SKIP) ? SKIP : skip;
      transform = IsDefined(TRANSFORM) ? TRANSFORM : transform;
      onCompleted = IsDefined(ON_COMPLETED) ? ON_COMPLETED : onCompleted;
      onError = IsDefined(ON_ERROR) ? ON_ERROR : onError;

      /**
       * Config Validation
       */
      GraphSocketConfigValidation({ subscription, variables, fetchPolicy, errorPolicy, skip, transform, onCompleted, onError });
    }

    /**
     * Local interface
     * @interface CoreGraphSocketConsumerShape
     */


    /**
     * Anonymous Consumer Class
     * @export
     * @implements {CoreGraphSocketConsumerShape}
     * @return {*} {ConsumerBase<CoreGraphSocketProps, never>}
     */
    return class extends ConsumerBase<CoreGraphSocketProps> {
      /**
       * Display Name
       * @static
       * @type {string}
       */
      public static displayName: string = 'CoreGraphSocketConsumer';

      /**
       * Subscription
       * @protected
       * @type {DocumentNode}
       */
      protected _subscription: DocumentNode = subscription;

      /**
       * Fetch Policy
       * @protected
       * @type {(FetchPolicy | WatchQueryFetchPolicy)}
       */
      protected _fetchPolicy: (FetchPolicy | WatchQueryFetchPolicy) = fetchPolicy;

      /**
       * Error Policy
       * @protected
       * @type {ErrorPolicy}
       */
      protected _errorPolicy: ErrorPolicy = errorPolicy;

      /**
       * Skip
       * @protected
       * @type {boolean}
       */
      protected _skip: boolean = skip;

      /**
       * Params
       * @protected
       * @type {HashMap<any>}
       */
      protected _params: HashMap<any> = null!;

      /**
       * Transform
       * @protected
       * @type {(params: HashMap<any>) => HashMap<any>}
       */
      protected _transform: (params: HashMap<any>) => HashMap<any> = transform;

      /**
       * On Completed
       * @protected
       * @type {(data: HashMap<any>) => void}
       */
      protected _onCompleted: (data: HashMap<any>) => void = (data: HashMap<any>): void => void 0;

      /**
       * On Error
       * @protected
       * @type {(error: ApolloError) => void}
       */
      protected _onError: (error: ApolloError) => void = (error: ApolloError): void => void 0;

      /**
       * Stashed
       * @private
       * @type {HashMap<any>}
       */
      private _stashed: HashMap<any> = null!;

      /**
       * Creates an instance of anon class.
       * @param {CoreGraphSocketProps} props
       * @param {CoreGraphConnectionType} context
       */
      constructor(props: CoreGraphSocketProps, context: CoreGraphConnectionType) {
        super(props);

        // apply config properties to base class
        this._variables = variables;

        // public methods
        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.SocketOnRefreshToken = this.SocketOnRefreshToken.bind(this);

        // protected methods
        this.cancel = this.cancel.bind(this);
        this.watch = this.watch.bind(this);

        // init
        this.init(props);
      }

      /**
       * LifeCycle Hook
       */
      public componentDidMount(): void {
        const { context, skip, SocketOnRefreshToken } = this;

        this.listener = new AbortController();

        /* istanbul ignore next */
        if (context.authenticate) {
          CoreAuth.emitter.on(AUTH_REFRESH_EVENT, SocketOnRefreshToken);
        }

        /**
         * Skip
         * When (skip) is true, it means we avoid initial fetch and 
         * automatic polling.
         * 
         * Skip is used to avoid a possibly large network request 
         * on instantiation of component. This allows us to curry 
         * the request engine (client) to a sub-component where it 
         * can be used. 
         * (for example: in the sub-component's `componentDidMount` lifecycle hook)
         */
        if (!skip) {
          this.watch();
        }
      }

      /**
       * LifeCycle Hook
       */
      /* istanbul ignore next */
      public componentWillUnmount(): void {
        const { context, SocketOnRefreshToken } = this;

        /* istanbul ignore next */
        if (context.authenticate) {
          CoreAuth.emitter.off(AUTH_REFRESH_EVENT, SocketOnRefreshToken);
        }

        this.listener.abort();
        this.cancel();
      }

      /**
       * Render
       * @return {JSX.Element} 
       */
      public render(): JSX.Element {
        const { client, error, loading, data, variables, networkStatus, polling, start, stop } = this;
        const CoreGraphConsumerContext = createContext({ client, error, loading, data, variables, networkStatus, polling, start, stop });
        return <CoreGraphConsumerContext.Consumer children={this.props.children} />;
      }

      /**
       * Start Socket Connection
       * @param {number} pollInterval
       */
      /* istanbul ignore next */
      public start(): void {
        this.stashed = null;
        this.watch();
      }

      /**
       * Stop Socket Connection
       * @param {number} pollInterval
       */
      /* istanbul ignore next */
      public stop(): void {
        this.cancel();
        this.stashed = null;
        this.networkStatus = NetworkStatus.ready;
        this.forceUpdate();
      }

      /**
       * Socket On Refresh Token
       * @notes
       * - it isn't enough for a socket client to be 
       * mutated as other types of consumers since 
       * socket connections make use internally of 
       * SubscriptionClient, which ultimately holds the token.
       */
      /* istanbul ignore next */
      public SocketOnRefreshToken(): void {
        const { skip } = this;
        WaitForDelay(500).then(() => {
          if (!this.listener.signal.aborted) {
            this.loading = true;
            this.stashed = null!;
            this.data = null!;
            this.networkStatus = NetworkStatus.loading;
            this.context.connection.wsProtocols = ['graphql-ws', CoreAuth.token];
            if (skip) {
              this.forceUpdate();
            } else {
              this.watch();
            }
          }
        });
      };

      /**
       * Init
       * @protected
       * @param {CoreGraphSocketProps} props
       */
      protected init(props: CoreGraphSocketProps): void {
        try {

          /**
           * Socket Init
           * @notes
           * - it isn't enough for a socket client to be 
           * mutated as other types of consumers since 
           * socket connections make use internally of 
           * SubscriptionClient, which ultimately holds the token.
           */
          /* istanbul ignore next */
          WaitForDelay().then(() => {
            if (this.context && this.context.authenticate) {
              if (this.context.connection.wsProtocols === 'graphql-ws') {
                this.context.connection.wsProtocols = ['graphql-ws', CoreAuth.token];
              }
            }
          });

          /**
           * Config Transform
           */
          const { subscription, variables, fetchPolicy, errorPolicy, skip, params, onCompleted, onError } = CoreGraphConfigToPropsTransform(props, config as CoreGraphConsumerConfigType);

          /**
           * Props Validation
           */
          GraphSocketConfigValidation({ subscription, variables, fetchPolicy, errorPolicy, skip, params, onCompleted, onError });

          /* istanbul ignore next */
          if (IsDefined(this._subscription) && IsDefined(subscription)) {
            throw new ReferenceError(`built with defined [subscription: DocumentNode], subscription as prop is not allowed (use: CoreGraphSocket for generic consumers)`);
          }
          /* istanbul ignore next */
          if (!IsDefined(this.transform) && IsDefined(params)) {
            throw new ReferenceError(`a Subscription using prop[params: HashMap<any>] must be built with config[transform: (params: HashMap<any>) => HashMap<any>]`);
          }
          /* istanbul ignore next */
          if (IsDefined(this.transform) && !IsDefined(params)) {
            throw new ReferenceError(`a Subscription built with config[transform: (params: HashMap<any>) => HashMap<any>] must use prop[params: HashMap<any>].`);
          }

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developer to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          /* istanbul ignore next */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          /* istanbul ignore next */
          if (!IsDefined(this._subscription) && IsDefined(subscription)) {
            this._subscription = subscription;
          }
          /* istanbul ignore next */
          if (IsDefined(variables)) {
            this._variables = HashMapMergeProps(this.variables, variables);
          }
          /* istanbul ignore next */
          if (IsDefined(fetchPolicy)) {
            this._fetchPolicy = fetchPolicy;
          }
          /* istanbul ignore next */
          if (IsDefined(errorPolicy)) {
            this._errorPolicy = errorPolicy;
          }
          /* istanbul ignore next */
          if (IsDefined(skip)) {
            this._skip = skip;
          }
          /* istanbul ignore next */
          if (IsDefined(params)) {
            this._params = params;
          }

          /**
           * Set Defaults
           */
          /* istanbul ignore next */
          if (skip) {
            this.loading = false;
          }

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphSocketConsumer.init() ${err.message || err}`);
          }
        }
      }

      /**
       * Watch
       * @protected
       */
      /* istanbul ignore next */
      protected watch(): void {
        try {
          const { client, subscription: query, variables, fetchPolicy, errorPolicy, transform, params } = this;

          this.networkStatus = NetworkStatus.loading;
          this.loading = true;

          /**
           * Transforms
           * Using a `transform` [config] property in conjunction with the `params` [prop] 
           * allows developer to pass an object that injects into variables, usually an `{ id }`
           * in CRUD operations to GET/PUSH a specific record from the api.
           */
          if (IsDefined(transform) && IsDefined(params)) {
            Object.assign(variables, transform(params));
          }

          const observable: Observable<FetchResult> = client.subscribe({ query, variables, fetchPolicy, errorPolicy });

          const next = ({ data }): void => {
            this.networkStatus = NetworkStatus.poll;
            this.loading = false;
            this.data = data;
            this.error = null;
            if (!IsEqual(this.stashed, this.data)) {
              this.stashed = data;
              if (!this.listener.signal.aborted) {
                this.forceUpdate();
              }
            }
          };
 
          const error = (error: any): void => {
            const code: string = error?.extensions?.code || '';
            this.networkStatus = NetworkStatus.error;
            this.loading = false;
            this.data = null;
            this.error = error;
            this.stashed = null;

            this.onError(error);

            if (code === 'GRAPHQL_VALIDATION_FAILED') {
              this.cancel();
              if (!this.listener.signal.aborted) {
                this.forceUpdate();
              }
            }
          };

          const complete = (): void => {
            this.networkStatus = NetworkStatus.ready;
            this.loading = false;
            this.data = null;
            this.error = null;
            this.stashed = null;
            this.onCompleted(this.data);
          };

          this.subscribed = observable.subscribe({ next, error, complete });

        } catch (err: any) {
          /* istanbul ignore next */
          if (process.env.NODE_ENV !== 'production') {
            console.error(`DEVELOPER ERROR::CoreGraphSocketConsumer.watch() ${err.message || err}`);
          }
        }

        return void 0;
      }

      /**
       * Cancel
       * @protected
       */
      /* istanbul ignore next */
      protected cancel(): void {
        if (this.subscribed && !this.subscribed.closed) {
          this.subscribed.unsubscribe();
        }
      }

      /**
       * Polling
       * @readonly
       * @type {boolean}
       */
      /* istanbul ignore next */
      public get polling(): boolean {
        const { networkStatus } = this;
        return networkStatus === NetworkStatus.poll;
      }

      /**
       * Subscription - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get subscription() {
        return this._subscription;
      }

      /**
       * FetchPolicy - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get fetchPolicy() {
        return this._fetchPolicy;
      }

      /**
       * FetchPolicy - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set fetchPolicy(fetchPolicy) {
        this._fetchPolicy = fetchPolicy;
      }

      /**
       * ErrorPolicy - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get errorPolicy() {
        return this._errorPolicy;
      }

      /**
       * ErrorPolicy - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set errorPolicy(errorPolicy) {
        this._errorPolicy = errorPolicy;
      }

      /**
       * Skip - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get skip() {
        return this._skip;
      }

      /**
       * Skip - setter
       * @protected
       */
      /* istanbul ignore next */
      protected set skip(skip) {
        this._skip = skip;
      }

      /**
       * Params - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get params() {
        return this._params;
      }

      /**
       * Transform - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get transform() {
        return this._transform;
      }

      /**
       * On Completed - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onCompleted() {
        return this._onCompleted;
      }

      /**
       * On Error - getter
       * @protected
       */
      /* istanbul ignore next */
      protected get onError() {
        return this._onError;
      }

      /**
       * Stashed - getter
       * @private
       */
      /* istanbul ignore next */
      private get stashed() {
        return this._stashed;
      }

      /**
       * Stashed - setter
       * @private
       */
      /* istanbul ignore next */
      private set stashed(stashed) {
        this._stashed = stashed;
      }
    }

  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR::CoreGraphSocketConsumer ${err.message || err}`);
    }
    /* istanbul ignore next */
    return CoreNoop<CoreGraphSocketProps, never>;
  }
}

