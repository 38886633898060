import React, { PureComponent } from 'react';

import CoreFormContext from '../../forms/contexts/Form';
import { CoreInput, CoreFormEventsEnum, } from '../../forms/';
import { WaitForDelay } from '../../../../utils/';

/**
 * Hidden Value Component
 * @export
 * @class HiddenValueComponent
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class HiddenValueComponent extends PureComponent<GenericPropType, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof HiddenValueComponent
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * LifeCycle Hook
   * @memberof HiddenValueComponent
   */
   public componentDidMount() {
    const { FormEventListener, context } = this;
    context.emitter.on(CoreFormEventsEnum.FORM_EVENT_RESET, FormEventListener);
  }

  /**
   * LifeCycle Hook
   * @memberof HiddenValueComponent
   */
  /* istanbul ignore next */
  public componentWillUnmount() {
    const { FormEventListener, context } = this;
    context.emitter.off(CoreFormEventsEnum.FORM_EVENT_RESET, FormEventListener);
  }

  /**
   * Render
   * @returns
   * @memberof HiddenValueComponent
   */
  public render() {
    const { props: { name, required } } = this;
    return <CoreInput type="hidden" name={name} required={required} />;
  }

  /**
   * Form Event Listener
   * @protected
   * @memberof HiddenValueComponent
   */
  /* istanbul ignore next */
  protected FormEventListener = (): void => {
    WaitForDelay(10).then(() => {
      this.props.reset();
    });
  };
}
