import EventEmitter from 'events';

import { CoreNavigationServiceShape, CoreNavItemShape } from './interfaces/';
import { NAVIGATION_EVENT_CHANGE } from './constants';

/**
 * Navigation Service Base
 * @class CoreNavigationService
 * @implements {CoreNavigationServiceShape}
 */
export class CoreNavigationService implements CoreNavigationServiceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreNavigationService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Items
   * @private
   * @type {Map<string, CoreNavItemShape>}
   * @memberof CoreNavigationService
   */
  private _items: Map<string, CoreNavItemShape> = new Map<string, CoreNavItemShape>();

  /**
   * Current Name
   * @private
   * @type {string}
   * @memberof CoreNavigationService
   */
  private _current: string = '';

  /**
   * Creates an instance of CoreNavigationService.
   * @memberof CoreNavigationService
   */
  constructor() {
    this._emitter.setMaxListeners(100);
  }

  /**
   * Use
   * @param {Map<string, CoreNavItemShape>} items
   * @memberof CoreNavigationService
   */
  public use(items: Map<string, CoreNavItemShape>): void {
    this._items = items;
  }

  /**
   * Subscribe
   * @param {Func<void>} callback
   * @returns {Func<void>}
   * @memberof CoreNavigationService
   */
  public subscribe(callback: Func<void>): Func<void> {
    this._emitter.on(NAVIGATION_EVENT_CHANGE, callback);
    return callback;
  }

  /**
   * Unsubscribe
   * @param {Func<void>} callback
   * @memberof CoreNavigationService
   */
  public unsubscribe(callback: Func<void>): void {
    this._emitter.off(NAVIGATION_EVENT_CHANGE, callback);
    callback = ()=>void 0;
  }

  /**
   * Emit
   * @memberof CoreNavigationService
   */
  public emit(): void {
    const { current } = this;
    if (this._emitter.listenerCount(NAVIGATION_EVENT_CHANGE)) {
      this._emitter.emit(NAVIGATION_EVENT_CHANGE, current);
    }
  }

  /**
   * Has
   * @param {string} query
   * @returns {boolean}
   * @memberof CoreNavigationService
   */
  public has(query: string): boolean {
    const segs: string[] = query.split('.');

    const matched: string[] = [];
    let item: Map<string, CoreNavItemShape> = this.items;
    let match: string = '';

    while(segs.length) {
      matched.push(segs.shift());
      match = matched.join('.');
      if (item.has(match)) {
        item = item.get(match).children;
      } else {
        return false;
      }
    }

    return true;
  }

  /**
   * Change
   * @param {string} current
   * @memberof CoreNavigationService
   */
  public change(current: string): void {
    this.current = current;
    this.emit();
  }

  /**
   * Items - getter
   * @type {string}
   * @memberof CoreNavigationService
   */
  public get items(): Map<string, CoreNavItemShape> {
    return this._items;
  }

  /**
   * Current - getter
   * @type {string}
   * @memberof CoreNavigationService
   */
  public get current(): string {
    return this._current;
  }

  /**
   * Current - setter
   * @memberof CoreNavigationService
   */
  public set current(current: string) {
    this._current = current;
  }

  /**
   * Populated - getter
   * @readonly
   * @memberof CoreNavigationService
   */
  public get populated() {
    return this.items.size > 0;
  }
}

/**
 * Core Navigation Service
 * @export
 * @class CoreNavigationService
 * @implements {CoreNavigationServiceShape}
 * @description
- Core Navigation Service contains navigation items.
-- Items are Map based on interface `CoreNavItemShape`
-- Each item must have properties: `name`, `label` and `path` or `url`
-- * property `url` allows navigation to be used for external urls.
-- Items may also contain `children`, recursive Maps of `CoreNavItemShape` as "Sub Navigation"

- Sub Navigation may extend 1 or 2 more tiers.
- The public `use` method of the CoreNavigation Service allows assignment of Navigation Maps to the UI.
 */
const CoreNavigation = new CoreNavigationService();

export default CoreNavigation;
