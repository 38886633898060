import gql from 'graphql-tag';

export const GET_NOTIFICATIONS_UNREAD = gql`
  query GetNotificationsUnread {
    notifications {
      count
      results
    }
  }
`;

export const POST_NOTIFICATIONS_MARK = gql`
  mutation MarkAllAsRead($input: Any!) {
    MarkAllAsRead(input: $input) {
      data
    }
  }
`;
