import React from 'react';

import FormElementBase from './Base';
import { FormElementValueFromProps, FormElementSyncRestProps, FormUniqueKey } from '../../factories/';
import { CoreFormElementProps, CoreFormOptionShape } from '../../interfaces/';
import { IsDefined } from '../../../../../utils/';

/**
 * Form Element Select Base
 * @export
 * @class CoreFormElementSelectBase
 * @extends {FormElementBase}
 */
export default class CoreFormElementSelectBase extends FormElementBase<CoreFormElementProps> {
  /**
   * Render
   * @returns
   * @memberof CoreFormElementSelectBase
   */
  public render() {
    const { OnChangeOrInput, OnBlur, props } = this;
    const { type, name, value: VALUE, options, children, placeholder, onChange, onInput, onBlur, ...rest } = props;
    const value = FormElementValueFromProps(this, { type, value: VALUE });
    const synced = FormElementSyncRestProps(type, rest);
    const key = FormUniqueKey(type, name, value);
    const optional: boolean = !IsDefined(VALUE);
    return (
      <select key={key} name={name} defaultValue={value as any} onChange={OnChangeOrInput} onBlur={OnBlur} {...synced}>
        {optional ? (<option key={`option.${name}.optional`} label={placeholder} />) : null}
        {options.map((option: CoreFormOptionShape, ndx: number) => <option key={`option.${name}.${ndx}`} value={option.value as string} label={option.label} />)}
      </select>
    );
  }
}
