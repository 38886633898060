import React, { FormEvent, Fragment } from 'react';
import { cloneDeep } from 'lodash';

import CoreBootBase from './Base';

import { CoreBootActivationState } from '../interfaces/';

import { PASSWORD_INPUT_PATTERN, PASSWORD_INPUT_REQUIREMENTS, ACTIVATION_STATE, PASSWORD_MISMATCH_ERROR_MESSAGE } from '../constants';
import { BootStartExternal, ApplyActivationComplete, EscapePasswordRegExp } from '../factories/';
import { CoreTitle, CoreForm, CoreFormGroup, CoreInput, CoreFormSaveGroup, CoreHelp, CoreFormConsumer, CoreFormConsumerTypeEnum as E } from '../../../shared/';
import { WaitForDelay } from '../../../utils/';

/**
 * Boot Activate Container
 * @export
 * @class CoreBootActivateContainer
 * @extends {Component<CoreRouteProps, S = CoreBootActivationState>}
 */
export default class CoreBootActivateContainer extends CoreBootBase<CoreBootActivationState> {
  /**
   * State
   * @type {CoreBootActivationState}
   * @memberof CoreBootActivateContainer
   */
  public readonly state: CoreBootActivationState = cloneDeep(ACTIVATION_STATE);

  /**
   * LifeCycle Hook
   * @memberof CoreBootActivateContainer
   */
  public componentDidMount() {
    WaitForDelay().then(() => {
      /* istanbul ignore next */
      BootStartExternal('activation')
        .then(({ token, email }) => {
          this.setState({ token, email });
        })
        .catch((err: any) => {
          /* istanbul ignore next */
          (window as any).alert(err.message || err).then(() => {
            this.GoBase();
          });
        });
    });
  }

  /**
   * Render
   * @returns
   * @memberof CoreBootActivateContainer
   */
  public render() {
    const {
      state: { email, token }
    } = this;

    return (
      <Fragment>
        <CoreTitle>Welcome</CoreTitle>
        <CoreForm
          source={{ email, token }}
          onSubmit={(evt: FormEvent, values: HashMap<any>) => {
            /* istanbul ignore next */
            ApplyActivationComplete(values).then(() => {
              this.GoBase();
            }).catch((err: any) => {
              (window as any).alert(err.message || err).then(() => {
                this.GoBase();
              });
            });
          }}
          onReset={(evt: FormEvent) => {
            /* istanbul ignore next */
            this.GoBase();
          }}
          className="activate auth-form-wrapper"
          key={`.${Date.now()}`}
        >
          <CoreInput type="hidden" name="token" value={token} />
          <header>
            <h4>Choose your password</h4>
          </header>
          <section>
            <CoreFormGroup>
              <CoreInput type="email" name="email" readOnly={true} />
            </CoreFormGroup>
            <br/>
            <CoreFormGroup>
              <header>
                <h4>Password <CoreHelp>{PASSWORD_INPUT_REQUIREMENTS}</CoreHelp></h4>
              </header>
              <CoreInput type="password" name="password" pattern={PASSWORD_INPUT_PATTERN} required={true} />
            </CoreFormGroup>
            <CoreFormGroup>
              <CoreFormConsumer name="password" type={E.WATCHER}>
                {({ values: { password } }) => {
                  const escapedPassword = EscapePasswordRegExp(password);
                  return <CoreInput type="password" name="confirm" pattern={`^${escapedPassword}$`} errors={{
                    patternMismatch: PASSWORD_MISMATCH_ERROR_MESSAGE
                  }} title="Confirm" required={true} />
                }}
              </CoreFormConsumer>
            </CoreFormGroup>
          </section>
          <footer>
            <CoreFormSaveGroup reset="Cancel" submit="Send" />
          </footer>
        </CoreForm>
      </Fragment>
    );
  }
}
