export * from '../../Config/constants/';

const PATTERN = `(?=.{8,})((?=.*[^a-zA-Z])(?=.*[a-z])(?=.*[A-Z])|(?=.*[^a-zA-Z0-9])(?=.*d)(?=.*[a-zA-Z])).*`;

export const PASSWORD_INPUT_PATTERN = PATTERN.replace(/\r|\n|\s|\t/gm, '');

export const PASSWORD_INPUT_REQUIREMENTS = `Must use 8 or more chars combining U/l case + numerals or #!@$%^&*`;

export const ON_FORGOT_SENT_MESSAGE = 'Successfully sent reset password email';

export const AUTH_ROUTE_PATH = '/';

export const PASSWORD_MISMATCH_ERROR_MESSAGE = "Doesn't match password.";

export const RESET_STATE = {
  email: '',
  token: '',
};

export const ACTIVATION_STATE = {
  email: '',
  token: '',
};

export const PLUGIN_REQUIRED = 'A custom plugin is required for this action.'
