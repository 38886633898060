import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { CoreHeaderMenuItemProps } from '../interfaces/';
import { HEADER_MENU_ITEM_PROPS } from '../constants';

import { CoreAnchor } from '../../shared/';

/**
 * Header Menu Item Component
 * @export
 * @class CoreHeaderMenuItemComponent
 * @extends {PureComponent<CoreHeaderMenuItemProps, {}>}
 */
export default class CoreHeaderMenuItemComponent extends PureComponent<CoreHeaderMenuItemProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreHeaderMenuItemProps}
   * @memberof CoreHeaderMenuItemComponent
   */
  public static defaultProps: CoreHeaderMenuItemProps = cloneDeep(HEADER_MENU_ITEM_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreHeaderMenuItemComponent
   */
  public render() {
    const { name, label, href, permissions, external } = this.props.item;

    /* istanbul ignore next */
    if (permissions && !permissions()) {
      return null;
    }

    return (
      <li className={name}>
        <CoreAnchor href={href} rel={external ? 'external' : null}>{label}</CoreAnchor>
      </li>
    );
  }
}
