import React, { Fragment } from 'react';

import { CoreDialogInstance } from '../../Activity/';
import { SESSION_DIALOG_YES, SESSION_DIALOG_NO, SESSION_EARLY_MINUTES, SESSION_FINAL_MINUTES } from '../constants';

import { WithinMinutesOf, Noop, CORE_EPOCH_MINUTE, CORE_EPOCH_SECOND } from '../../utils/';

/**
 * Session Watcher Factory
 * @export
 * @class SessionWatcherFactory
 */
export class SessionWatcherFactory {
  /**
   * Context
   * @protected
   * @type {CoreAuthServiceType}
   * @memberof SessionWatcherFactory
   */
  protected context: CoreAuthServiceType;

  /**
   * Interval
   * @protected
   * @type {number}
   * @memberof SessionWatcherFactory
   */
  protected I: number = null;

  /**
   * Dialogs
   * @protected
   * @type {CoreDialogInstance}
   * @memberof SessionWatcherFactory
   */
  protected dialog_early: CoreDialogInstance;
  protected dialog_final: CoreDialogInstance;

  /**
   * Init
   * @returns {SessionWatcherFactory}
   * @memberof SessionWatcherFactory
   */
  public init(context: CoreAuthServiceType): SessionWatcherFactory {
    this.context = context;
    const dialog_early = new CoreDialogInstance(<EarlyWarning />, 'confirm');
    dialog_early.title = 'Session Ending';
    dialog_early.labels = { yes: SESSION_DIALOG_YES, no: SESSION_DIALOG_NO };
    this.dialog_early = dialog_early;

    const dialog_final = new CoreDialogInstance(<FinalWarning />, 'confirm', 'warning');
    dialog_final.title = 'Session Ending';
    dialog_final.labels = { yes: SESSION_DIALOG_YES, no: SESSION_DIALOG_NO };
    this.dialog_final = dialog_final;
    
    return this.clear();
  }

  /**
   * Start
   * @param {boolean} [ignored_early=false]
   * @param {boolean} [ignored_final=false]
   * @memberof SessionWatcherFactory
   */
  public start(ignored_early: boolean = false, ignored_final: boolean = false): void {
    const { context } = this;

    /* istanbul ignore next */
    this.I = (window as any).setInterval(() => {
      const now: number = Date.now();
      const exp: number = context.expires;

      if (exp === undefined || now > exp) {
        this.stop().Eject();
      } else {
        if (!ignored_early) {
          const early_warning: boolean = WithinMinutesOf(SESSION_EARLY_MINUTES, exp, now);
          if (early_warning) {
            this.clear().StartEarlyWarning();
          }
        }
  
        if (!ignored_final) {
          const final_warning: boolean = WithinMinutesOf(SESSION_FINAL_MINUTES, exp, now);
          if (final_warning) {
            this.clear().StartFinalWarning();
          }
        }
      }
    }, CORE_EPOCH_MINUTE);
  }

  /**
   * Stop
   * @returns {SessionWatcherFactory}
   * @memberof SessionWatcherFactory
   */
  public stop(): SessionWatcherFactory {
    const { dialog_early, dialog_final } = this;

    /* istanbul ignore next */
    if (dialog_early && dialog_early.active) {
      dialog_early.deactivate();
    }
    /* istanbul ignore next */
    if (dialog_final && dialog_final.active) {
      dialog_final.deactivate();
    }

    return this.clear();
  }

  /**
   * Start Early Warning
   * @protected
   * @memberof SessionWatcherFactory
   */
  protected StartEarlyWarning(): void {
    const { dialog_early, context } = this;

    dialog_early
      .activate()
      .promise()
      .then(() => {
        /* istanbul ignore next */
        this.context.refresh().catch(Noop);
        /* istanbul ignore next */
        this.clear().start();
      })
      .catch(() => {
        /* istanbul ignore next */
        this.clear().start(true);
      });

    /* istanbul ignore next */
    this.I = (window as any).setInterval(() => {
      const now: number = Date.now();
      const exp: number = context.expires;

      if (exp === undefined || now > exp) {
        this.stop().Eject();
      } else {
        const final_warning: boolean = WithinMinutesOf(0.5, exp, now);
        if (final_warning) {
          this.clear().StartFinalWarning();
        }
      }
    }, CORE_EPOCH_SECOND * 5);
  }


  /**
   * Start Final Warning
   * @protected
   * @memberof SessionWatcherFactory
   */
  protected StartFinalWarning(): void {
    const { dialog_final, context } = this;

    dialog_final
      .activate()
      .promise()
      .then(() => {
        /* istanbul ignore next */
        this.context.refresh().catch(Noop);
        /* istanbul ignore next */
        this.clear().start();
      })
      .catch(() => {
        /* istanbul ignore next */
        this.clear().start(true, true);
      });

    /* istanbul ignore next */
    this.I = (window as any).setInterval(() => {
      const now: number = Date.now();
      const exp: number = context.expires;
      if (exp === undefined || now > exp) {
        this.stop().Eject();
      }
    }, CORE_EPOCH_SECOND);
  }

  /**
   * Eject
   * @protected
   * @memberof SessionWatcherFactory
   */
  /* istanbul ignore next */
  protected Eject(): void {
    this.context.logout().catch(Noop);
  }

  /**
   * Clear
   * @protected
   * @returns {SessionWatcherFactory}
   * @memberof SessionWatcherFactory
   */
  protected clear(): SessionWatcherFactory {
    (window as any).clearInterval(this.I);
    this.I = null;
    return this;
  }
}

export const EarlyWarning = () => {
  return (
    <Fragment>
      <p>Your session will end in {SESSION_EARLY_MINUTES} minutes.</p>
      <p>
        Click <strong>{SESSION_DIALOG_YES}</strong> to keep working.
      </p>
      <p>
        You may ignore this warning to complete your current work; a final notice will appear in a few minutes.
      </p>
      <p>
        Click <strong>{SESSION_DIALOG_NO}</strong> to allow session timeout.
      </p>
    </Fragment>
  );
};

export const FinalWarning = () => {
  return (
    <Fragment>
      <p>Your session will end in {60 * SESSION_FINAL_MINUTES} seconds.</p>
      <p>
        Click <strong>{SESSION_DIALOG_YES}</strong> to keep working.
      </p>
      <p>
        Click <strong>{SESSION_DIALOG_NO}</strong> to allow session timeout.
      </p>
    </Fragment>
  );
};
