import React, { cloneElement, ReactElement } from 'react';

import CoreTabsItemBase from './Base';

import { CoreTabsProps, CoreTabItemProps } from '../interfaces';
import { IsDefined } from '../../../../utils/';

/**
 * Tabs Item Definitions Component
 * @export
 * @class CoreTabItemDefinitionsComponent
 * @extends {CoreTabsItemBase<CoreTabsProps>}
 */
export default class CoreTabItemDefinitionsComponent extends CoreTabsItemBase<CoreTabsProps> {
  /**
   * Render
   * @return {*}
   * @memberof CoreTabItemDefinitionsComponent
   */
  public render() {
    const { context: { items, current }, props: { strict } } = this;

    /* istanbul ignore next */
    if (!IsDefined(current)) {
      return null;
    }

    return (
      <dd>
        {Array.from(items).map(([name, child]: any, ndx: number) => {
          const { hide } = child.props;
          if (hide) {
            return null;
          }
          const active: boolean = current === name;
          const key = `tab.item.${name}.${ndx}`;
          const props: Partial<CoreTabItemProps> = strict ? { active, strict } : { active };
          return cloneElement(child as ReactElement, { key, ...props });
        })}
      </dd>
    );
  }
}
