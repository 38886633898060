import React, { Fragment } from 'react';
import { Auth0DecodedHash } from 'auth0-js';

import CoreBootBase from './Base';

import { AUTH_LOCK_CONTAINER } from '../constants';
import { RedirectAuth0DecodedHash } from '../interfaces/';
import { CoreTitle } from '../../../shared/';

import { CoreAuth, IsAuthenticatedAsync, InstallAuth0User, RemoveTransactionalCookies, AUTH_INSTALL_EVENT } from '../../../Auth/';
import { CoreNavigate } from '../../../Router/';
import { CoreBootEventEnum } from '../../enums/';
import { AuthLockInstance } from '../../factories/';
import { SearchToHashMap, WaitForDelay } from '../../../utils/';

/**
 * Boot Login Container
 * @export
 * @class CoreBootLoginContainer
 * @extends {CoreBootBase<CoreRouteProps, {}>}
 */
export default class CoreBootLoginContainer extends CoreBootBase {
  /**
   * LifeCycle Hook
   * @memberof CoreBootLoginContainer
   */
  public componentDidMount() {
    const { RedirectLockInstance, StandardLockInstance } = this;

    /* istanbul ignore next */
    IsAuthenticatedAsync().catch(() => {
      if (CoreAuth.credentials.mfa) {
        RedirectLockInstance();
      } else {
        StandardLockInstance();
      }
    });
  }

  /**
   * Render
   * @returns
   * @memberof CoreBootLoginContainer
   */
  public render() {
    return (
      <Fragment>
        <CoreTitle>Log In</CoreTitle>
        <div id={AUTH_LOCK_CONTAINER}></div>
      </Fragment>
    );
  }

  /**
   * Redirect Lock Instance
   * @private
   * @memberof CoreBootLoginContainer
   */
  private RedirectLockInstance = (): void => {
    const { DiscoverPasswordLink, context, props: { location: { hash } } } = this;

    let decoded: RedirectAuth0DecodedHash;

    /* istanbul ignore next */
    if (hash && (decoded = SearchToHashMap<RedirectAuth0DecodedHash>(hash)) && ('access_token' in decoded) && ('state' in decoded)) {

      const token = decoded['access_token'];
      const state = decoded['state'];

      CoreAuth.install(token, state)
        .then(RemoveTransactionalCookies)
        .then(InstallAuth0User)
        .then(() => {
          CoreAuth.emit(AUTH_INSTALL_EVENT);
          this.GoBase();
        })
        .catch(() => {
          this.props.location.hash = '';
          context.emit(CoreBootEventEnum.UNAUTHENTICATED);
        });
    } else {
      const lock: Auth0LockStatic = AuthLockInstance(AUTH_LOCK_CONTAINER, context.credentials);

      /* istanbul ignore next */
      WaitForDelay().then(() => {
        lock.show();
      });

      /* istanbul ignore next */
      lock.on('signin ready', DiscoverPasswordLink);
    }
  };

  /**
   * Standard Lock Instance
   * @private
   * @memberof CoreBootLoginContainer
   */
  /* istanbul ignore next */
  private StandardLockInstance = (): void => {
    
    const { context } = this;
 
    const lock: Auth0LockStatic = AuthLockInstance(AUTH_LOCK_CONTAINER, context.credentials, false);

    WaitForDelay().then(() => {
      lock.show();
    });

    lock.on('authenticated', (decoded: Auth0DecodedHash): void => {
      const { accessToken, state } = decoded;

      CoreAuth.install(accessToken, state)
        .then(RemoveTransactionalCookies)
        .then(InstallAuth0User)
        .then(() => {
          CoreAuth.emit(AUTH_INSTALL_EVENT);
          this.GoBase();
        })
        .catch((err: any) => {
          context.emit(CoreBootEventEnum.UNAUTHENTICATED);
        });
    });
  };

  /**
   * Discover Password Link
   * @protected
   * @memberof CoreBootLoginContainer
   */
  /* istanbul ignore next */
  protected DiscoverPasswordLink = (...args: any[]): void => {
    const link: HTMLAnchorElement = document.querySelector('a.auth0-lock-alternative-link');
    if (link) {
      link.onclick = (evt: Event): void => {
        evt.preventDefault();
        CoreNavigate(link.href);
      };
    }
  };
}
