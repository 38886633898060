import React, { PureComponent, Fragment } from 'react';
import { NetworkStatus } from '@apollo/client';

import CoreGraphContext from '../context';
import { Subscription } from '../interfaces/';

/**
 * NConsumer Base
 * @export
 * @abstract
 * @class ConsumerBase
 * @extends {PureComponent<P, never>}
 * @template P
 */
export default abstract class ConsumerBase<P = any> extends PureComponent<P, never> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreGraphContext>}
   */
  public static contextType: Readonly<typeof CoreGraphContext> = CoreGraphContext;

  /**
   * Abort Controller
   * @protected
   * @type {AbortController}
   * @memberof CoreGraphConsumer
   */
  protected listener: AbortController;

  /**
   * Variables
   * @protected
   * @type {HashMap<any}
   */
  protected _variables: HashMap<any> = undefined!;

  /**
   * Data
   * @protected
   * @type {HashMap<any>}
   */
  protected _data: HashMap<any> = null!;

  /**
   * Loading
   * @protected
   * @type {boolean}
   */
  protected _loading: boolean = true;

  /**
   * Network Status
   * @protected
   * @type {NetworkStatus}
   */
  protected _networkStatus: NetworkStatus = NetworkStatus.loading;

  /**
   * Error
   * @protected
   * @type {Error}
   */
  protected _error: Error = null!;

  /**
   * Subscribed
   * @protected
   * @type {Subscription}
   */
  protected subscribed: Subscription = { closed: true, unsubscribe: () => void 0 };

  /**
   * Creates an instance of ConsumerBase.
   * @param {*} props
   * @memberof ConsumerBase
   */
  constructor(props: any) {
    super(props);
    this.init = this.init.bind(this);
  }

  /**
   * Render
   * @return {JSX.Element} 
   */
  /* istanbul ignore next */
  public render(): JSX.Element {
    console.warn('must override in sub-class');
    return <Fragment />;
  }

  /**
   * Init
   * @protected
   * @param {*} props
   */
  /* istanbul ignore next */
  protected init(props: any): void {
    console.warn('must override in sub-class');
  }

  /**
   * Client - getter
   * @protected
   */
  protected get client() {
    return this.context.client;
  }

  /**
   * Variables - getter
   * @protected
   */
  protected get variables() {
    return this._variables;
  }

  /**
   * Variables - setter
   * @protected
   */
  protected set variables(variables) {
    this._variables = variables;
  }

  /**
   * Data - getter
   * @protected
   */
  protected get data() {
    return this._data;
  }

  /**
   * Data - setter
   * @protected
   */
  protected set data(data) {
    this._data = data;
  }

  /**
   * Loading - getter
   * @protected
   */
  protected get loading() {
    return this._loading;
  }

  /**
   * Loading - setter
   * @protected
   */
  protected set loading(loading) {
    this._loading = loading;
  }

  /**
   * Network Status - getter
   * @protected
   */
  protected get networkStatus() {
    return this._networkStatus;
  }

  /**
   * Network Status - setter
   * @protected
   */
  protected set networkStatus(networkStatus) {
    this._networkStatus = networkStatus;
  }

  /**
   * Error - getter
   * @protected
   */
  protected get error() {
    return this._error;
  }

  /**
   * Error - setter
   * @protected
   */
  protected set error(error) {
    this._error = error;
  }
}