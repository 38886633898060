import moment, { Moment } from 'moment';

import PickerServiceBase from './picker_base';

import { DatePickerProps, DateRangePickerServiceShape } from '../interfaces/';
import { DATE_RANGE_DISPLAY_SHORT_FORMAT, DATE_RANGE_DISPLAY_LONG_FORMAT, DATE_FORMAT_DATE } from '../constants';
import { IsDefined, IsEmpty, WithinYearOf, WaitForDelay } from '../../../../utils/';

/**
 * Date Range Picker Service
 * @export
 * @class DateRangePickerService
 * @extends {PickerServiceBase}
 * @implements {DateRangePickerServiceShape}
 */
export default class DateRangePickerService extends PickerServiceBase implements DateRangePickerServiceShape {
  /**
   * Min
   * @protected
   * @type {number}
   * @memberof DateRangePickerService
   */
  protected _min: number = null!;

  /**
   * Max
   * @protected
   * @type {number}
   * @memberof DateRangePickerService
   */
  protected _max: number = null!;

  /**
   * Days Span
   * @protected
   * @type {number}
   * @memberof DateRangePickerService
   */
  protected _dayspan: number = null!;

  /**
   * Update
   * @override
   * @param {string} [start_date]
   * @param {string} [end_date]
   * @memberof DateRangePickerService
   */
  public update(start_date?: string, end_date?: string): void {
    if (IsDefined(start_date)) {
      Object.assign(this.source, { start_date });
    }

    if (IsDefined(end_date)) {
      Object.assign(this.source, { end_date });
    }

    /* istanbul ignore next */
    WaitForDelay().then(() => {
      this.broadcast();
    });
  }

  /**
   * Init
   * @override
   * @protected
   * @param {DatePickerProps} props
   * @param {CoreFormServiceType} form
   * @memberof DateRangePickerService
   */
  public init(props: DatePickerProps, form: CoreFormServiceType): void {
    this._props = props;
    this._form = form;

    const { utc } = this;
    const { name, min, max, progressive } = props;

    try {
      if (min) {
        this._min = min;
      }

      if (max) {
        this._max = max;
      }

      /* istanbul ignore next */
      if (progressive) {
        if (!min || !max) {
          throw new ReferenceError('DateRangePicker : must supply props[min & max] if prop[progressive] is true.');
        }
        this._dayspan = moment(max).diff(moment(min), 'days');
      }

      let value: string = form.value(name) || null;

      /* istanbul ignore next */
      if (!value && props.value) {
        value = props.value as string;
        form.update(name, value);
      }

      this.source = {
        start_date: undefined,
        end_date: undefined,
      };

      const [start, end] = value ? value.split(',') : [undefined, undefined];

      if (start) {
        const $start: Moment = moment(utc(start));
        if ($start.isValid) {
          this._source.start_date = $start.format(DATE_FORMAT_DATE);
        }
      }

      if (end) {
        const $end: Moment = moment(utc(end));
        if ($end.isValid) {
          this._source.end_date = $end.format(DATE_FORMAT_DATE);
        }
      }

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * Display Name - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get display_name() {
    const { id } = this;
    return `${id}-display`;
  }

  /**
   * Display Value - getter
   * @override
   * @readonly
   * @memberof DateRangePickerService
   */
  public get display_value(): string {
    const { utc, source: { start_date, end_date } } = this;

    /* istanbul ignore next */
    if (start_date || end_date) {
      const format = WithinYearOf(start_date, end_date) ? DATE_RANGE_DISPLAY_SHORT_FORMAT : DATE_RANGE_DISPLAY_LONG_FORMAT;
      const $start: Date = start_date ? utc(start_date) : null;
      const $end: Date = end_date ? utc(end_date) : null;

      if (start_date === end_date || (start_date && !end_date)) {
        return moment($start).format(format);
      } else if (end_date && !start_date) {
        return moment($end).format(format);
      } else {
        return `${moment($start).format(format)} - ${moment($end).format(format)}`;
      }
    }

    return '';
  }

  /**
   * Reset
   * @memberof DateTimeRangePickerService
   */
  public reset = (): void => {
    this.init(this.props, this.form);
    this.broadcast();
  };

  /**
   * Min - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get min() {
    return this._min;
  }

  /**
   * Min - setter
   * @memberof DateRangePickerService
   */
  public set min(min) {
    this._min = min;
  }

  /**
   * Max - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get max() {
    return this._max;
  }

  /**
   * Max - setter
   * @memberof DateRangePickerService
   */
  public set max(max) {
    this._max = max;
  }

  /**
   * Day Span - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  /* istanbul ignore next */
  public get dayspan() {
    return this._dayspan;
  }

  /**
   * Start Date Namel - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get start_date_name() {
    const { id } = this;
    return `${id}-start-date`;
  }

  /**
   * Start Date Value - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get start_date_value() {
    const {
      source: { start_date },
    } = this;
    return start_date || '';
  }

  /**
   * End Date Name - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get end_date_name() {
    const { id } = this;
    return `${id}-end-date`;
  }

  /**
   * End Date Value - getter
   * @readonly
   * @memberof DateRangePickerService
   */
  public get end_date_value() {
    const {
      source: { end_date },
    } = this;
    return end_date || '';
  }

  /**
   * Min Date Value
   * @readonly
   * @memberof DateRangePickerService
   */
  public get min_date_value() {
    const { min } = this;
    return min ? moment(min).format('YYYY-MM-DD') : null;
  }

  /**
   * Max Date Value
   * @readonly
   * @memberof DateRangePickerService
   */
  public get max_date_value() {
    const { max } = this;
    return max ? moment(max).format('YYYY-MM-DD') : null;
  }

  /**
   * Actual - getter
   * @override
   * @readonly
   * @memberof DateRangePickerService
   */
  public get actual() {
    const { start_date, end_date } = this.source;
    return [start_date, end_date].join(',');
  }

  /**
   * Ready - getter
   * @override
   * @readonly
   * @memberof DateRangePickerService
   */
  public get ready() {
    const {
      source: { start_date, end_date },
      props: { required },
    } = this;
    /* istanbul ignore next */
    if (IsEmpty(start_date) && IsEmpty(end_date) && !required) {
      return true;
    }
    /* istanbul ignore next */
    return !IsEmpty(start_date) && !IsEmpty(end_date);
  }
}
