import CoreButtonBase from './ButtonBase';

import { ButtonProps } from '../interfaces/';

/**
 * Success Button
 * @export
 * @class CoreSuccessButton
 * @extends {CoreButtonBase<Component<ButtonProps, ButtonState>>}
 */
export default class CoreSuccessButton extends CoreButtonBase {
  public static defaultProps: ButtonProps = {
    type: 'button',
    variant: 'success',
    outline: false
  };
}
