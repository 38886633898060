import './header.scss';

import SampleToolsModule from 'App/modules/Tools/';

import { CoreAuth, CoreConfig, CoreHeaderMenu, CoreGetApiLocation } from '@neustar/core-ui';

/**
 * Header Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function HeaderPlugin(): Promise<boolean|Error> {
  try {
    const IsSocAdmin: Func<boolean> = () => {
      return CoreAuth.user.role.isSocAdmin;
    };

    const HEADER: Map<string, CoreHeaderItemType> = new Map<string, CoreHeaderItemType>()
      .set('soc', {
        label: 'SOC',
        name: 'soc',
        href: CoreGetApiLocation('soc'),
        permissions: IsSocAdmin
      })
      .set('support', {
        label: 'Support',
        name: 'support',
        href:  CoreConfig.header.support_url,
      });

    CoreHeaderMenu.use(HEADER);

    CoreHeaderMenu.tool.use(SampleToolsModule, IsSocAdmin);

    return true;
  } catch (err: any) {
    throw Error(err);
  }
};
