import { IsEqual } from '../../../../utils/';

/**
 * Graph Provider Props To Config Transform
 * @export
 * @param {*} props
 * @param {*} config
 * @return {*} {((CoreGraphLinkProviderType | CoreGraphRestProviderType | CoreGraphSocketProviderType) | boolean)}
 */
export function GraphProviderPropsToConfigTransform(props: any, config: any): (CoreGraphLinkProviderType | CoreGraphRestProviderType | CoreGraphSocketProviderType) | boolean {
  /* istanbul ignore next */
  if (IsEqual(props, config)) {
    return false;
  }
  return Object.assign({}, config, props);
}