export const LIST_MODULE_PROPS = {
  name: null,
  title: null,
  rows: 5,
  search: null,
  limit: null,
  addable: true,
  editable: true
};

export const LIST_TABLE_PROPS = {
  title: '',
  search: null,
  addable: true,
  editable: true
};

export const LIST_TABLE_STATE = {
  current: null,
};

export const UNDERSCORED_PROPS = ['_key', '_ref'];
