import { Options } from 'highcharts';
import CoreMapBase from './MapBase';
import { MAP_OPTIONS_CONTINENTS } from '../constants';

/**
 * Core Map Continents
 * @export
 * @class CoreMapContinents
 * @extends {CoreMapBase}
 */
export default class CoreMapContinents extends CoreMapBase {
  /**
   * Options
   * @type {Options}
   * @memberof CoreMapBase
   */
  options: Options = Object.assign({}, MAP_OPTIONS_CONTINENTS);
}
