import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreTableContext from '../context';

import { CoreTableColumnProps } from '../interfaces/';
import { CoreTableSortMethodMask } from '../enums/';

import { TABLE_COLUMN_PROPS } from '../constants';

import { MergeClassNames } from '../../../../utils/';

/**
 * Table Column Component
 * @export
 * @class CoreTableColumnComponent
 * @extends {Component<CoreTableColumnProps, {}>}
 */
export default class CoreTableColumnComponent extends Component<CoreTableColumnProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof CoreTableColumnComponent
   */
  public static displayName = 'CoreTableColumn';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof CoreTableColumnComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Default Props
   * @static
   * @type {CoreTableColumnProps}
   * @memberof CoreTableColumnComponent
   */
  public static defaultProps: CoreTableColumnProps = cloneDeep(TABLE_COLUMN_PROPS);

  /**
   * LifeCycle Hook
   * @memberof CoreTableColumnComponent
   */
  public componentDidMount() {
    const {
      context,
      props: { name, label, sort },
    } = this;

    context.columns.add(name, label || name);

    if (sort) {
      context.sort.add(name);
    }

    context.subscribe(`${CoreTableColumnComponent.displayName}_${name}`, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableColumnComponent
   */
  public componentWillUnmount() {
    const {
      context,
      props: { name },
    } = this;
    context.unsubscribe(`${CoreTableColumnComponent.displayName}_${name}`);
  }

  /**
   * Render
   * @returns
   * @memberof CoreTableColumnComponent
   */
  public render() {
    const { OnColumnSort, sortable, ascending, descending, unsorted, props, context: { columns } } = this;
    const { name, label, sort, hide, className, ...rest } = props;

    const $label = columns.label(name) || label || name;

    const hclz = MergeClassNames(className, { sortable, hide });
    const sclz = MergeClassNames(className, { ascending, descending, unsorted });

    return (
      <th className={hclz} onClick={OnColumnSort} {...rest}>
        <span className={sclz} />
        {$label}
      </th>
    );
  }

  /**
   * Sortable
   * @readonly
   * @type {boolean}
   * @memberof CoreTableColumnComponent
   */
  public get sortable(): boolean {
    return this.props.sort === true;
  }

  /**
   * Ascending
   * @readonly
   * @type {boolean}
   * @memberof CoreTableColumnComponent
   */
  public get ascending(): boolean {
    const { sortable, props: { name }, context: { sort: { column, method }} } = this;
    return sortable && column === name && method === CoreTableSortMethodMask.ASCENDING;
  }

  /**
   * Descending
   * @readonly
   * @type {boolean}
   * @memberof CoreTableColumnComponent
   */
  public get descending(): boolean {
    const { sortable, props: { name }, context: { sort: { column, method }} } = this;
    return sortable && column === name && method === CoreTableSortMethodMask.DESCENDING;
  }

  /**
   * Unsorted
   * @readonly
   * @type {boolean}
   * @memberof CoreTableColumnComponent
   */
  public get unsorted(): boolean {
    const { sortable, props: { name }, context: { sort: { column, method }} } = this;
    return sortable && (column !== name || column === name && method === CoreTableSortMethodMask.UNSORTED);
  }

  /**
   * On Column Sort
   * @protected
   * @param {any} evt
   * @memberof CoreTableColumnComponent
   */
  protected OnColumnSort = (evt: any): void => {
    const { context, sortable, props: { name } } = this;

    if (!sortable) {
      return void 0;
    }

    context.sort.update(name);
    context.process();
  };
}
