import { CoreMapChartShape } from '../interfaces/';

import { FormatBPS, FormatPPS } from '../../../../utils/';

/**
 * Core Maps Tooltip Formatter
 * @export
 * @param {CoreMapChartShape} map
 * @param {boolean} is_bits
 */
export function CoreMapsTooltipFormatter(map: CoreMapChartShape, is_bits: boolean = true): void {
  /* istanbul ignore next */
  const formatter = function(this: any) {
    if (!this.point) {
      return '';
    }
    const { label, name, value } = this.point;
    const format = is_bits ? FormatBPS(value) : FormatPPS(value);
    return `<strong>${label || name}</strong><br/><span>${format.size}${format.rate}</span>`;
  };
  map.options.tooltip.formatter = formatter;
  map.build();
}
