import React, { PureComponent, createContext } from 'react';
import { ApolloClient } from '@apollo/client';

import { CoreNoop } from '../../../components/';

import CoreGraphContext from '../context';
import { CoreGraphLink, CoreGraphRest, CoreGraphSocket } from '../clients/';
import { CoreGraphProviderProps } from '../interfaces/';
import { GraphProviderPropsToConfigTransform } from '../helpers/';
import { GRAPH_PROVIDER_TYPES } from '../constants';

import { IsDefined, IsEmpty } from '../../../../utils/';

/**
 * Core Graph Provider - Base
 * @export
 * @param {(CoreGraphConnectionType | CoreGraphLinkProviderType | CoreGraphRestProviderType | CoreGraphSocketProviderType)} config
 * @param {CoreGraphProviderType} type
 * @return {*} {CoreGraphProviderClassType}
 */
export default function CoreGraphProvider(config: (CoreGraphConnectionType | CoreGraphLinkProviderType | CoreGraphRestProviderType | CoreGraphSocketProviderType), type: CoreGraphProviderType): CoreGraphProviderClassType {
  try {

    /* istanbul ignore next */
    if (!IsDefined(config)) {
      throw new ReferenceError(`must pass config [of type (CoreGraphLinkProviderType || CoreGraphRestProviderType || CoreGraphSocketProviderType)] or graph [of type CoreGraphConnectionType].`);
    }

    /* istanbul ignore next */
    if (!~GRAPH_PROVIDER_TYPES.indexOf(type)) {
      throw new ReferenceError(`must apply CoreGraphProviderType of [${GRAPH_PROVIDER_TYPES.join(', ')}].`);
    }

    /* istanbul ignore next */
    if (config instanceof ApolloClient) {
      throw new ReferenceError(`must not pass ApolloClient as config. 
Use graph object [of type CoreGraphConnectionType] which contains the client, and needed properties.
----------
// DO:
const graph = CoreGraph[Link|Rest|Socket]({ uri });
cons MyProvider = CoreGraph[Link|Rest|Socket]Provider(graph);
----------
// DONT:
const client = CoreGraph[Link|Rest|Socket]({ uri }).client;
cons MyProvider = CoreGraph[Link|Rest|Socket]Provider(client);
----------`);
    }

    let graph: CoreGraphConnectionType = null!;
    let configuration = config;

    /**
     * Configuratiopn and Graph
     * @notes
     * Because we allow the passing of a Graph Connection Object,
     * we must detect and build configuration from the graph if passed.
     * Otherwise, we build Graph Connection Object from passed config.
     * ----
     * Why we break it down like this is so that we can assign `configuration`
     * to a class property and use it to compare injected props.
     * 
     * If configuration has not changed, we need not rebuild 
     * the Graph Connection Object and therefore the context
     * (see: `init`)
     */
    /* istanbul ignore next */
    if (('client' in config) && config.client instanceof ApolloClient) {
      graph = config;
      configuration = { uri: graph.uri, cache: graph.cache } as CoreGraphConnectionType;
      if (IsDefined(graph.silent)) {
        configuration.silent = graph.silent;
      } 
    } else {
      switch (type) {
        case 'link':
          graph = CoreGraphLink(config as CoreGraphLinkProviderType);
          break;
        case 'rest':
          graph = CoreGraphRest(config as CoreGraphRestProviderType);
          break;
        case 'socket':
          graph = CoreGraphSocket(config as CoreGraphSocketProviderType);
          break;
      }
    }

    /* istanbul ignore next */
    if (graph.type !== type) {
      throw new ReferenceError(`client type[${graph.type}] does not match passed provider type[${type}].`);
    }

    /**
     * Init Context
     */
    const ProviderContext = createContext<CoreGraphConnectionType>(graph);

    /**
     * Set Display name
     */
    let displayName: string;

    switch (type) {
      case 'link':
        displayName = 'CoreGraphLinkProvider';
        break;
      case 'rest':
        displayName = 'CoreGraphRestProvider';
        break;
      case 'socket':
        displayName = 'CoreGraphSocketProvider';
        break;
    }

    /**
     * Graph Provider Base
     * @export
     * @return {*} {PureComponent<CoreGraphProviderProps, never>}
     */
    return class CoreGraphProviderBase extends PureComponent<CoreGraphProviderProps, never> {
      /**
       * Context
       * @static
       * @type {Readonly<typeof ProviderContext>}
       */
      public static contextType: Readonly<typeof ProviderContext> = ProviderContext;

      /**
       * Display Name
       * @static
       * @type {string}
       */
      public static displayName: string = displayName;

      /**
       * CLIENT
       * @static
       * @type {CoreGraphClientType}
       */
      public static CLIENT: CoreGraphClientType = graph.client;

      /**
       * Configuration
       * @protected
       * @type {CoreGraphSocketProviderType}
       */
      protected configuration: CoreGraphSocketProviderType = configuration as any;

      /**
       * Creates an instance of CoreGraphProviderBase.
       * @param {CoreGraphProviderProps} props
       * @param {CoreGraphConnectionType} context
       */
      constructor(props: CoreGraphProviderProps, context: CoreGraphConnectionType) {
        super(props);
        this.init = this.init.bind(this);
        this.init(props);
      }

      /**
       * LifeCycle Hook
       */
      public componentDidMount(): void {
        const { context } = this;
        if (context.authenticate) {
          this.context.open();
        }
      }

      /**
       * LifeCycle Hook
       */
      public componentWillUnmount(): void {
        const { context } = this;
        if (context.authenticate) {
          this.context.close();
        }
      }

      /**
       * Render
       * @return {*} {JSX.Element}
       */
      public render(): JSX.Element {
        return (
          <CoreGraphContext.Provider value={this.context}>
            {this.props.children}
          </CoreGraphContext.Provider>
        );
      }

      /**
       * Init
       * @protected
       * @param {CoreGraphProviderProps} props
       */
      protected init({ children, ...props }: CoreGraphProviderProps): void {
        if (!IsEmpty(props)) {
          const configuration = this.configuration;
          let graph: CoreGraphConnectionType = null!;
          let conf: (CoreGraphLinkProviderType | CoreGraphRestProviderType | CoreGraphSocketProviderType) | boolean = false;
          let ProviderContext: any;

          switch (type) {
            case 'link':
              conf = GraphProviderPropsToConfigTransform(props, configuration);
              if (conf) {
                graph = CoreGraphLink(conf as CoreGraphLinkProviderType);
              }
              break;
            case 'rest':
              conf = GraphProviderPropsToConfigTransform(props, configuration);
              if (conf) {
                graph = CoreGraphRest(conf as CoreGraphRestProviderType);
              }
              break;
            case 'socket':
              conf = GraphProviderPropsToConfigTransform(props, configuration);
              if (conf) {
                graph = CoreGraphSocket(conf as CoreGraphSocketProviderType);
              }
              break;
          }

          /**
           * Difference detected: re-Init Context
           */
          if (conf && graph) {
            ProviderContext = createContext<CoreGraphConnectionType>(graph);
            CoreGraphProviderBase.contextType = ProviderContext;
            CoreGraphProviderBase.CLIENT = graph.client;
            this.context = graph;
            this.configuration = conf as any;
          }
        }
      }
    }
  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR::CoreGraphProvider [type=${type}] ${err.message || err}.`);
    }
    /* istanbul ignore next */
    return CoreNoop<any, never>;
  }
}
