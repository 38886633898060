import React from 'react';
import FormElementBase from './Base';
import { FormElementSyncRestProps, FormUniqueKey } from '../../factories/';
import { CoreFormElementProps } from '../../interfaces/';

/**
 * Form Element Input Base
 * @export
 * @class CoreFormElementInputBase
 * @extends {FormElementBase<CoreFormElementProps>}
 */
export default class CoreFormElementInputBase extends FormElementBase<CoreFormElementProps> {
  /**
   * Render
   * @returns
   * @memberof CoreFormElementInputBase
   */
  public render() {
    const { OnChangeOrInput, OnBlur, props } = this;
    const { type, name, value, onChange, onInput, onBlur, ...rest } = props;
    const synced = FormElementSyncRestProps(type, rest);
    const key = FormUniqueKey(type, name, value);
    return <input key={key} type={type} name={name} defaultValue={value as any} onChange={OnChangeOrInput} onBlur={OnBlur} {...synced} />;
  }
}
