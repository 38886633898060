import { ApolloQueryResult, DocumentNode } from '@apollo/client';

import { CoreGraphCRUDServiceShape } from '../interfaces/';
import { GraphCrudClientTransform } from '../helpers/';

import { FindPropInHashMap } from '../../../../utils/';

/**
 * Core Graph CRUD Service Base
 * @export
 * @class CoreGraphCRUDService
 * @implements {CoreGraphCRUDServiceShape}
 */
export default class CoreGraphCRUDService implements CoreGraphCRUDServiceShape {
  /**
   * ID
   * @private
   * @type {string}
   * @memberof CoreGraphCRUDService
   */
  private _id: string = null!;

  /**
   * RECORD
   * @protected
   * @type {DocumentNode}
   * @memberof CoreGraphCRUDService
   */
  protected RECORD: DocumentNode = null!;

  /**
   * CREATE
   * @protected
   * @type {DocumentNode}
   * @memberof CoreGraphCRUDService
   */
  protected CREATE: DocumentNode = null!;

  /**
   * UPDATE
   * @protected
   * @type {DocumentNode}
   * @memberof CoreGraphCRUDService
   */
  protected UPDATE: DocumentNode = null!;

  /**
   * DELETE
   * @protected
   * @type {DocumentNode}
   * @memberof CoreGraphCRUDService
   */
  protected DELETE: DocumentNode = null!;

  /**
   * Graph
   * @protected
   * @type {CoreGraphResultType}
   * @memberof CoreGraphCRUDService
   */
  private _graph: CoreGraphResultType = null!;

  /**
   * Creates an instance of CoreGraphCRUDService.
   * @param {CoreGraphResultType} [graph=null]
   * @memberof CoreGraphCRUDService
   */
  constructor(graph: CoreGraphResultType = null) {
    if (graph) {
      this.graph = graph;
    }
  }

  /**
   * Record
   * @param {HashMap<any>} input
   * @returns {Promise<any>}
   * @memberof CoreGraphCRUDService
   */
  public async record(input: HashMap<any>): Promise<ApolloQueryResult<any>> {
    try {
      if (!this.RECORD) {
        throw new ReferenceError('missing service DocumentNode: RECORD');
      }
      const query: DocumentNode = this.RECORD;
      return this.graph.client.query({ query, variables: { ...input } });
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  /**
   * Create
   * @param {HashMap<any>} input
   * @returns {Promise<string | Error>}
   * @memberof CoreGraphCRUDService
   */
  public async create(input: HashMap<any>): Promise<string | Error> {
    try {
      if (!this.CREATE) {
        throw new ReferenceError('missing service DocumentNode: CREATE');
      }
      const mutation: DocumentNode = this.CREATE;
      const response = await this.graph.client.mutate({ mutation, variables: { input } });
      return FindPropInHashMap(response, 'id');
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  /**
   * Update
   * @param {HashMap<any>} input
   * @returns {Promise<string | Error>}
   * @memberof CoreGraphCRUDService
   */
  public async update(input: HashMap<any>): Promise<string | Error> {
    try {
      /* istanbul ignore next */
      if (!input.id) {
        throw new TypeError('missing input property [id]');
      }
      if (!this.UPDATE) {
        throw new ReferenceError('missing service DocumentNode: UPDATE');
      }
      const mutation: DocumentNode = this.UPDATE;
      const response = await this.graph.client.mutate({ mutation, variables: { input } });
      return FindPropInHashMap(response, 'id');
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  /**
   * Save
   * @param {HashMap<any>} input
   * @returns {Promise<string | Error>}
   * @memberof CoreGraphCRUDService
   */
  public async save(input: HashMap<any>): Promise<string | Error> {
    // establish Create or Update
    const $new: boolean = input.id ? false: true;
    if ($new) {
      return this.create(input);
    } else {
      return this.update(input);
    }
  }

  /**
   * Delete
   * @param {*} input
   * @returns {Promise<boolean | Error>}
   * @memberof CoreGraphCRUDService
   */
  public async delete(): Promise<boolean | Error> {
    try {
      if (!this.DELETE) {
        throw new ReferenceError('missing service DocumentNode: DELETE');
      }
      const id: string = this.id;
      const mutation: DocumentNode = this.DELETE;
      await this.graph.client.mutate({ mutation, variables: { input: { id } } });
      return true;
    } catch (err: any) {
      /* istanbul ignore next */
      return Promise.reject(err);
    }
  }

  /**
   * ID - getter
   * @readonly
   * @memberof CoreGraphCRUDService
   */
  public get id() {
    return this._id;
  }

  /**
   * ID - setter
   * @memberof CoreGraphCRUDService
   */
  public set id(id: string) {
    this._id = id;
  }

  /**
   * Is New - getter
   * @readonly
   * @memberof CoreGraphCRUDService
   */
  public get isNew() {
    return this._id === null;
  }

  /**
   * Graph - getter
   * @readonly
   * @type {CoreGraphResultType}
   * @memberof CoreGraphCRUDService
   */
  public get graph(): CoreGraphResultType {
    return this._graph;
  }

  /**
   * Graph - setter
   * @memberof CoreGraphCRUDService
   */
  public set graph(graph) {
    this._graph = GraphCrudClientTransform(graph);
  }
}
