import React, { ComponentClass } from 'react'

import ControllerBase from '../ControllerBase';
import ControllerWrapper from '../ControllerWrapper';

import ControllerMenu from './Menu';
import { CoreFormElementSelect, ControllerLabel } from '../../../components/';

/**
 * Select Base
 * @export
 * @class SelectBase
 * @extends {ControllerBase}
 */
export default class SelectBase extends ControllerBase {
  /**
   * Controlled Element
   * @static
   * @type {ComponentClass<any>}
   * @memberof SelectBase
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementSelect;

  /**
   * Render
   * @returns
   * @memberof SelectBase
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, menu, controller, tooltip } = this;
    const { name, value, options } = controller;
    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel {...labels} htmlFor="_">
          <ControlledElement name={name} value={value} options={options} onChange={OnChangeOrInput} {...elements} />
          <ControllerMenu {...menu} />
          {tooltip}
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
