/**
 * Menu Sync Select Element
 * @export
 * @param {HTMLSelectElement} select
 * @param {any[]} value
 */
export function MenuSyncSelectElement(select: HTMLSelectElement, value: any[]): void {
  /**
   * Sanitize select object
   */
  select.value = value.join(',');

  /**
   * Iterate -> apply selected values
   */
  let i: number = select.options.length;
  let option: HTMLOptionElement;
  while(i--) {
    option = select.options[i];
    option.selected = !!~value.indexOf(option.value);
  }
}

/**
 * Menu Select Multi Label
 * @export
 * @param {number} count
 * @return {string}
 */
export function MenuSelectMultiLabel(count: number): string {
  return `Selected (${count} items)`;
}
