import React, { PureComponent } from 'react';

import CoreNavigationContext from '../context';
import CoreNavItemsContainer from './Items';
import { CoreMaskComponent } from '../components/';
import { CoreNavigationHistoryDirective } from '../directives/';

/**
 * Navigation Container
 * @export
 * @class CoreNavigationContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNavigationContainer extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreNavigationContext>}
   * @memberof CoreNavigationContainer
   */
  public static contextType: Readonly<typeof CoreNavigationContext> = CoreNavigationContext;

  /**
   * Render
   * @returns
   * @memberof CoreNavigationModule
   */
  public render() {
    const {
      context: { items },
    } = this;

    return (
      <nav>
        <CoreMaskComponent />
        <CoreNavigationHistoryDirective />
        <CoreNavItemsContainer items={items} top={true} />
      </nav>
    );
  }
}
