/**
 * Table Columns Service
 * @export
 * @class CoreTableColumnsService
 */
export default class CoreTableColumnsService {
  /**
   * Count
   * @private
   * @type {number}
   * @memberof CoreTableColumnsService
   */
  private _count: number = 0;

  /**
   * Columns
   * @private
   * @type {number}
   * @memberof CoreTableColumnsService
   */
  private _columns: Map<string, string> = new Map<string, string>();

  /**
   * Update
   * @param {HashMap<string>} [add={}]
   * @param {string[]} [remove=[]]
   * @memberof CoreTableColumnsService
   */
  public update(add: HashMap<string> = {}, remove: string[] = []): void {
    for (const name in add) {
      this.add(name, add[name]);
    }
    remove.forEach((name: string) => {
      this.remove(name);
    });
  }

  /**
   * Add
   * @param {string} name
   * @param {string} label
   * @memberof CoreTableColumnsService
   */
  public add(name: string, label: string): void {
    this._columns.set(name, label);
  }

  /**
   * Remove
   * @param {string} name
   * @memberof CoreTableColumnsService
   */
  public remove(name: string): void {
    this._columns.delete(name);
  }

  /**
   * Label
   * @param {string} name
   * @memberof CoreTableColumnsService
   */
  public label(name: string): string {
    return this._columns.get(name);
  }

  /**
   * Count - getter
   * @memberof CoreTableColumnsService
   */
  public get count() {
    return this._count;
  }

  /**
   * Count - setter
   * @memberof CoreTableColumnsService
   */
  public set count(count: number) {
    this._count = count;
  }
}
