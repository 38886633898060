import React, { PureComponent } from 'react';

import { TextAreaController } from './TextAreas/';
import { CoreFormControllerProps } from '../../interfaces/';
import { CORE_TEXTAREA_PROPS, TEXT_TYPE_AREA } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * TextArea Container
 * @export
 * @class CoreTextAreaContainer
 * @extends {PureComponent<CoreFormControllerProps, {}>}
 */
export default class CoreTextAreaContainer extends PureComponent<CoreFormControllerProps, {}> {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormControllerProps, 'name'>}
   * @memberof CoreTextAreaContainer
   */
  public static defaultProps: Omit<CoreFormControllerProps, 'name'> = HashMapMergeProps(CORE_TEXTAREA_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreTextAreaContainer
   */
  public render() {
    const { props } = this;
    switch (props.type) {
      case TEXT_TYPE_AREA:
        return <TextAreaController {...props} />;
      /* istanbul ignore next */
      default:
        if (process.env.NODE_ENV !== 'production') {
          console.warn(`DEVELOPER ERROR:: not a textarea type="${props.type}".`);
        }
        return null;
    }
  }
}
