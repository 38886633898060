import React, { FormEvent, Fragment } from 'react';
import { cloneDeep } from 'lodash';

import CoreBootBase from './Base';

import {
  CoreTitle,
  CoreForm,
  CoreFormGroup,
  CoreInput,
  CoreFormSaveGroup,
  CoreHelp,
  CoreFormConsumer,
  CoreFormConsumerTypeEnum as E
} from '../../../shared/';

import { CoreBootResetState } from '../interfaces/';
import { BootStartExternal, ApplyResetComplete, EscapePasswordRegExp } from '../factories/';
import { PASSWORD_INPUT_PATTERN, PASSWORD_INPUT_REQUIREMENTS, PASSWORD_MISMATCH_ERROR_MESSAGE, RESET_STATE } from '../constants';
import { WaitForDelay } from '../../../utils/';

/**
 * Boot Reset Container
 * @export
 * @class CoreBootResetContainer
 * @extends {Component<CoreRouteProps, S = CoreBootResetState>}
 */
export default class CoreBootResetContainer extends CoreBootBase<CoreBootResetState> {
  /**
   * State
   * @type {CoreBootResetState}
   * @memberof CoreBootResetContainer
   */
  public readonly state: CoreBootResetState = cloneDeep(RESET_STATE);

  /**
   * LifeCycle Hook
   * @memberof CoreBootResetContainer
   */
  public componentDidMount() {
    WaitForDelay().then(() => {
      /* istanbul ignore next */
      BootStartExternal('reset')
        .then(({ token, email }) => {
          this.setState({ token, email });
        })
        .catch((err: any) => {
          /* istanbul ignore next */
          (window as any).alert(err.message || err).then(() => {
            this.GoBase();
          });
        });
    });
  }

  /**
   * Render
   * @returns
   * @memberof CoreBootResetContainer
   */
  public render() {
    const {
      state: { token, email },
    } = this;

    return (
      <Fragment>
        <CoreTitle>Reset Password</CoreTitle>
        <CoreForm
          source={{ token, email }}
          onSubmit={(evt: FormEvent, values: HashMap<any>) => {
            /* istanbul ignore next */
            ApplyResetComplete(values)
              .then(() => {
                this.GoBase();
              })
              .catch((err: any) => {
                (window as any).alert(err.message || err).then(() => {
                  this.GoBase();
                });
              });
          }}
          onReset={(evt: FormEvent) => {
            /* istanbul ignore next */
            this.GoBase();
          }}
          className="reset auth-form-wrapper"
          key={`.${Date.now()}`}
        >
          <CoreInput type="hidden" name="token" value={token} />
          <header>
            <h4>Choose new password</h4>
          </header>
          <section>
            <CoreFormGroup>
              <CoreInput type="email" name="email" readOnly={true} />
            </CoreFormGroup>
            <br/>
            <CoreFormGroup>
              <header>
                <h4>Password <CoreHelp>{PASSWORD_INPUT_REQUIREMENTS}</CoreHelp></h4>
              </header>
              <CoreInput type="password" name="password" pattern={PASSWORD_INPUT_PATTERN} required={true} />
            </CoreFormGroup>
            <CoreFormGroup>
              <CoreFormConsumer name="password" type={E.WATCHER}>
                {({ values: { password } }) => {
                  const escapedPassword = EscapePasswordRegExp(password);
                  return <CoreInput type="password" name="confirm" pattern={`^${escapedPassword}$`} errors={{
                    patternMismatch: PASSWORD_MISMATCH_ERROR_MESSAGE
                  }} title={'Confirm'} required={true} /> 
                }}
              </CoreFormConsumer>
            </CoreFormGroup>
          </section>
          <footer>
            <CoreFormSaveGroup reset="Cancel" submit="Send" />
          </footer>
        </CoreForm>
      </Fragment>
    );
  }
}
