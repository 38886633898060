import React, { FormEvent } from 'react';

import DatePickerBase from './Base';

import { CoreFormElementTime, CoreFormInputProps } from '../../forms/';
import { IsFunction } from '../../../../utils/';

/**
 * Start Time Component
 * @export
 * @class StartTimeComponent
 * @extends {DatePickerBase<CoreFormInputProps>}
 */
export default class StartTimeComponent extends DatePickerBase<CoreFormInputProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof StartTimeComponent
   */
  public static displayName: string = 'StartTime';

  /**
   * Render
   * @returns
   * @memberof StartTimeComponent
   */
  public render() {
    const { OnStartTimeChange, context: { start_time_name, start_time_value }, props: { required: REQUIRED }} = this;
    const required: boolean = IsFunction(REQUIRED) ? (REQUIRED as any)() : REQUIRED;
    return <CoreFormElementTime name={start_time_name} value={start_time_value} onBlur={OnStartTimeChange} required={required} className="width-17" />;
  }

  /**
   * On Start Time Change
   * @protected
   * @param {FormEvent<HTMLInputElement>} { currentTarget: { validity: { valid } }}
   * @param {CoreFormValueType} value
   * @memberof StartTimeComponent
   */
  /* istanbul ignore next */
  protected OnStartTimeChange = ({ currentTarget: { validity: { valid } }}: FormEvent<HTMLInputElement>, value: CoreFormValueType): void => {
    if (valid) {
      this.context.update(undefined, undefined, value);
    }
  };
}
