import { IsNumber, IsString, IsBoolean, IsDate, IsEqual, IsEmpty } from './checks';
import { ObjectKeys } from './hashmaps';

/**
 * Sort Helpers
 */
const LEXICAL_SORT_ALG = (a: string, b: string): number => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a > b ? 1 : a < b ? -1 : 0;
};
const NUMERIC_SORT_ALG = (a: number, b: number): number => a - b;
const BOOLEAN_SORT_ALG = (a: boolean, b: boolean): number => Number(a) - Number(b);
const DATE_SORT_ALG = (a: boolean, b: boolean): number => a < b ? -1 : a > b ? 1 : 0;

/**
 * Arrays Equal
 * @export
 * @param {Array<any>} a
 * @param {Array<any>} b
 * @returns {boolean}
 */
export const ArraysEqual = (a: Array<any>, b: Array<any>): boolean => {
  const A = a.slice(0);
  const B = b.slice(0);
  A.sort();
  B.sort();
  return IsEqual(A, B);
};

/**
 * HashMap To Dictionary
 * @export
 * @param {HashMap<any>} src
 * @returns {Array<HashMap<any>>}
 */
export const HashMapToDictionary = (src: HashMap<any>): Array<HashMap<any>> => {
  return ObjectKeys(src).reduce((d: Array<HashMap<any>>, prop: string) => {
    d.push(src[prop]);
    return d;
  }, []);
};

/**
 * Dictionary Index Of
 * @export
 * @param {HashMap<any>} src
 * @returns {Array<HashMap<any>>}
 */
export const DictionaryIndexOf = (src: Array<HashMap<any>>, compare: HashMap<any>): number => {
  let index: number = -1;
  let i: number = 0;
  let l: number = src.length;
  for(;i<l;i++) {
    if (IsEqual(src[i], compare)) {
      index = i;
      break;
    }
  }
  return index;
};

/**
 * HashMap To Key-Value Array
 * @export
 * @param {HashMap<any>} src
 * @param {string} [prop='prop']
 * @param {string} [value='value']
 * @returns {Array<HashMap<any>>}
 */
export const HashMapToKeyValueArray = (src: HashMap<any>, prop: string = 'prop', value: string = 'value'): Array<HashMap<any>> => {
  const arr: Array<HashMap<any>> = [];
  let v: any;
  for (const k in src) {
    v = src[k];
    arr.push({
      [prop]: k,
      [value]: v,
    });
  }
  return arr;
};

/**
 * Enum To Array
 * @export
 * @param {any} src
 * @returns {any[]}
 */
export const EnumToArray = (src: any): any[] => {
  const arr: any[] = [];
  let val: any;
  for (const prop in src) {
    val = src[prop];
    arr.push(val);
  }
  return arr;
};

/**
 * Array Unique
 * @export
 * @param {any[]>} a
 * @returns {any[]}
 */
export const ArrayUnique = (a: any[]): any[] => {
  return [...new Set(a)];
};

/**
 * Array MultiSort
 * @export
 * @param {Array<any>} src
 * @returns {Array<any>}
 */
export const ArrayMultiSort = (src: Array<any>): Array<any> => {
  try {
    const samp = src[0];

    let ALG: Func<number, any>;
    if (IsString(samp)) {
      ALG = LEXICAL_SORT_ALG;
    } else if (IsNumber(samp)) {
      ALG = NUMERIC_SORT_ALG;
    } else if (IsBoolean(samp)) {
      ALG = BOOLEAN_SORT_ALG;
    } else if (IsDate(samp)) {
      ALG = DATE_SORT_ALG;
    } else {
      throw new TypeError('utils: cannot sort on non Lexical/Numeric/Boolean/Date fields.');
    }

    src.sort(ALG);
  } catch (err: any) {
    console.error(err);
  }

  return src;
};

/**
 * Array MultiSort Complex
 * @export
 * @param {string} prop
 * @param {Array<HashMap<any>>} src
 * @returns {Array<HashMap<any>>}
 */
export const ArrayMultiSortComplex = (prop: string, src: Array<HashMap<any>>): Array<HashMap<any>> => {
  try {

    const samp = src[0][prop][0];

    let ALG: Func<number, any>;
    if (IsString(samp)) {
      ALG = LEXICAL_SORT_ALG;
    } else if (IsNumber(samp)) {
      ALG = NUMERIC_SORT_ALG;
    } else if (IsBoolean(samp)) {
      ALG = BOOLEAN_SORT_ALG;
    } else if (IsDate(samp)) {
      ALG = DATE_SORT_ALG;
    } else {
      throw new TypeError('utils: cannot sort on non Lexical/Numeric/Boolean/Date fields.');
    }

    src.forEach((row) => {
      if (row[prop]) {
        row[prop].sort(ALG);
      }
    });
  } catch (err: any) {
    console.error(err);
  }

  return src;
};

/**
 * HashMap To Multi-Dimensional Array
 * @export
 * @param {HashMap<any>} src
 * @returns {Array<any>}
 */
export const HashMapToMultiDimensionalArray = (src: HashMap<any>, acc: any[] = []): Array<any> => {
  return Object.keys(src).reduce((d: any[], k: string): any[] => {
    d.push([k, src[k]]);
    return d;
  }, acc);
};

/**
 * Numerals To Numbers
 * @export
 * @param {HashMap<any>} src
 * @returns {Array<any>}
 */
export const ArrayNumeralsToNumbers = (src: string[]): number[] => {
  return src.map((v: string): number => Number(v));
};

/**
 * Trim
 * @export
 * @param {Array<any>} src
 * @returns {Array<any>}
 */
export const ArrayTrim = (src: Array<any>): Array<any> => {
  return src.slice(0).filter((v: any): boolean => !IsEmpty(v));
};
