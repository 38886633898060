import React, { PureComponent } from 'react';

import CoreBusyContext, { Context } from './context';

/**
 * Busy Wrapper
 * @export
 * @class CoreBusyWrapper
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBusyWrapper extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBusyWrapper
   */
  public render() {
    return (
      <CoreBusyContext.Provider value={Context}>
        {this.props.children}
      </CoreBusyContext.Provider>
    );
  }
}
