import React, { PureComponent } from 'react';

/**
 * Empty Component
 * @export
 * @class CoreEmptyComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreEmptyComponent extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreEmptyComponent
   */
  public render() {
    return <span />;
  }
}
