import React, { PureComponent, Fragment } from 'react';

import { UNDERSCORED_PROPS } from '../constants';
import { IsBoolean } from '../../../../utils/';

/**
 * List Table Cell
 * @export
 * @class CoreListTableCellComponent
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class CoreListTableCellComponent extends PureComponent<GenericPropType, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreListTableCellComponent
   */
  public render() {
    const { props: { ndx, ...rest} } = this;

    const rows = [];

    let value: string;

    for (let prop in rest) {
      value = rest[prop];
      if (!!~UNDERSCORED_PROPS.indexOf(prop)) {
        prop = prop.slice(1);
      }
      if (IsBoolean(value)) {
        value = value ? 'TRUE' : 'FALSE';
      }
      rows.push({ prop, value });
    }

    return (
      <Fragment>
        {rows.map(({ prop, value }: GenericPropType, ndx: number) => (<span key={`lbr.${ndx}`}>{ndx ? ' | ' : null}{prop}: <small><strong>{value}</strong></small></span>))}
      </Fragment>
    );
  }
}
