import { ApolloCache, InMemoryCache } from '@apollo/client';
import { SubscriptionClient, MessageTypes } from 'subscriptions-transport-ws';

import { CoreGraphBaseShape } from '../interfaces/';

import { CoreAuth, AUTH_REFRESH_EVENT, AUTH_INSTALL_EVENT } from '../../../../Auth/';

/**
 * Core Graph Base
 * @export
 * @abstract
 * @class CoreGraphBase
 * @implements {CoreGraphBaseShape}
 * @template T = Apollo Client Type
 * @template P = Configuration Props
 * @notes returns an object containing public properties:
 * 1. client
 * 2. open and close methods.
 *  - Auth Refresh event handlers.
 *  - Intended for in-component instantiation & clean up
 */
export default abstract class CoreGraphBase<T = any, P = any> implements CoreGraphBaseShape<T> {
  /**
   * Client
   * @type {T}
   * @memberof CoreGraphBase
   */
  public client: T | any = null!;

  /**
   * Connection
   * @type {SubscriptionClient}
   * @memberof CoreGraphBase
   */
  public connection: SubscriptionClient = null!;

  /**
   * Token
   * @protected
   * @type {string}
   * @memberof CoreGraphBase
   */
  protected token: string = null!;

  /**
   * URI
   * @protected
   * @type {string}
   * @memberof CoreGraphBase
   */
  protected uri: string = null!;

  /**
   * Silent
   * @protected
   * @type {boolean}
   * @memberof CoreGraphBase
   */
  protected silent: boolean = false;

  /**
   * Cache
   * @protected
   * @type {((InMemoryCache | ApolloCache<any>))}
   * @memberof CoreGraphBase
   */
  protected cache: (InMemoryCache | ApolloCache<any>) = null!;

  /**
   * Authenticate - Authentication Required
   * @protected
   * @type {boolean}
   * @memberof CoreGraphBase
   */
  protected authenticate: boolean = false;

  /**
   * Creates an instance of CoreGraphBase.
   * @param {P} config
   * @param {CoreGraphAuthType} [auth=CoreAuth]
   * @memberof CoreGraphBase
   */
  constructor(config: P, auth: CoreGraphAuthType = CoreAuth) {
    this.GraphOnRefreshToken = this.GraphOnRefreshToken.bind(this);
    this.GraphOnInstallToken = this.GraphOnInstallToken.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.make = this.make.bind(this);
    this.init = this.init.bind(this);
    this.init(config, auth);
  }

  /**
   * Graph On Refresh Token
   * @protected
   * @memberof CoreGraphBase
   */
  /* istanbul ignore next */
  protected GraphOnRefreshToken(): void {
    /**
     * Client Clear Store
     * Stops all current queries.
     * - we use `clearStore` here because 
     * `resetStore` causes a fresh request,
     * triggering a re-render of sub-components.
     */
    if (this.client) {
      this.client.clearStore();
    }

    if (this.token !== CoreAuth.token) {
      this.token = CoreAuth.token;

      /**
       * Socket Connections don't get closed on rebuild
       */
      if (this.connection) {
        (this.connection as any).close(true, true);
      }

      this.client = this.make();

      /**
       * So after rebuild we re-establish the connection.
       */
      if (this.connection) {
        (this.connection as any).connect();
        Object.keys(this.connection.operations).forEach((id: string): void => {
          (this.connection as any).sendMessage(id, MessageTypes.GQL_START, this.connection.operations[id].options);
        });
      }
    }
  }

  /**
   * Graph On Install Token
   * @protected
   * @memberof CoreGraphBase
   */
  /* istanbul ignore next */
  protected GraphOnInstallToken(): void {
    try {
      const { authenticate, token } = this;

      if (!CoreAuth.authenticated) {
        throw new Error(`CoreAuth[AUTH_INSTALL_EVENT] was emitted in an unauthenticated state.`);
      }

      if (authenticate && !token) {
        /**
         * Client Clear Store
         * Stops all current queries.
         * - we use `clearStore` here because 
         * `resetStore` causes a fresh request,
         * triggering a re-render of sub-components.
         */
        if (this.client) {
          this.client.clearStore();
        }

        this.token = CoreAuth.token;

        /**
         * Socket Connections don't get closed on rebuild
         */
        if (this.connection) {
          (this.connection as any).close(true, true);
        }

        this.client = this.make();

        /**
         * So after rebuild we re-establish the connection.
         */
        if (this.connection) {
          (this.connection as any).connect();
          Object.keys(this.connection.operations).forEach((id: string): void => {
            (this.connection as any).sendMessage(id, MessageTypes.GQL_START, this.connection.operations[id].options);
          });
        }
      }

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`GRAPH CLIENT MAKE ERROR:: CoreGraphBase.init: ${err.message || err}`);
      }
    }
  }

  /**
   * Open
   * @memberof CoreGraphBase
   */
  /* istanbul ignore next */
  public open(): void {
    const { GraphOnRefreshToken } = this;
    CoreAuth.emitter.on(AUTH_REFRESH_EVENT, GraphOnRefreshToken);
  }

  /**
   * Close
   * @memberof CoreGraphBase
   */
  /* istanbul ignore next */
  public close(): void {
    const { GraphOnRefreshToken } = this;
    CoreAuth.emitter.off(AUTH_REFRESH_EVENT, GraphOnRefreshToken);
  }

  /**
   * Init
   * @protected
   * @param {HashMap<any>} config
   * @param {CoreGraphAuthType} auth
   * @memberof CoreGraphBase
   */
  protected init(config: HashMap<any>, auth: CoreGraphAuthType): void {
    try {
      const { GraphOnInstallToken } = this;

      /* istanbul ignore next */
      if (!('uri' in config)) {
        throw new ReferenceError('must pass configuration property [uri: string].');
      }

      this.uri = config.uri;
      this.authenticate = config.authenticate || false;

      if ('silent' in config) {
        this.silent = config.silent;
      }

      if ('cache' in config) {
        this.cache = config.cache;
      }
      else {
        /* istanbul ignore next */
        this.cache = new InMemoryCache();
      }

      // Init token
      this.token = auth.token || '';

      // Init client
      if (!this.client) {
        this.client = this.make();
      }

      /**
       * Watch once for Install Event.
       */
      /* istanbul ignore next */
      CoreAuth.emitter.once(AUTH_INSTALL_EVENT, GraphOnInstallToken);

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreGraphBase.init: ${err.message || err}`);
      }
    }
  }

  /**
   * Make
   * @protected
   * @return {*} {T}
   * @memberof CoreGraphBase
   */
  /* istanbul ignore next */
  protected make(): T {
    console.warn('DEVELOPER WARNING:: CoreGraphBase.make: must extend in sub-class.');
    return null!;
  }
}