import gql from 'graphql-tag';

import { UserWhiteLabelFrag, UserAccountFrag } from './fragments';

export const QUERY_USER_GET = gql`
  query GetCurrentUser {
    company {
      id
      ...UserWhiteLabelFields
      ...UserAccountFields
    }
  }
  ${UserWhiteLabelFrag}
  ${UserAccountFrag}
`;


export const QUERY_USER_DNAME_GET = gql`
  query GetCurrentUserDnameName($company: CompanyFilterInput) {
   company(filter: $company) {
      id
      dName
    }
  }
`;

export const QUERY_USER_SHORTNAME_GET = gql`
  query GetCurrentUserShortName($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      shortname
    }
  }
`;