/* istanbul ignore file */
import json from './mocks/notifications.json';
import { CoreGraphRest, CoreGraphRestProvider } from '../../shared/modules/graph/';

const uri: string = (window as any).location.origin;
const api = `${uri}/`;
const endpoints = { api };
const authenticate: boolean = false;
const NotificationsMockGraph = CoreGraphRest({ uri, endpoints, authenticate });
const NotificationsClientProvider = CoreGraphRestProvider(NotificationsMockGraph);

NotificationsMockGraph.client.watchQuery = function (...args: any[]): any {
  function subscribe(callback: any): any {
    const data: any = { notifications: { count: 1, results: json } };
    callback({ loading: false, data });
    return null;
  }
  return { subscribe };
}

export default NotificationsClientProvider;
