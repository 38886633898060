import React, { PureComponent, MouseEvent } from 'react';

import CoreInteropContext from '../../Interop/context';
import { CoreHeaderToggleMask } from '../../Interop/enums/';

/**
 * Mask Component
 * @export
 * @class CoreMaskComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreMaskComponent extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreMaskComponent
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * Render
   * @returns
   * @memberof CoreMaskComponent
   */
  public render() {
    const { OnTapAction } = this;
    return <div className="mask" onClick={OnTapAction} />;
  }

  /**
   * On Tap Actions
   * @private
   * @memberof CoreMaskComponent
   */
  private OnTapAction = (evt: MouseEvent<HTMLDivElement>) => {
    const { context } = this;
    context.header = CoreHeaderToggleMask.OFF;
    context.emit();
  };
}
