
import CoreGraphQueryConsumer from './QueryConsumer';
import CoreGraphMutationConsumer from './MutationConsumer';
import CoreGraphSocketConsumer from './SocketConsumer';

/**
 * Core Graph Query
 * @export
 * @return {*} {CoreGraphQueryConsumer()}
 * @notes Generic Query Component
 * - allows application of prop[query: DocumentNode] 
 */
export const CoreGraphQuery = CoreGraphQueryConsumer(null);

/**
 * Core Graph Mutation
 * @export
 * @return {*} {CoreGraphMutationConsumer()}
 * @notes Generic Mutation Component
 * - allows application of prop[mutate: DocumentNode] 
 */
export const CoreGraphMutation = CoreGraphMutationConsumer(null);

/**
 * Core Graph Subscription
 * @export
 * @return {*} {CoreGraphMutationConsumer()}
 * @notes Generic Subscription Component
 * - allows application of prop[subscription: DocumentNode] 
 */
export const CoreGraphSubscription = CoreGraphSocketConsumer(null);

