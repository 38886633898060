import React from 'react';
import CoreFormMenuBase from './Base';

import { CoreFormMenuInstanceShape } from '../../../../interfaces/';
import { CoreFormOptionsMap, MenuSelectMultiLabel } from '../../../../factories/';
import { SELECT_DEFAULT_LABEL } from '../../../../constants/';
import { ArrayTrim } from '../../../../../../../utils/';

/**
 * Menu Value
 * @export
 * @class MenuValueComponent
 * @extends {CoreFormMenuBase}
 */
export default class MenuValueComponent extends CoreFormMenuBase<GenericPropType> {
  /**
   * Map
   * @protected
   * @type {HashMap<any>}
   * @memberof MenuValueComponent
   */
  protected map: HashMap<any> = {};

  /**
   * Creates an instance of MenuValueComponent.
   * @param {GenericPropType} props
   * @memberof MenuValueComponent
   */
  constructor(props: GenericPropType, context: CoreFormMenuInstanceShape) {
    super(props);
    this.map = CoreFormOptionsMap(context.options);
  }

  /**
   * LifeCycle Hook
   * @memberof MenuValueComponent
   */
  /* istanbul ignore next */
  public componentDidUpdate(): void {
    this.map = CoreFormOptionsMap(this.context.options);
  }

  /**
   * Render
   * @returns
   * @memberof MenuValueComponent
   */
  public render() {
    const { map, context, props: { placeholder } } = this;
    const { value: VALUE, single } = context;

    let display: string;

    if (Array.isArray(VALUE)) {
      if (single) {
        if (VALUE.length) {
          display = map[VALUE[0]];
          return <span data-value={VALUE[0]}>{display}</span>;
        }
      } else {
        const value = ArrayTrim(VALUE);
        if (value.length > 1) {
          return <span>{MenuSelectMultiLabel(value.length)}</span>;
        }
  
        if (value.length) {
          display = map[value[0]];
          return <span data-value={value[0]}>{display}</span>;
        }
      }
    }

    display = placeholder || SELECT_DEFAULT_LABEL;
    return <span>{display}</span>;
  }
}
