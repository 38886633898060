import CoreGraphProvider from './BaseProvider';

/**
 * Core Graph Socket Provider
 * @export
 * @param {(CoreGraphConnectionType | CoreGraphSocketProviderType)} config
 * @return {*} {CoreGraphProviderClassType}
 */
export default function CoreGraphSocketProvider(config: (CoreGraphConnectionType | CoreGraphSocketProviderType)): CoreGraphProviderClassType {
  return CoreGraphProvider(config, 'socket');
}
