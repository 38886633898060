import CoreUserProfile from './profile';
import CoreUserRoleService from './role';
import { CoreUserServiceShape, CoreUserInfoShape, CoreUserProfileServiceShape, CoreUserRoleServiceShape } from '../interfaces/';

/**
 * User Service
 * @export
 * @class CoreUserService
 * @implements {CoreUserServiceShape}
 */
export class CoreUserService implements CoreUserServiceShape {
  /**
   * Profile
   * @private
   * @type {CoreUserProfileServiceShape}
   * @memberof CoreUserService
   */
  private _profile: CoreUserProfileServiceShape = new CoreUserProfile();

  /**
   * Role
   * @private
   * @type {CoreUserRoleServiceShape}
   * @memberof CoreUserService
   */
  private _role: CoreUserRoleServiceShape = new CoreUserRoleService();

  /**
   * Install
   * @param {CoreUserInfoShape>} userinfo
   * @param {string} token
   * @memberof CoreUserService
   */
  public install(userinfo: CoreUserInfoShape, token: string): void {
    this.profile.install(userinfo);
    this.role.install(userinfo, token);
  }

  /**
   * ID - getter
   * @readonly
   * @memberof CoreUserService
   */
  /* istanbul ignore next */
  public get id() {
    return this.profile.userinfo.sub;
  }

  /**
   * Account - getter
   * @readonly
   * @memberof CoreUserService
   */
  /* istanbul ignore next */
  public get account() {
    return this.profile.account;
  }

  /**
   * Profile - getter
   * @readonly
   * @memberof CoreUserService
   */
  /* istanbul ignore next */
  public get profile() {
    return this._profile;
  }

  /**
   * Role - getter
   * @memberof CoreUserService
   */
  /* istanbul ignore next */
  public get role() {
    return this._role;
  }
}
