import React, { ComponentClass } from 'react'

import ControllerBase from '../ControllerBase';
import ControllerWrapper from '../ControllerWrapper';

import { CoreFormElementText, ControllerLabel, ControllerError } from '../../../components/';

/**
 * Input Base
 * @export
 * @class InputBase
 * @extends {ControllerBase}
 */
export default class InputBase extends ControllerBase {
  /**
   * Controlled Element
   * @static
   * @type {ComponentClass<any>}
   * @memberof InputBase
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementText;

  /**
   * Render
   * @returns
   * @memberof InputBase
   */
  public render() {
    const { ControlledElement, OnChangeOrInput, labels, elements, exceptions, controller, kbd, tooltip, description, datalist } = this;
    const { name, value } = controller;

    return (
      <ControllerWrapper controller={controller}>
        <ControllerLabel {...labels}>
          <ControlledElement name={name} value={value} onChange={OnChangeOrInput} {...elements} />
          <ControllerError {...exceptions} />
          {datalist}
          {kbd}{tooltip}{description}
        </ControllerLabel>
      </ControllerWrapper>
    );
  }
}
