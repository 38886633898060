import { merge } from 'lodash';

/**
 * Apply Chart Title
 * @export
 * @param {HashMap<any>} title
 * @param {string} text
 */
export function ApplyChartTitle(title: HashMap<any>, text: string): void {
  merge(title, { text });
}

/**
 * Apply Solid Gauge Tooltips
 * @export
 * @param {HashMap<any>} tooltip
 * @param {string} suffix
 */
export function ApplyChartTooltips(tooltip: HashMap<any>, suffix: string): void {
  merge(tooltip, { valueSuffix: ` ${suffix}` });
}
