import React, { Component, ReactNode, Children } from 'react';
import { cloneDeep } from 'lodash';

import CoreListContext from '../context';

import {
  CoreScrollTable,
  CoreTableCaption,
  CoreTableSearch,
  CoreTableConsumer,
  CoreTableHead,
  CoreTableColumn,
  CoreTableEmpty,
  CoreTableBody,
  CoreTableCell,
  CoreTableFooter,
  CoreTableActionCell
} from '../../tables/';
import { CoreFormGroupInline, CoreInputLabel } from '../../forms/';
import { CoreDialogInstance } from '../../../../Activity/';

import ListModal from './Modal';
import { CoreListTableCell } from '../components/';
import { CoreListServiceShape, CoreListTableProps, CoreListTableState } from '../interfaces/';
import { LIST_TABLE_PROPS, LIST_TABLE_STATE } from '../constants';

import { IsNumeric, Noop } from '../../../../utils/';

/**
 * List Table
 * @export
 * @class CoreListTableContainer
 * @extends {Component<CoreListTableProps, CoreListTableState>}
 */
export default class CoreListTableContainer extends Component<CoreListTableProps, CoreListTableState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreListContext>}
   * @memberof CoreListTableContainer
   */
  public static contextType: Readonly<typeof CoreListContext> = CoreListContext;

  /**
   * Default Props
   * @static
   * @type {CoreListTableProps}
   * @memberof CoreListTableContainer
   */
  public static defaultProps: CoreListTableProps = cloneDeep(LIST_TABLE_PROPS);

  /**
   * State
   * @type {CoreListTableState}
   * @memberof CoreListTableContainer
   */
  public readonly state: CoreListTableState = cloneDeep(LIST_TABLE_STATE);

  /**
   * Head
   * @protected
   * @type {ReactNode[]}
   * @memberof CoreListTableContainer
   */
  protected head: ReactNode = null;

  /**
   * Body
   * @protected
   * @type {ReactNode[]}
   * @memberof CoreListTableContainer
   */
  protected body: ReactNode = null;

  /**
   * Empty
   * @protected
   * @type {ReactNode[]}
   * @memberof CoreListTableContainer
   */
  protected empty: ReactNode = null;

  /**
   * Footer
   * @protected
   * @type {ReactNode[]}
   * @memberof CoreListTableContainer
   */
  protected footer: ReactNode = null;

  /**
   * On Add Promise
   * @protected
   * @type {Func<Promise<boolean>>}
   * @memberof CoreListTableContainer
   */
  protected OnAddPromise: Func<Promise<boolean>> = () => Promise.resolve(true);

  /**
   * On Remove Promise
   * @protected
   * @type {Func<Promise<boolean>>}
   * @memberof CoreListTableContainer
   */
  protected OnRemovePromise: Func<Promise<boolean>> = () => Promise.resolve(true);

  /**
   * Creates an instance of CoreListTableContainer.
   * @param {CoreListTableProps} props
   * @param {CoreListServiceShape} context
   * @memberof CoreListTableContainer
   */
  constructor(props: CoreListTableProps, context: CoreListServiceShape) {
    super(props);

    Children.forEach((props as any).children, (child: any) => {
      if (child.type.displayName === 'CoreTableHead') {
        this.head = child;
      } else if (child.type.displayName === 'CoreTableEmpty') {
        this.empty = child;
      } else if (child.type.displayName === 'CoreTableFooter') {
        this.footer = child;
      } else if (child.type.displayName === 'CoreTableBody') {
        this.body = child;
      }
    });

    if (props.onAddPromise) {
      this.OnAddPromise = props.onAddPromise;
    }
    if (props.onRemovePromise) {
      this.OnRemovePromise = props.onRemovePromise;
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreListTableContainer
   */
  public render() {
    const {
      context,
      head,
      body,
      empty,
      footer,
      OnRemoveMarked,
      OnAddRow,
      OnEditAction,
      OnMarkAction,
      props: { title, rows, search, limit, addable, editable },
    } = this as any;

    const ACTIONS = [
      { label: 'Mark', name: 'mark', mini: true, action: OnMarkAction },
    ];

    if (editable) {
      ACTIONS.push({ label: 'Edit', name: 'edit', mini: true,  action: OnEditAction });
    }

    return (
      <CoreScrollTable source={context.table} rows={rows}>
        <CoreTableCaption>
          <CoreTableConsumer>
            {(table: any) => {
              const { collection } = table;

              let AddDisabled: boolean = false;

              if (IsNumeric(limit)) {
                if (collection.results.length > limit) {
                  const results = collection.results.slice(0, limit);
                  collection.update(results);
                }
                if (collection.results.length === limit) {
                  AddDisabled = true;
                }
              }

              const IsMarked: boolean = collection.marked.length > 0;
              const key: number = Date.now();

              return (
                <CoreFormGroupInline className="float-right">
                  {IsMarked ? (
                    <CoreInputLabel key={`list.remove.${key}`}>
                      <nux-button-primary onClick={OnRemoveMarked} size="medium">
                        Remove
                      </nux-button-primary>
                    </CoreInputLabel>
                  ) : null}
                  {addable ? (
                    <CoreInputLabel key={`list.add.${key}`}>
                      <nux-button-primary onClick={OnAddRow} disabled={AddDisabled} size="medium">
                        Add
                      </nux-button-primary>
                    </CoreInputLabel>
                  ) : null}
                </CoreFormGroupInline>
              );
            }}
          </CoreTableConsumer>
          {title ? (<header><h3>{title}</h3></header>) : null}
          {search ? <CoreTableSearch /> : null}
        </CoreTableCaption>
        <CoreTableHead>
          {head ? (head.props.children) : <CoreTableColumn label="Items" />}
          <CoreTableColumn className="cell-menu" />
        </CoreTableHead>
        <CoreTableEmpty key={`list.empty.${Date.now()}`}>{empty ? empty.props.children : null}</CoreTableEmpty>
        <CoreTableBody>
          {body ? (body.props.children) : (
            <CoreTableCell>
              <CoreListTableCell />
            </CoreTableCell>
          )}
          <CoreTableActionCell actions={ACTIONS} />
        </CoreTableBody>
        <CoreTableFooter key={`list.footer.${Date.now()}`}>{footer ? footer.props.children : null}</CoreTableFooter>
      </CoreScrollTable>
    );
  }

  /**
   * Remove Marked
   * @protected
   * @memberof CoreListTableContainer
   */
  protected OnRemoveMarked = (): void => {
    const { context, OnRemovePromise } = this;
    const { table } = context;
    const { collection: { marked }} = table;
    context.filter(marked);
    this.setState({ current: null });
    OnRemovePromise().catch(Noop);
  };

  /**
   * On Add Row
   * @protected
   * @memberof CoreListTableContainer
   */
  /* istanbul ignore next */
  protected OnAddRow = (evt: Event): void => {
    const { OnAddPromise, clear } = this;
    clear();
    OnAddPromise().then((valid: boolean) => {
      if (valid) {
        const { context, props: { title } } = this;
        const { source, modal, prop } = context;
        const ndx = source.length;
        const dialog = new CoreDialogInstance(
          (
            <ListModal context={context} current={ndx}>
              {modal}
            </ListModal>
          )
        );
        dialog.title = title ? `Add to ${title}` : 'Add';
        dialog.name = `list_add_${prop}_${ndx}`;
        dialog.labels = { yes: 'commit' };
        this.setState({ current: ndx });
        dialog
          .promise()
          .then((values: HashMap<any>) => {
            this.context.update(ndx, values);
            this.setState({ current: null });
          })
          .catch(Noop);
        dialog.activate();
      }
    });
  };

  /**
   * On Edit Action
   * @protected
   * @memberof CoreListTableContainer
   */
  /* istanbul ignore next */
  protected OnEditAction = ({ ndx }: HashMap<any>): void => {
    const {
      clear,
      context,
      props: { title },
    } = this;

    clear();

    this.setState({ current: ndx });
    const dialog = new CoreDialogInstance(
      (
        <ListModal context={context} current={ndx}>
          {context.modal}
        </ListModal>
      )
    );
    dialog.title = `Edit ${title}`;
    dialog.name = `list_edit_${context.prop}_${ndx}`;
    dialog.labels = { yes: 'commit' };
    dialog
      .promise()
      .then((values: HashMap<any>) => {
        context.update(ndx, values);
        this.setState({ current: null });
      })
      .catch(Noop);
    dialog.activate();
  };

  /**
   * On Mark Action
   * @protected
   * @memberof CoreListTableContainer
   */
  protected OnMarkAction = ({ ndx }: HashMap<any>) => {
    this.setState({ current: null });
  };

  /**
   * Clear
   * @protected
   * @memberof CoreListTableContainer
   */
  /* istanbul ignore next */
  protected clear = (): void => {
    this.context.table.collection.marked = [];
  };
}
