import { PureComponent, Children, ReactChild, ReactFragment, ReactPortal } from 'react';

import { CoreCRUDRouteProps } from '../interfaces/';

/**
 * Core CRUD Router
 * @export
 * @class CoreCRUDRouter
 * @extends {PureComponent<CoreCRUDRouteProps, {}>}
 */
export default class CoreCRUDRouter extends PureComponent<CoreCRUDRouteProps, {}> {
  /**
   * LifeCycle Hook
   * @memberof CoreCRUDRouter
   */
  /* istanbul ignore next */
  public componentDidMount() {
    const { props: { children }} = this;
    try {
      if (Children.count(children) !== 2) {
        throw new ReferenceError(`CoreCRUDRouter requires two children; List then Detail.`);
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreCRUDRouter
   */
  /* istanbul ignore next */
  public render() {
    const { props: { list, children } } = this;
    const [ List, Detail ]: Array<ReactChild | ReactFragment | ReactPortal> = Children.toArray(children);
    if (list) {
      return List;
    }
    return Detail;
  }
}