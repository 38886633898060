import { isEqual } from 'lodash';
import { isChrome } from 'react-device-detect';

/**
 * Is Equal
 * @export
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const IsEqual = (a: any, b: any): boolean => {
  return isEqual(a, b);
};

/**
 * Is Empty
 * @export
 * @param {*} val
 * @returns {boolean}
 */
export const IsEmpty = (val: any): boolean => {
  if (IsString(val) || Array.isArray(val)) {
    return val.length === 0;
  }
  if (IsNull(val)) {
    return true;
  }
  if (IsHashMap(val)) {
    return Object.keys(val).length === 0;
  }
  if (!IsDefined(val)) {
    return true;
  }
  return false;
};

/**
 * Is Defined
 * @export
 * @param {*} val
 * @returns {boolean}
 */
export const IsDefined = (val: any): boolean => {
  return typeof val !== 'undefined';
};

/**
 * Is Null
 * @export
 * @param {*} val
 * @returns {boolean}
 */
export const IsNull = (val: any): boolean => {
  return val === null;
};

/**
 * Is Number
 * @export
 * @param {*} v
 * @returns {boolean}
 */
export const IsNumber = (v: any): boolean => {
  return typeof v === 'number';
};

/**
 * Is Numeric
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsNumeric = (value: any): boolean => {
  if (value === '' || IsNull(value) || IsBoolean(value)) {
    return false;
  }
  return IsNumber(value) || !isNaN(Number(value));
};

/**
 * Is Boolean
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsBoolean = (v: any): boolean => {
  return typeof v === 'boolean';
};

/**
 * Is String
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsString = (v: any): boolean => {
  return typeof v === 'string';
};

/**
 * Is HashMap
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsHashMap = (v: any): boolean => {
  return v !== null && typeof v === 'object' && !Array.isArray(v) && Array.isArray(Object.keys(v));
};

/**
 * Is Dictionary
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsDictionary = (v: any): boolean => {
  return Array.isArray(v) ? IsHashMap(v[0]) : false;
};


/**
 * Is Date
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsDate = (v: any): boolean => {
  return v instanceof Date;
};

/**
 * Is Function
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsFunction = (v: any): boolean => {
  return typeof v === 'function';
};

/**
 * Is Symbol
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsSymbol = (v: any): boolean => {
  return typeof v === 'symbol';
};

/**
 * Is HTML
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsHTML = (v: any): boolean => {
  return v.charAt(0) === '<' && v.slice(-1) === '>';
};

/**
 * Is External Url
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsExternalUrl = (v: any): boolean => {
  return /^https?:\/\//i.test(v);
};

/**
 * Is Component
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsComponent = (value: any): boolean => {
  return typeof value === 'object' && 'type' in value && 'props' in value;
};

/**
 * Is HexDec
 * @export
 * @param {*} value
 * @returns {boolean}
 */
export const IsHexDec = (value: any): boolean => {
  return /^#[0-9A-Fa-f]{6}$|^#[0-9A-Fa-f]{3}$/.test(value);
};

/**
 * Is HexDec
 * @export
 * @param {*} value
 * @returns {boolean}
 */
/* istanbul ignore next */
export const IsChromeBrowser = (): boolean => {
  return isChrome;
};