import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreDialogsService from './service';

import { CoreDialogInstance } from './factories/';
import { CoreDialogsContainer } from './containers/';
import { CoreDialogContentWrapper } from './components/';
import { DialogModuleState, CoreDialogShape, DialogLabelsShape } from './interfaces/';
import { DIALOG_MODULE_STATE, DIALOG_TYPE_ALERT, DIALOG_TYPE_CONFIRM, DIALOG_TYPE_PROMPT } from './constants';

/**
 * Dialogs Module
 * @export
 * @class CoreDialogsModule
 * @extends {Component<{}, DialogModuleState>}
 * `
    - HOC

    - Manual
    import { CoreDialogInstance } from 'core-ui';
    const dialog = new CoreDialogInstance(<YourComponent />);

    dialog
       .activate()
       .promise()
       .then((response) => {
         console.log('response', (response as any).value);
       })
       .catch((reason) => {
         console.log('reason', reason);
       });
    `
 */
export default class CoreDialogsModule extends Component<GenericPropType, DialogModuleState> {
  /**
   * State
   * @type {DialogModuleState}
   * @memberof CoreDialogsModule
   */
  public readonly state: DialogModuleState = cloneDeep(DIALOG_MODULE_STATE);

  /**
   * Creates an instance of CoreDialogsContainer.
   * @param {GenericPropType} props
   * @memberof CoreDialogsContainer
   */
  constructor(props: GenericPropType) {
    super(props);

    // these must be in constructor
    /**
     * Window Alert
     * @override
     * @param {string} message
     * @memberof Window
     * @returns {Promise<any>}
     */
    /* istanbul ignore next */
    (window as any).alert = (message: string, title: string = 'Alert!', labels: DialogLabelsShape = { yes: 'ok' }): Promise<any> => {
      const dialog = new CoreDialogInstance((<CoreDialogContentWrapper message={message} />), DIALOG_TYPE_ALERT);
      dialog.title = title;
      dialog.labels = labels;
      return dialog.activate().promise();
    };

    /**
     * Window Confirm
     * @override
     * @param {string} message
     * @memberof Window
     * @returns {Promise<any>}
     */
    /* istanbul ignore next */
    (window as any).confirm = (message: string, title: string = 'Confirm!', labels: DialogLabelsShape = { yes: 'confirm', no: 'cancel' }): Promise<any> => {
      const dialog = new CoreDialogInstance((<CoreDialogContentWrapper message={message} />), DIALOG_TYPE_CONFIRM);
      dialog.title = title;
      dialog.labels = labels;
      return dialog.activate().promise();
    };

    /**
     * Window Prompt
     * @override
     * @param {string} message
     * @param {string} [value='']
     * @memberof Window
     * @returns {Promise<any>}
     */
    /* istanbul ignore next */
    (window as any).prompt = (message: string, value: string = '', title: string = 'Prompt!', labels: DialogLabelsShape = { yes: 'send', no: 'cancel' }): Promise<any> => {
      const dialog = new CoreDialogInstance((<CoreDialogContentWrapper message={message} value={value} />), DIALOG_TYPE_PROMPT);
      dialog.title = title;
      dialog.labels = labels;
      return dialog.activate().promise();
    };
  }

  /**
   * LifeCycle Hook
   * @memberof CoreDialogsModule
   */
  public componentDidMount() {
    const { DialogServiceSubscriber } = this;
    CoreDialogsService.subscribe(DialogServiceSubscriber);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreDialogsModule
   */
  public componentWillUnmount() {
    CoreDialogsService.unsubscribe();
  }

  /**
   * Render
   * @returns
   * @memberof CoreDialogsModule
   */
  public render() {
    const { open, current } = this.state;
    return (
      <dialog open={open}>
        <CoreDialogsContainer dialog={current} />
        <div className="mask" />
      </dialog>
    );
  }

  /**
   * Dialog Service Subscriber
   * @protected
   * @param {boolean} open
   * @param {CoreDialogShape} current
   * @memberof CoreDialogsModule
   */
  /* istanbul ignore next */
  protected DialogServiceSubscriber = (open: boolean, current: CoreDialogShape) => {
    this.setState({ open, current });
  };
}
