import React, { PureComponent } from 'react';

import CoreConfigContext from '../../Config/context';
import { CoreNavigate } from '../../Router/';
import { CoreAnchor, CoreAppName, CoreLogo } from '../../shared/';

/**
 * Hgroup Container
 * @export
 * @class CoreHgroupContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreHgroupContainer extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreHgroupContainer
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Render
   * @returns
   * @memberof CoreHgroupContainer
   */
  public render() {
    const {
      context: {
        app: { default_route },
      },
    } = this;

    const route = default_route.charAt(0) === '/' ? default_route : `/${default_route}`;

    return (
      <hgroup className="header">
        <h1>
          <CoreAnchor
            onClick={() => {
              /* istanbul ignore next */
              CoreNavigate(route);
            }}
          >
            <CoreLogo />
          </CoreAnchor>
        </h1>
        <CoreAppName />
      </hgroup>
    );
  }
}
