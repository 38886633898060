import { CoreAuthCredentialsService } from '@neustar/core-ui';

/**
 * Auth Plugin [required]
 * @export
 * @returns {Promise<CoreAuthCredentialsType>}
 */
export default async function AuthPlugin(): Promise<CoreAuthCredentialsType> {
  const { clientID, domain, audience } = (window as any).__COREUIENV__;

  try {
    CoreAuthCredentialsService.CLIENTID = clientID;
    CoreAuthCredentialsService.DOMAIN = domain;
    CoreAuthCredentialsService.AUDIENCE = audience;
    CoreAuthCredentialsService.MFA = false;
    return { clientID, domain, audience };
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err);
    }
    return err;
  }
};


