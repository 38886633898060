import React, { Component, Fragment } from 'react';

import CoreInteropContext from '../../Interop/context';
import { ROUTE_CHANGE_EVENT } from '../../Interop';
import { CoreNavigate } from '../../Router';

/**
 * Link Directive Component
 * @export
 * @class CoreLinkDirective
 * @extends {Component<CoreRouteProps, {}>}
 */
export default class CoreLinkDirectiveComponent extends Component<CoreRouteProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreLinkDirectiveComponent
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * Routes Subscriber
   * @private
   * @type {Func<void>}
   * @memberof CoreLinkDirectiveComponent
   */
  private subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreLinkDirectiveComponent
   */
  public componentDidMount() {
    const { context } = this;
    this.subscriber = context.subscribe(this.RouteRequestListener, ROUTE_CHANGE_EVENT);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreLinkDirectiveComponent
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(this.subscriber, ROUTE_CHANGE_EVENT);
  }

  /**
   * Render
   * @returns
   * @memberof CoreLinkDirective
   */
  public render() {
    return <Fragment />;
  }

  /**
   * Route Request Listener
   * @protected
   * @param {string} link
   * @memberof CoreLinkDirectiveComponent
   */
  protected RouteRequestListener = (link: string) => {
    /* istanbul ignore next */
    CoreNavigate(link);
  };
}

