import { ApolloClient, ApolloClientOptions, InMemoryCache, ApolloLink, HttpLink, DefaultOptions, ErrorPolicy } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'unfetch';

import { CoreAuth } from '../../../../Auth/';

import CoreGraphBase from './base';

import { CoreGraphConnectionShape } from '../interfaces/';
import { InitialCap } from '../../../../utils/';

/**
 * Core Graph Link
 * @export
 * @param {CoreGraphLinkProviderType} { uri, silent, authenticate = true, cache = new InMemoryCache() }
 * @param {CoreGraphAuthType} [auth=CoreAuth]
 * @return {*} {CoreGraphConnectionShape}
 */
export default function CoreGraphLink({ uri, silent, authenticate = true, cache = new InMemoryCache() }: CoreGraphLinkProviderType, auth: CoreGraphAuthType = CoreAuth): CoreGraphConnectionShape {
  /**
   * Type
   * @type {CoreGraphProviderType}
   */
  const type: CoreGraphProviderType = 'link';

  /**
   * Core Graph Link
   * @export
   * @class CoreGraphLink
   * @extends {CoreGraphBase<CoreGraphClientType, CoreGraphLinkProviderType>}
   */
  class CoreGraphLinkInstance extends CoreGraphBase<CoreGraphClientType, CoreGraphLinkProviderType> {
    /**
     * Make
     * @override
     * @protected
     * @return {*} 
     * @memberof CoreGraphLink
     */
    protected make() {
      const { cache, silent, uri } = this;
      let { token } = this;

      const HTTPOptions: any = silent ? { uri } : { uri, fetch };

      /* istanbul ignore next */
      const AuthLink: ApolloLink = setContext((_, { headers }) => {
        const config = { headers: { ...headers } };
        if (silent && authenticate) {
          if (!token && CoreAuth.token) {
            this.token = CoreAuth.token;
            token = this.token;
          }
          const authorization = `Bearer ${token}`;
          Object.assign(config, { headers: { authorization } });
        }
        return config;
      });

      /* istanbul ignore next */
      const RefreshLink: any = onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          for (let err of graphQLErrors) {
            if (err.path && Array.isArray(err.path)) {
              if (err.extensions && err.extensions.code) {
                switch (err.extensions.code) {
                  case 'DOWNSTREAM_SERVICE_ERROR':
                    continue;
                }
              }
              (window as any).alert(err.message, InitialCap(`${err.path[0]} - error`));
            }
            if (err.extensions && err.extensions.code) {
              switch (err.extensions.code) {
                case 'UNAUTHENTICATED':
                  if (authenticate) {
                    if (!token && CoreAuth.token) {
                      this.token = CoreAuth.token;
                      token = this.token;
                    }
                    const headers = operation.getContext().headers;
                    operation.setContext({
                      headers: {
                        ...headers,
                        authorization: `Bearer ${token}`
                      },
                    });
                    return forward(operation);
                  }
                  break;
              }
            }
          }
        }
      });

      const HTTPLink: HttpLink = new HttpLink(HTTPOptions);

      let errorPolicy: ErrorPolicy = 'ignore';
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        errorPolicy = 'all';
      }

      const defaultOptions: DefaultOptions = { query: { errorPolicy }, watchQuery: { fetchPolicy: 'cache-and-network', errorPolicy }, mutate: { fetchPolicy: 'network-only' } };
      const link: ApolloLink = ApolloLink.from([RefreshLink, AuthLink, HTTPLink]);
      const options: ApolloClientOptions<any> = { link, cache, defaultOptions };
      return new ApolloClient<any>(options);
    }
  }

  const { client, open, close } = new CoreGraphLinkInstance({ uri, silent, authenticate, cache }, auth);
  return { client, open, close, type, uri, silent, cache, authenticate };
}
