import { PathToDots, IsDefined, IsEqual, IsPluralOf } from '../../utils/';

/**
 * Are Paths Matched
 * @export
 * @param {string} path
 * @param {string} name
 * @returns {boolean}
 */
export function ArePathsMatched(path: string, name: string): boolean {
  const a: string[] = PathToDots(path).split('.');
  const b: string[] = name.split('.');

  if (IsEqual(a, b)) {
    return true;
  }

  let aseg: string;
  let bseg: string;

  while(a.length) {
    aseg = a.shift();
    bseg = b.shift();
    if (IsDefined(aseg) && IsDefined(bseg) && !IsEqual(aseg, bseg)) {
      if (IsPluralOf(bseg, aseg) && (a.length - b.length === 1)) {
        return true;
      }
      return false;
    }
  }
  /* istanbul ignore next */
  return false;
}
