import { CoreConfig, CoreGraphSocketConsumer } from '@neustar/core-ui';

import { SUBSCRIPTION_GEO_TRAFFIC } from './models';

/**
 * Get Geo Traffic Subscription
 */
export const GetGeoTrafficSubscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_GEO_TRAFFIC,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
  };
});
