import React, { PureComponent, Fragment } from 'react';

import { CoreNotificationsCountBadge, CoreNotificationsList } from '../components/';

/**
 * Notifications Menu Container
 * @export
 * @class CoreNotificationsMenuContainer
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreNotificationsMenuContainer extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreNotificationsMenuContainer
   */
  public render() {
    return (
      <Fragment>
        <CoreNotificationsCountBadge />
        <CoreNotificationsList />
      </Fragment>
    );
  }
}
