import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions Create
 * @export
 * @class CorePermissionsCanCreate
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsCanCreate extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsCanCreate
   */
  public render() {
    const {
      service: { CanCreate },
      props: { children },
    } = this;
    return CanCreate? <Fragment>{children}</Fragment> : null;
  }
}
