import React, { PureComponent, Children } from 'react';
import { MergeClassNames } from '../../../../../utils/';

/**
 * Form Group Row
 * @export
 * @class CoreFormGroupRowContainer
 * @extends {PureComponent<GenericPropType, {}>}
 * @notes
 * 1. A block level container that arranges direct child <CoreFormGroup/> as Row Blocks.
 */
export default class CoreFormGroupRowContainer extends PureComponent<GenericPropType, {}> {
  /**
   * LifeCycle Hook
   * @memberof CoreFormGroupRowContainer
   */
  public componentDidMount() {
    const { props: { children }} = this;
    try {
      const kids = Children.toArray(children);
      /* istanbul ignore next */
      if (kids.length > 1) {
        throw new ReferenceError(`FormGroupRow may only have one child.`);
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreFormGroupRowContainer
   */
  public render() {
    const { className, children } = this.props;
    const clz = MergeClassNames(className, {'form-group-row': true });
    return (
      <div className={clz}>
        {children}
      </div>
    );
  }
}
