import React from 'react';

import CoreBootBase from './Base';

import { CoreBootEventEnum } from '../../enums/';
import { CoreBusy } from '../../../Activity/';
import { IsAuthenticatedAsync } from '../../../Auth/';
import { CoreNavigate } from '../../../Router/';
import { CoreTitle } from '../../../shared/';
import { WaitForDelay } from '../../../utils/';

/**
 * Auth Container
 * @export
 * @class CoreBootAuthContainer
 * @extends {PureComponent<CoreRouteProps, {}>}
 */
export default class CoreBootAuthContainer extends CoreBootBase {
  /**
   * LifeCycle Hook
   * @memberof CoreBootAuthContainer
   */
  public componentDidMount() {
    const {
      context
    } = this;

    /* istanbul ignore next */
    IsAuthenticatedAsync()
      .then(() => {
        context.emit(CoreBootEventEnum.AUTHENTICATED);
      })
      .catch(() => {
        WaitForDelay().then(() => {
          return CoreNavigate('/auth/login').then(() => {
            CoreBusy.off();
          });
        });
      });
  }

  /**
   * LifeCycle Hook
   * @memberof CoreBootAuthContainer
   */
  public componentWillUnmount() {
    /* istanbul ignore next */
    CoreBusy.off();
  }

  /**
   * Render
   * @returns
   * @memberof CoreBootAuthContainer
   */
  public render() {
    if (!CoreBusy.active) {
      CoreBusy.keep().on();
    }
    return <CoreTitle>Authentication</CoreTitle>;
  }
}
