/**
 * Match Perms Factory
 * @export
 * @param {Array<string>} map
 * @param {Array<string>} compare
 * @returns {boolean}
 */
export function MatchPermsFactory (map: Array<string>, compare: Array<string>): boolean {
  let i: number = map.length;
  let m: number = 0;
  let p: string;
  while(i--) {
    p = map[i];
    if (!!~compare.indexOf(p)) {
      m++;
    }
  }
  return m === map.length;
}
