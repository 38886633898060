import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import { CoreBadgeProps } from '../interfaces/';
import { BADGE_PROPS } from '../constants';
import { MergeClassNames } from '../../utils/';

/**
 *  Badge Component
 * @export
 * @class CoreBadgeComponent
 * @extends {PureComponent<CoreBadgeProps, {}>}
 */
export default class CoreBadgeComponent extends PureComponent<CoreBadgeProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreBadgeProps}
   * @memberof CoreBadgeComponent
   */
  public static defaultProps: CoreBadgeProps = cloneDeep(BADGE_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreBadgeComponent
   */
  public render() {
    const { children, type, outline, disabled } = this.props;

    const clz = MergeClassNames('', {
      badge: true,
      'badge-primary': type === 'primary',
      'badge-success': type === 'success',
      'badge-danger': type === 'danger',
      'badge-warning': type === 'warning',
      'badge-info': type === 'info',
      'badge-trivial': type === 'trivial',
      'badge-default': type === 'default',
      'badge-outline': outline,
      'badge-disabled': disabled
    });

    return <span className={clz}>{children}</span>;
  }
}
