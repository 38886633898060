import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { CoreCounterBadgeProps } from '../interfaces/';
import { COUNTER_BADGE_PROPS } from '../constants';

/**
 * Counter Badge Component
 * @export
 * @class CoreCounterBadgeComponent
 * @extends {PureComponent<CoreCounterBadgeProps, {}>}
 */
export default class CoreCounterBadgeComponent extends PureComponent<CoreCounterBadgeProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreCounterBadgeProps}
   * @memberof CoreCounterBadgeComponent
   */
  public static defaultProps: CoreCounterBadgeProps = cloneDeep(COUNTER_BADGE_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreCounterBadgeComponent
   */
  public render() {
    const { count } = this.props;
    return count ? <span className="counter-badge">{count}</span> : null;
  }
}
