import React, { PureComponent, createContext } from 'react';

import CoreFormContext from '../../contexts/Form';

import { CoreFormValueType } from '../../types';
import { CoreFormEventsEnum as E } from '../../enums/';
import { CoreFormConsumerProps } from '../../interfaces/';
import { CONTROLLER_EVENT_DELAY } from '../../constants/';
import { GetDeepHashMap, WaitForDelay, IsEqual } from '../../../../../utils/';

/**
 * Watcher Consumer
 * @export
 * @class WatcherConsumer
 * @extends {PureComponent<CoreFormConsumerProps, {}>}
 */
export default class WatcherConsumer extends PureComponent<CoreFormConsumerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof WatcherConsumer
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof WatcherConsumer
   */
  protected listener: AbortController;

  /**
   * Last Value
   * @protected
   * @type {CoreFormValueType}
   * @memberof WatcherConsumer
   */
  protected value: CoreFormValueType = undefined!;

  /**
   * LifeCycle Hook
   * @memberof WatcherConsumer
   */
  public componentDidMount() {
    const { FormEventListener, context } = this;
    if (!this.listener) {
      this.listener = new AbortController();
    }
    context.emitter.on(E.FORM_EVENT_UPDATE, FormEventListener);
    this.Refresh();
  }

  /**
   * LifeCycle Hook
   * @memberof WatcherConsumer
   */
  public componentWillUnmount() {
    const { FormEventListener, context, listener } = this;
    if (listener) {
      listener.abort();
    }
    context.emitter.off(E.FORM_EVENT_UPDATE, FormEventListener);
  }

  /**
   * Render
   * @returns
   * @memberof WatcherConsumer
   */
  public render() {
    const { ConsumerContext, props: { children } } = this;
    return <ConsumerContext.Consumer children={children as any} />;
  }

  /**
   * Form Event Listener
   * @memberof WatcherConsumer
   */
  /* istanbul ignore next */
  public FormEventListener = (name: string, value: CoreFormValueType, options: CoreFormOptionsType): void => {
    if (name === this.props.name) {
      if (IsEqual(value, this.value)) {
        return void 0;
      }

      this.value = value;

      if (this.listener) {
        this.listener.abort();
      }
      this.listener = new AbortController();
      this.Refresh();
    }
  };

  /**
   * Refresh
   * @protected
   * @memberof WatcherConsumer
   */
  /* istanbul ignore next */
  protected Refresh = (): void => {
    WaitForDelay(CONTROLLER_EVENT_DELAY).then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  }

  /**
   * ConsumerContext - getter
   * @readonly
   * @memberof WatcherConsumer
   */
  public get ConsumerContext() {
    const { Refresh, context, props: { name } } = this;
    let value = GetDeepHashMap(context.source, name);
    const consumer = { refresh: Refresh };
    return createContext({ form: context, values: { [name]: value, value }, consumer });
  }
}
