import CoreGraphProvider from './BaseProvider';

/**
 * Core Graph Rest Provider
 * @export
 * @param {(CoreGraphConnectionType | CoreGraphRestProviderType)} config
 * @return {*} {CoreGraphProviderClassType}
 */
export default function CoreGraphRestProvider(config: (CoreGraphConnectionType | CoreGraphRestProviderType)): CoreGraphProviderClassType {
  return CoreGraphProvider(config, 'rest');
}
