export const DIALOG_TYPE_ALERT = 'alert';
export const DIALOG_TYPE_CONFIRM = 'confirm';
export const DIALOG_TYPE_PROMPT = 'prompt';
export const DIALOG_TYPE_DEFAULT = 'default';

export const DIALOG_TYPES = [DIALOG_TYPE_ALERT, DIALOG_TYPE_CONFIRM, DIALOG_TYPE_PROMPT, DIALOG_TYPE_DEFAULT];

export const DIALOG_LABELS_MAP = {
  [DIALOG_TYPE_ALERT]: { yes: 'ok' },
  [DIALOG_TYPE_CONFIRM]: { yes: 'confirm', no: 'cancel' },
  [DIALOG_TYPE_PROMPT]: { yes: 'send', no: 'cancel' },
  [DIALOG_TYPE_DEFAULT]: { yes: 'complete', no: 'cancel' },
};

export const DIALOG_MODE_DEFAULT = 'default';
export const DIALOG_MODE_SUCCESS = 'success';
export const DIALOG_MODE_INFO = 'info';
export const DIALOG_MODE_WARNING = 'warning';
export const DIALOG_MODE_DANGER = 'danger';
export const DIALOG_MODE_ACCENT = 'accent';
export const DIALOG_MODE_TRIVIAL = 'trivial';

export const DIALOG_MODES = [DIALOG_MODE_DEFAULT, DIALOG_MODE_SUCCESS, DIALOG_MODE_INFO, DIALOG_MODE_WARNING, DIALOG_MODE_DANGER, DIALOG_MODE_ACCENT, DIALOG_MODE_TRIVIAL];

export const DIALOG_EVENT_CHANGE = 'DIALOG_EVENT_CHANGE';

export const DIALOG_MODULE_STATE = {
  open: false,
  current: null,
};

export const DIALOGS_PROPS = { dialog: undefined };

export const DIALOG_CONTENT_PROPS = {
  message: '',
  value: null,
  dialog: undefined,
};
