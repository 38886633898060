import { cloneDeep } from 'lodash';

import { CoreTableCollectionServiceShape } from '../interfaces/';

import { IsDefined, ArrayUnique, DictionaryIndexOf } from '../../../../utils/';

/**
 * Table Collection Service
 * @export
 * @class CoreTableCollectionService
 */
export default class CoreTableCollectionService implements CoreTableCollectionServiceShape {
  /**
   * Results
   * @private
   * @type {Array<HashMap<any>>}
   * @memberof CoreTableCollectionService
   */
  private _results: Array<HashMap<any>> = [];

  /**
   * Results
   * @private
   * @type {Array<HashMap<any>>}
   * @memberof CoreTableCollectionService
   */
  private _orig: Array<HashMap<any>> = [];

  /**
   * Highlighted
   * @private
   * @type {string}
   * @memberof CoreTableCollectionService
   */
  private _highlighted: string = null;

  /**
   * Marked
   * @private
   * @type {Array<HashMap<any>>}
   * @memberof CoreTableCollectionService
   */
  private _marked: Array<HashMap<any>> = [];

  /**
   * Update
   * @param {Array<HashMap<any>>} results
   * @memberof CoreTableCollectionService
   */
  public update(results: Array<HashMap<any>>): void {
    if(IsDefined(results)) {
      this._results = results.slice(0);
      this._orig = results.slice(0);
    }
  }

  /**
   * Mark
   * @param {HashMap<any>} row
   * @memberof CoreTableCollectionService
   */
  public mark(row: HashMap<any>): void {
    const { marked, MarkedIndexOf } = this;
    if (!~MarkedIndexOf(row)) {
      const data = cloneDeep(row);
      delete data.ndx;
      delete data._key;
      delete data._ref;
      marked.push(data);
      this.marked = marked;
    }
  }

  /**
   * Un-Mark
   * @param {HashMap<any>} row
   * @memberof CoreTableCollectionService
   */
  public unmark(row: HashMap<any>): void {
    const { marked, MarkedIndexOf } = this;
    let index: number;
    if (!!~(index = MarkedIndexOf(row))) {
      marked.splice(index, 1);
      this.marked = marked;
    }
  }

  /**
   * Marked Index Of
   * @param {HashMap<any>} row
   * @returns {number}
   * @memberof CoreTableCollectionService
   */
  public MarkedIndexOf = (row: HashMap<any>): number => {
    const data = cloneDeep(row);
    delete data.ndx;
    delete data._key;
    delete data._ref;
    const marked = this.marked;
    return DictionaryIndexOf(marked, data);
  }

  /**
   * Show - getter
   * @readonly
   * @type {boolean}
   * @memberof CoreTableCollectionService
   */
  public get show(): boolean {
    return this.results.length > 0;
  }

  /**
   * Results - getter
   * @readonly
   * @memberof CoreTableCollectionService
   */
  public get results() {
    return this._results;
  }

  /**
   * Orig - getter
   * @readonly
   * @memberof CoreTableCollectionService
   */
  /* istanbul ignore next */
  public get orig() {
    return this._orig;
  }

  /**
   * Marked - getter
   * @readonly
   * @memberof CoreTableCollectionService
   */
  /* istanbul ignore next */
  public get marked() {
    return this._marked;
  }

  /**
   * Marked - setter
   * @memberof CoreTableCollectionService
   */
  public set marked(marked: Array<HashMap<any>>) {
    this._marked = ArrayUnique(marked);
  }

  /**
   * Highlighted - getter
   * @readonly
   * @memberof CoreTableCollectionService
   */
  public get highlighted() {
    return this._highlighted;
  }

  /**
   * Highlighted - setter
   * @memberof CoreTableCollectionService
   */
  public set highlighted(highlighted: string) {
    this._highlighted = highlighted;
  }
}
