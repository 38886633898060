import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import { MarqueeProps, MarqueeState } from './interfaces/';
import { CoreMarqueeItemComponent } from './components/';
import { MARQUEE_PROPS } from './constants';

import { IsEqual } from '../../../utils/';

/**
 * Marquee List Container
 * @export
 * @class CoreMarqueeModule
 * @extends {Component<MarqueeProps, MarqueeState>}
 */
export default class CoreMarqueeModule extends Component<MarqueeProps, MarqueeState> {
  /**
   * Default Props
   * @static
   * @type {MarqueeProps}
   * @memberof CoreMarqueeModule
   */
  public static defaultProps: MarqueeProps = cloneDeep(MARQUEE_PROPS);

  /**
   * Default State
   * @type {MarqueeState}
   * @memberof CoreMarqueeModule
   */
  public readonly state: MarqueeState = cloneDeep(MARQUEE_PROPS);

  /**
   * Get Derived State From Props
   * @static
   * @param {MarqueeProps} props
   * @param {MarqueeState} state
   * @returns
   * @memberof CoreMarqueeModule
   */
  public static getDerivedStateFromProps(props: MarqueeProps, state: MarqueeState) {
    if (!IsEqual(props.items, state.items)) {
      Object.assign(state.items, props.items);
    }
    return state;
  }

  /**
   * Interval
   * @private
   * @type {number}
   * @memberof CoreMarqueeModule
   */
  protected I: number;

  /**
   * LifeCycle Hook
   * @memberof CoreMarqueeModule
   */
  public componentDidMount() {
    const { state: { items }} = this;
    /* istanbul ignore next */
    if (items.length) {
      this.I = window.setInterval(() => {
        let tick: number = this.state.tick + 1;
        if (tick === this.state.items.length) {
          tick = 0;
        }
        this.setState({ tick });
      }, 5000);
    }
  }


  /**
   * LifeCycle Hook
   * @memberof CoreMarqueeModule
   */
  public componentWillUnmount() {
    /* istanbul ignore next */
    if (this.I) {
      (window as any).clearInterval(this.I);
    }
  }

  /**
   * Render
   * @returns
   * @memberof CoreMarqueeModule
   */
  public render() {
    const {
      active,
      state: { items },
    } = this;
    return (
      <ul className="marquee">
        {items.map((item: any, ndx: number) => {
          return <CoreMarqueeItemComponent key={`marquee.item.${ndx}`} active={active(ndx)} item={item} />;
        })}
      </ul>
    );
  }

  /**
   * Active
   * @private
   * @param {number} ndx
   * @returns {boolean}
   * @memberof CoreMarqueeModule
   */
  private active = (ndx: number): boolean => {
    return this.state.tick === ndx;
  };
}
