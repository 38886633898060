import React, { PureComponent, MouseEvent } from 'react';

/**
 * Anchor Component
 * @export
 * @class CoreAnchorComponent
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class CoreAnchorComponent extends PureComponent<GenericPropType, {}> {
  /**
   * Default Props
   * @static
   * @type {GenericPropType}
   * @memberof CoreAnchorComponent
   */
  /* eslint no-script-url: 0 */
  public static defaultProps: GenericPropType = {
    href: null,
  };

  /**
   * Render
   * @returns
   * @memberof CoreAnchorComponent
   */
  public render() {
    const { props, OnClick } = this;
    const { children, onClick, ...rest } = props;

    return (
      <a onClick={OnClick} {...rest}>
        {children}
      </a>
    );
  }

  /**
   * On Click
   * @private
   * @param {any} evt
   * @memberof CoreAnchorComponent
   */
  private OnClick = (evt: MouseEvent<HTMLAnchorElement>): void => {
    const { rel, href, onClick } = this.props;
    if (href === null) {
      evt.preventDefault();
    }

    if (onClick) {
      onClick(evt);
    }

    if (rel === 'external' && href !== null) {
      evt.preventDefault();
      window.open(href, rel);
    }
  };
}
