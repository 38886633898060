
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { INPUT_TYPE_TEXT, FORM_ELEMENT_PROPS } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Text Input Element
 * @export
 * @class CoreFormElementText
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementText extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementText
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_TEXT
  });
}
