import { cloneDeep } from 'lodash';

import { CoreAuth } from '../../../../Auth/';
import { CoreConfig, CoreConfigPermissionSimpleMask as MASK } from '../../../../Config/';

import { CorePermissionSimpleServiceShape } from '../interfaces/';

import { ObjectKeys } from '../../../../utils/';

/**
 * Permission Simple Service
 * @export
 * @class CorePermissionSimpleService
 * @implements {CorePermissionSimpleServiceShape}
 */
export default class CorePermissionSimpleService implements CorePermissionSimpleServiceShape {
  /**
   * Permissions Map
   * @private
   * @type {HashMap<CoreConfigPermissionSimpleMask>}
   * @memberof CorePermissionSimpleService
   */
  private _map: HashMap<MASK> = cloneDeep(CoreConfig.permissions.simple_map);

  /**
   * Type
   * @private
   * @type {string}
   * @memberof CorePermissionSimpleService
   */
  private _type: string;

  /**
   * Init
   * @param {string[]} [permissions]
   * @memberof CorePermissionSimpleService
   */
  /* istanbul ignore next */
  public init(permissions?: string[]): void {
    try {
      const { map, type } = this;

      if (process.env.NODE_ENV !== 'production') {
        if (!type) {
          throw new ReferenceError(`missing prop [type].`);
        }
        if (!(type in map)) {
          throw new ReferenceError(`prop 'type' must be of [${ObjectKeys(CoreConfig.permissions.simple_map).join(', ')}].`);
        }
      }

      if (!permissions) {
        permissions = CoreAuth.permissions;
      }

      const ALLOWED: Array<string> = CoreConfig.permissions.simple.slice(0);

      let permission: string;
      let i: number = permissions.length;
      while (i--) {
        permission = permissions[i];
        if (permission in map) {
          if (!!~ALLOWED.indexOf(permission)) {
            this.map[permission] = MASK.ALLOWED;
          }
        }
      }
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
      }
    }
  }

  /**
   * Map - getter
   * @readonly
   * @memberof CorePermissionSimpleService
   */
  public get map() {
    return this._map;
  }

  /**
   * Type - getter
   * @readonly
   * @memberof CorePermissionSimpleService
   */
  public get type() {
    return this._type;
  }

  /**
   * Type - setter
   * @memberof CorePermissionSimpleService
   */
  public set type(type: string) {
    this._type = type;
  }

  /**
   * Mask - getter
   * @readonly
   * @private
   * @memberof CorePermissionSimpleService
   */
  private get mask() {
    return this.map[this.type];
  }

  /**
   * Disallowed - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionSimpleService
   */
  public get Disallowed(): boolean {
    const { mask } = this;
    return mask === MASK.DISALLOWED;
  }

  /**
   * Allowed - getter
   * @readonly
   * @type {boolean}
   * @memberof CorePermissionSimpleService
   */
  public get Allowed(): boolean {
    const { mask } = this;
    return mask === MASK.ALLOWED;
  }
}
