import React, { PureComponent, StrictMode } from 'react';

import { CoreActivityModule } from '../../Activity/';
import { CoreAuthWrapper } from '../../Auth/';
import { CoreConfigWrapper } from '../../Config/';

import { BootMainPage } from './pages/';
import CoreBootMainWrapper from './Wrapper';

/**
 * Boot Main Module
 * @export
 * @class CoreBootMainModule
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreBootMainModule extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreBootMainModule
   */
  public render() {
    // console.debug('** MAIN **');

    return (
      <StrictMode>
        <CoreBootMainWrapper>
          <CoreAuthWrapper>
            <CoreConfigWrapper>
              <BootMainPage />
              <CoreActivityModule />
            </CoreConfigWrapper>
          </CoreAuthWrapper>
        </CoreBootMainWrapper>
      </StrictMode>
    );
  }
}
