import React, { FormEvent } from 'react';

import CoreFormMenuBase from './Base';

import { CoreFormOptionShape } from '../../../../interfaces/';
import { StringToKebabCase } from '../../../../../../../utils/';

/**
 * Menu Selected
 * @export
 * @class MenuSelectedComponent
 * @extends {CoreFormMenuBase}
 */
export default class MenuSelectedComponent extends CoreFormMenuBase {
  /**
   * Render
   * @returns
   * @memberof MenuSelectedComponent
   */
  public render() {
    const { OnDeSelect, context: { single, value, options, disabled, ValueSelected }} = this;

    if (single || !value.length) {
      return null;
    }

    return (
      <dd className="selected">
        <ol>
          {options.map(({ label, value, title }: CoreFormOptionShape, ndx: number) => {
            const selected: boolean = ValueSelected(value);
            const kc: string = Array.isArray(value) ? StringToKebabCase(value.join('-')) : StringToKebabCase(String(value));
            if (selected) {
              return (
                <li key={`so.${ndx}`}>
                  <button type="button" disabled={disabled} data-value={kc} onClick={OnDeSelect(value)} title={`de-select ${title||value}`}>
                    <span>{label}</span>
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ol>
      </dd>
    );
  }

  /**
   * On De-Select
   * @protected
   * @param {string | number | boolean  | readonly string[]} candidate;
   * @memberof MenuSelectedComponent
   * @returns {Func<void, FormEvent<HTMLButtonElement>>}
   */
  /* istanbul ignore next */
  protected OnDeSelect = (candidate: string | number | boolean | readonly string[]): Func<void, FormEvent<HTMLButtonElement>> => {
    const { SelectValue } = this.context;
    return (evt: FormEvent<HTMLButtonElement>): void => {
      SelectValue(candidate);
    };
  };
}
