import React, { PureComponent, Children, ReactNode } from 'react';

import { CoreInputLabel } from '../modules/';
import { ArticleHeaderProps } from '../interfaces/';
import { CoreNavigate } from '../../Router/';

import { IsDefined } from '../../utils/';

/**
 * Article Header Component
 * @export
 * @class CoreArticleHeaderComponent
 * @extends {PureComponent<ArticleHeaderProps, {}>}
 */
export default class CoreArticleHeaderComponent extends PureComponent<ArticleHeaderProps, {}> {
  /**
   * Help
   * @protected
   * @type {*}
   * @memberof CoreArticleHeaderComponent
   */
  protected help: any = null;

  /**
   * Kids
   * @protected
   * @type {*}
   * @memberof CoreArticleHeaderComponent
   */
  protected kids: any[] = [];

  /**
   * Creates an instance of CoreArticleHeaderComponent.
   * @param {ArticleHeaderProps} props
   * @memberof CoreArticleHeaderComponent
   */
  constructor(props: ArticleHeaderProps) {
    super(props);
    this.init(props);
  }

  /**
   * Render
   * @returns
   * @memberof CoreArticleHeaderComponent
   */
  public render() {
    const {
      GoBack,
      help,
      kids,
      props: { title, back, className },
    } = this;

    return (
      <article className={className}>
        <header className="article-header-row">
          <h2>
            {title || ''}
            {help}
          </h2>
          <div>
            {back ? (
              <div>
                <CoreInputLabel>
                  <nux-button-secondary outline={true} onClick={GoBack}>
                    Back
                  </nux-button-secondary>
                </CoreInputLabel>
              </div>
            ) : null}
            {kids.map((kid: ReactNode, ndx: number) => {
              /* istanbul ignore next */
              return <div key={`ah.${ndx}`}>{kid}</div>;
            })}
          </div>
        </header>
      </article>
    );
  }

  /**
   * Init
   * @protected
   * @param {ArticleHeaderProps} { children }
   * @memberof CoreArticleHeaderComponent
   */
  protected init = ({ children }: ArticleHeaderProps): void => {
    if (children) {
      /* istanbul ignore next */
      Children.toArray(children).forEach((Child: any): void => {
        if (IsDefined(Child.type)) {
          if (Child.type.displayName === 'CoreHelp') {
            this.help = Child;
          } else {
            this.kids.push(Child);
          }
        }
      });
    }
  };

  /**
   * Go Back
   * @private
   * @memberof CoreArticleHeaderComponent
   */
  private GoBack = (): void => {
    /* istanbul ignore next */
    CoreNavigate(-1);
  };
}
