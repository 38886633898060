import React, { PureComponent } from 'react';
import { MergeClassNames } from '../../../../../utils/';

/**
 * Form Group
 * @export
 * @class CoreFormGroupContainer
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class CoreFormGroupContainer extends PureComponent<GenericPropType, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreFormGroupContainer
   */
  public render() {
    const { className, children } = this.props;

    const clz = MergeClassNames(className, {'form-group': true });

    return (
      <div className={clz}>
        {children}
      </div>
    );
  }
}
