import React from 'react';
import { cloneDeep } from 'lodash';

import { CoreConfigHeader, CoreConfigBounce } from '../../Config/';

import { SectionHeaderProps, CoreSectionHeaderState } from '../interfaces/';
import { SECTION_HEADER_PROPS, SECTION_HEADER_STATE } from '../constants';

/**
 * Section Header Container
 * @export
 * @class CoreSectionHeaderComponent
 * @extends {Component<any, any>}
 */
export default class CoreSectionHeaderComponent extends CoreConfigBounce<SectionHeaderProps, CoreSectionHeaderState> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof CoreSectionHeaderComponent
   */
  public static displayName: string = 'OnSectionHeaderBounce';

  /**
   * Default Props
   * @static
   * @type {SectionHeaderProps}
   * @memberof CoreSectionHeaderComponent
   */
  public static defaultProps: SectionHeaderProps = cloneDeep(SECTION_HEADER_PROPS);

  /**
   * State
   * @type {CoreSectionHeaderState}
   * @memberof CoreSectionHeaderComponent
   */
  public readonly state: CoreSectionHeaderState = cloneDeep(SECTION_HEADER_STATE);

  /**
   * Render
   * @returns
   * @memberof CoreSectionHeaderComponent
   */
  public render() {
    const product_name = CoreConfigHeader.PRODUCT_NAME;
    return (
      <header className="main-section-header">
        <h2>{product_name}</h2>
      </header>
    );
  }
}
