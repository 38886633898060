import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import CoreInteropContext from '../../Interop/context';
import { ROUTE_CHANGE_EVENT } from '../../Interop';

import { CoreAnchor } from '..';

import { InteropLinkProps } from '../interfaces';

import { DELAYED_LINK_PROPS } from '../constants';

/**
 * Interop Link Component
 * @export
 * @class CoreInteropLinkComponent
 * @extends {Component<LinkProps, {}>}
 */
export default class CoreInteropLinkComponent extends Component<InteropLinkProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreInteropLinkComponent
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * Default Props
   * @static
   * @type {InteropLinkProps}
   * @memberof CoreInteropLinkComponent
   */
  public static defaultProps: InteropLinkProps = cloneDeep(DELAYED_LINK_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreInteropLinkComponent
   */
  public render() {
    const { props, OnClick } = this;
    const { children } = props;
    return <CoreAnchor onClick={OnClick}>{children}</CoreAnchor>;
  }

  /**
   * On Click
   * @protected
   * @param {any} evt
   * @memberof CoreInteropLinkComponent
   */
  /* istanbul ignore next */
  protected OnClick = (evt: any): void => {
    const { context, props } = this;
    const { link } = props;
    context.emit(link, ROUTE_CHANGE_EVENT);
  };
}
