import React, { Fragment } from 'react';

import DatePickerBase from './Base';

import HiddenValue from './HiddenValue';

import { CoreInputLabel, CoreFormElementText } from '../../forms/';
import { PickerValueProps } from '../interfaces/';

/**
 * Display Value Component
 * @export
 * @class DisplayValueComponent
 * @extends {DatePickerBase<PureComponent<P = PickerValueProps, {}>>}
 */
export default class DisplayValueComponent extends DatePickerBase<PickerValueProps> {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof DisplayValueComponent
   */
  public static displayName: string = 'DisplayValue';

  /**
   * Render
   * @returns
   * @memberof DisplayValueComponent
   */
  public render() {
    const { props: { toggle }, context: { display_name, display_value, reset, props: { name, label, required, children, className } } } = this;

    return (
      <Fragment key={`picker.display.value.${Date.now()}`}>
        <HiddenValue reset={reset} name={name} required={required} />
        <CoreInputLabel type="date" label={label} htmlFor="_">
          <CoreFormElementText name={display_name} value={display_value} onClick={toggle} readOnly={true} className={className} />
          {children}
        </CoreInputLabel>
      </Fragment>
    );
  }
}
