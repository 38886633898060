import COLORS from '../../../json/colors.json';

export const FORM_ELEMENT_PROPS = {
  value: undefined,
  placeholder: '...'
};

export const FORM_ELEMENT_STATE = {
  value: undefined,
  options: null
};

// colors
const NAMES: string[] = Object.keys(COLORS);
const LC_COLORS = NAMES.reduce((d: any, r: string) => { d[r.toLowerCase()] = COLORS[r];return d;}, {});
const LC_NAMES: string[] = Object.keys(LC_COLORS);
export const COLOR_INPUT_COLORS = Object.assign({}, COLORS, LC_COLORS);
export const COLOR_INPUT_PATTERN = `#[0-9a-fA-F]{6}|${NAMES.join('|')}|${LC_NAMES.join('|')}`;

