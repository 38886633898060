import EventEmitter from 'events';

import { CoreInteropServiceShape } from './interfaces/';
import { CoreHeaderToggleMask, CoreHeaderMenusMask } from './enums/';

import { INTEROP_CHANGE_EVENT } from './constants';

/**
 * Interop Service
 * @export
 * @class CoreInteropService
 * @implements {CoreInteropServiceShape}
 * @notes - Coordinates UI Display States across major modules
 */
export class CoreInteropService implements CoreInteropServiceShape {
  /**
   * Event Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreInteropService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Header
   * @private
   * @type {number}
   * @memberof CoreInteropService
   */
  private _header: number = CoreHeaderToggleMask.OFF;

  /**
   * Menus
   * @private
   * @type {number}
   * @memberof CoreInteropService
   */
  private _menus: number = CoreHeaderMenusMask.OFF;

  /**
   * Subscribe
   * @param {Func<void>} callback
   * @param {string} [event=INTEROP_CHANGE_EVENT]
   * @memberof CoreInteropService
   */
  public subscribe(callback: Func<void>, event: string = INTEROP_CHANGE_EVENT): Func<void> {
    this._emitter.on(event, callback);
    return callback;
  }

  /**
   * Unsubscribe
   * @param {Func<void>} callback
   * @param {string} [event=INTEROP_CHANGE_EVENT]
   * @memberof CoreInteropService
   */
  public unsubscribe(callback: Func<void>, event: string = INTEROP_CHANGE_EVENT): void {
    this._emitter.off(event, callback);
    callback = undefined;
  }

  /**
   * Emit
   * @param {*} [value=undefined]
   * @param {string} [event=INTEROP_CHANGE_EVENT]
   * @memberof CoreInteropService
   */
  public emit(value: any = undefined, event: string = INTEROP_CHANGE_EVENT): void {
    if (this._emitter.listenerCount(event)) {
      this._emitter.emit(event, value);
    }
  }

  /**
   * Header State - getter
   * @memberof CoreInteropService
   */
  public get header() {
    return this._header;
  }

  /**
   * Header State - setter
   * @memberof CoreInteropService
   */
  public set header(header: number) {
    this._header = header;
  }

  /**
   * Menus State - getter
   * @memberof CoreInteropService
   */
  public get menus() {
    return this._menus;
  }

  /**
   * Menus State - setter
   * @memberof CoreInteropService
   */
  public set menus(menus: number) {
    this._menus = menus;
  }
}

const Interop = new CoreInteropService();

export default Interop;
