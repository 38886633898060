import React, { PureComponent } from 'react';

import CoreConfigContext from './context';
import CoreConfig from './service';
import { CoreConfigHtmlDirective } from './directives/';

/**
 * Config Wrapper
 * @export
 * @class CoreConfigWrapper
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreConfigWrapper extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns
   * @memberof CoreConfigWrapper
   */
  public render() {
    return (
      <CoreConfigContext.Provider value={CoreConfig}>
        <CoreConfigHtmlDirective />
        {this.props.children}
      </CoreConfigContext.Provider>
    );
  }
}
