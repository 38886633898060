import React from 'react';

import DTGBase from './DTGBase';

import { CoreFormGroupInline, CoreFormGroup } from '../../forms/';

import { DisplayValue, StartDate, EndDate, StartTime, EndTime, ApplyButton } from '../components/';

import { MergeClassNames, IsDefined } from '../../../../utils/';

/**
 * Date Time Range Container
 * @export
 * @class DateTimeRangeContainer
 * @extends {DTGBase<Component<GenericPropType, PickerState>}>
 */
export default class DateTimeRangeContainer extends DTGBase {
  /**
   * Render
   * @returns
   * @memberof DateTimeRangeContainer
   */
  public render() {
    const { OnToggleActive, OnApplyAction, state: { active }, context: { props } } = this;
    const { className, label, disabled } = props;

    const clz = MergeClassNames(className, {
      'date-time-range-picker': true,
      labelled: IsDefined(label),
      active,
    });

    return (
      <CoreFormGroup className={clz}>
        <DisplayValue toggle={OnToggleActive} {...props} className={className} />
        {disabled ? null : (
          <div>
            <CoreFormGroupInline>
              <CoreFormGroup>
                <StartDate {...props} />
              </CoreFormGroup>
              <CoreFormGroup>
                <StartTime {...props} />
              </CoreFormGroup>
            </CoreFormGroupInline>
            <CoreFormGroupInline>
              <CoreFormGroup>
                <EndDate {...props} />
              </CoreFormGroup>
              <CoreFormGroup>
                <EndTime {...props} />
              </CoreFormGroup>
            </CoreFormGroupInline>
            <ApplyButton action={OnApplyAction} />
          </div>
        )}
      </CoreFormGroup>
    );
  }
}
