export enum CoreFormConsumerTypeEnum {
  /**
   * FORM - consumers will fire on every change in the form.
   */
  FORM = 'FORM',
  /**
   * WATCHER - consumers will fire on every change within named controller.
   */
  WATCHER = 'WATCHER',
  /**
   * MANUAL - consumers will refresh only if consumer.refresh is invoked
   */
  MANUAL = 'MANUAL',
}
