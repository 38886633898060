import React, { Component, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';

import { CoreConfig, CoreConfigHeader } from '../../Config/';

import { CoreMasqueradeProps } from '../interfaces/';
import { MASQUERADE_PROPS, MASQUERADE_ACTIVE_BUTTON_TITLE } from '../constants';

import { CoreAnchor, CoreGetApiLocation } from '../../shared/';

import { MergeClassNames } from '../../utils/';

/**
 * Masquerade Container
 * @export
 * @class CoreMasqueradeComponent
 * @extends {Component<HeaderUserProps, {}>}
 */
export default class CoreMasqueradeComponent extends Component<CoreMasqueradeProps, {}> {
  /**
   * Default Props
   * @static
   * @type {CoreMasqueradeProps}
   * @memberof CoreMasqueradeComponent
   */
  public static defaultProps: CoreMasqueradeProps = cloneDeep(MASQUERADE_PROPS);

  /**
   * Render
   * @returns
   * @memberof CoreMasqueradeComponent
   */
  public render() {
    const { OnMasqueradeClick, props } = this;
    const { masquerade, shortname } = props;

    const active = masquerade;
    const clz = MergeClassNames('', { active });
    return (
      <aside className={clz}>
        <CoreAnchor onClick={OnMasqueradeClick} title={MASQUERADE_ACTIVE_BUTTON_TITLE}>
          {shortname}
        </CoreAnchor>
      </aside>
    );
  }

  /**
   * On Masquerade Click
   * @protected
   * @memberof CoreMasqueradeComponent
   */
  /* istanbul ignore next */
  protected OnMasqueradeClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    CoreConfig.company.clear();
    const accounts_url = CoreGetApiLocation(CoreConfigHeader.ACCOUNTS_DOMAIN);
    const href = `${accounts_url}/unmask`;
    (window as any).location.assign(href);
  };
}
