import { CoreUserInfoPermissionsShape } from '../interfaces/';

export const PERMISSIONS_DEFAULT: CoreUserInfoPermissionsShape = {
  accounts: 'readonly',
  siteprotect: 'readonly',
};

export const SOC_ADMIN_NEUSTAR_PERMS = ['mitigations:provider'];

export const SOC_ADMIN_PERMS = [
  'certificate:write',
  'certificate:other',
  'company:other',
  'company:write',
  'config-engine:other',
  'mitigations:other',
  'policy:write',
  'policy:other',
  'proxy:write',
  'proxy:other',
  'traffic:other',
  'vip-pools:other',
  'vip-pools:reserve',
  'vip-pools:write',
];

export const ADMIN_PERMS = [
  'company:write',
  'proxy:write',
  'policy:write',
  'certificate:write',
];

export const RESELLER_PERMS = [
  'certificate:other',
  'company:other',
  'config-engine:other',
  'mitigations:other',
  'policy:other',
  'proxy:other',
  'traffic:other',
];


export const CSR_PERMS = [
  'certificate:other',
  'certificate:write',
  'company:other',
  'company:write',
  'config-engine:other',
  'mitigations:other',
  'mitigations:provider',
  'policy:write',
  'policy:other',
  'proxy:write',
  'proxy:other',
  'traffic:other',
];

export const SSSE_PERMS = [
  'certificate:write',
  'certificate:other',
  'company:other',
  'config-engine:other',
  'mitigations:other',
  'mitigations:provider',
  'policy:write',
  'policy:other',
  'proxy:write',
  'proxy:other',
  'traffic:other',
];

export const AUDIT_PERMS = [
  'audit:read'
];
