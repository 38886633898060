import React, { PureComponent, createContext } from 'react';

import CoreFormContext from '../../contexts/Form';
import { CoreFormConsumerProps } from '../../interfaces/';
import { WaitForDelay } from '../../../../../utils/';

/**
 * Manual Consumer
 * @export
 * @class ManualConsumer
 * @extends {PureComponent<CoreFormConsumerProps, {}>}
 */
export default class ManualConsumer extends PureComponent<CoreFormConsumerProps, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormContext>}
   * @memberof ManualConsumer
   */
  public static contextType: Readonly<typeof CoreFormContext> = CoreFormContext;

  /**
   * Abort Controller
   * @private
   * @type {AbortController}
   * @memberof ManualConsumer
   */
  protected listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof ManualConsumer
   */
  public componentDidMount() {
    this.listener = new AbortController();
  }

  /**
   * LifeCycle Hook
   * @memberof ManualConsumer
   */
  public componentWillUnmount() {
    if (this.listener) {
      this.listener.abort();
    }
  }

  /**
   * Render
   * @returns
   * @memberof ManualConsumer
   */
  public render() {
    const { ConsumerContext, props: { children } } = this;
    return <ConsumerContext.Consumer children={children as any} />;
  }

  /**
   * Refresh
   * @protected
   * @memberof ManualConsumer
   */
  protected refresh = () => {
    /* istanbul ignore next */
    WaitForDelay().then(() => {
      if (!this.listener.signal.aborted) {
        this.forceUpdate();
      }
    });
  };

  /**
   * ConsumerContext - getter
   * @readonly
   * @memberof ManualConsumer
   */
  public get ConsumerContext() {
    const { context, refresh } = this;
    const { source } = context;
    const values = source;
    const consumer = { refresh };
    return createContext({ form: context, values, consumer });
  }
}
