import React, { Component, MouseEvent } from 'react';
import { cloneDeep } from 'lodash';

import CoreInteropContext from '../../Interop/context';
import { CoreHeaderToggleMask } from '../../Interop/enums/';
import { CoreNavigation } from '../../Navigation/';

import { CoreHeaderToggleState } from '../interfaces/';
import { HEADER_TOGGLE_STATE } from '../constants';

import { MergeClassNames } from '../../utils/';

/**
 * Header Form Container
 * @export
 * @class CoreHeaderFormContainer
 * @extends {Component<{}, CoreHeaderToggleState>}
 */
export default class CoreHeaderFormContainer extends Component<{}, CoreHeaderToggleState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreHeaderFormContainer
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * State
   * @type {CoreHeaderToggleState}
   * @memberof CoreHeaderFormContainer
   */
  public readonly state: CoreHeaderToggleState = cloneDeep(HEADER_TOGGLE_STATE);

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreHeaderFormContainer
   */
  private subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreHeaderFormContainer
   */
  public componentDidMount() {
    const { context } = this;
    this.subscriber = context.subscribe(this.CoreHeaderFormContainerListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreHeaderFormContainer
   */
  public componentWillUnmount() {
    const { context, subscriber } = this;
    context.unsubscribe(subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreHeaderFormContainer
   */
  public render() {
    const { state, OnNavigationToggle, OnMenusToggle } = this;
    const { header } = state;

    /* istanbul ignore next */
    const NavClz = MergeClassNames('', {
      active: CoreNavigation.populated ? header === CoreHeaderToggleMask.NAVIGATION : false,
    });

    /* istanbul ignore next */
    const MenusClz = MergeClassNames('', {
      active: header === CoreHeaderToggleMask.MENUS,
    });

    /* istanbul ignore next */
    const ntd: boolean = CoreNavigation.populated ? header === CoreHeaderToggleMask.NONE : true;
    const mtd: boolean = false;

    return (
      <form className="header">
        <label title="toggle navigation">
          <button type="button" className={NavClz} onClick={OnNavigationToggle} disabled={ntd}>
            <i />
            <i />
          </button>
        </label>
        <label title="toggle menu">
          <button type="button" className={MenusClz} onClick={OnMenusToggle} disabled={mtd}>
            <i />
            <i />
          </button>
        </label>
      </form>
    );
  }

  /**
   * Form Container Listener
   * @memberof CoreHeaderFormContainer
   */
  public CoreHeaderFormContainerListener = (): void => {
    const { state, context } = this;
    const { header } = context;
    if (header !== state.header) {
      this.setState({ header });
    }
  };

  /**
   * On Navigation Toggle
   * @private
   * @param {MouseEvent<HTMLButtonElement>} evt
   * @memberof CoreHeaderFormContainer
   */
  private OnNavigationToggle = (evt: MouseEvent<HTMLButtonElement>): void => {
    const { state, context } = this;
    const header = state.header === CoreHeaderToggleMask.NAVIGATION ? CoreHeaderToggleMask.OFF : CoreHeaderToggleMask.NAVIGATION;
    context.header = header;
    context.emit();
  };

  /**
   * On Menus Toggle
   * @private
   * @param {MouseEvent<HTMLButtonElement>} evt
   * @memberof CoreHeaderFormContainer
   */
  private OnMenusToggle = (evt: MouseEvent<HTMLButtonElement>): void => {
    const { state, context } = this;
    const header = state.header === CoreHeaderToggleMask.MENUS ? CoreHeaderToggleMask.OFF : CoreHeaderToggleMask.MENUS;
    context.header = header;
    context.emit();
  };
}
