
import CoreFormElementInputBase from './BaseInput';
import { CoreFormElementProps } from '../../interfaces/';
import { FORM_ELEMENT_PROPS, INPUT_TYPE_EMAIL } from '../../constants/';
import { HashMapMergeProps } from '../../../../../utils/';

/**
 * Email Input Element
 * @export
 * @class CoreFormElementEmail
 * @extends {CoreFormElementInputBase}
 */
export default class CoreFormElementEmail extends CoreFormElementInputBase {
  /**
   * Default Props
   * @static
   * @type {Omit<CoreFormElementProps, 'name'>}
   * @memberof CoreFormElementEmail
   */
  public static defaultProps: Omit<CoreFormElementProps, 'name'> = HashMapMergeProps(FORM_ELEMENT_PROPS, {
    type: INPUT_TYPE_EMAIL,
    placeholder: 'your@email.com',
    autoComplete: 'off',
    autoCapitalize: 'off',
    spellCheck: false
  });
}
