import classnames from 'classnames';
import { singular, plural } from 'pluralize';
import { StringToHashMap } from './hashmaps';

/**
 * String To Snake Case
 * @export
 * @param {string} src
 * @returns {HashMap<any>}
 */
export const StringToSnakeCase = (src: string): string => {
  return src.trim().replace(/\s+/g, '_');
};

/**
 * String To Kebab Case
 * @export
 * @param {string} src
 * @returns {HashMap<any>}
 */
export const StringToKebabCase = (src: string): string => {
  return src.trim().replace(/[^a-zA-Z0-9\s]+/g, '').replace(/\s+/g, '-');
};

/**
 * RGBA As String
 * @export
 * @param {HashMap<number>} src
 * @returns {HashMap<any>}
 */
export const RGBAAsString = (src: HashMap<number>): string => {
  return `rgba(${src.red},${src.green},${src.blue},${src.alpha})`;
};

/**
 * Parse Html
 * @export
 * @param {string} src
 * @param {HashMap<any>} props
 * @returns {string}
 */
export const ParseHtml = (src: string, props: HashMap<any> = {}): string => {
  const matches: Array<string> = src.match(/\{[\w]+\}/g) || [];
  if (matches.length) {
    matches.forEach((match: string): void => {
      const rx: RegExp = new RegExp(match, 'g');
      const item: string = match.replace(/[{}]/g, '');
      src = src.replace(rx, props[item]);
    });
  }
  return src;
};

/**
 * HashMap To URI
 * @export
 * @param {HashMap<string>} src
 * @returns {string}
 */
export const HashMapToURI = (src: HashMap<string>): string => {
  return Object.keys(src)
    .reduce((d: string[], r: string) => {
      d.push(`${r}=${encodeURIComponent(src[r])}`);
      return d;
    }, [])
    .join('&');
};

/**
 * Parse URL
 * @export
 * @param {HashMap<string>} src
 * @returns {string}
 */
export const ParseURL = (href: string): HashMap<any> => {
  const a = document.createElement('a');
  a.href = href;
  return ['protocol', 'hostname', 'pathname', 'search', 'hash'].reduce((d: HashMap<string>, r: string) => {
    d[r] = a[r];
    return d;
  }, {});
};

/**
 * Initial Cap
 * @export
 * @param {string} src
 * @returns {string}
 */
export const InitialCap = (src: string): string => {
  return src.slice(0, 1).toUpperCase() + src.slice(1).toLowerCase();
};

/**
 * Sentence Cap
 * @export
 * @param {string} src
 * @returns {string}
 */
export const SentenceCap = (src: string): string => {
  src = src.trim();
  return src.slice(0, 1).toUpperCase() + src.slice(1);
};

/**
 * Merge Class Names
 * @export
 * @param {string} [className='']
 * @returns {string}
 */
export const MergeClassNames = (className: string = '', source: HashMap<any> = {}): string => {
  return classnames(StringToHashMap(className, source));
};

/**
 * Path To Dots
 * @export
 * @param {string} [path='']
 * @returns {string}
 */
export const PathToDots = (path: string = ''): string => {
  if (/^\//.test(path)) {
    path = path.slice(1);
  }
  return path.toLowerCase().replace(/\//g, '.');
};

/**
 * Is Plural Of
 * @export
 * @param {string} a
 * @param {string} b
 * @returns {boolean}
 */
export const IsPluralOf = (a: string, b: string): boolean => {
  return singular(a) === b && plural(b) === a;
};
