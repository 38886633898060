import React, { Component, Fragment } from 'react';
import { cloneDeep } from 'lodash';

import PickerContext from '../context';

import { PickerState } from '../interfaces/';
import { PICKER_STATE } from '../constants';

import { IsDefined, IsFunction } from '../../../../utils/';

/**
 * DTG Base
 * @export
 * @class DTGBase
 * @extends {Component<GenericPropType, PickerState>}
 */
export default class DTGBase extends Component<GenericPropType, PickerState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof PickerContext>}
   * @memberof DTGBase
   */
  public static contextType: Readonly<typeof PickerContext> = PickerContext;

  /**
   * State
   * @type {PickerState}
   * @memberof DTGBase
   */
  public readonly state: PickerState = cloneDeep(PICKER_STATE);

  /**
   * Render
   * @returns
   * @memberof DTGBase
   */
  public render() {
    return <Fragment>must extend in sub-class.</Fragment>;
  }

  /**
   * On Toggle Active
   * @protected
   * @memberof DTGBase
   */
  protected OnToggleActive = (): void => {
    if (!this.props.disabled) {
      this.setState({ active: !this.state.active });
    }
  };

  /**
   * On Apply Action
   * @protected
   * @memberof DateRangeContainer
   */
  /* istanbul ignore next */
  protected OnApplyAction = (): void => {
    const { context } = this;
    const { form, props: { name, onChange } } = context;

    if (IsDefined(onChange) && IsFunction(onChange)) {
      const value = form.value(name) || '';
      onChange(value, context, form);
    }

    this.setState({ active: false });
  };
}
