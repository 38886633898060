import { THEME_DEFAULT, MARQUEE_MESSAGES, FAVICONS } from '../constants';
import { CoreConfigMetaShape } from '../interfaces/';

/**
 * Config Meta Service
 * @export
 * @class CoreConfigMeta
 */
export class CoreConfigMeta implements CoreConfigMetaShape {
  public static WHITELABELED: boolean = false;
  public static THEME: string = THEME_DEFAULT;
  public static FAVICON: HashMap<any> = FAVICONS;
  public static MARQUEE: string[] = MARQUEE_MESSAGES;
  public get whitelabeled() { return CoreConfigMeta.WHITELABELED; }
  public get theme() { return CoreConfigMeta.THEME; }
  public get favicon() { return CoreConfigMeta.FAVICON; }
  public get marquee() { return CoreConfigMeta.MARQUEE; }
}
