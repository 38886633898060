import { get } from 'lodash';

import { IsString, IsNumber, IsBoolean, IsDate } from '../../../../utils/';
import { CoreTableSortMethodMask } from '../enums/';

/**
 * Sort Flat Factory
 * @param {Array<HashMap<any>>} data
 * @param {string} column
 * @param {CoreTableSortMethodMask} method
 * @returns {GenericDataType<Array<HashMap<any>>>}
 * @memberof CoreTableSortService
 */
export const CoreSortFlatFactory = (data: Array<HashMap<any>>, column: string, method: CoreTableSortMethodMask): Array<HashMap<any>> => {
  try {

    if (!Array.isArray(data) || !data.length) {
      return [];
    }

    /* istanbul ignore next */
    if (!column && method === CoreTableSortMethodMask.UNSORTED) {
      return data;
    }

    data = data.slice(0);

    const samp = get(data, `0.${column}`);
    const is_string: boolean = IsString(samp);
    const is_num: boolean = IsNumber(samp);
    const is_bool: boolean = IsBoolean(samp);
    const is_date: boolean = IsDate(samp);

    /* istanbul ignore next */
    if (!is_string && !is_num && !is_bool && !is_date) {
      throw new TypeError('cannot sort on non Lexical/Numeric/Boolean/Date fields.');
    }

    const unsort = method === CoreTableSortMethodMask.UNSORTED;

    // Words
    const LexicalSort = (a: HashMap<any>, b: HashMap<any>) => {
      if (unsort) {
        return 0;
      }
      a = (get(a, column)).toLowerCase();
      b = (get(b, column)).toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    };

    // Numbers
    const NumericSort = (a: HashMap<any>, b: HashMap<any>) => {
      /* istanbul ignore next */
      if (unsort) {
        return 0;
      }
      const aa: number = get(a, column);
      const bb: number = get(b, column);
      return aa - bb;
    };

    // Booleans
    const BooleanSort = (a: HashMap<any>, b: HashMap<any>) => {
      /* istanbul ignore next */
      if (unsort) {
        return 0;
      }
      const aa: number = get(a, column) === true ? 1 : 0;
      const bb: number = get(b, column) === true ? 1 : 0;
      return aa - bb;
    };

    // Dates
    const DateSort = (a: HashMap<any>, b: HashMap<any>) => {
      /* istanbul ignore next */
      if (unsort) {
        return 0;
      }
      a = get(a, column);
      b = get(b, column);
      return a < b ? -1 : a > b ? 1 : 0;
    };

    let SortMethod: any;

    if (is_string) {
      SortMethod = LexicalSort;
    } else if (is_num) {
      SortMethod = NumericSort;
    } else if (is_bool) {
      SortMethod = BooleanSort;
    } else if (is_date) {
      SortMethod = DateSort;
    }

    data.sort(SortMethod);

    return method === CoreTableSortMethodMask.DESCENDING ? data.reverse() : data;
  } catch (err: any) {
    /* istanbul ignore next */
    console.error(err);
  }

  /* istanbul ignore next */
  return data;
};
