import EventEmitter from 'events';
import { WebAuth } from 'auth0-js';

import { CoreAsyncWebStorage } from '../shared/modules/storage/';

import { CoreBootEventEnum } from '../Boot/enums/';
import { CoreAuthServiceShape, CoreAuthStorageShape, CoreUserServiceShape, CoreAuthCredentialsServiceShape } from './interfaces/';
import { RefreshAuth0Session, ValidateAuth0Token, GetExpiresAuth0Token } from './factories/';
import { CoreAuthCredentialsService, CoreUserService } from './services/';
import { AUTH_RETURN_TO as returnTo, REFRESH_ERROR_ALERT, AUTH_REFRESH_EVENT, AUTH_INSTALL_EVENT, AUTH_STORAGE, AUTH_STORAGE_T, AUTH_STORAGE_E } from './constants/';
import { CoreBootAuthPlugins } from '../Boot/services/';
import { IsDefined, Noop, WaitForDelay } from '../utils/';

/**
 * Auth Service
 * @export
 * @class CoreAuthService
 * @implements {CoreAuthServiceShape}
 */
export class CoreAuthService implements CoreAuthServiceShape {
  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CoreAuthService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Web Auth
   * @static
   * @type {WebAuth}
   * @memberof CoreAuthService
   */
  public static WEBAUTH: WebAuth;

  /**
   * Credentials
   * @private
   * @type {CoreAuthCredentialsServiceShape}
   * @memberof CoreAuthService
   */
  private _credentials: CoreAuthCredentialsServiceShape = new CoreAuthCredentialsService();

  /**
   * User
   * @private
   * @type {CoreUserServiceShape}
   * @memberof CoreAuthService
   */
  private _user: CoreUserServiceShape = new CoreUserService();

  /**
   * Installed
   * @private
   * @type {boolean}
   * @memberof CoreAuthService
   */
  private _installed: boolean = false;

  /**
   * Token
   * @private
   * @type {string}
   * @memberof CoreAuthService
   */
  private _token: string = null!;

  /**
   * Token Storage
   * @private
   * @type {string}
   * @memberof CoreAuthService
   */
  private __token: CoreAsyncWebStorage = new CoreAsyncWebStorage(AUTH_STORAGE, AUTH_STORAGE_T, true);

  /**
   * Expires
   * @private
   * @type {string}
   * @memberof CoreAuthService
   */
  private _expires: number = null!;

  /**
   * Expires Storage
   * @private
   * @type {string}
   * @memberof CoreAuthService
   */
  private __expires: CoreAsyncWebStorage = new CoreAsyncWebStorage(AUTH_STORAGE, AUTH_STORAGE_E);

  /**
   * Creates an instance of CoreAuthService.
   * @memberof CoreAuthService
   */
  constructor() {
    this._emitter.setMaxListeners(3000);
    this.install = this.install.bind(this);
    this.refresh = this.refresh.bind(this);
    this.logout = this.logout.bind(this);
    this.destroy = this.destroy.bind(this);
    this.emit = this.emit.bind(this);
    this.init = this.init.bind(this);
    this.store = this.store.bind(this);
    this.init();
  }

  /**
   * Install
   * @async
   * @param {string} token
   * @param {string} state
   * @returns {Promise<any>}
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public async install(token: string, state: string): Promise<any> {
    try {

      // make sure token is valid
      const { token_error } = await ValidateAuth0Token(token);

      if (IsDefined(token_error)) {
        throw new Error(token_error);
      }

      const { exp, expires_error } = await GetExpiresAuth0Token(token);

      if (IsDefined(expires_error)) {
        throw new TypeError(expires_error);
      }

      // the expires value is returned in the form of
      // seconds since epoch, this converts timestamp to js (ms since epoch)
      const expires: number = Number(exp) * 1e3;

      await this.store({ token, expires, state });

      // lets graph clients rebuild once logged in.
      this.emit(AUTH_INSTALL_EVENT);

      /**
       * Why is this here?
       * @note Auth0 operations like inspecting user info object for
       * params like "enabled" must be done AFTER authentication so that
       * the plugin may apply a valid token to the external request
       * CoreBootAuthPlugins.apply('authenticate', { token })
       *
       * That's because those types of operations are under the management API and require the
       * Client Secret Key, which should NEVER, EVER be stored in a front end app.
       *
       * In cases where this request returns failed, the mechanism below
       * undoes the authentication and returns the user back to login.
       * i.e. CoreBoot : UNAUTHENTICATED
       */
      const success: boolean = await CoreBootAuthPlugins.apply('authenticate', { token });

      // removes client references to session
      if (!success) {
        this.destroy();
        throw new Error(CoreBootEventEnum.UNAUTHENTICATED);
      }

      return true;
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  /**
   * Refresh
   * @returns {Promise<string>}
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public async refresh(): Promise<string> {
    try {
      const { token, state, refresh_error } = await RefreshAuth0Session(this.auth);

      if (IsDefined(refresh_error)) {
        throw new Error(refresh_error);
      }

      const { exp, expires_error } = await GetExpiresAuth0Token(token);

      if (IsDefined(expires_error)) {
        throw new TypeError(expires_error);
      }

      // the expires value is returned in the form of
      // seconds since epoch, this converts timestamp to js (ms since epoch)
      const expires: number = Number(exp) * 1e3;

      await this.store({ token, expires, state });

      // lets graph subscribers rebuild with fresh token.
      await WaitForDelay();

      // notifies subscribers.
      this.emit();

      return token;
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(err);
      }
      return (window as any).confirm(REFRESH_ERROR_ALERT, undefined, { yes: 'logout', no: 'cancel' }).then(() => {
        return this.logout();
      }).catch(Noop);
    }
  }

  /**
   * Logout
   * @returns {Promise<any>}
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public async logout(): Promise<any> {
    const { token } = this;
    const { clientID } = this.credentials;

    // apply logout plugin if any
    await CoreBootAuthPlugins.apply('logout', { token });

    // remove client references to session
    this.destroy();

    // auth0 - logout must go last as it involves a redirect.
    this.auth.logout({ clientID, returnTo, federated: true });

    return true;
  }

  /**
   * Destroy
   * @memberof CoreAuthService
   */
  public destroy(): void {
    this._installed = false;
    this._user = new CoreUserService();
    this.__token.remove();
    this.__expires.remove();
  }

  /**
   * Emit
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public emit(event: string = AUTH_REFRESH_EVENT): void {
    this._emitter.emit(event);
  }

  /**
   * Init
   * @private
   * @return {*}  {Promise<void>}
   * @memberof CoreAuthService
   */
   private async init(): Promise<void> {
    if (!this._token) {
      this._token = await this.__token.get();
    }
    if (!this._expires) {
      this._expires = await this.__expires.get();
    } 
  }

  /**
   * Store
   * @param {CoreAuthStorageShape} { token, expires, state }
   * @return {*}  {Promise<void>}
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  private async store({ token, expires, state }: CoreAuthStorageShape): Promise<void> {
    try {
      await this.__token.make(state);
      await this.__token.set(token);
      await this.__expires.set(expires);

      this._token = await this.__token.get();
      this._expires = await this.__expires.get();

    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.error(`DEVELOPER ERROR:: CoreAuthService.store: ${err.message || err}`);
      }
    }
  }

  /**
   * Auth - getter
   * @readonly
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get auth() {
    return CoreAuthService.WEBAUTH;
  }

  /**
   * Credentials - getter
   * @readonly
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get credentials() {
    return this._credentials;
  }

  /**
   * User - getter
   * @readonly
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get user() {
    return this._user;
  }

  /**
   * User - setter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public set user(user: CoreUserServiceShape) {
    this._user = user;
  }

  /**
   * Token - getter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get token() {
    if (!this._token) {
      this.__token.get().then((token: string) => {
        this._token = token;
      });
    }
    return this._token;
  }

  /**
   * Expires - getter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get expires() {
    if (!this._expires) {
      this.__expires.get().then((expires: number) => {
        this._expires = expires;
      });
    }
    return this._expires;
  }

  /**
   * Authenticated - getter
   * @readonly
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get authenticated() {
    const { token, expires } = this;
    if (!token || !token.length || !expires || expires <= Date.now()) {
      return false;
    }
    return true;
  }

  /**
   * Installed - getter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get installed() {
    return this._installed;
  }

  /**
   * Permissions - getter
   * @readonly
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public get permissions() {
    return this.user.role.permissions;
  }

  /**
   * Installed - setter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  public set installed(installed: boolean) {
    this._installed = installed;
  }

  /**
   * Emitter - getter
   * @readonly
   * @memberof CoreAuthService
   */
  public get emitter() {
    return this._emitter;
  }

  /**
   * Token - getter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  private set token(token) {
    this.__token.set(token);
  }

  /**
   * Expires - getter
   * @memberof CoreAuthService
   */
  /* istanbul ignore next */
  private set expires(expires) {
    this.__expires.set(expires);
  }
}

/**
 * Core Auth
 * @export
 * @instanceof CoreAuthService
 * @implements {CoreAuthServiceShape}
 */
const CoreAuth = new CoreAuthService();

export default CoreAuth;