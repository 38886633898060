import React, { FormEvent } from 'react';

import CoreFormMenuBase from './Base';
import { SELECT_ALL, DESELECT_ALL } from '../../../../constants/';

/**
 * Menu All Or None
 * @export
 * @class MenuAllOrNoneComponent
 * @extends {CoreFormMenuBase}
 */
export default class MenuAllOrNoneComponent extends CoreFormMenuBase {
  /**
   * Render
   * @returns
   * @memberof MenuAllOrNoneComponent
   */
  public render() {
    const { OnClick, context: { value, options, allornone, disabled } } = this;

    if (allornone === false) {
      return null;
    }

    /* istanbul ignore next */
    const label: string = value.length === options.length ? DESELECT_ALL : SELECT_ALL;
    return (
      <label className="all-or-none">
        <button type="button" disabled={disabled} onClick={OnClick}>{label}</button>
      </label>
    );
  }

  /**
   * On Click
   * @protected
   * @param {FormEvent<HTMLButtonElement>} evt
   * @memberof MenuAllOrNoneComponent
   */
  /* istanbul ignore next */
  protected OnClick = (evt: FormEvent<HTMLButtonElement>): void => {
    const { context: { ToggleAllOrNone }} = this;
    ToggleAllOrNone();
  };
}
