import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import CoreInteropContext from '../../Interop/context';
import { CoreHeaderToggleMask } from '../../Interop/enums/';
import { CoreNavigation, CoreNavigationModule } from '../../Navigation/';
import { HEADER_TOGGLE_STATE } from '../../Header/';
import { CoreHeaderToggleState } from '../../Header/interfaces/';

import { MergeClassNames } from '../../utils/';

/**
 * Aside Container
 * @export
 * @class CoreAsideContainer
 * @extends {Component<{}, CoreHeaderToggleState>}
 */
export default class CoreAsideContainer extends Component<{}, CoreHeaderToggleState> {
  /**
   * Context - System
   * @static
   * @type {Readonly<typeof CoreInteropContext>}
   * @memberof CoreAsideContainer
   */
  public static contextType: Readonly<typeof CoreInteropContext> = CoreInteropContext;

  /**
   * State
   * @type {CoreHeaderToggleState}
   * @memberof CoreAsideContainer
   */
  public readonly state: CoreHeaderToggleState = cloneDeep(HEADER_TOGGLE_STATE);

  /**
   * Subscriber
   * @private
   * @type {*}
   * @memberof CoreAsideContainer
   */
  private subscriber: Func<void> = null!;

  /**
   * LifeCycle Hook
   * @memberof CoreAsideContainer
   */
  public componentDidMount() {
    const { context } = this;
    this.subscriber = context.subscribe(this.AsideContainerListener);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreAsideContainer
   */
  public componentWillUnmount() {
    const { context, subscriber } = this;
    context.unsubscribe(subscriber);
  }

  /**
   * Render
   * @returns
   * @memberof CoreAsideContainer
   */
  public render() {
    const { header } = this.state;

    if (!CoreNavigation.populated) {
      return null;
    }

    const main = true;
    const active = header === CoreHeaderToggleMask.NAVIGATION;
    const clz = MergeClassNames('', { main, active });

    return (
      <aside className={clz}>
        <CoreNavigationModule />
      </aside>
    );
  }

  /**
   * Aside Container Listener
   * @protected
   * @memberof CoreAsideContainer
   */
  /* istanbul ignore next */
  protected AsideContainerListener = (): void => {
    const { state, context } = this;
    const { header } = context;
    if (header !== state.header) {
      this.setState({ header });
    }
  };
}
