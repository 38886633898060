/**
 * Footer Links Factory
 * @export
 * @param {number} page
 * @param {number} pages
 * @param {number} threshold
 * @returns {number[]}
 */
export const CoreFooterLinksFactory = (page: number, pages: number, threshold: number): number[] => {
  let lastPage = Math.floor(pages/threshold);
  let currentPage = Math.floor(page/threshold);
  let startOfPage = currentPage * threshold;
  let endThreshold = (currentPage === lastPage ) ? (pages -1) % threshold: threshold - 1;
  let endOfPage = (startOfPage + endThreshold);
  return Array.from({ length: endOfPage - startOfPage + 1}, (_, i) => startOfPage + 1 + i)
};

