import React, { Fragment } from 'react';

import CorePermissionsBase from './Base';

/**
 * Core Permissions Admin
 * @export
 * @class CorePermissionsAdmin
 * @extends {CorePermissionsBase<PureComponent<CorePermissionProps, {}>>}
 */
export default class CorePermissionsAdmin extends CorePermissionsBase {
  /**
   * Render
   * @returns
   * @memberof CorePermissionsAdmin
   */
  public render() {
    /* istanbul ignore next */
    const { service: { IsAdmin }, props: { children } } = this;
    /* istanbul ignore next */
    return IsAdmin ? <Fragment>{children}</Fragment> : null;
  }
}
