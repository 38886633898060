import React from 'react';

import CoreMenuComponentBase from './Base';

import { CoreHeaderMenusMask } from '../../Interop/enums/';
import { CoreToolsMenu } from '../containers/';

import { CoreAnchor } from '../../shared/';
import { MergeClassNames } from '../../utils/';

/**
 * SuperUser Menu Component
 * @export
 * @class CoreToolsMenuComponent
 * @extends {CoreMenuComponentBase}
 */
export default class CoreToolsMenuComponent extends CoreMenuComponentBase {
  /**
   * Creates an instance of CoreToolsMenuComponent.
   * @param {GenericPropType} props
   * @memberof CoreToolsMenuComponent
   */
  constructor(props: GenericPropType) {
    super(props);
    this.STATE = CoreHeaderMenusMask.TOOLS;
  }

  /**
   * Render
   * @returns
   * @memberof CoreToolsMenuComponent
   */
  public render() {
    const { OnMenuClick } = this;

    const tools = true;
    const active = this.STATE === this.state.value;
    const clz = MergeClassNames('', { tools, active });

    return (
      <li className={clz}>
        <CoreAnchor onClick={OnMenuClick}>SuperUser</CoreAnchor>
        <CoreToolsMenu />
      </li>
    );
  }
}
