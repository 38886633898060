import { ComponentClass } from 'react';

import InputBase from './Base';
import { CoreFormElementEmail } from '../../../components/';

/**
 * Email Input Controller
 * @export
 * @class EmailInputController
 * @extends {InputBase}
 */
export default class EmailInputController extends InputBase {
  /**
   * Controlled Element
   * @override
   * @static
   * @memberof EmailInputController
   */
  public static ControlledElement: ComponentClass<any> = CoreFormElementEmail;
}
