import { DocumentNode } from '@apollo/client';
import NotificationsClientProvider from './Provider';

import { GET_NOTIFICATIONS_UNREAD, POST_NOTIFICATIONS_MARK } from './models';

/**
 * Notifications GraphQL Static
 * @class CoreNotificationsService
 */
class CoreNotificationsGraphStatic {
  /**
   * Provider
   * @static
   * @type {*}
   * @memberof CoreNotificationsGraphStatic
   */
  public static Provider: any = NotificationsClientProvider;
  /**
   * Query
   * @static
   * @type {DocumentNode}
   * @memberof CoreNotificationsGraphStatic
   */
  public static NOTIFICATIONS_QUERY: DocumentNode = GET_NOTIFICATIONS_UNREAD;
  /**
   * Mutation
   * @static
   * @type {DocumentNode}
   * @memberof CoreNotificationsGraphStatic
   */
  public static NOTIFICATIONS_MUTATION: DocumentNode = POST_NOTIFICATIONS_MARK;
  /**
   * View
   * @static
   * @type {string}
   * @memberof CoreNotificationsGraphStatic
   */
   public static VIEW: string = null;
  /**
   * All
   * @static
   * @type {string}
   * @memberof CoreNotificationsGraphStatic
   */
  public static ALL: string = null;
  /**
   * Interval
   * @static
   * @type {number}
   * @memberof CoreNotificationsGraphStatic
   */
  public static INTERVAL: number = 0;

  /**
   * Test Mode
   * @static
   * @type {boolean}
   * @memberof CoreNotificationsGraphStatic
   */
  public static TEST_MODE: boolean = false;

  /**
   * Count Path
   * @static
   * @type {string}
   * @memberof CoreNotificationsGraphStatic
   */
  public static COUNT_PATH: string = "count";

  /**
   * Results Path
   * @static
   * @type {string}
   * @memberof CoreNotificationsGraphStatic
   */
  public static RESULTS_PATH: string = "results";

  /**
   * Mutation Variables Resolver
   * @static
   * @type {Function}
   * @memberof CoreNotificationsGraphStatic
   */
  public static MUTATION_VARIABLES_RESOLVER: Func<HashMap<any>> = ()=>(undefined);
}

/**
 * With Core Notifications
 * @param {CoreGraphProviderClassType} Provider
 * @param {DocumentNode} QUERY
 * @param {DocumentNode} MUTATION
 * @param {number} [INTERVAL=0]
 * @param {string} [VIEW=null]
 * @param {string} [ALL=null]
 * @param {string} [COUNT_PATH="count"]
 * @param {string} [RESULTS_PATH="notifications"]
 * @param {Function} [MUTATION_VARIABLES_RESOLVER=()=>(undefined)]
 * @param {boolean} [TEST_MODE=false]
 */
function WithCoreNotifications(
  Provider: any,
  QUERY: DocumentNode,
  MUTATION: DocumentNode,
  INTERVAL: number = 0,
  VIEW: string = null,
  ALL: string = null,
  COUNT_PATH: string = "count",
  RESULTS_PATH: string = "notifications",
  MUTATION_VARIABLES_RESOLVER: Func<HashMap<any>> = ()=>(undefined),
  TEST_MODE: boolean = false,
): void {
  CoreNotificationsGraphStatic.Provider = Provider;
  CoreNotificationsGraphStatic.NOTIFICATIONS_QUERY = QUERY;
  CoreNotificationsGraphStatic.NOTIFICATIONS_MUTATION = MUTATION;
  CoreNotificationsGraphStatic.INTERVAL = INTERVAL;
  CoreNotificationsGraphStatic.VIEW = VIEW;
  CoreNotificationsGraphStatic.ALL = ALL;
  CoreNotificationsGraphStatic.COUNT_PATH = COUNT_PATH;
  CoreNotificationsGraphStatic.RESULTS_PATH = RESULTS_PATH;
  CoreNotificationsGraphStatic.MUTATION_VARIABLES_RESOLVER = MUTATION_VARIABLES_RESOLVER;
  CoreNotificationsGraphStatic.TEST_MODE = TEST_MODE;

}

export { WithCoreNotifications };
export default CoreNotificationsGraphStatic;
