export const SAVE_PROPS = {
  label: 'Save',
  type: 'submit'
};

export const RESET_PROPS = {
  label: 'Reset',
  type: 'reset'
};

export const SAVE_GROUP_PROPS = {
  reset: RESET_PROPS.label,
  save: SAVE_PROPS.label
};

