import { ApolloClient, NetworkStatus } from '@apollo/client';

import { IsNull } from '../../../../utils/';

/**
 * Graph CRUD Client Transform
 * @export
 * @param {*} graph
 * @return {*} {CoreGraphResultType}
 */
export function GraphCrudClientTransform(graph: any = null): CoreGraphResultType {
  try {
    if (IsNull(graph)) {
      throw new Error('graph is null.');
    }

    if ('client' in graph && 'open' in graph && 'close' in graph) {
      graph = graph.client;
    }

    // allow conversion of raw client to CoreGraphResultType
    if ((graph instanceof ApolloClient) && !('variables' in graph)) {
      const client: CoreGraphClientType = graph;
      graph = {
        client: client,
        variables: {},
        data: null,
        error: null,
        loading: true,
        networkStatus: NetworkStatus.ready,
        refetch: null,
        startPolling: null,
        stopPolling: null,
        observable: null,
        subscribeToMore: null, 
        updateQuery: null,
        reobserve: null, 
        fetchMore: null,
        called: false,
      };
    }

    return graph;
  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR::CoreGraphCRUDService ${err.message || err}.`);
    }
    return err;
  }
}