import React, { PureComponent } from 'react';

import CoreConfigContext from '../../Config/context';

/**
 * Logo Component
 * @export
 * @class CoreLogoComponent
 * @extends {PureComponent<{}, {}>}
 */
export default class CoreLogoComponent extends PureComponent<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreConfigContext>}
   * @memberof CoreLogoComponent
   */
  public static contextType: Readonly<typeof CoreConfigContext> = CoreConfigContext;

  /**
   * Render
   * @returns
   * @memberof CoreLogoComponent
   */
  public render() {
    const { context: { header: { logo, product_name } } } = this;
    return <img className="logo" src={`data:image;base64,${logo}`} alt={product_name} />;
  }
}
