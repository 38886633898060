import { INPUT_TYPE_TEXT, INPUT_TYPE_BUTTON } from './config';

export const CONTROLLER_LABEL_PROPS = {
  type: INPUT_TYPE_TEXT,
  label: null,
  title: null,
  dirty: false,
  htmlFor: null,
  className: '',
  disabled: false
}

export const INPUT_LABEL_PROPS = {
  type: INPUT_TYPE_BUTTON,
  htmlFor: null,
  className: ''
}

export const INPUT_KBD_PROPS = {
  value: null
}
