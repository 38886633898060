import { set, clone, has } from 'lodash';
import { IsNumeric, IsNumber, IsHashMap, IsEmpty, IsFunction } from './checks';

/**
 * Objects Equal
 * @export
 * @param {*} a
 * @param {*} b
 * @returns {boolean}
 */
export const ObjectsEqual = (a: HashMap<any>, b: HashMap<any>): boolean => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * Object Keys
 * @export
 * @param {*} src
 * @returns {*}
 */
export const ObjectKeys = (src: any, def: any = []): any => {
  if (typeof src !== 'object' || src === null) {
    return def;
  }
  const keys: any[] = Object.keys(src);
  return keys.length ? keys : def;
};

/**
 * HashMap Props as Values
 * @export
 * @param {HashMap<any>} src
 * @returns {HashMap<any>}
 */
export const HashMapPropsAsValues = (src: HashMap<any>): HashMap<any> => {
  src = clone(src);
  for (const prop in src) {
    src[prop] = prop;
  }
  return src;
};

/**
 * HashMap Value By Index
 * @export
 * @param {HashMap<any>} src
 * @param {number} [ndx=0]
 * @returns {HashMap<any>}
 */
export const HashMapValueByIndex = (src: HashMap<any>, ndx: number = 0): HashMap<any> => {
  try {
    const keys: any[] = Object.keys(src);
    if (!keys[ndx]) {
      throw new ReferenceError(`hashmap has no index at ${ndx}`);
    }
    return src[keys[ndx]];
  } catch (err: any) {
    console.error(err);
  }
};

/**
 * Hex To RGBA
 * @export
 * @param {string} hex
 * @param {number} [alpha=1]
 * @returns {HashMap<any>}
 */
export const HexToRGBA = (hex: string, alpha: number = 1): HashMap<any> => {
  const p = (s: string) => parseInt(s, 16);
  const rx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(rx, (m, r, g, b) => r + r + g + g + b + b);
  const rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return rgb ? { red: p(rgb[1]), green: p(rgb[2]), blue: p(rgb[3]), alpha } : null;
};

/**
 * Get Deep HashMap
 * @export
 * @param {HashMap<any>} src
 * @param {string} dots
 * @param {any} [def=null]
 * @param {boolean} [strict=false]
 * @returns {*}
 */
export const GetDeepHashMap = (src: HashMap<any>, dots: string, def: any = null, strict: boolean = false): any => {
  try {
    const args = dots.split('.');
    let prop;

    while (args.length) {
      if (!src) {
        break;
      }

      prop = args.shift();
      if (IsNumeric(prop) && !Array.isArray(src)) {
        throw new TypeError(`src at property [${prop}] is not an array.`);
      }
      if (!(prop in src)) {
        throw new TypeError(`property [${prop}] not in src.`);
      }
      src = src[prop];
    }

    if (def && strict && IsEmpty(src)) {
      throw new TypeError(`return of empty not allowed with strict mode.`);
    }

    return src;
  } catch (err: any) {
    return def;
  }
};

/**
 * Update Deep HashMap
 * @export
 * @param {HashMap<any>} src
 * @param {string} dots
 * @param {*} value
 * @returns {*}
 */
export const UpdateDeepHashMap = (src: HashMap<any>, dots: string, value: any): HashMap<any> => {
  return set<HashMap<any>>(src, dots.split('.'), clone(value));
};

/**
 * Delete Deep HashMap
 * @export
 * @param {HashMap<any>} src
 * @param {string} dots
 * @returns {boolean}
 */
export const DeleteDeepHashMap = (src: HashMap<any>, dots: string): boolean => {
  try {
    const args = dots.split('.');
    let prop;

    while (args.length) {
      /* istanbul ignore next */
      if (!src) {
        break;
      }

      prop = args.shift();

      if (IsNumeric(prop)) {
        if (!Array.isArray(src)) {
          /* istanbul ignore next */
          throw new TypeError(`src at property [${prop}] is not an array.`);
        }
        if (!args.length) {
          src.splice(prop, 1);
          return true;
        }
      }

      /* istanbul ignore next */
      if (!(prop in src)) {
        throw new TypeError(`property [${prop}] not in src.`);
      }

      if (args.length) {
        src = src[prop];
      } else {
         /* istanbul ignore if */
        if (Array.isArray(src)) {
          src.splice(prop, 1);
        } else {
          delete src[prop];
        }
      }
    }
  
    return true;
  } catch (err: any) {
    /* istanbul ignore next */
    return false;
  }
};

/**
 * Has Deep HashMap
 * @export
 * @param {HashMap<any>} src
 * @param {string} dots
 * @returns {boolean}
 */
export const HasDeepHashMap = (src: HashMap<any>, dots: string): boolean => {
  return has<HashMap<any>>(src, dots);
};

/**
 * HashMap From Dots
 * @export
 * @param {string} dots
 * @param {*} value
 * @returns {HashMap<any>}
 */
export const HashMapFromDots = (dots: string, value: any): HashMap<any> => {
  return set<HashMap<any>>({}, dots.split('.'), value);
};

/**
 * Map To HashMap
 * @export
 * @param {Map<any, any>} src
 * @returns {HashMap<any>}
 */
export const MapToHashMap = (src: Map<any, any>): HashMap<any> => {
  const hm: HashMap<any> = {};
  for (const [prop, value] of src.entries()) {
    hm[prop] = value;
  }
  return hm;
};

/**
 * Arguments To HashMap
 * @export
 * @param {Map<any, any>} src
 * @returns {HashMap<any>}
 */
export const ArgumentsToHashMap = (args: any[]): HashMap<any> => {
  try {
    return args.reduce((d: HashMap<any>, r: HashMap<any>) => {
      /* istanbul ignore next */
      if (!IsHashMap(r)) {
        throw new TypeError(`argument is not a hashmap.`);
      }
      d = Object.assign(d, r);
      return d;
    }, {});
  } catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.warn(`DEVELOPER ERROR:: ${err.message||err}`);
    }
  }
};

/**
 * Search To HashMap
 * @export
 * @param {string} search
 * @param {boolean} [raw=false]
 * @return {*}  {T}
 */
export const SearchToHashMap = <T = HashMap<any>>(search: string, raw: boolean = false): T => {
  return search
    .slice(1)
    .split('&')
    .reduce((d: T, r: any) => {
      r = r.split('=');
      d[r[0]] = raw ? r[1] : decodeURIComponent(r[1]);
      return d;
    }, {} as T);
};

/**
 * String Hash To HashMap
 * @export
 * @template T
 * @param {string} hash
 * @return {*}  {T}
 */
export const StringHashToHashMap = <T = HashMap<any>>(hash: string): T => {
  return decodeURI(hash)
    .slice(1)
    .split('&')
    .reduce((d: T, r: string): T => {
      const p: string[] = r.split('=');
      d[p[0]] = IsNumeric(p[1]) ? Number(p[1]) : p[1];
      return d;
    }, {} as T);
};

/**
 * String To HashMap
 * @export
 * @param {string} [src='']
 * @param {HashMap<boolean>} [source={}]
 * @returns {HashMap<boolean>}
 */
export const StringToHashMap = (src: string = '', source: HashMap<boolean> = {}): HashMap<boolean> => {
  return src
    .trim()
    .split(' ')
    .reduce((d: HashMap<boolean>, s: string): HashMap<boolean> => {
      if (s) {
        s = s.trim();
        d[s] = true;
      }
      return d;
    }, source);
};

/**
 * Find Prop In HashMap
 * @export
 * @param {HashMap<any} src
 * @param {prop} prop
 * @returns {HashMap<boolean>}
 */
export const FindPropInHashMap = (src: HashMap<any>, prop: string): any => {
  if (prop in src) {
    return src[prop];
  }
  let value: any = null;
  for (const k in src) {
    if (src.hasOwnProperty(k) && IsHashMap(src[k])) {
      value = FindPropInHashMap(src[k], prop);
      if (value) {
        return value;
      }
    }
  }
  return value;
};

/**
 * HashMap Merge Props
 * @export
 * @template T
 * @param {...any[]} args
 * @return {*} {T}
 */
export function HashMapMergeProps<T = any>(...args: any[]): T {
  return args.reduce((d: any, r: any) => {
    return {...d, ...r};
  }, {});
}

/**
 * Flatten HashMap
 * @export
 * @param {HashMap<any} src
 * @param {string[]} segments
 * @returns {HashMap<boolean>}
 */
export const FlattenHashMap = (src: HashMap<any>, segments: string[] = []): HashMap<any> => {
  try {
    return Object.keys(src).reduce((accumulator: HashMap<any>, prop: string) => {
      let next: any;
      let key: string;
      let value: any;

      /* istanbul ignore next */
      if (IsFunction(src[prop])) {
        throw new TypeError(`You may not apply a function as HashMap property [${segments.concat([prop]).join('.')}]`);
      }
      if (IsHashMap(src[prop])) {
        next = FlattenHashMap(src[prop], segments.concat([prop]));
      } else if (Array.isArray(src[prop])) {
        /* istanbul ignore else */
        if (src[prop].length) {
          next = FlattenHashMap(src[prop], segments.concat([prop]));
        }
        else {
          key = segments.concat([prop]).join('.');
          value = src[prop];
          next = { [key]: value };
        }
      } 
      else {
        /* istanbul ignore next */
        if (IsNumber(prop)) {
          key = segments.join('.');
          value = src;
        } else {
          key = segments.concat([prop]).join('.');
          value = src[prop];
        }
        next = { [key]: value };
      }
      return {...accumulator, ...next};
    }, {});
  }
  catch (err: any) {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production') {
      console.error(`DEVELOPER ERROR:: ${err.message||err}`);
    }
    /* istanbul ignore next */
    return null;
  }
};
