/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './core-ui.scss';

import App from './App';
import { CoreBootLoader } from '@neustar/core-ui';
import { AuthPlugin, PermissionsPlugin, HeaderPlugin, 
  // NotificationsPlugin, 
  NavigationPlugin, SitePlugin, FusionPlugin, NuxPlugin
  // TestPlugin 
} from './Plugins/';

import reportWebVitals from './reportWebVitals';

CoreBootLoader(App, AuthPlugin, PermissionsPlugin, NavigationPlugin, SitePlugin, HeaderPlugin, 
  // NotificationsPlugin, 
  FusionPlugin, NuxPlugin);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((...args: any[]) => {
  console.log('web-vitals', args);
});