import { CorePermissionSimpleService } from '../services';
import { CorePermissionSimpleServiceShape } from '../interfaces';

/**
 * Permission Simple Instance
 * @export
 * @param {string} type
 * @returns {CorePermissionsServiceShape}
 */
export function CorePermissionSimpleInstance(type: string): CorePermissionSimpleServiceShape {
  const service = new CorePermissionSimpleService();
  service.type = type;
  service.init();
  return service;
}
