import { PureComponent } from 'react';

import CoreFormControllerContext from '../../contexts/Controller';
import { CoreFormEventsEnum as E } from '../../enums/';

/**
 * Controller Child Base
 * @export
 * @abstract
 * @class ControllerChildBase
 * @extends {PureComponent<P extends GenericPropType, {}>}
 * @template P
 */
export default abstract class ControllerChildBase<P extends GenericPropType> extends PureComponent<P, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreFormControllerContext>}
   * @memberof ControllerChildBase
   */
  public static contextType: Readonly<typeof CoreFormControllerContext> = CoreFormControllerContext;

  /**
   * Listener - AbortController
   * @private
   * @type {AbortController}
   * @memberof ControllerChildBase
   */
  private _listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof ControllerChildBase
   */
  public componentDidMount() {
    const { ControllerEventListener, context } = this;
    context.emitter.on(E.CONTROLLER_EVENT_UPDATE, ControllerEventListener);
    this._listener = new AbortController();
  }

  /**
   * LifeCycle Hook
   * @memberof ControllerChildBase
   */
  public componentWillUnmount() {
    const { ControllerEventListener, context, listener } = this;
    context.emitter.off(E.CONTROLLER_EVENT_UPDATE, ControllerEventListener);
    listener.abort();
  }

  /**
   * Controller Event Listener
   * @protected
   * @param {...any[]} args
   * @memberof ControllerChildBase
   */
  /* istanbul ignore next */
  protected ControllerEventListener = (...args: any[]): void => {
    const { listener } = this;
    if (!listener.signal.aborted) {
      this.forceUpdate();
    }
  };

  /**
   * Listener - getter
   * @readonly
   * @memberof ControllerChildBase
   */
  public get listener() {
    return this._listener;
  }
}
