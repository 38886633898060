import { DEFAULT_ROUTE, UAID, FUSION_ENDPOINT, FUSION_TIMEOUT, DEFAULT_PDF_HEADER_IMAGE, DEFAULT_CONFIG_META_STORAGE_NAME } from '../constants/';
import { CoreConfigAppShape } from '../interfaces/';

/**
 * Config App Service
 * @export
 * @class CoreConfigApp
 */
export class CoreConfigApp implements CoreConfigAppShape {
  public static DEFAULT_ROUTE: string = DEFAULT_ROUTE;
  public static UAID: string = UAID;
  public static FUSION_ENDPOINT: string = FUSION_ENDPOINT;
  public static FUSION_TIMEOUT: number = FUSION_TIMEOUT;
  public static PDF_HEADER_IMAGE: string = DEFAULT_PDF_HEADER_IMAGE;
  public static CONFIG_META_STORAGE_NAME: string = DEFAULT_CONFIG_META_STORAGE_NAME;

  public get default_route() {
    return CoreConfigApp.DEFAULT_ROUTE;
  }
  public get uaid() {
    return CoreConfigApp.UAID;
  }
  public get fusion_endpoint() {
    return CoreConfigApp.FUSION_ENDPOINT;
  }

  public get fusion_timeout() {
    return CoreConfigApp.FUSION_TIMEOUT;
  }

  public get pdf_header_image() {
    return CoreConfigApp.PDF_HEADER_IMAGE;
  }  
  
  public get config_meta_storage_name() {
    return CoreConfigApp.CONFIG_META_STORAGE_NAME;
  }  
}
